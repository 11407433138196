import React from 'react';
import { colors } from "common/styles/configLayout.js";
import { makeStyles } from "@material-ui/core/styles";
import StatusesEnum from 'common/enums/StatusesEnum';

const useStyles = makeStyles({
    readOnlyGrid: {
        borderRadius: "50px",
        padding: "4px 16px",
        fontSize: "0.9em"
    }
});

const StatusTextField = (props) => {
    const { intl, status, type } = props
    const classes = useStyles();

    let typeText = (!type) ? 'status' : `${type}.status`
    const getColors = (status) => {
        if (status === StatusesEnum.AWAITING || status === StatusesEnum.READY || status === StatusesEnum.PARTIAL_READY || status === StatusesEnum.PAID) {
            if (status === StatusesEnum.AWAITING && type === "transfersAndHosting") return { backgroundColor: colors.primaryAlpha, color: colors.primaryHover }
            return { backgroundColor: colors.warningAlpha, color: colors.warningHover }
        } else if (status === StatusesEnum.APPROVED || status === StatusesEnum.COMPLETED || status === StatusesEnum.SUCCEEDED || status === StatusesEnum.UPLOADED || status === StatusesEnum.ADMIN_UPLOADED)
            return { backgroundColor: colors.successAlpha, color: colors.successHover }
        else if (status === StatusesEnum.REJECTED || status === StatusesEnum.REMOVED || status === StatusesEnum.CANCELED || status === StatusesEnum.EMPTY || status === StatusesEnum.NOT_LICENSED)
            return { backgroundColor: colors.errorAlpha, color: colors.errorHover }
        else if (status === StatusesEnum.RETURNED || status === StatusesEnum.PENDING)
            return { backgroundColor: colors.primaryAlpha, color: colors.primaryHover }
        else if (status === StatusesEnum.DOWNLOADED)
            return { backgroundColor: colors.secondaryAlpha, color: colors.secondaryHover }
        else
            return { backgroundColor: colors.disabledAlpha, color: colors.disabledHover }
    }

    return (
        <span className={classes.readOnlyGrid} style={{ backgroundColor: getColors(status).backgroundColor, margin: "1px" }}>
            <span variant="body2" style={{ color: getColors(status).color, fontSize: "1em" }}>{intl.formatMessage({ id: `${typeText}.${status}` })}</span>
        </span>
    )
}

export default StatusTextField;

//112