import { colors, parameters } from "common/styles/configLayout.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    inputContainer: {
        display: "-ms-flexbox", /* IE10 */
        display: "flex",
        width: "100%",
    },
    selectDropdown: { color: colors.textBody, backgroundColor: colors.backgroundReadInputs },
    selectIcon: {
        color: colors.labelAndBorderInputs,
        "&:hover": {
            color: colors.textBody,
        }
    },
    menuItem: {
        fontSize: '0.9em',
        paddingTop: '1px',
        paddingBottom: '1px',
        "&:hover": {
            backgroundColor: colors.tableWidgetsHover
        }
    },

    icon: {
        padding: "8px 10px 2px 5px",
        color: colors.labelAndBorderInputs,
        cursor: 'pointer',
        //minWidth: "50px",
        textAlign: "center",
        '&:hover': {
            color: colors.textBody,
        },
    },

    iconHover: {
        '&:hover': {
            color: colors.textBody,
        },
    },

    inputField: {
        width: "100%",
        outline: "none"
    },
    input: {
        width: "100%",
        outline: "none"
    },

    /*buttons: {
        display: "none",
    },*/

    activityBox: {
        cursor: 'pointer',
        margin: '5px',
        borderRadius: '4px',
        backgroundColor: colors.backgroundWidgets,
        '&:hover': {
            backgroundColor: colors.tableWidgetsHover,
        },
    },
    activityBoxWithoutHover: {
        //cursor: 'not-allowed',
        margin: '5px',
        borderRadius: '4px',
        backgroundColor: colors.backgroundWidgets,
    },
    paginationBullet: {
        //borderTop: `1px solid ${colors.textBody}`,
        paddingLeft: 0,
        color: colors.textBody,
    },
    paginationBulletActive: {
        background: "purple",
    },
    inputSelectColor: {
        color: colors.textBody,
        fontSize: "0.9em",
    },
    tableRow: {
        '&:hover': {
            backgroundColor: `rgba(${colors.sidebarButtons}, 0.2)`,
        },
        readOnlyGrid: {
            backgroundColor: 'rgba(60,72,88, 0.4)',
            borderRadius: "4px",
            padding: "4px 14px",
            //margin: "2px 0px 2px 0px"
        }
    },

    select: {
        "& ul": {
            backgroundColor: colors.backgroundWidgets,
            color: colors.textBody,

        },
        "& li": {
            margin: 5,
            fontSize: 12,
        },
    },
    label: {
        backgroundColor: "white"
    },
    inputLabelNoShrink: {
        marginTop: "-5.3px",
        '& label.Mui-focused': {
            marginTop: "80px",

        }
    },

    inputLabelNoShrinkAndFocused: {
        marginTop: 0,
        //backgroundColor: 'red'
    },


    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    disabledInput: {
        "& .MuiInputBase-root.Mui-disabled": {
            borderColor: 'red',
            color: colors.textBody,
        },
        '& label': {
            color: colors.textBody,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
    },

    actionsPanel: {
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                borderColor: 'transparent'
            }
        },
    },

    root: {
        borderRadius: parameters.inputsRounded,
        color: colors.textBody,
        fontSize: "0.9em",
        zIndex: 1000,
        margin: 0,
        // margin: "0px 0px",

        "& .MuiTablePagination-select": {
            backgroundColor: colors.tableWidgetsHover,
            borderRadius: '10px',
            border: `0.3px solid ${colors.textBody}`,
        },
        "& .MuiTablePagination-actions": {
            color: colors.textBody,
            "& .MuiSvgIcon-root": {

                '&:hover': {
                    backgroundColor: colors.tableWidgetsHover,
                    //margin: '100px',
                    borderRadius: '50%',
                },
            },
        },
        "& .MuiPaper-root": {
            //color: colors.textBody,
            backgroundColor: colors.backgroundReadInputs,
            color: colors.textBody,
            borderRadius: '15px'
        },
        "& .materialui-daterange-picker-makeStyles-contrast-34": {
            color: colors.primaryHover,
        },

        "& .MuiTypography-colorTextPrimary": {
            color: colors.textBody,
        },
        "& .MuiTypography-colorTextSecondary": {
            color: colors.labelAndBorderInputs,
        },

        "& .materialui-daterange-picker-makeStyles-filled-32": {
            backgroundColor: colors.primaryAlpha
        },
        "& .materialui-daterange-picker-makeStyles-outlined-31": {
            border: `1px solid ${colors.primaryAlpha}`
        },

        "& .materialui-daterange-picker-makeStyles-highlighted-33": {
            backgroundColor: colors.primaryAlpha,
        },

        "& .MuiSvgIcon-colorAction": {
            color: 'colors.textBody',
        },

        "& .MuiSelect-select.MuiSelect-select": {
            color: colors.textBody,
        },

        "& .MuiGrid-root": {
            //color: colors.textBody,
            //backgroundColor: colors.backgroundReadInputs,
            //borderRadius: '15px'
        },


        "& .MuiInputBase-root.Mui-disabled": {
            color: colors.textBody,
            borderColor: 'red'
        },


        '& label.Mui-disabled': {
            color: colors.textBody,
        },

        '& input.Mui-disabled': {
            color: colors.textBody,
        },

        '& label': {
            //marginLeft: '-5px',
            color: colors.textBody,
            zIndex: 1001,
            //backgroundColor: colors.backgroundWidgets,
            //padding: '0 3px',
            //borderRadius: '50%',
            //paddingTop: "12px"

        },
        '& label.Mui-focused': {
            color: colors.primary,
            //paddingTop: "8px",

        },

        '& .MuiInput-underline:after': {
            borderBottomColor: colors.primary,
        },
        '& input': {
            //marginLeft: '1px',
            //fontSize: '0.8',
            color: colors.textBody,
            zIndex: 1000,
            "&:-webkit-autofill": {
                //WebkitBoxShadow: `0 0 0 1000px rgb(22, 28, 36) inset`,
                WebkitBoxShadow: `0 0 0 1000px #1f6b86 inset`,
                //WebkitBoxShadow: `0 0 0 1000px ${colors.backgroundWidgets} inset`,
                // backgroundColor: 'red !important',
                WebkitTextFillColor: 'white !important',
                //fontSize: 12
            },
            '&::placeholder': {
                color: colors.textBody,
                fontSize: '0.8em',
                opacity: 0.7
            },

        },

        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: "#4F5D73",
                borderRadius: parameters.inputsRounded,
                backgroundColor: colors.backgroundReadInputs,
                zIndex: -100
            },
            '&:hover fieldset': {
                borderColor: 'white',
                '&:disabled': {
                    borderColor: 'red'
                },
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.primary,
            },
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.textBody,
        },


        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                borderColor: "rgba(255, 255, 255, 0.3)",
            },
            '&:hover fieldset': {
                borderColor: "rgba(255, 255, 255, 0.3)",

            },
            '&.Mui-focused fieldset': {
                borderColor: "rgba(255, 255, 255, 0.3)",
            },
        },
    },

});
export default useStyles;
