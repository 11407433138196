import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import { injectIntl } from 'react-intl';

const PageHeader = ({ withBackButton = true, title = '', actions = [], handleBackAction = () => { }, subTitle = '', intl, tabs }) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const actionsArray = (actions) ? actions.length : 0

    const baseFreeCols = withBackButton ? 11 : 12;
    const baseFreeColsLg = withBackButton ? 11 : 12;

    const actionsGridSizeXl = actionsArray;
    const titleGridSizeXl = baseFreeCols - actionsArray;

    const actionsGridSizeLg = actionsArray + 2;
    const titleGridSizeLg = baseFreeColsLg - (actionsArray + 2);

    const actionsGridSizeMd = actionsArray + 3;
    const titleGridSizeMd = baseFreeCols - (actionsArray + 3);


    return (
        <Grid container spacing={3} style={{ marginBottom: "-26px" }}>
            {!title ? <>
                {withBackButton && (
                    <Grid item lg={1} md={2} sm={2} xs={4} style={{ marginTop: "-13px", marginLeft: "-10px" }}>
                        <Button size="sm" color="facebook" round onClick={(e) => handleBackAction()}>
                            <ArrowBackIcon className={classes.backIcon} />
                            {intl.formatMessage({ id: 'pageHeader.back' })}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={10} >{tabs}</Grid>
                <Grid item xs={2} >
                    {actions.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                            {actions.map((action, index) => action.component
                                ?
                                action.component
                                :
                                <Button
                                    disabled={action.disabled}
                                    key={index}
                                    size="sm"
                                    round
                                    color={action.color || 'info'}
                                    onClick={action.onClick}
                                >
                                    <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                </Button>
                            )}
                        </div>
                    )}
                </Grid>
            </>
                : <>
                    <Grid item xs={10} ><Typography variant="h5" className={classes.title}>{title}</Typography>
                        {subTitle && (
                            <Typography variant="body2" className={classes.subTitle}>{subTitle}</Typography>
                        )}</Grid>
                    <Grid item xs={2} >
                        {actions.length > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                {actions.map((action, index) => action.component
                                    ?
                                    action.component
                                    :
                                    <Button
                                        disabled={action.disabled}
                                        key={index}
                                        size="sm"
                                        round
                                        color={action.color || 'info'}
                                        onClick={action.onClick}
                                    >
                                        <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                    </Button>
                                )}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} >{tabs}</Grid>
                </>
            }
        </Grid>


    )


    /*return (
        <div className={classes.pageHeaderWrapper} style={{zIndex: 10000}}>
            <Grid container spacing={3}>
                {withBackButton && (
                    <Grid item lg={1} md={2} sm={2} xs={4} style={{marginTop: "-13px", marginLeft: "-10px"}}>
                        <Button size="sm" color="facebook" round onClick={(e) => handleBackAction()}>
                            <ArrowBackIcon className={classes.backIcon} />
                            {intl.formatMessage({ id: 'pageHeader.back' })}
                        </Button>
                    </Grid>
                )}
                <Grid
                    item
                    xs={titleGridSizeXl}
                    lg={titleGridSizeLg}
                    md={titleGridSizeMd}
                    sm={9}
                >
                    <Typography variant="h5" className={classes.title}>{title}</Typography>
                    {subTitle && (
                        <p className={classes.subTitle}>{subTitle}</p>
                    )}
                </Grid>
                {actions.length > 0 && (
                    <Grid
                        item
                        xs={actionsGridSizeXl}
                        lg={actionsGridSizeLg}
                        md={actionsGridSizeMd}
                        sm={12}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {actions.map((action, index) => action.component
                                ?
                                action.component
                                :
                                <Button
                                    key={index}
                                    size="sm"
                                    round
                                    color={action.color || 'info'}
                                    onClick={action.onClick}
                                >
                                    <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                </Button>
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    )*/
};

export default injectIntl(PageHeader);