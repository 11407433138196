const StatusesEnum = {
    AWAITING: 'Awaiting',
    READY: 'Ready',
    PARTIAL_READY: 'PartialReady',
    APPROVED: "Approved",
    COMPLETED: "Completed",
    SUCCEEDED: "Succeeded",
    UPLOADED: "Uploaded",
    ADMIN_UPLOADED: "AdminUploaded",
    REJECTED: 'Rejected',
    REMOVED: 'Removed',
    CANCELED: "Canceled",
    EMPTY: 'Empty',
    NOT_LICENSED: 'NotLicensed',   
    EXPIRED: "Expired",
    PAID: "Paid",
    DOWNLOADED: "Downloaded",
    PENDING: 'Pending',
    RETURNED: 'Returned'

}
export default StatusesEnum;