import { colors } from "common/styles/configLayout.js";

const pageHeaderStyles = (theme) => ({
    backIcon: {
        left: '-8px',
    },
    title: {
        marginLeft: '6px',
        //fontSize: '20px',
        color: colors.textBody
    },
    pageHeaderWrapper: {
        //marginBottom: theme.spacing(4)
        marginBottom: "-35px",
    },
    subTitle: {
        marginLeft: '6px',
        fontSize: '14px',
        color: colors.labelAndBorderInputs
    }
});

export default pageHeaderStyles;
