import gql from 'graphql-tag';

const CLUB_REGISTER_REQUEST_UPDATE= gql`
mutation clubRegisterRequestUpdate($id: ID! $input: ClubRegisterRequestInput!) { 
  clubRegisterRequestUpdate(id: $id input: $input) {
    id
    bid
    type
    status
    createdAt
  }
} 
`;

export default CLUB_REGISTER_REQUEST_UPDATE;