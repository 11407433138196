import gql from 'graphql-tag';

const CLUB_TRANSFER_REQUEST_APPROVE= gql`
mutation clubTransferRequestApprove($id:ID! $input: ClubTransferRequestUpdateInput!) { 
  clubTransferRequestApprove(id: $id input: $input) {
    id
    bid
   }
} 
`;

export default CLUB_TRANSFER_REQUEST_APPROVE;