import gql from 'graphql-tag';

const MEMBER_LICENSE_REQUEST_CONFIRM = gql`
mutation memberLicenseRequestConfirm($id: ID!) {
	memberLicenseRequestConfirm (id: $id) {
		id
    bid
  }
}
`;

export default MEMBER_LICENSE_REQUEST_CONFIRM;