import gql from 'graphql-tag';

const CLUB_TRANSFER_REQUEST_REJECT= gql`
mutation clubTransferRequestReject($id:ID!) { 
  clubTransferRequestReject(id: $id) {
    id
    bid
   }
} 
`;

export default CLUB_TRANSFER_REQUEST_REJECT;