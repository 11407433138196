import gql from 'graphql-tag';

const MEMBER_LICENSE_REGENERATE = gql`
mutation memberLicenseRegenerate($ids: [ID!]!) {
  memberLicenseRegenerate(ids: $ids)
}
`;

export default MEMBER_LICENSE_REGENERATE;

