import React from 'react';
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { styled } from '@material-ui/core/styles';
import { colors } from 'common/styles/configLayout';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

export const AntTabs = styled(Tabs)({
    marginTop: "-12px",
    '& .MuiTabs-indicator': {
        backgroundColor: colors.primary,
    },
});
export const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    //minWidth: 72,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    //fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: colors.textBody,
    
    '&:hover': {
        color: 'white',
        opacity: 1,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));
export const StyledTableCell = withStyles((theme) => ({
    head: {
        color: colors.textBody,
        borderBottomColor: colors.strokeColor,
        padding: "5px 15px"
    },
    body: {
        backgroundColor: 'transparent',
        color: colors.textBody,
        borderBottomColor: colors.strokeColor,
        //borderBottom: "none",
        fontSize: 12.5,
        paddingTop: "7px",
        paddingBottom: "7px"
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:hover': {
            backgroundColor: `rgba(${colors.sidebarButtons}, 0.2)`,
        },
        //paddingTop: "-20px",
        //paddingBottom: "-20px",
    },
}))(TableRow);