import gql from 'graphql-tag';

export const DOCUMENTS_COUNT = gql`
query documentsTable($filter: [DocumentsTableFilterInput]) {
  documentsTable (filter: $filter) {
      totalCount
  }
}
`;
export const MEMBER_LICENSE_REQUESTS_COUNT = gql`
query memberLicenseRequestsTable($filter: [MemberLicenseRequestsTableFilterInput]) {
  memberLicenseRequestsTable (filter: $filter) {
      totalCount,
  }
}
`;
