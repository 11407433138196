import gql from 'graphql-tag';

const REQUESTS_TABLE= gql`
query requestsTable($offset: Int, $limit: Int, $sort: [RequestsTableSortInput!], $filter: [RequestsTableFilterInput]) {
  requestsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
  			id
        bid
        type
        status
        createdAt
        updatedAt
        deletedAt
        createdById
        createdBy
      }
  }
}
`;

export default REQUESTS_TABLE;