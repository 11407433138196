import React, { useState, createRef } from 'react';
import Grid from '@material-ui/core/Grid';
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { connect } from 'react-redux';
import Button from "components/CustomButtons/Button.js";
import Modal from "components-lib/Modal/Modal";
import defaultImage from "assets/img/image_placeholder.jpg";
import { getAxiosClient } from 'configFiles/axios'
import PROFILE_UPDATE from 'queries/ProfileQueries/profileUpdate'
import CLUB_MEMBER_UPDATE from 'queries/MembersQueries/clubMemberUpdate'
import PERSON_PROFILE_UPDATE_REQUEST_APPROVE from 'queries/RequestsQueries/personProfileUpdateRequestApprove';
import PERSON_PROFILE_UPDATE_REQUEST_CANCEL from 'queries/RequestsQueries/personProfileUpdateRequestCancel';
import PERSON_PROFILE_UPDATE_REQUEST_REJECT from 'queries/RequestsQueries/personProfileUpdateRequestReject';
import Typography from "@material-ui/core/Typography";
import { colors } from "common/styles/configLayout.js";
import { changePendingRequestsNumberAction } from 'redux/actions';
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import { getApolloClient } from "configFiles/apollo";
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import RequestStatusesEnum from 'common/enums/RequestStatusesEnum';
import RoleEnum from 'common/enums/RoleEnum';
import { pathOr } from 'rambda';
import { changeUserProfileAction } from 'redux/actions';
import PROFILE from 'queries/ProfileQueries/profile';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';

const AvatarCard = (props) => {
    const { intl, history, role, photo, photoRequest, refetch, memberId, requestId, changePendingRequestsNumberAction, changeUserProfileAction } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [modalOpen, setModalOpen] = useState(false);
    const [control, setControl] = useState(false);
    const [controlPhoto, setControlPhoto] = useState(null);
    const [blob, setBlob] = useState(null);
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const [profileUpdate] = useMutation(PROFILE_UPDATE);
    const [clubMemberUpdate] = useMutation(CLUB_MEMBER_UPDATE);
    const [personProfileUpdateRequestApprove] = useMutation(PERSON_PROFILE_UPDATE_REQUEST_APPROVE);
    const [personProfileUpdateRequestCancel] = useMutation(PERSON_PROFILE_UPDATE_REQUEST_CANCEL);
    const [personProfileUpdateRequestReject] = useMutation(PERSON_PROFILE_UPDATE_REQUEST_REJECT);

    const getUserProfileAction = async () => {
        try {
            const result = await client.query({
                query: PROFILE,
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changeUserProfileAction(pathOr('', ['data'], result));

        } catch (error) {
            throw error;
        }
    };

    const getPendingRequestsNumber = async () => {
        try {
            const result = await client.query({
                query: REQUESTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "eq": (role === RoleEnum.ADMIN) ? RequestStatusesEnum.AWAITING : RequestStatusesEnum.RETURNED
                            },
                            "type": {
                                "notIn": [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING]
                            },
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changePendingRequestsNumberAction(pathOr('', ['data', 'requestsTable', 'totalCount'], result))
        } catch (error) {
            throw error;
        }
    }

    const handleProfilePhotoUpload = () => {
        if (memberId) {
            clubMemberUpdate({
                variables: {
                    id: memberId,
                    input: {
                        person: {
                            photo: (controlPhoto) ? controlPhoto : undefined
                        },
                    }
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: "member.avatarCard.snackbar.successUploadPhoto" }), { variant: 'success' });
                refetch();
            }).catch((err) => {
                console.log('[Error]: ', err);
            });
        } else {
            profileUpdate({
                variables: {
                    input: {
                        photo: (controlPhoto) ? controlPhoto : undefined
                    }
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: "profile.snackbar.success" }), { variant: 'success' });
                refetch();
                getPendingRequestsNumber();
                getUserProfileAction();
            }).catch((err) => {
                console.log('[Error]: ', err);
            });
        }
        setModalOpen(false)
        setControl(false);
        setControlPhoto(null)
        setBlob(null)

    }

    let fileInput = createRef();


    const handleImageChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            handleControlPhotoUpload(file)
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleClick = () => {
        fileInput.current.click();
    };


    const handleControlPhotoUpload = async (file) => {
        const formData = new FormData();
        formData.append('photo', file);
        const response = await getAxiosClient().post("/person/profile/photo/preview", file, { responseType: 'blob' });
        setBlob(URL.createObjectURL(response.data))
        setControl(true);
        setControlPhoto(file)
        enqueueSnackbar(intl.formatMessage({ id: "member.avatarCard.snackbar.successControlPhoto" }), { variant: 'success' });
    }

    const handlePersonProfileUpdateRequestApprove = () => {
        personProfileUpdateRequestApprove({
            variables: {
                id: requestId,
                input: {
                    profile: {
                        photo: (controlPhoto) ? controlPhoto : undefined
                    }
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "member.avatarCard.snackbar.successApprovedPhoto" }), { variant: 'success' });
            refetch();
            getPendingRequestsNumber();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const handlePersonProfileUpdateRequestCancel = () => {
        personProfileUpdateRequestCancel({
            variables: {
                id: requestId
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "member.avatarCard.snackbar.successCanceledPhoto" }), { variant: 'success' });
            refetch();
            getPendingRequestsNumber();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });

    }

    const handlePersonProfileUpdateRequestReject = () => {
        personProfileUpdateRequestReject({
            variables: {
                id: requestId
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "member.avatarCard.snackbar.successCanceledPhoto" }), { variant: 'success' });
            refetch();
            getPendingRequestsNumber();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });

    }

    const renderModalButtons = () => {
        return (
            <>
                <Button
                    disabled={!control}
                    onClick={() => handleProfilePhotoUpload()}
                    color="primary"
                    table
                    round
                    size="sm">
                    <CheckIcon /> {intl.formatMessage({ id: 'button.saveChanges' })}
                </Button>
            </>
        );
    };

    return (<>
        <IconCard background={photoRequest && colors.warningBackground}>
            <Grid container spacing={1} style={{ padding: '8px 4px 2px 4px' }}>
                {photoRequest &&
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{ color: colors.warning }}>{photo
                            ? intl.formatMessage({ id: 'member.avatarCard.change.title' })
                            : intl.formatMessage({ id: 'member.avatarCard.approve.title' })}
                        </Typography>
                    </Grid>
                }
                <Grid container justify="center" align="center" >
                    <>
                        {(photoRequest && !photo) ? "" :
                            <Grid item xs={(photoRequest && photo) ? 6 : 12} align="center">
                                <div className="fileinput text-center">
                                    <div className={"thumbnail"}>
                                        {photoRequest && <Typography variant="body2" style={{ color: colors.warning }}>{intl.formatMessage({ id: 'member.avatarCard.actual.title' })}</Typography>}
                                        <img src={(photo) ? photo : defaultImage} />
                                    </div>
                                </div>
                            </Grid>
                        }
                        {photoRequest &&
                            <Grid item xs={(photoRequest && !photo) ? 12 : 6}>
                                <span className="fileinput text-center">
                                    <span className={"thumbnail"}>
                                        <Typography variant="body2" style={{ color: colors.primary, paddingTop: "16px", paddingBottom: "3px" }}>{intl.formatMessage({ id: 'member.avatarCard.new.title' })}</Typography>
                                        <img src={(photoRequest) ? photoRequest : defaultImage} />
                                    </span>
                                </span>
                            </Grid>
                        }
                    </>
                    {(role === RoleEnum.ADMIN) && <>
                        {photoRequest
                            ? <>
                                <Button
                                    disabled={false}
                                    onClick={() => handlePersonProfileUpdateRequestApprove()}
                                    color="primary"
                                    table
                                    round
                                    size="sm">
                                    <CheckIcon /> {intl.formatMessage({ id: 'member.avatarCard.button.approve' })}
                                </Button>
                                <Button
                                    disabled={false}
                                    onClick={() => handlePersonProfileUpdateRequestReject()}
                                    color="danger"
                                    table
                                    round
                                    size="sm">
                                    <BlockIcon /> {intl.formatMessage({ id: 'member.avatarCard.button.reject' })}
                                </Button>
                            </>
                            :
                            <Button
                                disabled={false}
                                onClick={() => setModalOpen(true)}
                                color="primary"
                                table
                                round
                                size="sm">
                                <PhotoCameraIcon /> {(photo) ? "Zmeniť foto" : "Nahrať foto"}
                            </Button>
                        }
                    </>
                    }
                    {(role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) && <>
                        {photoRequest
                            ? <>
                                <Button
                                    disabled={false}
                                    onClick={() => handlePersonProfileUpdateRequestCancel(true)}
                                    color="primary"
                                    table
                                    round
                                    size="sm">
                                    <BlockIcon />  {intl.formatMessage({ id: 'member.avatarCard.button.cancel' })}
                                </Button>
                            </>
                            :
                            <Button
                                disabled={false}
                                onClick={() => setModalOpen(true)}
                                table
                                color="primary"
                                round
                                size="sm">
                                <PhotoCameraIcon /> {(photo) ? intl.formatMessage({ id: 'member.avatarCard.button.change' }) : intl.formatMessage({ id: 'member.avatarCard.button.upload' })}
                            </Button>
                        }
                    </>
                    }
                    {(role === RoleEnum.USER) && <>
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{ color: colors.labelAndBorderInputs, fontSize: "0.8em" }}>{intl.formatMessage({ id: 'member.avatarCard.notChange.body' })}</Typography>
                        </Grid>
                    </>
                    }
                </Grid>
            </Grid>
        </IconCard >
        <Modal
            title={intl.formatMessage({ id: 'member.avatarCard.change.title' })}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            actions={renderModalButtons()}
            fullWidth
        >
            <Grid container spacing={1} justify="center">
                <Grid container spacing={1} justify="center">
                    <span className="fileinput">
                        <input type="file" onChange={handleImageChange} ref={fileInput} />
                        <span className={"thumbnail"}>
                            <img src={(blob) ? blob : (photo) ? photo : defaultImage} />
                        </span>
                    </span>
                </Grid>
                {(role !== RoleEnum.MEMBER) &&
                    <Button
                        disabled={false}
                        onClick={() => handleClick()}
                        color="primary"
                        table
                        round
                        size="sm">
                        <PhotoCameraIcon /> {intl.formatMessage({ id: "member.avatarCard.button.upload" })}
                    </Button>
                }
            </Grid>
        </Modal>
    </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changePendingRequestsNumberAction: (value) => dispatch(changePendingRequestsNumberAction(value)),
    changeUserProfileAction: (value) => dispatch(changeUserProfileAction(value)),

});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AvatarCard));
