import gql from 'graphql-tag';

const MEMBER_LICENSE_REQUEST_CREATE = gql`
mutation memberLicenseRequestCreate($input: MemberLicenseRequestInput!) {
	memberLicenseRequestCreate (input: $input) {
  id
  bid
  }
}
`;

export default MEMBER_LICENSE_REQUEST_CREATE;