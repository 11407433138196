import gql from 'graphql-tag';

const CLUB_REGISTER_REQUEST_APPROVE= gql`
mutation clubRegisterRequestApprove($id: ID! $input: ClubRegisterRequestInput!) { 
  clubRegisterRequestApprove(id: $id input: $input) {
    id
    bid
    type
    status
    createdAt
  }
} 
`;

export default CLUB_REGISTER_REQUEST_APPROVE;