import gql from 'graphql-tag';

const REQUESTS_TABLE_BY_CLUB = (id) => gql`
query requestsTableByClub($offset: Int, $limit: Int, $sort: [RequestsTableSortInput!], $filter: [RequestsTableFilterInput]) {
  requestsTableByClub (clubId: ${id}, offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
  			id
        bid
        type
        status
        createdAt
        updatedAt
        deletedAt
        createdById
        createdBy
      }
  }
}
`;

export default REQUESTS_TABLE_BY_CLUB;