import gql from 'graphql-tag';

const DOCUMENT_APPROVE= gql`
mutation documentApprove ($id:ID!,$input: DocumentApproveInput) {
  documentApprove(id:$id, input: $input) {
  id
  }
}
`;

export default DOCUMENT_APPROVE;