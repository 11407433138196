import gql from 'graphql-tag';

const CLUB_HOSTING_REQUEST_CREATE= gql`
mutation clubHostingRequestCreate($input: ClubHostingRequestCreateInput!) { 
  clubHostingRequestCreate(input: $input) {
    id
    bid
   }
} 
`;

export default CLUB_HOSTING_REQUEST_CREATE;