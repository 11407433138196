import gql from 'graphql-tag';

const CLUBS_PUBLIC_TABLE= gql`
query clubsPublicTable($offset: Int, $limit: Int, $sort: [ClubsPublicTableSortInput!], $filter: [ClubsPublicTableFilterInput]) {
	clubsPublicTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
		totalCount,
		items {
				team
		  organization
		  ico
		  dic
		  email
		  address
		  city
		  postal
		  statutory
		  chairman
		}
	}
  }
`;

export default CLUBS_PUBLIC_TABLE;