import gql from 'graphql-tag';

const REQUEST = gql`
query request($id: ID!) {
  request(id: $id) {
      id
    bid
    type
    status
    createdAt
    updatedAt
    deletedAt
    data {
    ... on ClubRegisterRequest {
        profile {
          name
          email
          site
          social
        businessName
        logoUrl
        legalForm
        ico
        dic
        icDPH
        registrationNumber
        registrationDate
        address1
        address2
        postal
        city
        country
        district
        correspondenceAddress1
        correspondenceAddress2
        correspondencePostal
        correspondenceCity
        correspondenceCountry
        bankName
        bankIBAN
        bankSWIFT
        bankLocation
        useCorrespondenceAddress
      }
      club {
        id
        bid
      }
      documents {
          id
        bid
        type
        validFrom
        validTo
        status
        name
        files {
            size
          format
          downloadUrl
        }
        downloadUrl
      }
      functionary {
        isYouthWorker
        profile {
          email
          firstName
          lastName
          phone
          address1
          address2
          postal
          city
          country
        }
      }
    }
    ... on ClubUpdateRequest {
        profile {
          name
        email
        site
        social
        businessName
        logoUrl
        legalForm
        ico
        dic
        icDPH
        registrationNumber
        registrationDate
        address1
        address2
        postal
        city
        country
        district
        correspondenceAddress1
        correspondenceAddress2
        correspondencePostal
        correspondenceCity
        correspondenceCountry
        correspondenceDistrict
        bankName
        bankIBAN
        bankSWIFT
        bankLocation
        useCorrespondenceAddress
      }
      club {
        id
        bid
      }
      documents {
          id
        bid
        type
        status
        validFrom
        validTo
        name
        files {
          size
        format
        downloadUrl
      }
       }
      }
   	... on PersonProfileUpdateRequest {
      person {
        id
        member{
          id
        }
      }
    }
    }
      reviews {
        description
        createdAt
        person {
          id
          profile {
            firstName
            lastName
            photoUrl
          }
      }
    }
    createdBy {
        id
      bid
    }
  }
}

`;

export default REQUEST;