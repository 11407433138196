import gql from 'graphql-tag';

const CLUB_MEMBERS_PUBLIC_TABLE= gql`
query clubMembersPublicTable($offset: Int, $limit: Int, $sort: [ClubMembersPublicTableSortInput!], $filter: [ClubMembersPublicTableFilterInput]) {
	clubMembersPublicTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
		totalCount,
		items {
		  federationId
		  uciId
		  country
		  firstName
		  lastName
		  year
		  gender
		  team
		  nationalCategory
		  uciCategory
		  licensedAt
		}
	}
  }
`;

export default CLUB_MEMBERS_PUBLIC_TABLE;