import gql from 'graphql-tag';

const CLUB_DOCUMENT_UPLOAD= gql`
mutation clubDocumentUpload($clubId: ID!, $documents: [ClubDocumentUploadInput!]!) { 
  clubDocumentUpload(clubId: $clubId, documents: $documents) { 
      id 
      bid 
      type 
      status 
      name 
      createdAt 
      updatedAt 
      deletedAt 
      files { 
          size 
          format 
      } 
  } 
}
`;

export default CLUB_DOCUMENT_UPLOAD;