import React, { useState, useEffect } from 'react';
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import DocumentsTable from 'pages/Documents/DocumentTables/DocumentsTable';
import ProfileDocumentsEnum from 'common/enums/ProfileDocumentsEnum';
import CLUB_MEMBER_DOCUMENTS_TABLE_BY_CLUB from 'queries/DocumentsQueries/clubMemberDocumentsTableByMember';
import CLUB_MEMBER_DOCUMENT_UPLOAD from 'queries/DocumentsQueries/clubMemberDocumentUpload';
import CLUB_MEMBER_DOCUMENT_DELETE from 'queries/DocumentsQueries/clubMemberDocumentDelete';
import PROFILE_DOCUMENTS_TABLE from 'queries/DocumentsQueries/profileDocumentsTable';
import PROFILE_DOCUMENT_UPLOAD from 'queries/DocumentsQueries/profileDocumentUpload';
import PROFILE_DOCUMENT_DELETE from 'queries/DocumentsQueries/profileDocumentDelete';

const DocumentsCard = (props) => {
    const { intl, formikProps, memberId = false } = props;
    const [loadDocuments, { data: dataDocuments, refetch: refetchDocuments }] = useLazyQuery(PROFILE_DOCUMENTS_TABLE);
    const [loadMemberDocuments, { data: dataMemberDocuments, refetch: refetchMemberDocuments }] = useLazyQuery(CLUB_MEMBER_DOCUMENTS_TABLE_BY_CLUB, { variables: { memberId: memberId } });
    const [documentOptions, setDocumentOptions] = useState({})
    useEffect(() => {
        let options = [];
        options = Object.values(ProfileDocumentsEnum).map((item) => (
            {
                value: item,
                label: intl.formatMessage({ id: `documents.profile.${item}` })
            }
        ));
        setDocumentOptions(options);
    }, []);

    return (
        <IconCard>
            <DocumentsTable
                data={(memberId) ? dataMemberDocuments : dataDocuments}
                loadData={(memberId) ? loadMemberDocuments : loadDocuments}
                refetch={(memberId) ? refetchMemberDocuments : refetchDocuments}
                formikProps={formikProps}
                queryDataPath={(memberId) ? ['clubMemberDocumentsTableByMember', 'items'] : ['profileDocumentsTable', 'items']}
                memberId={(memberId) ? memberId : null}
                documentOptions={documentOptions}
                documentUploadMutation={(memberId) ? CLUB_MEMBER_DOCUMENT_UPLOAD : PROFILE_DOCUMENT_UPLOAD}
                documentDeleteMutation={(memberId) ? CLUB_MEMBER_DOCUMENT_DELETE : PROFILE_DOCUMENT_DELETE}
                parentId={undefined}
                documentType={"Profile"}
            />
        </IconCard >
    )
}

export default injectIntl(DocumentsCard);