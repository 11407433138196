import gql from 'graphql-tag';

const CLUB_HOSTING_REQUEST_APPROVE= gql`
mutation clubHostingRequestApprove($id:ID! $input: ClubHostingRequestUpdateInput!) { 
  clubHostingRequestApprove(id: $id input: $input) {
    id
    bid
   }
} 
`;

export default CLUB_HOSTING_REQUEST_APPROVE;