import gql from 'graphql-tag';

const CLUB_MEMBER_DOCUMENTS_TABLE_BY_CLUB= gql`
query clubMemberDocumentsTableByMember($memberId:ID!,$offset: Int, $limit: Int, $sort: [PersonDocumentsTableSortInput!], $filter: [PersonDocumentsTableFilterInput]) {
  clubMemberDocumentsTableByMember (memberId: $memberId, offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
         	id
        bid
        name
        type
        status
        name
        createdAt
        updatedAt
        deletedAt
        downloadUrl
        validFrom
        validTo
      }
  }
}
`;

export default CLUB_MEMBER_DOCUMENTS_TABLE_BY_CLUB;