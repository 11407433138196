import gql from 'graphql-tag';

const CLUB_TRANSFER_REQUEST_CANCEL= gql`
mutation clubTransferRequestCancel($id:ID!) { 
  clubTransferRequestCancel(id: $id) {
    id
    bid
   }
} 
`;

export default CLUB_TRANSFER_REQUEST_CANCEL;