import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import INVITATION_DETAILS from 'queries/InvitationsQueries/invitationDetails'
import { useQuery } from '@apollo/client';
import Loading from 'components-lib/Loading/Loading';
import RegisterInvitationPage from './RegisterInvitationPage';
import RegisterEmailPage from './RegisterEmailPage'
import { connect } from 'react-redux';

const useStyles = makeStyles(styles);

const RegisterPage = (props) => {
  const { intl, allLocations } = props;
  const classes = useStyles();

  var url_string = window.location.href
  var url = new URL(url_string);
  var token = url.searchParams.get("token");

  const { loading, data, refetch } = useQuery(INVITATION_DETAILS, { variables: { token: token }, skip: !token });

  const getContent = () => {
    if (token) {
      return <RegisterInvitationPage data={data} registrationType={data?.invitationDetails?.action?.type} token={token} />
    } else {
      return <RegisterEmailPage />
    }
  }

  const getInvalidContent = () => {
    return (
      <div className={classes.contentCenter}>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="h4" color="error">{intl.formatMessage({ id: 'activate.not.title' })}</Typography>
            <Typography variant="body1">{intl.formatMessage({ id: 'activate.not.title' })}</Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  if (loading) return <Loading />
  return (
    <>{(token && !data) ? getInvalidContent() : getContent()}</>
  );
}


const mapStateToProps = (state) => ({
  allLocations: state.allLocations,
  role: state.role,
});

export default connect(mapStateToProps)(withRouter(injectIntl(RegisterPage)));

