import React from 'react';
import { userIsLogged } from '../../helpers/helpers';
import { Route, Redirect } from 'react-router-dom';
import paths from '../../paths';
import { connect } from 'react-redux';
import { changeLastUrlAction } from 'redux/actions';

const PublicRouteQueries = (props) => {
    const { lastUrlString, changeLastUrlAction } = props;
    changeLastUrlAction(lastUrlString)
    const isLogged = userIsLogged();

    return  <Route exact {...props} />
};


const mapStateToProps = (state) => ({
    role: state.role,
    lastUrl: state.lastUrl,
    pendingRequestsNumber: state.pendingRequestsNumber
  });
  
  const mapDispatchToProps = (dispatch) => ({
    changeLastUrlAction: (value) => dispatch(changeLastUrlAction(value)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(PublicRouteQueries);