import React from "react";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import FormikField from "components-lib/FormikField/FormikField";
import Typography from '@material-ui/core/Typography';
import { colors, variables } from "common/styles/configLayout.js";
import { connect } from 'react-redux';

const InvitationFormData = (props) => {
  const { intl, formikProps, validateParam, allLocations } = props;

  return (<>
    <Grid item xs={12} lg={6}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ color: colors.primary }}>Osobné údaje</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormikField
            highlightChange={false}
            name="email"
            labelText={intl.formatMessage({ id: 'register.email' })}
            formikProps={formikProps}
            validateParam={validateParam}
            mandatory={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormikField
            name="firstName"
            labelText={intl.formatMessage({ id: 'personal.firstName' })}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="lastName"
            labelText={intl.formatMessage({ id: 'personal.lastName' })}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="preNominals"
            labelText="Tituly pred menom"
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="postNominals"
            labelText="Tituly za menom"
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} >
          <FormikField
            name="personalId"
            labelText="Rodné číslo"
            formikProps={formikProps}
            type="number"
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="birth"
            labelText="Dátum narodenia"
            formikProps={formikProps}
            type="datePicker"
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="countryOfBirth"
            labelText="Krajina narodenia"
            formikProps={formikProps}
            validateParam={validateParam}
            type="autocomplete"
            options={allLocations.countries}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="nationality"
            labelText="Štátna príslušnosť"
            formikProps={formikProps}
            validateParam={validateParam}
            type="autocomplete"
            options={allLocations.countries}
            highlightChange={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="gender"
            labelText="Pohlavie"
            formikProps={formikProps}
            validateParam={validateParam}
            type="select"
            options={[
              {
                value: 'Male',
                label: 'Muž'
              },
              {
                value: 'Female',
                label: 'Žena'
              },

            ]}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormikField
            name="phone"
            labelText="Telefón"
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
          />
        </Grid>
          <Grid item xs={12}>
            <FormikField
              name="isYouthWorker"
              labelText="Osoba pracuje s mládežou"
              formikProps={formikProps}
              validateParam={validateParam}
              type="select"
              options={[
                {
                  value: true,
                  label: 'Áno'
                },
                {
                  value: false,
                  label: 'Nie'
                },

              ]}
              highlightChange={false}
              mandatory={true}
            />
          </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} lg={6}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'profile.personalDataCard.address.title' })}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormikField
            name="address1"
            labelText={intl.formatMessage({ id: 'address.street' })}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormikField
            name="address2"
            labelText={intl.formatMessage({ id: 'address.streetNumber' })}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormikField
            name="postal"
            labelText={intl.formatMessage({ id: 'address.postal' })}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={8} >
          <FormikField
            name="city"
            disabled={!formikProps?.values?.country}
            labelText={intl.formatMessage({ id: 'address.city' })}
            type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
            options={(formikProps?.values?.country === variables.defaultCountry) ? allLocations.cities : []}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="country"
            labelText={intl.formatMessage({ id: 'address.country' })}
            type="autocomplete"
            options={allLocations.countries}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikField
            name="district"
            disabled={!formikProps?.values?.country}
            labelText="Okres"
            type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
            options={(formikProps?.values?.country === variables.defaultCountry) ? allLocations.districts : []}
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
            mandatory={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ color: colors.primary, paddingTop: "11px", paddingBottom: "5px" }}>Núdzový kontakt</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikField
            name="emergencyFirstName"
            labelText="Krstné meno"
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikField
            name="emergencyLastName"
            labelText="Priezvisko"
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikField
            name="emergencyPhone"
            labelText="Telefónne číslo"
            formikProps={formikProps}
            validateParam={validateParam}
            highlightChange={false}
          />
        </Grid>
      </Grid>
    </Grid>
  </>
  );
};

const mapStateToProps = (state) => ({
  allLocations: state.allLocations,
});

export default connect(mapStateToProps)(injectIntl(InvitationFormData));
