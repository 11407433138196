import gql from 'graphql-tag';

const DOCUMENTS_TABLE= gql`
query documentsTable($offset: Int, $limit: Int, $sort: [DocumentsTableSortInput!], $filter: [DocumentsTableFilterInput]) {
  documentsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
   			id
        bid
        type
        status
        name
        validFrom
        validTo
        createdAt
        updatedAt
        deletedAt
        createdById
        createdBy
        relation
        owner
        clubId
        personId
        files
        downloadUrl
      }
  }
}
`;

export default DOCUMENTS_TABLE;