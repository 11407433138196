export default function legalEntityFormEnum(intl) {
    return [
        { value: "SK100", label: intl.formatMessage({ id: 'legalForm.SK100' }) },
        { value: "SK101", label: intl.formatMessage({ id: 'legalForm.SK101' }) },
        { value: "SK102", label: intl.formatMessage({ id: 'legalForm.SK102' }) },
        { value: "SK103", label: intl.formatMessage({ id: 'legalForm.SK103' }) },
        { value: "SK104", label: intl.formatMessage({ id: 'legalForm.SK104' }) },
        { value: "SK105", label: intl.formatMessage({ id: 'legalForm.SK105' }) },
        { value: "SK106", label: intl.formatMessage({ id: 'legalForm.SK106' }) },
        { value: "SK107", label: intl.formatMessage({ id: 'legalForm.SK107' }) },
        { value: "SK108", label: intl.formatMessage({ id: 'legalForm.SK108' }) },
        { value: "SK109", label: intl.formatMessage({ id: 'legalForm.SK109' }) },
        { value: "SK110", label: intl.formatMessage({ id: 'legalForm.SK110' }) },
        { value: "SK111", label: intl.formatMessage({ id: 'legalForm.SK111' }) },
        { value: "SK112", label: intl.formatMessage({ id: 'legalForm.SK112' }) },
        { value: "SK113", label: intl.formatMessage({ id: 'legalForm.SK113' }) },
        { value: "SK117", label: intl.formatMessage({ id: 'legalForm.SK117' }) },
        { value: "SK118", label: intl.formatMessage({ id: 'legalForm.SK118' }) },
        { value: "SK119", label: intl.formatMessage({ id: 'legalForm.SK119' }) },
        { value: "SK121", label: intl.formatMessage({ id: 'legalForm.SK121' }) },
        { value: "SK122", label: intl.formatMessage({ id: 'legalForm.SK122' }) },
        { value: "SK123", label: intl.formatMessage({ id: 'legalForm.SK123' }) },
        { value: "SK124", label: intl.formatMessage({ id: 'legalForm.SK124' }) },
        { value: "SK205", label: intl.formatMessage({ id: 'legalForm.SK205' }) },
        { value: "SK271", label: intl.formatMessage({ id: 'legalForm.SK271' }) },
        { value: "SK272", label: intl.formatMessage({ id: 'legalForm.SK272' }) },
        { value: "SK301", label: intl.formatMessage({ id: 'legalForm.SK301' }) },
        { value: "SK311", label: intl.formatMessage({ id: 'legalForm.SK311' }) },
        { value: "SK312", label: intl.formatMessage({ id: 'legalForm.SK312' }) },
        { value: "SK321", label: intl.formatMessage({ id: 'legalForm.SK321' }) },
        { value: "SK331", label: intl.formatMessage({ id: 'legalForm.SK331' }) },
        { value: "SK381", label: intl.formatMessage({ id: 'legalForm.SK381' }) },
        { value: "SK382", label: intl.formatMessage({ id: 'legalForm.SK382' }) },
        { value: "SK383", label: intl.formatMessage({ id: 'legalForm.SK383' }) },
        { value: "SK421", label: intl.formatMessage({ id: 'legalForm.SK421' }) },
        { value: "SK422", label: intl.formatMessage({ id: 'legalForm.SK422' }) },
        { value: "SK433", label: intl.formatMessage({ id: 'legalForm.SK433' }) },
        { value: "SK434", label: intl.formatMessage({ id: 'legalForm.SK434' }) },
        { value: "SK445", label: intl.formatMessage({ id: 'legalForm.SK445' }) },
        { value: "SK701", label: intl.formatMessage({ id: 'legalForm.SK701' }) },
        { value: "SK711", label: intl.formatMessage({ id: 'legalForm.SK711' }) },
        { value: "SK721", label: intl.formatMessage({ id: 'legalForm.SK721' }) },
        { value: "SK741", label: intl.formatMessage({ id: 'legalForm.SK741' }) },
        { value: "SK745", label: intl.formatMessage({ id: 'legalForm.SK745' }) },
        { value: "SK751", label: intl.formatMessage({ id: 'legalForm.SK751' }) },
        { value: "SK752", label: intl.formatMessage({ id: 'legalForm.SK752' }) },
        { value: "SK801", label: intl.formatMessage({ id: 'legalForm.SK801' }) },
        { value: "SK803", label: intl.formatMessage({ id: 'legalForm.SK803' }) },
        { value: "SK804", label: intl.formatMessage({ id: 'legalForm.SK804' }) },
        { value: "SK901", label: intl.formatMessage({ id: 'legalForm.SK901' }) },
        { value: "SK911", label: intl.formatMessage({ id: 'legalForm.SK911' }) },
        { value: "SK921", label: intl.formatMessage({ id: 'legalForm.SK921' }) },
        { value: "SK931", label: intl.formatMessage({ id: 'legalForm.SK931' }) },
        { value: "SK951", label: intl.formatMessage({ id: 'legalForm.SK951' }) },
        { value: "SK995", label: intl.formatMessage({ id: 'legalForm.SK995' }) },
    ]
}

