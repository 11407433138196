import countries from './countries'
import { variables } from 'common/styles/configLayout'


const activities = {
    'activities.title': 'Aktivity',
    'activities.table.type': 'Typ aktivity',
    'activities.table.type.Membership': 'Členstvo',
    'activities.table.type.Payments': 'Platby',
    'activities.table.type.Licenses': 'Licencie',
    'activities.table.type.Documents': 'Dokumenty',
    'activities.table.type.Club': 'Kluby',
    'activities.table.type.ClubTransfer': 'Prestupy',
    'activities.table.type.ClubHosting': 'Hosťovania',
    'activities.table.club': 'Klub',
    'activities.table.person': 'Používateľ',
    'activities.table.activity': 'Aktivita',
}

const payments = {
    'payments.title': 'Platby',
    'payments.table.club': 'Klub',
    'payments.table.description': 'Účel',
    'payments.table.amount': 'Suma',
    'payments.table.variableSymbol': 'Variabilný s.',
    'payments.table.method': 'Forma úhrady',
    'payments.method.PaymentGateway': 'Platobná brána',
    'payments.method.BankTransfer': 'Bankový prevod',
    'payments.method.Cash': 'Hotovosť',
    'payments.table.status': 'Stav',
    'payments.table.createdAt': 'Vytvorená',
    'payments.table.paidAt': 'Uhradená',
    "payments.infoCard.title": "Informácie o transakcií",
    "payments.infoCard.amount": "Suma transakcie",
    "payments.infoCard.status": "Stav transakcie",
    "payments.infoCard.method": "Forma úhrady",
    "payments.infoCard.payBy": "Platca",
    "payments.infoCard.purpose": "Účel platby",
    "payments.infoCard.paymentId": "ID platby",
    'payments.status.Succeeded': 'uhradená',
    'payments.status.Pending': 'čaká na platbu',
    'payments.status.Canceled': 'zrušená',
    'payments.purpose.ClubLicense': 'Licencia klubu',
    'payments.purpose.MemberLicense': 'Licencie členov',
    'payments.purpose.ClubTransfer': 'Prestup člena',
    'payments.purpose.ClubHosting': 'Hosťovanie člena',
    'payments.snackbar.create.success': "Úhrada platby bola zadaná",
    'payments.create.button': 'Zadať platbu',
    'payments.online.button': 'Online platba',
    'payments.paymentCard.amount': 'Suma',
    'payments.paymentCard.bankIBAN': 'IBAN',
    'payments.paymentCard.variableSymbol': 'Variabilný symbol',
    'payments.paymentCard.createdAt': 'Dátum fakturácie',
    'payments.paymentCard.paidAt': 'Dátum úhrady',
    'payments.paymentCard.dueDate': 'Dátum splatnosti',
    'payments.detail.button': 'Detail platby',
    'payments.paymentCard.title': 'Platobné údaje',
    'payments.paymentCard.modal.title': "Zadať zrealitovanú platbu do systému",
    "payments.paymentCard.method": "Forma úhrady",
    "payments.paymentCard.paymentDate": "Dátum úhrady",
    'payments.paymentCard.bankTransfer': "Platba bankovým prevodom",
    'payments.paymentCard.cash': "Platba v hotovosti",
    'payments.licenseTableCard.title': 'Licenčné poplatky',
    'payments.licenseTableCard.type': 'Druh licencie',
    'payments.licenseTableCard.amount': 'Poplatok',
    'payments.licenseTableCard.sum': 'Spolu',
    'payments.licenseTableCard.count': 'Počet',
    'payments.licenseTableCard.standardClub': 'Štandardný klub',
    'payments.licenseTableCard.oneItem': '1',
    'payments.licenseTableCard.summary': 'Poplatky celkom',
    'payments.action.invoiceDownload': 'Stiahnuť faktúru',
    'payments.action.invoiceExport': 'Faktúry export',
    'payments.action.invoiceExport.button': 'Exportovať faktúry',
    'payments.modal.invoiceExport.file': 'exportFaktury',
    'payments.modal.invoiceExport.title': 'Export zoznamu faktúr',
    'payments.modal.invoiceExport.body': 'Zadajte obdobie počas ktorého chcete exportovať faktúry.',
    'payments.modal.invoiceExport.from': 'Obdobie od',
    'payments.modal.invoiceExport.to': 'Obdobie do',
}

const transfersAndHosting = {
    'transfers.action.new': 'Nový prestup',
    'hosting.action.new': 'Nové hosťovanie',
    'transfers.tab.title': 'Prestupy',
    'hosting.tab.title': 'Hosťovania',
    'transfers.transfer': 'prestup',
    'hosting.hosting': 'hosťovanie',
    'transfersAndHosting.table.member': 'Člen',
    'transfersAndHosting.table.sourceClub': 'Materský klub',
    'transfersAndHosting.table.targetClub': 'Cieľový klub',
    'transfersAndHosting.table.responses': 'Súhlasy',
    'transfersAndHosting.table.expiresIn': 'Spracovať do',
    'transfersAndHosting.status.Awaiting': 'čaká na zaplatenie',
    'transfersAndHosting.status.Paid': 'čaká na schválenie',
    'transfersAndHosting.status.Approved': 'schválená',
    'transfersAndHosting.status.Rejected': 'zamietnutá',
    'transfersAndHosting.status.Canceled': 'zrušená',
    'transfersAndHosting.status.Expired': 'expirovaná',
    'transfersAndHosting.status.null': 'čaká na vyjadrenie',
    'transfersAndHosting.responses.member': "Súhlas člena:",
    'transfersAndHosting.responses.sourceClub': "Súhlas štatutára materského klubu:",
    'transfersAndHosting.responses.targetClub': "Súhlas štatutára nového klubu:",
    'transfersAndHosting.responses.request': "Žiadosť",
    'transfers.snackbar.approve.success': "Žiadosť o prestup bola schválená",
    'hosting.snackbar.approve.success': "Žiadosť o hosťovanie bola schválená",
    'transfers.snackbar.reject.success': "Žiadosť o prestup bola zamietnutá",
    'hosting.snackbar.reject.success': "Žiadosť o hosťovanie bola zamietnutá",
    'transfers.snackbar.cancel.success': "Žiadosť o prestup bola zrušená",
    'hosting.snackbar.cancel.success': "Žiadosť o hosťovanie bola zrušená",
    'transfers.snackbar.create.success': "Žiadosť o prestup bola odoslaná",
    'hosting.snackbar.create.success': "Žiadosť o hosťovanie bola odoslaná",
    'transfersAndHosting.button.approve': "Schváliť žiadosť",
    'transfersAndHosting.button.reject': "Zamietnuť žiadosť",
    'transfersAndHosting.button.cancel': "Zrušiť žiadosť",
    'transfersAndHosting.button.create': "Odoslať žiadosť",
    'transfersAndHosting.new.title': "Žiadosť o {0}",
    'transfersAndHosting.detail.title': "Žiadosť o {0}",
    'transfersAndHosting.detail.approved.title': "Žiadosť o {0} bola schválená",
    'transfersAndHosting.detail.rejected.title': "Žiadosť o {0} bola zamietnutá",
    'transfersAndHosting.detail.canceled.title': "Žiadosť o {0} bola zrušená",
    'transfersAndHosting.detail.awaiting.title': "Žiadosť o {0} čaká na schválenie",
    'transfersAndHosting.detail.unpaid.title': "Žiadosť o {0} čaká na zaplatenie",
    'transfersAndHosting.detail.unexpressed.title': "Žiadosť o {0} čaká na vyjadrenie všetkých zúčastnených strán",
    'transfersAndHosting.detail.awaiting.infoPanel': "Žiadosť o {0} odsúhlasili všetky zúčastnené strany a čaká sa na jej schválenie administrátorom platformy Cyklopas.",
    'transfersAndHosting.detail.unpaid.infoPanel': "Žiadosť o {0} čaká na zaplatenie manipulačného poplatku štatutárom cieľového klubu.",
    'transfersAndHosting.detail.unexpressed.infoPanel': "Žiadosť o {0} čaká na vyjadrenie všetkých zúčastnených strán.",
    'transfersAndHosting.detail.body.infoPanel1': 'Žiadosť je platná do',
    'transfersAndHosting.detail.body.infoPanel2': '(ešte {0} dní)',
    'transfersAndHosting.modal.create.title': 'Vytvorenie žiadosti o',
    'transfersAndHosting.modal.create.body': 'Naozaj chcete vytvoriť žiadosť o',
    'transfersAndHosting.modal.reject.title': 'Zamietnutie žiadosti o',
    'transfersAndHosting.modal.reject.body': 'Naozaj chcete zamietnuť žiadosť o',
    'transfersAndHosting.modal.cancel.title': 'Zrušenie žiadosti o',
    'transfersAndHosting.modal.cancel.body': 'Naozaj chcete zrušiť žiadosť o',
    'transfersAndHosting.modal.agreement.title': 'Vyjadrenie súhlasu s',
    'transfersAndHosting.modal.agreement.body': 'Ak súhlasíte  s {0} člena, stlačte tlačidlo "Súhlasím s {0}".',
    'transfersAndHosting.modal.disagreement.title': 'Vyjadrenie nesúhlasu s',
    'transfersAndHosting.modal.disagreement.body': 'Ak nesúhlasíte  s {0} člena, stlačte tlačidlo "Nesúhlasím s {0}".',
    'transfersAndHosting.approvedCard.snackbar.agreement': 'Vyjadrili ste súhlas s {0} člena.',
    'transfersAndHosting.approvedCard.snackbar.disagreement': 'Vyjadrili ste nesúhlas s {0} člena.',
    'transfers.approvedCard.transfer': 'prestupom',
    'hosting.approvedCard.hosting': 'hosťovaním',
    'transfersAndHosting.approvedCard.modal.button.disagreement': 'Nesúhlasím s {0}',
    'transfersAndHosting.approvedCard.modal.button.agreement': 'Súhlasím s {0}',
    'transfersAndHosting.approvedCard.me.agree': "Súhlasím",
    'transfersAndHosting.approvedCard.me.disagree': "Nesúhlasím",
    'transfersAndHosting.approvedCard.me.waiting': "Čaká na vyjadrenie",
    'transfersAndHosting.approvedCard.agreement.member': 'Súhlas člena',
    'transfersAndHosting.approvedCard.agreement.sourceClub': 'Súhlas materského klubu',
    'transfersAndHosting.approvedCard.agreement.targetClub': 'Súhlas cieľového klubu',
    'transfersAndHosting.approvedCard.approve': 'Vyjadrenie',
    'transfersAndHosting.approvedCard.agree': "Súhlas",
    'transfersAndHosting.approvedCard.disagree': "Nesúhlas",
    'transfersAndHosting.approvedCard.date': 'Dátum vyjadrenia',
    'transfersAndHosting.approvedCard.name': 'Meno a priezvisko',
    'transfersAndHosting.clubCard.sourceClub': 'Materský klub',
    'transfersAndHosting.clubCard.targetClub': 'Cieľový klub',
    'transfersAndHosting.clubCard.name': 'Názov klubu',
    'transfersAndHosting.clubCard.address': 'Sídlo',
    'transfersAndHosting.clubCard.president': 'Predseda',
    'transfersAndHosting.dataCard.personalInfo': 'Osobné údaje',
    'transfersAndHosting.dataCard.IdMember': 'ID člena',
    'transfersAndHosting.dataCard.firstName': 'Meno',
    'transfersAndHosting.dataCard.lastName': 'Priezvisko',
    'transfersAndHosting.dataCard.birth': 'Dátum narodenia',
    'transfersAndHosting.dataCard.personalNumber': 'Osobné číslo',
    'transfersAndHosting.dataCard.uciId': 'UCI ID',
    'transfersAndHosting.dataCard.dateInfo': 'Dátum {0}',
    'transfersAndHosting.dataCard.transfer': 'prestupu',
    'transfersAndHosting.dataCard.hosting': 'hosťovania',
    'transfersAndHosting.dataCard.startDate': 'Dátum začiatku',
    'transfersAndHosting.dataCard.endDate': 'Dátum konca',
    'transfersAndHosting.clubFunctions': 'Klubové funkcie',
    'transfersAndHosting.clubFunction': 'klubová funkcia',
    'transfersAndHosting.idCard.dateInfo': 'Termín {0}',
    'transfersAndHosting.idCard.identifyMember': 'Identifikácia člena',
    'transfersAndHosting.idCard.selectMember': "Meno a priezvisko člena (zadajte aspoň 4 znaky)",
    'transfers.snackbar.withoutLicense': 'Nie je možné vykonať prestup, pretože Váš klub nie je licencovaný',
    'hosting.snackbar.withoutLicense': 'Nie je možné vykonať prestup, pretože Váš klub nie je licencovaný'
}

const profile = {
    'profile.title': 'Profil',
    'profile.tab.personalInformation': 'Osobné informácie',
    'profile.tab.club': 'Klub',
    'profile.tab.documents': 'Dokumenty',
    'profile.tab.changePassword': 'Zmena hesla',
    'profile.tab.licenses': 'Licencie',
    'profile.snackbar.success': 'Vaše profilové informácie boli aktualitované',
    'profile.personalDataCard.personalTitle': 'Osobné údaje',
    'profile.personalDataCard.idNumber': 'Rodné číslo',
    'profile.personalDataCard.birth': 'Dátum narodenia',
    'profile.personalDataCard.contactTitle': 'Kontaktné údaje',
    'profile.personalDataCard.addressTitle': 'Trvalý pobyt',
    'profile.personalDataCard.emergencyContactTitle': 'Núdzový kontakt',
    'profile.clubCard.cyclist': 'Pretekár',
    'profile.clubCard.president': 'Predseda',
    'profile.clubCard.button.detail': 'Detail klubu',
    'profile.clubCard.sportsTitle': 'Cyklistické športy',
    'profile.clubCard.licenseTitle': 'Licencia UCI',
    'profile.clubCard.licenseHistoryTitle': 'História licencií',
    'profile.sportsTable.sector': 'Odvetvie',
    'profile.sportsTable.category': 'Kategória',
    'profile.avatarCard.snackbar.success': 'Profilová foto bola nahratá',
    'profile.avatarCard.uploadButton': 'Nahrať foto',
    'profile.avatarCard.changeButton': 'Zmeniť',
    'profile.avatarCard.deleteButton': 'Odstrániť',
    'profile.personalDataCard.address.title': 'Trvalý pobyt',
    'profile.changePasswordCard.title': 'Zmena hesla',
    'profile.changePasswordCard.actualPassword': 'Zadajte pôvodné heslo',
    'profile.changePasswordCard.newPassword': 'Zadajte nové heslo',
    'profile.changePasswordCard.newPasswordVerify': 'Zopakujte nové heslo',
    'profile.changePasswordCard.button.change': 'Zmeniť heslo',
    'profile.changePasswordCard.success': 'Tvoje heslo bolo zmenené'
};

const members = {

    "member.action.button.statutoryInvite": "Pozvať štatutára",
    "member.action.button.memberInvite": "Pozvať člena",
    "member.action.button.insuranceExport": "Export poisťovňa",
    "member.action.button.refereeExport": "Export rozhodcovia",

    "member.detail.action.button.memberResume": "Obnoviť členstvo",
    "member.detail.action.button.memberSuspended": "Pozastaviť členstvo",
    "member.detail.action.button.passwordReset": "Resetovať heslo",
    "member.detail.action.button.invitationSend": "Odoslať pozvánku",
    "member.detail.action.button.changeClub": "Presun do klubu",
    "member.snackbar.success.memberResume": "Členstvo bolo obnovené.",
    "member.snackbar.success.memberSuspended": "Členstvo bolo pozastavené.",
    "member.snackbar.success.passwordReset": "Používateľovi bol na e-mail odoslaný odkaz na zmenu hesla.",
    "member.snackbar.success.invitationSend": "Odoslali ste osobe pozvánku do systému Cyklopas na email: {0}.",
    "member.snackbar.warning.invitationSend": "Nie je možné odoslať pozvánku pretože používateľ nemá zadaný email.",
    "member.snackbar.success.changeClub": "Člena ste presunuli do nového klubu.",
    "member.detail.modal.button.changeClub": "Presunúť do klubu",
    "member.detail.modal.changeClub.title": "Vyberte nový klub pre člena",
    "member.detail.modal.changeClub.body": "Zoznam všetkých klubov",
    "member.detail.modal.changeClub.clubId": "Vyberte klub",
    "member.snackbar.warning.discipline": "Pretekárovi musíte vybrať aspoň jedno odvetvie",
    "member.snackbar.warning.discipline": "Pretekárovi musíte vybrať aspoň jedno odvetvie",
    "member.snackbar.warning.functions": "Nemôžete osobe vybrať súčasne funkciu pretekár aj pretekár so zmluvou. Odstráňte jednu z funkcií a uložte zmeny",
    "member.snackbar.success.update": "Profil člena bol upravený",

    "member.insuranceExport.file": "exportPoisťovňa",
    "member.refereeExport.file": "exportRozhodcovia",
    "member.insuranceExport.button": "Exportovať",
    "member.insuranceExport.modal.title": "Export dát pre poisťovňu",
    "member.insuranceExport.modal.body": "Vyberte mesiac a rok, za ktorý chcete exportovať dáta o členoch pre poisťovňu.",
    "member.table.firstName": "Meno",
    "member.table.lastName": "Priezvisko",
    "member.table.club": "Klub",
    "member.table.functions": "Funkcia",
    "member.table.birth": "Narodený",
    "member.table.license": "Licencia",
    "member.table.disciplines": "Odvetvie",
    "member.table.registeredAt": "Registrácia",
    "member.table.modal.title": "Vytvorenie žiadosti o vydanie licencií",
    "member.table.modal.year": "Vyberte rok",


    "member.avatarCard.snackbar.successUploadPhoto": "Profilová fotografia člena bola upravená.",
    "member.avatarCard.snackbar.successControlPhoto": "Profilová fotografia bola upravená na požadovanú veľkosť, prosím skontrolujte ju a odošlite žiadosť.",
    "member.avatarCard.snackbar.successApprovedPhoto": "Profilová fotografia člena bola schválená.",
    "member.avatarCard.snackbar.successCanceledPhoto": "Žiadosť o zmenu profilovej fotografie člena bola zrušená",
    "member.avatarCard.snackbar.successRejectedPhoto": "Žiadosť o zmenu profilovej fotografie člena bola zamietnutá",
    "member.avatarCard.button.approve": "Schváliť",
    "member.avatarCard.button.reject": "Zamietnuť",
    "member.avatarCard.button.cancel": "Zrušiť žiadosť",
    "member.avatarCard.button.change": "Zmeniť foto",
    "member.avatarCard.button.upload": "Nahrať foto",
    'member.avatarCard.change.title': "Žiadosť o zmenu profilovej fotografie",
    'member.avatarCard.approve.title': "Žiadosť o schválenie profilovej fotografie",
    'member.avatarCard.actual.title': "Pôvodná fotografia",
    'member.avatarCard.new.title': "Nová fotografia",
    'member.avatarCard.notChange.body': '* Profilovú fotografiu je možné meniť iba členom klubov.',
    'member.clubCard.membership': "Členstvo",
    'member.clubCard.membership.stopped': "Pozastavené",
    'member.clubCard.membership.active': "Aktivne",
    'member.clubCard.bid': 'ID klubu',
    'member.clubCard.type': 'Typ klubu',
    'member.clubCard.clubMembers': 'Počet členov',
    'member.clubCard.hosting': 'Hosťovanie',
    'member.clubCard.hosting.active': 'Hosťovanie v klube: ',
    'member.clubCard.hosting.without': 'Žiadne aktívne hosťovanie',
    'member.clubCard.snackbar.warning': "Niektoré údaje na karte 'Osobné informácie' nemáte doplnené. Prosím skontrolujte si všetky povnné údaje.",
    'member.licenseCard.snackbar.successRegenerate': 'Údaje na licenčnej kartičke boli obnovené, zmeny sa prejavia do niekoľkých minút.',
    'member.licenseCard.history.title': 'História licencií',
    'member.licenseCard.history.without': 'Neobsahuje žiadne záznamy',
    'member.licenseCard.notActive': '- Neaktívna',
    'member.licenseCard.button.regenerate': 'Obnoviť údaje',
    'member.licenseCard.button.download': 'Stiahnuť PDF',
    'member.personalDataCard.uci.title': 'UCI a ISŠ registrácia',
    'member.personalDataCard.federationId': 'Osobné číslo',
    'member.personalDataCard.uciId': "UCI ID",
    'member.personalDataCard.issSyncedAt': "Posledná synchronizácia ISŠ",
    'member.personalDataCard.button.iss': 'Zápis do ISŠ',
    'member.personalDataCard.uciSyncedAt': "Posledná synchronizácia UCI",
    'member.personalDataCard.button.uci': "Zápis do UCI",
    'member.personalDataCard.snackbar.success.uci': 'Žiadosť o zápis člena do UCI prebehla úspešne',
    'member.personalDataCard.snackbar.success.iss': 'Žiadosť o zápis člena do ISŠ prebehla úspešne',
    "member.button.checkAll": "Označiť všetkých",
    "member.button.uncheckAll": "Zrušiť označenie",
    "member.button.requestLicense": "Požiadať o licenciu",
    "member.store1": "Člen",
    "member.store2": "Členovia",
    "member.store3": "Členov",
    "member.differentClubs": " (Rôzne kluby)",
}

const tables = {
    'table.bid': 'ID',
    'table.date': 'Dátum',
    'table.name': 'Názov',
    'table.year': 'Rok',
    'table.month': 'Mesiac',
    'table.phone': 'Telefón',
    'table.email': 'Email',
    'table.address': 'Adresa',
    'table.status': 'Stav',
    'table.actions': 'Akcie',
    'table.detail': 'Detail',
}

const publicPages = {
    'publicPages.members.title': "Verejný zoznam členov SZC",
    'publicPages.clubs.title': "Verejný zoznam klubov SZC",
    'publicPages.clubs.table.team': 'Cyklistický tím',
    'publicPages.clubs.table.organization': 'Právny subjekt',
    'publicPages.clubs.table.ico': "IČO",
    'publicPages.clubs.table.dic': "DIČ",
    'publicPages.clubs.table.address': "Ulica",
    'publicPages.clubs.table.city': "Mesto",
    'publicPages.clubs.table.postal': "PSČ",
    'publicPages.clubs.table.statutory': 'Štatutár',
    'publicPages.clubs.table.chairman': 'Predseda',
    'publicPages.members.table.federationId': 'Číslo licencie',
    'publicPages.members.table.uciId': 'UCI ID',
    'publicPages.members.table.country': "Kód krajiny",
    'publicPages.members.table.firstName': "Meno",
    'publicPages.members.table.lastName': "Priezvisko",
    'publicPages.members.table.year': "Rok narodenia",
    'publicPages.members.table.gender': "Pohlavie",
    'publicPages.members.table.team': 'Klub',
    'publicPages.members.table.nationalCategory': 'Národná kategória',
    'publicPages.members.table.uciCategory': 'UCI kategória',
    'publicPages.members.table.licensedAt': "Vystavenie licencie",
}





const clubs = {
    'clubs.title': 'Klub',
    'clubs.table.locality': 'Lokalita',
    'clubs.table.members': 'Členovia',
    'clubs.table.functionary': 'Predseda',
    'clubs.table.license': 'Licencie',
    'clubs.create.title': 'Vytvorenie klubu',
    'clubs.create.button': 'Vytvoriť klub',
    'clubs.snackbar.create.success': "Vytvorili ste nový klub",
    'clubs.snackbar.update.success': "Klubové informácie boli aktualizované",
    'clubs.detail.button.invite': 'Pozvať člena',
    'clubs.update.modal.button': 'Zmeniť údaje klubu',
    'clubs.update.invalidInput.info1': "Niektoré údaje na tejto karte, alebo na karte 'Právny subjekt' nemáte doplnené. Prosím skontrolujte si všetky povnné údaje",
    'clubs.update.invalidInput.info2': "Niektoré údaje na tejto karte, alebo na karte 'Základné informácie' nemáte doplnené. Prosím skontrolujte si všetky povnné údaje",
    'clubs.tab.clubCard': 'Základné informácie',
    'clubs.tab.legalEntityCard': 'Právny subjekt',
    'clubs.tab.documentsCard': 'Dokumenty',
    'clubs.tab.requestsCard': 'Žiadosti',
    'clubs.update.modal.title': "Aktualizácia údajov klubu",
    'clubs.update.modal.body': 'Naozaj chcete aktualizovať údaje klubu?',
    'clubs.update.snackbar.success': 'Zmena údajov klubu prebehla úspešne',


    'club.new.button': 'Nový klub',
    'club.request.title.new': 'Žiadosť o vytvorenie klubu',
    'club.request.title.waiting': 'Žiadosť o klub čaká na schválenie',
    'club.request.title.sendBack': 'Žiadosť o klub vrátená na doplnenie',
    'club.tab.clubCard': 'Základné informácie',
    'club.tab.legalEntityCard': 'Právny subjekt',
    'club.tab.statutoryCard': 'Štatutári',
    'club.tab.documentsCard': 'Dokumenty',
    'club.form.gdpr': `Záväzne prehlasujem a potvrdzujem, že všetky mnou uvedené údaje sú úplné a pravdivé a že som plne oprávnený/ná uvedené údaje poskytnúť na spracovanie a evidenciu Slovenskému zväzu cyklistiky. Zároveň prehlasujem, že som plne právne spôsobilý na vykonanie registrácie do systému ${variables.systemName}.`,
    'club.button.deleteRequest': 'Zrušiť žiadosť ',
    'club.button.createClub': 'Vytvoriť klub',
    'club.button.sendRequest': 'Odoslať žiadosť',
    'club.commentsCard.title': 'Pripomienky',
    'club.commentsCard.body': 'Vaša žiadosť vykazuje nedostatky, ktoré sú zvýraznené vo formulári.Prosím doplňte požadované informácie v zmysle pripomienok uvedených nižšie.',
    'club.clubCard.clubNameShort': 'Názov klubu',
    'club.clubCard.clubName': 'Názov klubu / tímu / oddielu',
    'club.clubCard.title': 'Cyklistický klub',
    'club.clubCard.button.add': 'Nahrať logo',
    'club.clubCard.button.change': 'Zmeniť logo',
    'club.clubCard.button.remove': 'Odstrániť logo',
    'club.legalEntityCard.title': 'Právny subjekt',
    'club.legalEntityCard.basicInformation': 'Základné údaje',
    'club.legalEntityCard.name': 'Názov právneho subjektu',
    'club.legalEntityCard.legalForm': 'Právna forma',
    'club.legalEntityCard.ico': 'IČO',
    'club.legalEntityCard.dic': 'DIČ',
    'club.legalEntityCard.officeAddress': 'Adresa sídla',
    'club.legalEntityCard.mvSrRegistration': 'Číslo reg. MV SR',
    'club.legalEntityCard.mvSrRegistrationDate': 'Dátum reg. MV SR',
    'club.legalEntityCard.correspondenceAddress': 'Korešpondenčná adresa',
    'club.legalEntityCard.correspondenceAddress.checkbox': 'Rovnaká ako adresa sídla',
    'club.legalEntityCard.paymentDetails': 'Platobné údaje',
    'club.legalEntityCard.iban': 'Číslo účtu (IBAN)',
    'club.legalEntityCard.bankLocation': 'Sídlo banky',
    'club.documentsCard.newTitle': 'Požadované dokumenty',


    'club.statutoryCard.title': 'Štatutár',
    'club.statutoryCard.basicInformation': 'Základné údaje',
    'club.statutoryCard.address': 'Adresa trvalého pobytu',
    'club.statutoryCard.president.checkbox': 'Štatutár je zároveň aj predseda klubu',
    'club.statutoryCard.table.address': 'Trvalý pobyt',
    'club.statutoryCard.table.role': 'Úloha',
    'club.statutoryCard.button.add': 'Pridať štatutára',
    'club.statutoryCard.button.changeStructure': 'Zmeniť štruktúru',
    'club.statutoryCard.table.detail': 'Profil štatutára',

    'club.presidentCard.title': 'Predseda',
    'club.presidentCard.basicInformation': 'Základné údaje',
    'club.presidentCard.address': 'Adresa trvalého pobytu',

    'club.documentsCard.title': 'Požadované dokumenty',
    'club.documentsCard.document': 'Dokument',
    'club.documentsCard.file': 'Súbor',


};




const routes = {
    'routes.dashboard': 'Prehľad',
    'routes.clubs': 'Kluby',
    'routes.requests': 'Žiadosti',
    'routes.users': 'Používatelia',
    'routes.members': 'Členovia',
    'routes.profile': 'Profil',
    'routes.licenses': 'Správa licencií',
    'routes.transfers': 'Prestupy a hosťovania',
    'routes.payments': 'Platby',
    'routes.documents': 'Dokumenty',
    'routes.groups': 'Skupiny',
    'routes.settings': 'Nastavenia',
    'routes.logout': 'Odhlásiť',
    'routes.login': 'Prihlásenie',
    'routes.forgottenPassword': 'Zabudnuté heslo',
    'routes.activate': 'Aktivácia',
    'routes.confirm': 'Potvrdenie',
    'routes.register': 'Registrácia',
    'routes.hosting': 'Hosťovanie',
    'routes.loading': 'Načítavam',
};

const authorization = {
    'login.loginCard.title': 'Prihlásenie',
    'login.loginCard.email': 'Email / ID',
    'login.loginCard.password': 'Heslo',
    'login.loginCard.keepLoggedIn': 'Zostať prihlásený',
    'login.loginCard.termsOfUse': 'Podmienky používania',
    'login.loginCard.dataProtection': 'Ochrana údajov',
    'login.loginCard.button.submit': 'Prihlásiť',
    'login.loginCard.button.forgottenPassword': 'Zabudnuté heslo',
    'login.registrationCard.title': 'Registrácia',
    'login.registrationCard.button.submit': 'Registrovať',
    'login.snackbar.success': 'Boli ste úspešne prihlasený',
    'register.title': 'Registrácia',
    'register.email': 'Email',
    'register.password': 'Heslo',
    'register.repeatPassword': 'Zopakujte heslo',
    'register.birthDate': 'Dátum narodenia',
    'register.gdpr': 'Súhlasím s podmienkami ochrany údajov',
    'register.termsOfUse': 'Súhlasím s podmienkami používania',
    'register.szcConfirmation': `Záväzne prehlasujem a potvrdzujem, že všetky mnou uvedené údaje sú úplné a pravdivé a že som plne oprávnený/ná uvedené údaje poskytnúť na spracovanie a evidenciu Slovenskému zväzu cyklistiky. Zároveň prehlasujem, že som plne právne spôsobilý na vykonanie registrácie do systému ${variables.systemName}.`,
    'register.button.submit': 'Registrovať',
    'register.button.back': 'Späť na prihlásenie',
    'register.snackbar.success': 'Registrácia prebehla úspešne',
    'forgottenPassword.title': 'Zabudnuté heslo',
    'forgottenPassword.info': 'Uveďte e-mail spojený s Vaším účtom a pošleme Vám odkaz na zmenu hesla.',
    'forgottenPassword.email': 'Váš email',
    'forgottenPassword.button.submit': 'Odoslať email',
    'forgottenPassword.button.back': 'Späť na prihlásenie',
    'forgottenPassword.snackbar.success': 'Na váš email bol odoslaný link na vytvorenie nového hesla',
    'passwordReset.title': 'Obnovenie hesla',
    'passwordReset.info': `Zadajte nové heslo, ktoré budete používať pre prihlásenie do systému ${variables.systemName}.`,
    'passwordReset.newPassword': 'Nové heslo',
    'passwordReset.repeatPassword': 'Zopakujte heslo',
    'passwordReset.button.repeat': 'Obnoviť heslo',
    'passwordReset.snackbar.success': `Vaše heslo bolo úspešne obnovené, teraz sa môžete prihlásiť do systému ${variables.systemName}.`,
    'passwordReset.body': `Prosím kontaktujte adminstátora ${variables.systemName}.`,
    'confirm.title': 'Registrácia úspešná',
    'confirm.subtitle': 'Poslali sme Vám potvrdzujúci e-mail. Aktivujte svoj účet kliknutím na odkaz v ňom',
    'confirm.subtitle.joinToClub': `Úspešne ste sa zaregistrovali do systému ${variables.systemName}.`,
    'confirm.subtitle.newClub': 'Úspešne ste sa zaregistrovali. Následne môžete dokončiť registráciu klubu.',
    'confirm.button.profile': 'Prejsť na profil',
    'confirm.button.newClub': 'Pokračovať na registráciu klubu',
    'activate.title': 'Aktivácia úspešná',
    'activate.subtitle': 'Vaše konto je aktivované. Teraz sa môžete prihlásiť.',
    'activate.not.title': 'Váš autorizačný token je neplatný',
    'activate.not.subtitle': `Prosím kontaktujte adminstátora systému ${variables.systemName}.`,
    'activate.button.login': 'Prihlásiť sa',
    'activate.snackbar.success': `Váš ${variables.systemName} účet bol úspešne aktivovaný`,
    'activatePassword.title': `Aktivácia ${variables.systemName} účtu`,
    'activatePassword.subtitle': `Pre aktiváciu Vášho ${variables.systemName} účtu ({0}), si prosím vytvorte prístupové heslo.`,
    'activatePassword.button.submit': `Aktivovať účet`,
    'registerNewClub.title': 'Registrácia klubu',
    'registerInvitationClub.title': 'Registrácia',
    'registerNewClub.subtitle': `Pozvánka na registráciu klubu. Pre prístup k ${variables.systemName} účtu doplňte všetky povinné polia.`,
    'registerInvitationClub.subtitle': `Pozvánka na vstup do klubu {0}. Pre prístup k ${variables.systemName} účtu doplňte všetky povinné polia.`,
    'register.subtitle': `Pozvánka na vstup do platformy ${variables.systemName}. Pre prístup k účtu doplňte všetky povinné polia.`,
    'registerClub.body': 'Váš e-mail slúži ako prihlasovacie meno',
    'registerClub.gdpr': 'Súhlasím s podmienkami GDPR',
    'registerClub.trueData': `Záväzne prehlasujem a potvrdzujem, že všetky mnou uvedené údaje sú úplné a pravdivé a že som plne oprávnený/ná uvedené údaje poskytnúť na spracovanie a evidenciu Slovenskému zväzu cyklistiky. Zároveň prehlasujem, že som plne právne spôsobilý na vykonanie registrácie do systému ${variables.systemName}.`,
    'registerClub.password.title': 'Zmena hesla',
    'registerNewClub.snackbar.success': 'Boli ste úspešne priradený do klubu',
    'registerInvitationClub.snackbar.success': 'Boli ste úspešne registrovaný',
    'registerClub.button.body': "Registrovať a prejsť na založenie klubu",
    'registerInvitationClub.button.body': "Registrovať a pokračovať do profilu",
    'register.newPassword.title': 'Vytvorenie hesla'

}














const dashboard = {
    "dashboard.title": "Prehľad",
};


const requests = {
    'requests.tab.status.notEquipped': 'Nevybavené žiadosti',
    'requests.tab.status.equipped': 'Vybavené žiadosti',
    'request.table.type': 'Žiadosť',
    'request.table.createdAt': 'Podaná',
    'request.table.equip': 'Vybaviť žiadosť',
    'request.table.status.awaiting': 'čaká na schválenie',
    'request.table.status.approved': 'schválená',
    'request.table.status.rejected': 'zamietnutá',
    'request.table.status.returned': 'vrátená na doplnenie',
    'request.table.status.canceled': 'zrušená',
    'request.table.type.ClubRegistration': 'Založenie klubu',
    'request.table.type.ClubUpdate': 'Zmena klubových údajov',
    'request.table.type.ClubJoin': 'Pridanie členov do klubu',
    'request.table.type.ClubTransfer': 'Transfer klubu',
}



const users = {

    'user.table.title': 'Zoznam používateľov',
    'user.table.club': 'Kluby',
    'user.table.email': 'E-mail',
    'user.table.group': 'Skupiny',
    'user.table.status': 'Stav',
    'user.table.status.activated': 'Aktívny',
    'user.table.status.blocked': 'Blokovaný',
    'user.table.status.unactivated': 'Neaktívny',
    'user.new.button': 'Vytvoriť konto',

    'usersList.title': 'Používatelia',
    'usersList.button.new': 'Pridať používateľa',
    'usersList.table.title': 'Zoznam požívateľov',
    'usersList.table.id': 'ID',
    'usersList.table.name': 'Meno',
    'usersList.table.club': 'Kluby',
    'usersList.table.email': 'E-mail',
    'usersList.table.group': 'Skupiny',
    'usersList.table.status': 'Stav',
    'usersList.table.credits': 'Kredity',
    'usersList.table.status.activated': 'AKTÍVNY',
    'usersList.table.status.blocked': 'BLOKOVANÝ',
    'usersList.table.status.unactivated': 'NEAKTÍVNY',
    'usersList.table.actions': 'Akcie',
    'usersList.table.detail': 'Detail',
}

const settings = {

};


const functions = {
    'functions.title': 'Klubové funkcie',
    'functions.button.change': 'Zmeniť funkcie',
    'functions.button.add': 'Pridať funkciu',
    'functions.function': '. klubová funkcia',
    'functions.function.title': 'Klubová funkcia',
    'functions.chooseFunction': 'Vyberte klubovú funkciu',
    'functions.disciplines': 'Odvetvia',
    'functions.discipline': 'Odvetvie',
    'functions.category': 'Kategória',
}



const groups = {
    'groups.title': 'Skupiny a oprávnenia',
    'groups.groups.title': 'Zoznam skupín',
    'groups.update.title': 'Nastavenie oprávnení',
    'groups.table.name': 'Názov',
    'groups.table.action': 'Akcia',
    'groups.table.actions': 'Akcie',
    'groups.table.disabled': 'Žiadne',
    'groups.table.own': 'Vlastné',
    'groups.table.all': 'Všetky',
    'groups.table.accounts': 'Počet účtov',
    'groups.update.setGroup': 'Zvolená skupina',
    'groups.choosenGroup': 'Vyberte skupinu...',
    'groups.form.name': 'Názov skupiny',
    'groups.form.description': 'Popis skupiny',
    'groups.button.create': 'Vytvoriť',
    'groups.button.delete': 'Odstrániť',
    'groups.button.addGroup': 'Pridať skupinu',
    'groups.deleteModal.title': 'Naozaj chcete odstrániť túto skupinu?',
    'groups.deleteModal.body': 'Počet používateľov v skupine je',
    'groups.deleteModal.body2': 'Ak skupinu odstránite, títo použivatelia stratia práva patriace tejto skupine',
    'groups.createModal.title': 'Vytvorenie skupiny',
    'groups.success.update': 'Detaily skupiny boli aktualizované',
    'groups.success.create': 'Skupina bola vytvorená',
    'groups.success.delete': 'Skupina bola odstránená',
    'groups.scope.company': 'Spoločnosť',
    'groups.scope.device': 'Prehrávač',
    'groups.scope.store': 'Prevádzka',
    'groups.scope.commercial-campaign': 'Komerčná kampaň',
    'groups.scope.internal-campaign': 'Interná kampaň',
    'groups.scope.user': 'Používateľ',
    'groups.scope.invoice': 'Faktúra',
    'groups.scope.payout': 'Dobropis',
    'groups.scope.commission': 'Provízia',
    'groups.scope.operation': 'Operácie',
    'groups.scope.stream': 'Stream',
    'groups.scope.media': 'Médiá',
    'groups.scope.group': 'Skupina',
    'groups.scope.settings': 'Nastavenia',
    'groups.scope.overview': 'Prehľad',
    'groups.action.list': 'Zobrazenie',
    'groups.action.detail': 'Detail',
    'groups.action.create': 'Vytvorenie',
    'groups.action.update': 'Úprava',
    'groups.action.approve': 'Schválenie',
    'groups.action.reject': 'Zamietnutie',
    'groups.action.cancel': 'Ukončenie',
    'groups.action.discover': 'Obnovenie/ Prehľadanie',
    'groups.action.restart': 'Reštart',
    'groups.action.delete': 'Odstránenie',
    'groups.action.enable': 'Pripojenie',
    'groups.action.disable': 'Odpojenie',
    'groups.action.upload': 'Nahratie',
    'groups.action.download': 'Spustenie / stiahnutie',
    'groups.action.export': 'Export',
    'groups.action.block': 'Zablokovanie',
    'groups.action.unblock': 'Odblokovanie',
    'groups.action.identify': 'Identifikovanie',
    'groups.action.terminal': 'Terminál',
    'groups.action.password-reset': 'Resetovanie hesla',
    'groups.action.manage-assignment': 'Priradenie prevádzke',
    'groups.action.manage-members': 'Pridanie / odobratie používateľov',
    'groups.action.media-campaigns': 'Autorizácia kampaní',
    'groups.action.media-streams': 'Zmena rádio streamu',
    'groups.action.media-forbiden-topics': 'Správa zakázaných tém',
    'groups.action.media-jingle': 'Zmena jinglov',
};

const documents = {
    'documents.snackbar.upload.success': 'Dokumenty boli úspešne nahraté',
    'documents.snackbar.download.success': 'Dokumenty boli stiahnuté',
    'documents.snackbar.delete.success': 'Dokument bol úspešne odstránený',
    'documents.modalButton.upload': 'Nahrať dokument',
    'documents.table.name': 'Názov',
    'documents.table.createdAt': 'Nahraté',
    'documents.table.validFrom': 'Platnosť od',
    'documents.table.validTo': 'Platnosť do',
    'documents.table.status': 'Stav',

    'documents.status.Awaiting': 'čaká na schválenie',
    'documents.status.Approved': 'schválený',
    'documents.status.Rejected': 'zamietnutý',
    'documents.status.Uploaded': 'nahratý',
    'documents.status.AdminUploaded': 'nahratý (Admin)',
    'documents.status.Empty': 'nenahratý',
    'documents.status.Removed': 'odstránený',

    'documents.uploadModal.title': 'Nahratie dokumentu',
    'documents.dropzone.body': 'Kliknite alebo potiahnite potrebné súbory',
    'documents.dropzone.title': 'Vybrané dokumenty',
    'documents.type': 'Typ dokumentu',
    'documents.validFrom': 'Platnosť od',
    'documents.validTo': 'Platnosť do',
    'documents.deleteModal.title': 'Odstrániť dokument',
    'documents.deleteModal.body': 'Naozaj chcete odstrániť dokument?',
    'documents.button.upload': 'Nahrať',
    'documents.snackbar.approve': 'Dokumenty boli schválené',
    'documents.snackbar.reject': 'Dokumenty boli zamietnuté',
    'documents.button.approve': 'Schváliť dokumenty',
    'documents.button.reject': 'Zamietnuť dokumenty',

    'documents.profile.AffidavitOfPersonalIntegrity': 'Čestné vyhlásenie o bezúhonnosti',
    'documents.profile.CriminalRecordCertificate': 'Výpis z registra trestov',
    'documents.profile.AntidopingCertificate': 'Antidopingový certifikát',
    'documents.profile.OSHCertificate': 'Certifikát BOZP',
    'documents.profile.DriverLicense': 'Vodičský preukaz',
    'documents.profile.GraduateCertificate': 'Potvrdenie o skúške',
    'documents.profile.TrainerCertification': 'Certifkát trénera',
    'documents.profile.MassageTherapistCertification': 'Certifkát maséra',
    'documents.profile.MechanicCertification': 'Certifkát mechanika',
    'documents.profile.RefereeCertification': 'Certifkát rozhodcu',
    'documents.profile.MedicalDegree': 'Lekársky diplom',
    'documents.profile.MinutesOfAcceptanceConference': 'Zápisnica o prijatí konferenciou',

    'documents.club.CompanyRegistrationStatement': 'Registračný dokument ORSR',
    'documents.club.ICOAssignmentConfirmation': 'Potvrdenie o pridelení IČO',
    'documents.club.DICRegistrationStatement': 'Osvedčenie o registrácii DIČ',
    'documents.club.MinuteOfTheInauguralMeeting': 'Zápisnica z ustanovujúceho zasadnutia',
    'documents.club.StatutesOfTheCivicAssociation': 'Stanovy občianskeho združenia',
    "documents.compareCard.status.awaiting": "Dokument čaká na schválenie",
    "documents.compareCard.status.rejected": "Dokument je zamietnutý",
    "documents.compareCard.status.approved": "Dokument je schválený",

}

const helpers = {
    'pageHeader.back': 'Späť',
    'status.Awaiting': 'čaká na schválenie',
    'status.Ready': 'čaká na výrobu',
    'status.PartialReady': 'čiastočne spracovaná',
    'status.Approved': 'schválená',
    'status.Completed': 'dokončená',
    'status.Succeeded': 'uhradená',
    'status.Uploaded': 'nahratý',
    'status.AdminUploaded': 'nahratý (Admin)',
    'status.Rejected': 'zamietnutá',
    'status.Removed': 'odstránená',
    'status.Canceled': 'zrušená',
    'status.Empty': 'nenahratý',
    'status.NotLicensed': 'nevystavená',
    'status.Expire': 'expirovaná',
    'status.Expired': 'expirovaná',
    'status.Paid': 'zaplatená',
    'status.Downloaded': 'stiahnuté PDF',
    'status.Pending': 'čaká na platbu',
    'status.Returned': 'vrátená na doplnenie',
    'select.dropdown.noOptions': 'Žiadne možnosti',
    'select.dropdown.find': 'Hľadať...',
    'address.officeAddress': 'Ulica',
    'address.street': 'Ulica',
    'address.streetNumber': 'Popisné č.',
    'address.postal': 'PSČ',
    'address.city': 'Mesto',
    'address.country': 'Krajina',
    'personal.firstName': 'Meno',
    'personal.lastName': 'Priezvisko',
    'personal.preNominals': "Tituly pred menom",
    'personal.postNominals': "Tituly za menom",
    'personal.email': 'Email',
    'personal.idNumber': 'Rodné číslo',
    'personal.phone': 'Telefónne číslo',
    'personal.nationality': 'Národnosť',
    'personal.isYouthWorker': 'Osoba pracuje s mládežou',
    'personal.gender': 'Pohlavie',
    'personal.birth': 'Dátum narodenia',
    'personal.countryOfBirth': "Krajina narodenia",
    'personal.nationality': "Štátna príslušnosť",
    'personal.district': "Okres",
    'tableTexts.rowsPerPage': 'Počet záznamov',
    'tableTexts.of': 'z',
    'tableTexts.moreThan': 'viac ako',
    'tableTexts.piece': ' ks',
    'tableTexts.piece': ' spotov',
    'tableTexts.minutes': ' minút',
    'tableTexts.copyToClipboard': 'Kliknutím skopírujete text',
    'tableTexts.copyToClipboardShort': 'Kliknutím skopírujete',
    'tableTexts.copyToClipboard.success': 'Text bol skopírovaný do schránky',


    'button.saveChanges': 'Uložiť zmeny',
    'button.sendChanges': 'Odoslať na schválenie',
    'button.cancelChanges': 'Zrušiť zmeny',
    'button.editMode': 'Zmeniť údaje',
    'button.back': 'Späť',
    'button.delete': 'Odstrániť',

    'queryTable.select.all': 'Všetko',
    'queryTable.filter.placeholder': 'Filter..',
    "queryTable.filter.dateBetween": "Od - Do",
    "queryTable.filter.setDateRange": "Nastaviť časové rozmedzie: ",
    "queryTable.filter.pickerStart": "Dátum od",
    "queryTable.filter.pickerEnd": "Dátum do",

    'weekDay.Monday': 'Pondelok',
    'weekDay.Tuesday': 'Utorok',
    'weekDay.Wednesday': 'Streda',
    'weekDay.Thursday': 'Štvrtok',
    'weekDay.Friday': 'Piatok',
    'weekDay.Saturday': 'Sobota',
    'weekDay.Sunday': 'Nedeľa',

    'select.StandardDomestic': 'štandardný domáci',
    'select.StandardForeign': 'štandardný zahraničný',
    'select.UCIDomestic': 'UCI tím domáci',
    'select.UCIForeign': 'UCI tím zahraničný',
    'select.SK100': 'Fyzická osoba - príležitostne činná - zapísaná v registri daňového informačného systému',
    'select.SK101': 'Podnikateľ - fyzická osoba - nezapísaný v obchodnom registri',
    'select.SK102': 'Podnikateľ - fyzická osoba - zapísaný v obchodnom registri',
    'select.SK103': 'Samostatne hospodáriaci roľník nezapísaný v obchodnom registri',
    'select.SK104': 'Samostatne hospodáriaci roľník zapísaný v obchodnom registri',
    'select.SK105': 'Slobodné povolanie - fyzická osoba podnikajúca na základe iného ako živnostenského zákona',
    'select.SK106': 'Slobodné povolanie - fyzická osoba podnikajúca na základe iného ako živnostenského zákona zapísaná v obchodnom registri',
    'select.SK107': 'Podnikateľ - fyzická osoba - nezapís. v OR - podnikajúca súčasne ako sam.hosp.roľník',
    'select.SK108': 'Podnikateľ - fyzická osoba - zapís.v OR - podnikajúca súčasne ako sam.hosp.roľník',
    'select.SK109': 'Podnikateľ - fyzická osoba - nezapís. v OR - podnikajúca súčasne ako osoba so slobodným povolaním',
    'select.SK110': 'Podnikateľ - fyzická osoba - zapís.v OR - podnikajúca súčasne ako osoba so slobodným povolaním',
    'select.SK111': 'Verejná obchodná spoločnosť',
    'select.SK112': 'Spoločnosť s ručením obmedzeným',
    'select.SK113': 'Komanditná spoločnosť',
    'select.SK117': 'Nadácia',
    'select.SK118': 'Neinvestičný fond',
    'select.SK119': 'Nezisková organizácia',
    'select.SK121': 'Akciová spoločnosť',
    'select.SK122': 'Európske zoskupenie hospodárskych záujmov',
    'select.SK123': 'Európska spoločnosť',
    'select.SK124': 'Európske družstvo',
    'select.SK205': 'Družstvo',
    'select.SK271': 'Spoločenstvá vlastníkov pozemkov, bytov a pod.',
    'select.SK272': 'Pozemkové spoločenstvo s právnou subjektivitou',
    'select.SK301': 'Štátny podnik',
    'select.SK311': 'Národná banka Slovenska',
    'select.SK312': 'Banka - štátny peňažný ústav',
    'select.SK321': 'Rozpočtová organizácia',
    'select.SK331': 'Príspevková organizácia',
    'select.SK381': 'Fondy',
    'select.SK382': 'Verejnoprávna inštitúcia',
    'select.SK383': 'Iná organizácia verejnej správy',
    'select.SK421': 'Zahraničná osoba, právnická osoba so sídlom mimo územia SR',
    'select.SK422': 'Zahraničná osoba, fyzická osoba s bydliskom mimo územia SR',
    'select.SK433': 'Sociálna a zdravotné poisťovne',
    'select.SK434': 'Doplnková dôchodková poisťovňa',
    'select.SK445': 'Komoditná burza',
    'select.SK701': 'Združenie (zväz, spolok, spoločnosť, klub ai.)',
    'select.SK711': 'Politická strana, politické hnutie',
    'select.SK721': 'Cirkevná organizácia',
    'select.SK741': 'Stavovská organizácia - profesná komora',
    'select.SK745': 'Komora (s výnimkou profesných komôr)',
    'select.SK751': 'Záujmové združenie právnických osôb',
    'select.SK752': 'Záujmové združenie fyzických osôb bez právnej spôsobilosti',
    'select.SK801': 'Obec (obecný úrad), mesto (mestský úrad)',
    'select.SK803': 'Samosprávny kraj (úrad samosprávneho kraja)',
    'select.SK804': 'Európske zoskupenie územnej spolupráce',
    'select.SK901': 'Zastupiteľské orgány iných štátov',
    'select.SK911': 'Zahraničné kultúrne, informačné stredisko, rozhlasová, tlačová a televízna agentúra',
    'select.SK921': 'Medzinárodné organizácie a združenia',
    'select.SK931': 'Zastúpenie zahraničnej právnickej osoby',
    'select.SK951': 'Miestna jednotka bez právnej spôsobilosti',
    'select.SK995': 'Nešpecifikovaná právna forma',

    'select.Male': "Muž",
    'select.Female': "Žena",
    'select.true': 'Áno',
    'select.false': 'Nie',

    "month.january": "Január",
    "month.february": "Február",
    "month.march": "Marec",
    "month.april": "Apríl",
    "month.may": "Máj",
    "month.june": "Jún",
    "month.july": "Júl",
    "month.august": "August",
    "month.september": "September",
    "month.october": "Október",
    "month.november": "November",
    "month.december": "December",
    ...countries
}


const validationErrors = {
    'validation.required': 'Povinné pole',
    'validation.number': 'Toto pole musí obsahovať len čísla',
    'validation.number.min': 'Minimálna hodnota pre toto pole je {0}',
    'validation.number.max': 'Maximálna hodnota pre toto pole je {0}',
    'validation.number.integer': 'Toto pole musí obsahovať celé číslo',
    'validation.number.positive': 'Hodnota tohto poľa musí byť vyššia ako 0',
    'validation.password.match': 'Heslá sa musia zhodovať',
    'validation.password.characters': 'Musí obsahovať 8 znakov, aspoň 1 veľký znak, aspoň 1 malý znak a aspoň jedno číslo',
    'validation.email': 'Emailová adresa nie je v správnom tvare',
    'validation.phoneNumber': 'Telefónne číslo nie je v správnom tvare (+421903123123)',
    'validation.spaces': 'Telefónne číslo nesmie obsahovať medzery (+421903123123)',
    'validation.iban': 'IBAN je v nesprávnom tvare',
    'validation.date': 'Dátum je v nesprávnom tvare',
    'validation.graterDate': 'Konečný dátum musí byť väčší ako počiatočný',
    'validation.pastDate': 'Dátum a čas musí byť vyšší ako aktuálny',
    'validation.string.max': 'Maximálna dĺžka tohto poľa je {0} znakov',
    'validation.string.exact': 'Toto pole musí obsahovať presne {0} znakov',
    'validation.mustAccept': 'Musíte akceptovať podmienky',
    'validation.boolean': 'Musíte zaškrtnúť povinné pole'
};

const legalForm = {
    'legalForm.SK100': 'Fyzická osoba - príležitostne činná - zapísaná v registri daňového informačného systému',
    'legalForm.SK101': 'Podnikateľ - fyzická osoba - nezapísaný v obchodnom registri',
    'legalForm.SK102': 'Podnikateľ - fyzická osoba - zapísaný v obchodnom registri',
    'legalForm.SK103': 'Samostatne hospodáriaci roľník nezapísaný v obchodnom registri',
    'legalForm.SK104': 'Samostatne hospodáriaci roľník zapísaný v obchodnom registri',
    'legalForm.SK105': 'Slobodné povolanie - fyzická osoba podnikajúca na základe iného ako živnostenského zákona',
    'legalForm.SK106': 'Slobodné povolanie - fyzická osoba podnikajúca na základe iného ako živnostenského zákona zapísaná v obchodnom registri',
    'legalForm.SK107': 'Podnikateľ - fyzická osoba - nezapís. v OR - podnikajúca súčasne ako sam.hosp.roľník',
    'legalForm.SK108': 'Podnikateľ - fyzická osoba - zapís.v OR - podnikajúca súčasne ako sam.hosp.roľník',
    'legalForm.SK109': 'Podnikateľ - fyzická osoba - nezapís. v OR - podnikajúca súčasne ako osoba so slobodným povolaním',
    'legalForm.SK110': 'Podnikateľ - fyzická osoba - zapís.v OR - podnikajúca súčasne ako osoba so slobodným povolaním',
    'legalForm.SK111': 'Verejná obchodná spoločnosť',
    'legalForm.SK112': 'Spoločnosť s ručením obmedzeným',
    'legalForm.SK113': 'Komanditná spoločnosť',
    'legalForm.SK117': 'Nadácia',
    'legalForm.SK118': 'Neinvestičný fond',
    'legalForm.SK119': 'Nezisková organizácia',
    'legalForm.SK121': 'Akciová spoločnosť',
    'legalForm.SK122': 'Európske zoskupenie hospodárskych záujmov',
    'legalForm.SK123': 'Európska spoločnosť',
    'legalForm.SK124': 'Európske družstvo',
    'legalForm.SK205': 'Družstvo',
    'legalForm.SK271': 'Spoločenstvá vlastníkov pozemkov, bytov a pod.',
    'legalForm.SK272': 'Pozemkové spoločenstvo s právnou subjektivitou',
    'legalForm.SK301': 'Štátny podnik',
    'legalForm.SK311': 'Národná banka Slovenska',
    'legalForm.SK312': 'Banka - štátny peňažný ústav',
    'legalForm.SK321': 'Rozpočtová organizácia',
    'legalForm.SK331': 'Príspevková organizácia',
    'legalForm.SK381': 'Fondy',
    'legalForm.SK382': 'Verejnoprávna inštitúcia',
    'legalForm.SK383': 'Iná organizácia verejnej správy',
    'legalForm.SK421': 'Zahraničná osoba, právnická osoba so sídlom mimo územia SR',
    'legalForm.SK422': 'Zahraničná osoba, fyzická osoba s bydliskom mimo územia SR',
    'legalForm.SK433': 'Sociálna a zdravotné poisťovne',
    'legalForm.SK434': 'Doplnková dôchodková poisťovňa',
    'legalForm.SK445': 'Komoditná burza',
    'legalForm.SK701': 'Združenie (zväz, spolok, spoločnosť, klub ai.)',
    'legalForm.SK711': 'Politická strana, politické hnutie',
    'legalForm.SK721': 'Cirkevná organizácia',
    'legalForm.SK741': 'Stavovská organizácia - profesná komora',
    'legalForm.SK745': 'Komora (s výnimkou profesných komôr)',
    'legalForm.SK751': 'Záujmové združenie právnických osôb',
    'legalForm.SK752': 'Záujmové združenie fyzických osôb bez právnej spôsobilosti',
    'legalForm.SK801': 'Obec (obecný úrad), mesto (mestský úrad)',
    'legalForm.SK803': 'Samosprávny kraj (úrad samosprávneho kraja)',
    'legalForm.SK804': 'Európske zoskupenie územnej spolupráce',
    'legalForm.SK901': 'Zastupiteľské orgány iných štátov',
    'legalForm.SK911': 'Zahraničné kultúrne, informačné stredisko, rozhlasová, tlačová a televízna agentúra',
    'legalForm.SK921': 'Medzinárodné organizácie a združenia',
    'legalForm.SK931': 'Zastúpenie zahraničnej právnickej osoby',
    'legalForm.SK951': 'Miestna jednotka bez právnej spôsobilosti',
    'legalForm.SK995': 'Nešpecifikovaná právna forma',
}

const apiErrors = {
    'apiErrors.INTERNAL_SERVER_ERROR': 'Nastala neočakávana chyba',
    'apiErrors.InsufficientPermissionsError': 'Nemáte oprávnenie na vykonanie tejto akcie',
    'apiErrors.UnauthorizedUserError': 'Boli ste odhlásený z dôvodu nečinnosti',
    'apiErrors.InvalidRequestError': 'Odoslané dáta obsahujú neplatné hodnoty',
    'apiErrors.AccountDoesNotExistsError': 'Účet so zadanou e-mailovou adresou neexistuje',
    'apiErrors.AccountExistsError': 'Účet so zadanou e-mailovou adresou už existuje',
    'apiErrors.PasswordResetPendingError': 'Na e-mail už bol odoslaný odkaz pre zmenu hesla',
    'apiErrors.PasswordResetNotAllowedError': 'Tento účet nepodporuje resetovanie hesla',
    'apiErrors.InvalidLoginError': 'Zadaný email alebo heslo je nesprávne.',
    'apiErrors.InvalidLoginMethodError': 'Účet so zadaným emailom nepodporuje použitú autentifikačnú metódu.',
    'apiErrors.UnactivatedAccountError': 'Účet vyžaduje aktiváciu.',
    'apiErrors.BlockedAccountError': 'Účet bol zablokovaný administrátorom.',
    'apiErrors.DeviceOfflineError': 'Nie je možné vykonať akciu lebo zariadenie je Offline',
    'apiErrors.EntityUniqueConstraintError': 'Zariadne s rovnakým názvom už existuje',
    'apiErrors.CannotPerformOnOwnAccountError': 'Táto operácia nemôže byť vykonaná na vlastnom účte',
    'apiErrors.PasswordChangeError': 'Pôvodné heslo je nesprávne',
    'apiErrors.WeakPasswordError': 'Heslo musí byť aspoň 8 znakov dlhé a musí obsahovať aspoň jedno číslo, aspoň jeden malý a aspoň jeden veľký znak',
    'apiErrors.SubscriptionNotActivatedError': 'Používateľ nemá aktivované žiadne predplatné',
    'apiErrors.InvalidInputDataError': 'Niektorý zo vstupných údajov nie je správny',
    'apiErrors.GRAPHQL_VALIDATION_FAILED': 'Nastala neočakávaná chyba servera',
    'apiErrors.InvalidActivationTokenError': 'Neplatný autorizačný token',
    'apiErrors.MemberAlreadyExistsError': 'Osoba ktorú sa snažíte pozvať už je členom niektorého klubu',
    'apiErrors.MissingActionInvitationError': 'Nedostali ste pozvánku na vykonanie tejto akcie',
    'apiErrors.PaymentAlreadyPaidError': 'Platba už bola uhradená',
    'apiErrors.LicenseAlreadyExistsError': 'Klub už má uhradenú licenciu',
    'apiErrors.CannotUpdateRequestError': 'Nie je možné zmeniť žiadosť',
    'apiErrors.CannotChangeDocumentStatusError': 'Nie je možné zmeniť stav dokumentov',
    'apiErrors.InvitationAlreadyExistsError': 'Táto osoba už má platnú pozvánku na založenie klubu',
    'apiErrors.ActiveRequestsAlreadyExistsError': `Pre túto osobu už evidujeme v systéme ${variables.systemName} otvorenú žiadosť`,
    'apiErrors.BAD_USER_INPUT': 'Niektorý zo vstupov nie je správny',
    'apiErrors.ClubLicenseMissingError': 'Klub zvoleného používateľa nemá aktívnu licenciu',
    'apiErrors.MemberHostingAlreadyExistsError': 'Táto osoba vo vybranom období hosťuje v inom klube. Ak chcete pozvať túto osobu, musíte zmeniť obdobie hosťovania.',
    'apiErrors.RequestNotRespondedByAllPartiesError': 'Nie je možné schváliť žiadosť, kým nemáte udelené vyjadrenia všetkých zúčastnených strán',
    'apiErrors.MemberNotLicensedError': 'Zápis nie je možný pretože člen nemá platnú licenciu',
    'apiErrors.ClubNotRegisterdInISSError': 'Zápis člena nie je možný pretože jeho domáci klub nie je registrovaný v ISŠ',
    'apiErrors.ClubNotLicensedError': 'Zápis nie je možný pretože klub nemá platnú licenciu',
    'apiErrors.FunctionNotAvailableForMemberError': 'Pre vybraného člena nie je táto funkcia dostupná',
    'apiErrors.LicensedMemberCannotChangeClubError': 'Člena nie je možné presunúť do iného klubu, pretože už má udelenú licenciu na tento rok.',
    'apiErrors.FunctionaryClubChangeRestrictedError': 'Člena nie je možné presunúť do iného klubu, pretože je funkcionárom klubu.',
    'apiErrors.InvalidInvitationTokenError': 'Váš autorizačný token je neplatný',
    'apiErrors.CannotTransferMemberToOwnClubError': 'Nie je možné vytvoriť žiadosť o prestup, pretože vybraná osoba už je členom cieľového klubu.',
    'apiErrors.CannotHostMemberInOwnClub': 'Nie je možné vytvoriť žiadosť o hosťovanie, pretože vybraná osoba už je členom cieľového klubu.',
    'apiErrors.InvalidDocumentValidityRange': 'Dátum začiatku platnosti dokumentov je vyšší ako dátum konca platnosti.',
    'apiErrors.PersonIsAlreadyMemberError': 'Osoba s rovnakým emailom alebo rodným číslom už je členom niektorého klubu.',
    'apiErrors.SamePersonAlreadyExists': 'Osoba s rovnakým emailom alebo rodným číslom už je členom niektorého klubu.',
    'apiErrors.CannotDelegateOnlyMemberFunctionError': 'Ak chcete zmeniť pôvodného funkcionára klubu, prideľte mu v jeho detaile inú funkciu.',
    'apiErrors.RequestResponseAlreadySubmittedError': 'Vyjadrenie k žiadosti už bolo zaznamenané',
    'apiErrors.LicenseRequestInvalidStatusError': 'Nie je možné zmeniť stav žiadosti o licencie',
    'apiErrors.LicenseCannotBePurchasedError': 'Licenciu nie je možné obnoviť',
    'apiErrors.PendingClubLicenseRequestError': "Túto operáciu nie je možné vykonať, kým existuje nevybavená žiadosť o licenciu klubu."
};


export default {
    ...activities,
    ...tables,
    ...payments,
    ...transfersAndHosting,
    ...publicPages,
    ...clubs,
    ...routes,
    ...authorization,
    ...dashboard,
    ...members,
    ...requests,
    ...users,
    ...settings,
    ...profile,
    ...groups,
    ...legalForm,
    ...documents,
    ...helpers,
    ...validationErrors,
    ...apiErrors,
    ...functions,
};
