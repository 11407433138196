import gql from 'graphql-tag';

const ACTIVITIES_TABLE= gql`
query activitiesTable($offset: Int, $limit: Int, $sort: [ActivitiesTableSortInput!], $filter: [ActivitiesTableFilterInput]) {
  activitiesTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items{
     	id
		type
		event
		date
		text
		personId
		person
		clubId
		club
		entities{
			type
			role
			refId
		}
      }
  }
}
`;

export default ACTIVITIES_TABLE;