export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export const CHANGE_ALL_USER_ROLES = 'CHANGE_ALL_USER_ROLES';
export const CHANGE_USER_PROFILE = 'CHANGE_USER_PROFILE';
export const CHANGE_ADDRESS = 'CHANGE_ADDRESS';
export const CHANGE_LAST_URL = 'CHANGE_LAST_URL';
export const CHANGE_PENDING_REQUESTS_NUMBER = 'CHANGE_PENDING_REQUESTS_NUMBER';
export const CHANGE_LICENSES_NUMBER = 'CHANGE_LICENSES_NUMBER';
export const CHANGE_DOCUMENTS_NUMBER = 'CHANGE_DOCUMENTS_NUMBER';
export const CHANGE_PENDING_TRANSFERS_AND_HOSTING_NUMBER = 'CHANGE_PENDING_TRANSFERS_AND_HOSTING_NUMBER';
export const CHANGE_LOCATION = 'CHANGE_LOCATION';

