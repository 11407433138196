import gql from 'graphql-tag';

const CLUB_REGISTER_INVITE = gql`
mutation clubRegisterInvite($input: ClubRegisterInviteInput!) {
  clubRegisterInvite(input: $input)
}
`;

export default CLUB_REGISTER_INVITE;

