import gql from 'graphql-tag';

const CLUB_HOSTING_REQUEST_RESPOND= gql`
mutation clubHostingRequestRespond($id: ID!,$result:ResponseResult ) {
  clubHostingRequestRespond(id: $id, result: $result) {
    id
    bid
  }
}
`;

export default CLUB_HOSTING_REQUEST_RESPOND;