import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import DocumentsCard from '../Clubs/ClubCards/DocumentsCard';
import RoleEnum from 'common/enums/RoleEnum';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import LanguageIcon from '@material-ui/icons/Language';
import CustomSelect from 'components-lib/CustomSelect/CustomSelect';
import { IntlContext } from "index";
import { setPreferedLanguage } from 'helpers/helpers';
import Loading from "components-lib/Loading/Loading";
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { styled } from '@material-ui/core/styles';
import { widgets, colors, } from 'common/styles/configLayout';
import AvatarCard from '../Profile/DetailCards/AvatarCard';
import ClubCard from '../Clubs/ClubCards/ClubCard';
import ChangePasswordCard from '../Profile/DetailCards/ChangePasswordCard';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InfoIcon from '@material-ui/icons/Info';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PeopleIcon from '@material-ui/icons/People';
import LegalEntityCard from '../Clubs/ClubCards/LegalEntityCard';
import PresidentCard from '../Clubs/ClubCards/PresidentCard';
import FormikField from "components-lib/FormikField/FormikField";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import defaultAvatar from "assets/img/faces/avatar.jpg";
import Avatar from '@material-ui/core/Avatar';
import { getValidationSchemaRequestReview, getValidationSchemaRequestUpdate } from "./validationSchema";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EditIcon from '@material-ui/icons/Edit';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Modal from "components-lib/Modal/Modal";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { StickyContainer, Sticky } from 'react-sticky';
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import CommentsCard from '../Clubs/ClubCards/CommentsCard'
import PaymentCard from '../Clubs/ClubCards/PaymentCard'
import ControlDataCard from '../Clubs/ClubCards/ControlDataCard'
import ProfileDocumentsEnum from 'common/enums/ProfileDocumentsEnum';
import REQUEST from 'queries/RequestsQueries/request';
import { generatePath } from "react-router-dom";
import paths from "paths";
import LockIcon from '@material-ui/icons/Lock';
import { useLocation } from 'react-router-dom';
import ControlRequestDataCard from './RequestCards/ControlRequestDataCard';
import { connect } from 'react-redux';
import { changePendingRequestsNumberAction } from 'redux/actions';
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import { getApolloClient } from "configFiles/apollo";
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import RequestStatusesEnum from 'common/enums/RequestStatusesEnum';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';

const useStyles = makeStyles((theme) => styles(theme))

const RequestDetailPage = (props) => {
    const { intl,
        history,
        role,
        loadingRequest,
        dataRequest,
        refetchRequest,
        view,
        type,
        setView,
        clubCreate,
        clubUpdate,
        clubReview,
        clubApprove,
        clubReject,
        clubCancel,
        requestId,
        changePendingRequestsNumberAction
    } = props;

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [readMode, setReadMode] = useState(true);
    const [validateParam, setValidateParam] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [initialValuesNew, setInitialValuesNew] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [formOptions, setFormOptions] = useState({})
    const [rows, setRows] = useState([])
    const [removeDocuments, setRemoveDocuments] = useState([])
    const [approveDocuments, setApproveDocuments] = useState([])
    const [rejectDocuments, setRejectDocuments] = useState([])
    const [photo, setPhoto] = useState(null)
    const [approveSubmit, setApproveSubmit] = useState(null)
    const [review, setReview] = useState(false)
    const [disableButton, setDisableButton] = useState(false);

    const [updateModal, setUpdateModal] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [rejectModal, setRejectModal] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)

    let location = useLocation();
    let status = location.state ? location.state : null
    const client = getApolloClient(enqueueSnackbar, history, intl);


    //const requestId = props.match.params.requestId;
    //const queryParams = { id: requestId }





    let cantDocumentsUpload = Object.values(rows).find(item => item.status === "Empty" || item.status === "Removed")
    let cantDocumentsApprove = Object.values(rows).find(item => item.status !== "Approved" && item.status !== "AdminUploaded")


    const getPendingRequestsNumber = async () => {
        try {
            const result = await client.query({
                query: REQUESTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "eq": (role === RoleEnum.ADMIN) ? RequestStatusesEnum.AWAITING : RequestStatusesEnum.RETURNED
                            },
                            "type": {
                                "notIn": [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING]
                            },
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changePendingRequestsNumberAction(pathOr('', ['data', 'requestsTable', 'totalCount'], result))
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        let options = [];
        options = Object.values(ProfileDocumentsEnum).map((item) => (
            {
                value: item,
                label: item
            }
        ));
        setFormOptions(options);
    }, []);

    useEffect(() => {
        let profileData = pathOr('', ['request', 'data', 'profile'], dataRequest);
        //DOCUMENTS START
        let documents = [];
        let allDocuments = pathOr('', ['request', 'data', 'documents'], dataRequest);
        let allTypes = ["CompanyRegistrationStatement", "ICOAssignmentConfirmation", "DICRegistrationStatement", "MinuteOfTheInauguralMeeting"]

        if (type === RequestTypesEnum.CLUB_UPDATE) {
            Object.values(pathOr('', ['request', 'data', 'documents'], dataRequest)).map(item => {
                documents.push({ ...item, checked: true })
            })

        } else {
            for (var i = 0; i < 4; i++) {
                (Object.values(allDocuments).find(item => item.type === allTypes[i]))
                    ? Object.values(pathOr('', ['request', 'data', 'documents'], dataRequest)).filter(t => t.type === allTypes[i]).map(item => {
                        documents.push({ ...item, checked: true })
                    })
                    : documents.push({ status: "Empty", type: allTypes[i], validFrom: null, validTo: null, files: [], checked: true })
            }
        }

        setRows(documents)
        // DOCUMENTS END

        //setPhoto(pathOr('', ['request', 'data', 'profile', 'logoUrl'], dataRequest))

        let statutory = {
            useCorrespondenceAddress: true,
            statutoryEmail: pathOr('', ['request', 'data', 'functionary', 'profile', 'email'], dataRequest),
            statutoryFirstName: pathOr('', ['request', 'data', 'functionary', 'profile', 'firstName'], dataRequest),
            statutoryLastName: pathOr('', ['request', 'data', 'functionary', 'profile', 'lastName'], dataRequest),
            statutoryPhone: pathOr('', ['request', 'data', 'functionary', 'profile', 'phone'], dataRequest),
            statutoryAddress1: pathOr('', ['request', 'data', 'functionary', 'profile', 'address1'], dataRequest),
            statutoryAddress2: pathOr('', ['request', 'data', 'functionary', 'profile', 'address2'], dataRequest),
            statutoryCity: pathOr('', ['request', 'data', 'functionary', 'profile', 'city'], dataRequest),
            statutoryPostal: pathOr('', ['request', 'data', 'functionary', 'profile', 'postal'], dataRequest),
            statutoryCountry: pathOr('', ['request', 'data', 'functionary', 'profile', 'country'], dataRequest),
        }


        setInitialValuesNew({
            ...statutory
        });

        setInitialValues({
            ...profileData,
            ...statutory,
            bid: pathOr('', ['request', 'data', 'club', 'bid'], dataRequest),
            isYouthWorker: pathOr('', ['request', 'data', 'functionary', 'isYouthWorker'], dataRequest),
            type: pathOr('', ['request', 'type'], dataRequest),
        })
    }, [dataRequest]);

    const getActions = () => {
        if (role !== RoleEnum.MEMBER && view === 0)
            return [
                {
                    title: (readMode) ? "Režim úprav" : "Režim zobrazenie",
                    icon: (readMode) ? <EditIcon /> : <MenuBookIcon />,
                    onClick: () => setReadMode(readMode ? false : true),
                },
            ];
    };


    const getResult = (values) => {
        const result = { ...values };
        delete result.__typename;
        delete result.gdpr;
        delete result.logoUrl;
        delete result.bid;
        delete result.statutoryAddress1;
        delete result.statutoryAddress2;
        delete result.statutoryCity;
        delete result.statutoryCountry;
        delete result.statutoryEmail;
        delete result.statutoryFirstName;
        delete result.statutoryLastName;
        delete result.statutoryPhone;
        delete result.statutoryPostal;
        delete result.correspondenceAddress;
        delete result.description;
        //TODO - just temporary deleted
        delete result.type;
        delete result.isYouthWorker;
        delete result.nationality;
        return result;
    };

    const getDocuments = () => {
        let documents
        documents = rows?.filter(i => i.status === "Uploaded" || i.status === "AdminUploaded").map((item) => (
            {
                validFrom: (item?.validFrom) ? (item?.validFrom) : undefined,
                validTo: (item?.validTo) ? (item?.validTo) : undefined,
                type: (item?.type),
                files: (item?.files)?.map(item => item.file)
            }
        ));
        return documents;
    }

    const handleClubRegisterRequestApprove = (values) => {
        const result = getResult(values)
        let documents = getDocuments()
        /*if (cantDocumentsUpload)
            enqueueSnackbar("Niesú nahraté všetky povinné dokumenty", { variant: 'warning' });*/
        if (cantDocumentsApprove)
            enqueueSnackbar("Musíte schváliť všetky dokumenty", { variant: 'warning' });
        else {
            setDisableButton(true);
            clubApprove({
                variables: {
                    id: requestId,

                    input: {
                        profile: {
                            ...result,
                            logo: (photo) ? photo : undefined,
                        },
                        documents: {
                            upload: documents,
                            removeIds: (removeDocuments.length > 0) ? removeDocuments : undefined,
                            approveIds: (approveDocuments.length > 0) ? approveDocuments : undefined,
                            rejectIds: (rejectDocuments.length > 0) ? rejectDocuments : undefined,
                        },


                    }
                }
            }).then((response) => {
                enqueueSnackbar("Žiadosť o klub bola schválená", { variant: 'success' });
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }

        setApproveSubmit(false);
        setUpdateModal(false);

    };

    const handleClubRegisterRequestCancel = () => {
        setDisableButton(true);
        clubCancel({
            variables: {
                id: requestId,
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o klub bola zrušená", { variant: 'success' });
            refetchRequest();
            getPendingRequestsNumber();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
        setCancelModal(false)
    };

    const handleClubRegisterRequestReject = () => {
        setDisableButton(true);
        clubReject({
            variables: {
                id: requestId,
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o klub bola zamietnutá", { variant: 'success' });
            refetchRequest();
            getPendingRequestsNumber();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
        setRejectModal(false)
    };


    const handleClubRegisterRequestUpdate = (values) => {
        const result = getResult(values)
        let documents = getDocuments()
        if (cantDocumentsUpload)
            enqueueSnackbar("Nemáte nahraté všetky povinné dokumenty", { variant: 'warning' });
        else {
            setDisableButton(true);
            clubUpdate({
                variables: {
                    id: requestId,

                    input: {
                        profile: {
                            ...result,
                            logo: (photo) ? photo : undefined,
                        },
                        documents: {
                            upload: documents,
                            removeIds: (removeDocuments.length > 0) ? removeDocuments : undefined,
                            approveIds: (approveDocuments.length > 0) ? approveDocuments : undefined,
                            rejectIds: (rejectDocuments.length > 0) ? rejectDocuments : undefined,
                        },
                        functionary: (type === RequestTypesEnum.CLUB_UPDATE) ? undefined : {
                            isYouthWorker: (values?.isYouthWorker) ? true : false
                        }
                    }
                }
            }).then((response) => {
                enqueueSnackbar("Vaša upravená žiadosť bola odoslaná na schválenie", { variant: 'success' });
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }
        setUpdateModal(false);
        setReview(false);
    }

    const handleClubRegisterRequestReview = (description, values) => {
        const result = getResult(values)
        let documents = getDocuments();
        setDisableButton(true);
        if (view === 4)
            clubReview({
                variables: {
                    id: requestId,
                    description: description?.description,
                    input: {
                        profile: {
                            ...result,
                            logo: (photo) ? photo : undefined,

                        },
                        documents: {
                            upload: documents,
                            removeIds: (removeDocuments.length > 0) ? removeDocuments : undefined,
                            approveIds: (approveDocuments.length > 0) ? approveDocuments : undefined,
                            rejectIds: (rejectDocuments.length > 0) ? rejectDocuments : undefined,
                        },
                        functionary: (type === RequestTypesEnum.CLUB_UPDATE) ? undefined : {
                            isYouthWorker: (values?.isYouthWorker) ? true : false
                        }
                    }
                }
            }).then((response) => {
                enqueueSnackbar("Žiadosť klubu bola vrátená štatutárovi na zmenu", { variant: 'success' })
                refetchRequest();
                getPendingRequestsNumber();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        setUpdateModal(false);
        setReview(false);
    }


    const renderApproveModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    onClick={() => handleClubRegisterRequestApprove(formikProps?.values)}
                    table
                    color="success"
                    round
                    size="sm"
                >
                    <CheckIcon /> Schváliť žiadosť
                </Button>
            </>
        );
    };

    const renderUpdateModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    table
                    onClick={() => handleClubRegisterRequestUpdate(formikProps?.values)}
                    color="success"
                    round
                    size="sm"
                >
                    <CheckIcon /> Odoslať žiadosť
                </Button>
            </>
        );
    };

    const renderRejectModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    onClick={() => handleClubRegisterRequestReject()}
                    color="danger"
                    table
                    round
                    size="sm"
                >
                    <BlockIcon /> Zamietnuť žiadosť
                </Button>
            </>
        );
    };

    const renderCancelModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    table
                    onClick={() => handleClubRegisterRequestCancel()}
                    color="danger"
                    round
                    size="sm"
                >
                    <BlockIcon /> Záväzne zrušiť žiadosť
                </Button>
            </>
        );
    };


    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTitle = () => {
        //if (view === 1)
        if (pathOr('', ['request', 'status'], dataRequest) === "Approved") return <Box style={{ color: colors.success }}>Žiadosť bola schválená </Box>
        else if (pathOr('', ['request', 'status'], dataRequest) === "Rejected") return <Box style={{ color: colors.error }}>Žiadosť bola zamietnutá </Box>
        else if (pathOr('', ['request', 'status'], dataRequest) === "Canceled") return <Box style={{ color: colors.error }}>Žiadosť bola zrušená </Box>
        else if (pathOr('', ['request', 'status'], dataRequest) === "Returned") return <Box style={{ color: colors.primary }}>Žiadosť vrátená na doplnenie </Box>
        else if (pathOr('', ['request', 'status'], dataRequest) === "Awaiting") return <Box style={{ color: colors.warning }}>Žiadosť čaká na schválenie </Box>
        else return <Box>{intl.formatMessage({ id: 'club.request.title.waiting' })}</Box>

        //else if (view === 2) return <Box style={{ color: colors.warning }}>{intl.formatMessage({ id: 'club.request.title.sendBack' })}</Box>
        //else return <Box>{intl.formatMessage({ id: 'club.request.title.new' })}</Box>
    }

    const getBackgroundColor = () => {
        if (pathOr('', ['request', 'status'], dataRequest) === "Approved") return colors.successBackground
        else if (pathOr('', ['request', 'status'], dataRequest) === "Rejected") return colors.errorBackground
        else if (pathOr('', ['request', 'status'], dataRequest) === "Canceled") return colors.errorBackground
        else if (pathOr('', ['request', 'status'], dataRequest) === "Returned") return colors.primaryBackground
        else if (pathOr('', ['request', 'status'], dataRequest) === "Awaiting") return colors.warningBackground
        else return 'rgba(60,72,88, 0.6)'
    }

    const getSendButton = (formikProps) => {
        return (<Box className={classes.floatRight}>
            <br />
            {(role !== RoleEnum.MEMBER &&
                < Button
                    color="primary"
                    size="sm"
                    round
                    onClick={() => setReadMode(readMode ? false : true)}
                //onClick={() => (readMode) ? setReadMode(readMode ? false : true) : formikProps.handleSubmit()}
                >
                    {(readMode) ? <><EditIcon /> {intl.formatMessage({ id: 'button.editMode' })}</> : <>{(role === RoleEnum.ADMIN)
                        ? <><CheckIcon /> {intl.formatMessage({ id: 'button.sendChanges' })}</>
                        : <><CheckIcon /> {intl.formatMessage({ id: 'button.saveChanges' })}</>
                    }</>}
                </Button>
            )}
        </Box >)
    }

    if (loadingRequest) return <Loading />;
    return (<>
        <Formik
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize
            validationSchema={getValidationSchemaRequestUpdate(intl)}
            onSubmit={(values) => {
                (role === RoleEnum.ADMIN)
                    ? handleClubRegisterRequestApprove(values)
                    : handleClubRegisterRequestUpdate(values)
            }}
            initialValues={(view === 3) ? initialValuesNew : initialValues}
        >
            {(formikProps) => (<>
                <PageHeader
                    title={getTitle()}
                    subTitle={pathOr('', ['request', 'bid'], dataRequest)}
                />
                <Grid container spacing={1} >
                    <Grid item xs={12} lg={8}>


                        <Grid item xs={12}>
                            <ClubCard
                                inside
                                refetch={refetchRequest}
                                formikProps={formikProps}
                                title={null}
                                validateParam={validateParam}
                                readMode={view === 1 ? true : false}
                                button={getSendButton(formikProps)}
                                photo={pathOr('', ['request', 'data', 'profile', 'logoUrl'], dataRequest)}
                                setPhoto={(newValue) => setPhoto(newValue)}
                                clubRequest={true}
                                request={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LegalEntityCard
                                data={formikProps.values}
                                formikProps={formikProps}
                                newCompany={true}
                                validateParam={validateParam}
                                request={true}
                                //closed={view === 1 ? true : false}
                                readMode={view === 1 ? true : false}
                                options={formOptions}
                                highlightChange={(view === 3) ? false : true}
                            />
                        </Grid>
                        {(type !== RequestTypesEnum.CLUB_UPDATE) &&
                            <Grid item xs={12}>
                                <PresidentCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    newCompany={true}
                                    validateParam={validateParam}
                                    readMode={true}
                                    readModeYouthWorker={view === 1 ? true : false}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DocumentsCard
                                data={formikProps.values}
                                formikProps={formikProps}
                                newCompany={true}
                                validateParam={validateParam}
                                detail={false}
                                closed={view === 1 ? true : false}
                                callbackRows={(newValue) => setRows(newValue)}
                                rows={rows}
                                removeDocuments={removeDocuments}
                                approveDocuments={approveDocuments}
                                rejectDocuments={rejectDocuments}
                                callbackRemoveDocuments={(newValue) => setRemoveDocuments(newValue)}
                                callbackApproveDocuments={(newValue) => setApproveDocuments(newValue)}
                                callbackRejectDocuments={(newValue) => setRejectDocuments(newValue)}
                                role={role}
                                status={pathOr('', ['request', 'status'], dataRequest)}
                                request={true}
                                updateClub={(type === RequestTypesEnum.CLUB_UPDATE) ? true : false}
                            />
                        </Grid>
                        <Grid container spacing={1} justify="center">
                            {view === 3 ?
                                <Grid item xs={12}>
                                    <FormikField
                                        labelText={intl.formatMessage({ id: 'club.form.gdpr' })}
                                        type="checkbox"
                                        name="gdpr"
                                        fullWidth={false}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        closed={view === 1 ? true : false}
                                        highlightChange={false}
                                    />
                                </Grid>
                                : <Grid item xs={12}></Grid>

                            }
                            <Grid item sm={12}><br /></Grid>
                            {((pathOr('', ['request', 'status'], dataRequest)) !== "Rejected") && <>
                                {(view !== 3 && (pathOr('', ['request', 'status'], dataRequest) !== "Approved" && pathOr('', ['request', 'status'], dataRequest) !== "Rejected") && (pathOr('', ['request', 'status'], dataRequest) !== "Canceled")) &&
                                    <>
                                        <Button
                                            align="center"
                                            color="primary"
                                            size="sm"
                                            round
                                            //onClick={() => { (role === RoleEnum.ADMIN) ? handleClubRegisterRequestReject() : handleClubRegisterRequestCancel() }}
                                            onClick={() => { (role === RoleEnum.ADMIN) ? setRejectModal(true) : setCancelModal(true) }}
                                        >
                                            {(role === RoleEnum.ADMIN)
                                                ? <><BlockIcon /> Zamietnuť žiadosť</>
                                                : <><BlockIcon /> {intl.formatMessage({ id: 'club.button.deleteRequest' })}</>
                                            }
                                        </Button>
                                        {(role === RoleEnum.ADMIN && view !== 4) &&
                                            <Button
                                                align="center"
                                                color="primary"
                                                size="sm"
                                                round
                                                onClick={() => setView(4)}
                                            >
                                                <><EditIcon /> Opäť editovať</>
                                            </Button>
                                        }
                                    </>
                                }

                                {(view !== 1 && (pathOr('', ['request', 'status'], dataRequest) !== "Approved" && pathOr('', ['request', 'status'], dataRequest) !== "Rejected") && (pathOr('', ['request', 'status'], dataRequest) !== "Canceled")) &&
                                    <Button
                                        align="center"
                                        color="primary"
                                        size="sm"
                                        disabled={disableButton}
                                        round
                                        onClick={() => { setValidateParam(true); (formikProps?.isValid) && (role === RoleEnum.ADMIN) && setApproveSubmit(true); setReview(false); formikProps.handleSubmit() }}
                                    >
                                        {(role === RoleEnum.ADMIN)
                                            ? <><CheckIcon /> Schváliť žiadosť </>
                                            : <><CheckIcon /> {intl.formatMessage({ id: 'club.button.sendRequest' })} </>
                                        }
                                    </Button>
                                }
                            </>
                            }
                            <Modal
                                title={"Schválenie žiadosti"}
                                open={approveModal}
                                onClose={() => setApproveModal(false)}
                                actions={renderApproveModalButtons(formikProps)}
                                fullWidth
                            >
                                Naozaj chcete schváliť túto žiadosť?
                            </Modal>
                            <Modal
                                title={"Pred odoslaním žiadosti na schválenie si prosím skontrolujte povinné údaje!."}
                                open={updateModal}
                                maxWidth={"md"}
                                onClose={() => setUpdateModal(false)}
                                actions={renderUpdateModalButtons(formikProps)}
                                fullWidth
                            >

                                <ControlRequestDataCard
                                    formikProps={formikProps}
                                />
                            </Modal>
                            <Modal
                                title={"Zrušenie žiadosti"}
                                open={cancelModal}
                                onClose={() => setCancelModal(false)}
                                actions={renderCancelModalButtons(formikProps)}
                                fullWidth
                            >
                                Naozaj chcete zrušiť túto žiadosť?
                            </Modal>
                            <Modal
                                title={"Zamietnutie žiadosti"}
                                open={rejectModal}
                                onClose={() => setRejectModal(false)}
                                actions={renderRejectModalButtons(formikProps)}
                                fullWidth
                            >
                                Naozaj chcete zamietnuť túto žiadosť?
                            </Modal>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <CommentsCard
                            data={formikProps.values}
                            formikProps={formikProps}
                            disableButton={disableButton}
                            handleClubRegisterRequestReview={(values1, values2) => handleClubRegisterRequestReview(values1, values2)}
                            //photo={pathOr('', ['profile', 'photoUrl'], data)}
                            comment={pathOr('', ['request', 'reviews'], dataRequest)}
                            validateParam={validateParam}
                            returnButton={() => { setReview(true); setValidateParam(true); formikProps.handleSubmit() }}
                            view={view}
                        />
                    </Grid>
                </Grid>
            </>)
            }
        </Formik >
    </>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});
const mapDispatchToProps = (dispatch) => ({
    changePendingRequestsNumberAction: (value) => dispatch(changePendingRequestsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RequestDetailPage));