import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import FormikField from "components-lib/FormikField/FormikField";
import Button from "components/CustomButtons/Button.js";
import CHANGE_PASSWORD from 'queries/ProfileQueries/changePassword';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Formik } from "formik";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { getValidationSchemaChangePassword } from "../validationSchema";

const useStyles = makeStyles((theme) => styles(theme))

const ChangePasswordCard = (props) => {
    const { intl } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [changePassword] = useMutation(CHANGE_PASSWORD)
    const [disableButton, setDisableButton] = useState(false);

    const handleChangePassword = (values, resetForm) => {
        setDisableButton(true);
        changePassword({
            variables: {
                password: values?.actualPassword,
                newPassword: values?.newPassword
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "profile.changePasswordCard.success" }), { variant: 'success' });
            resetForm();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    return (
        <Formik
            onSubmit={(values, { resetForm }) => handleChangePassword(values, resetForm)}
            validationSchema={getValidationSchemaChangePassword(intl)}
            initialValues={{}}
            enableReinitialize
        >
            {(formikProps) => (
                <IconCard title={intl.formatMessage({ id: 'profile.changePasswordCard.title' })}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormikField
                                name="actualPassword"
                                labelText={intl.formatMessage({ id: 'profile.changePasswordCard.actualPassword' })}
                                formikProps={formikProps}
                                type="password"
                                highlightChange={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikField
                                name="newPassword"
                                labelText={intl.formatMessage({ id: 'profile.changePasswordCard.newPassword' })}
                                formikProps={formikProps}
                                type="password"
                                highlightChange={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikField
                                name="newPasswordVerify"
                                labelText={intl.formatMessage({ id: 'profile.changePasswordCard.newPasswordVerify' })}
                                formikProps={formikProps}
                                type="password"
                                highlightChange={false}
                            />
                        </Grid>
                    </Grid>
                    <Box className={classes.floatRight}>
                        <br />
                        <Button
                            disabled={disableButton}
                            color="primary"
                            size="sm"
                            round
                            table
                            onClick={() => formikProps.handleSubmit()}
                        >
                            <VpnKeyIcon /> {intl.formatMessage({ id: 'profile.changePasswordCard.button.change' })}
                        </Button>
                    </Box>
                </IconCard>
            )}
        </Formik>
    )
}

export default injectIntl(ChangePasswordCard);