import gql from 'graphql-tag';

const CHANGE_CLUB = gql`
mutation clubMemberChangeClub($id: ID!, $clubId: ID!) {
  clubMemberChangeClub(id: $id, clubId: $clubId)
}
`;

export default CHANGE_CLUB;

