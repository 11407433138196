import { Grid } from "@material-ui/core";
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { colors, variables } from 'common/styles/configLayout';
import Loading from "components-lib/Loading/Loading";
import legalEntityFormEnum from "common/enums/LegalEntityFormEnum"
import Divider from '@material-ui/core/Divider';
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.js";
import CLUB_PRESIDENT_CHANGE from "queries/ClubsQueries/clubPresidentChange";
import { useQuery, useMutation } from '@apollo/client';
import { pathOr } from 'rambda';
import CachedIcon from '@material-ui/icons/Cached';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import BlockIcon from '@material-ui/icons/Block';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getApolloClient } from "configFiles/apollo";
import RoleEnum from 'common/enums/RoleEnum';

const useStyles = makeStyles((theme) => styles(theme));

const LegalEntityCard = (props) => {
  const { intl, history, role, formikProps, loading, title = true, validateParam, highlightChange, readMode = false, closed = false,
    setReadMode, button, request = false, options, clubId, refetchClub, allLocations } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const client = getApolloClient(enqueueSnackbar, history, intl);

  const [changePresident, setChangePresident] = useState(false)

  const [clubPresidentChange] = useMutation(CLUB_PRESIDENT_CHANGE)
  const [disableButton, setDisableButton] = useState(false);


  const handleClubPresidentChange = (presidentId) => {
    setDisableButton(true);
    clubPresidentChange({
      variables: {
        id: clubId,
        memberId: presidentId
      }
    }).then((response) => {
      enqueueSnackbar("Predseda klubu bol zmenený", { variant: 'success' });
      refetchClub();
      setDisableButton(false);
      //setReadMode(true)
    }).catch((err) => {
      console.log('[Error]: ', err);
      setDisableButton(false);
    });
  }

  const renderContent = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={(!request && ((formikProps?.values?.type === "StandardDomestic") || (formikProps?.values?.type === "UCIDomestic"))) ? 4 : 6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'club.legalEntityCard.basicInformation' })}</Typography></Grid>
            <Grid item xs={12}>
              <FormikField
                name="businessName"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.name' })}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                highlightChange={highlightChange}
                mandatory={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="legalForm"
                type="autocomplete"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.legalForm' })}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                options={legalEntityFormEnum(intl)}
                highlightChange={highlightChange}
                mandatory={(formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="ico"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.ico' })}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                type="number"
                highlightChange={highlightChange}
                mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="dic"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.dic' })}
                readMode={readMode}
                type="number"
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
              />
            </Grid>
            {/*<Grid item xs={12} sm={6}>
              <FormikField
                name="registrationNumber"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.mvSrRegistration' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="registrationDate"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.mvSrRegistrationDate' })}
                formikProps={formikProps}
                validateParam={validateParam}
                //disabled={true}
                type="datePicker"
                disabled={closed}
                readMode={readMode}
                highlightChange={highlightChange}
              />
            </Grid>*/}
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >{intl.formatMessage({ id: 'club.legalEntityCard.paymentDetails' })}</Typography></Grid>
            <Grid item xs={12}>
              <FormikField
                name="bankIBAN"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.iban' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="bankLocation"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.bankLocation' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="bankSWIFT"
                labelText="SWIFT"
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={(!request && ((formikProps?.values?.type === "StandardDomestic") || (formikProps?.values?.type === "UCIDomestic"))) ? 4 : 6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >{intl.formatMessage({ id: 'club.legalEntityCard.officeAddress' })}</Typography></Grid>
            <Grid item xs={12} sm={8}>
              <FormikField
                name="address1"
                labelText={intl.formatMessage({ id: 'address.street' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
                readMode={readMode}
                mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormikField
                name="address2"
                labelText={intl.formatMessage({ id: 'address.streetNumber' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
                readMode={readMode}
                mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormikField
                name="postal"
                labelText={intl.formatMessage({ id: 'address.postal' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
                readMode={readMode}
                mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
              />
            </Grid>
            <Grid item xs={12} sm={8} >
              <FormikField
                name="city"
                disabled={!formikProps?.values?.country}
                labelText={intl.formatMessage({ id: 'address.city' })}
                type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
                options={(formikProps?.values?.country === variables.defaultCountry) ?  allLocations.cities : []}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                readMode={readMode}
                mandatory={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="country"
                labelText={intl.formatMessage({ id: 'address.country' })}
                type="autocomplete"
                options={allLocations.countries}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                disabled={closed}
                readMode={readMode}
                mandatory={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="district"
                disabled={!formikProps?.values?.country}
                labelText="Okres"
                type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
                options={(formikProps?.values?.country === variables.defaultCountry) ? allLocations.districts : []}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={highlightChange}
                readMode={readMode}
                mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
              />
            </Grid>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >Korešpondenčná adresa</Typography></Grid>
            {//(readMode && formikProps?.values?.useCorrespondenceAddress) &&
              <Grid item xs={12} style={{ marginTop: !readMode && "-15px" }}>
                <FormikField
                  labelText={intl.formatMessage({ id: 'club.legalEntityCard.correspondenceAddress.checkbox' })}
                  type="checkbox"
                  name="useCorrespondenceAddress"
                  formikProps={formikProps}
                  highlightChange={false}
                  disabled={closed}
                  readMode={readMode}
                />
              </Grid>
            }
            {!formikProps.values.useCorrespondenceAddress &&
              <>
                <Grid item xs={12} sm={8}>
                  <FormikField
                    name="correspondenceAddress1"
                    labelText={intl.formatMessage({ id: 'address.street' })}
                    formikProps={formikProps}
                    validateParam={validateParam}
                    highlightChange={highlightChange}
                    disabled={closed}
                    readMode={readMode}
                    mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormikField
                    name="correspondenceAddress2"
                    labelText={intl.formatMessage({ id: 'address.streetNumber' })}
                    formikProps={formikProps}
                    validateParam={validateParam}
                    highlightChange={highlightChange}
                    disabled={closed}
                    readMode={readMode}
                    mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormikField
                    name="correspondencePostal"
                    labelText={intl.formatMessage({ id: 'address.postal' })}
                    formikProps={formikProps}
                    validateParam={validateParam}
                    highlightChange={highlightChange}
                    disabled={closed}
                    readMode={readMode}
                    mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
                  />
                </Grid>
                <Grid item xs={12} sm={8} >
                  <FormikField
                    name="correspondenceCity"
                    disabled={!formikProps?.values?.country}
                    labelText={intl.formatMessage({ id: 'address.city' })}
                    type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
                    options={(formikProps?.values?.country === variables.defaultCountry) ?  allLocations.cities : []}
                    formikProps={formikProps}
                    validateParam={validateParam}
                    highlightChange={highlightChange}
                    readMode={readMode}
                    mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikField
                    name="correspondenceCountry"
                    labelText={intl.formatMessage({ id: 'address.country' })}
                    type="autocomplete"
                    options={allLocations.countries}
                    formikProps={formikProps}
                    validateParam={validateParam}
                    highlightChange={highlightChange}
                    disabled={closed}
                    readMode={readMode}
                    mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikField
                    name="correspondenceDistrict"
                    disabled={!formikProps?.values?.country}
                    labelText="Okres"
                    type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
                    options={(formikProps?.values?.country === variables.defaultCountry) ? allLocations.districts : []}
                    formikProps={formikProps}
                    validateParam={validateParam}
                    highlightChange={highlightChange}
                    readMode={readMode}
                    mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))}
                  />
                </Grid>
              </>
            }
          </Grid>
        </Grid>
        {!request &&
          <>
            {((formikProps?.values?.type === "StandardDomestic") || (formikProps?.values?.type === "UCIDomestic")) &&
              <>
                {(readMode || role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) ?
                  <Grid item xs={12} md={4}>
                    <Grid container spacing={1}>
                      {readMode && <>
                        <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{'Štatutár'}</Typography></Grid>
                        <Grid item xs={6}>
                          <FormikField
                            name="statutoryFirstName"
                            labelText={'Meno'}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            disabled={closed}
                            readMode={true}
                            highlightChange={highlightChange}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikField
                            name="statutoryLastName"
                            labelText={'Priezvisko'}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            disabled={closed}
                            readMode={true}
                            options={legalEntityFormEnum(intl)}
                            highlightChange={highlightChange}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormikField
                            name="statutoryPhone"
                            labelText={'Telefónne číslo'}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            disabled={closed}
                            readMode={true}
                            highlightChange={highlightChange}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormikField
                            name="statutoryEmail"
                            labelText={'E-mail'}
                            readMode={true}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={highlightChange}
                            disabled={closed}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{'Predseda'}</Typography></Grid>
                      </>
                      }
                      {(!changePresident) && <>
                        {(formikProps?.values?.statutoryEmail === formikProps?.values?.presidentEmail) ?
                          <Grid item xs={12} style={{ marginTop: !readMode && "-15px" }}>
                            {(!changePresident) &&
                              <Grid item xs={12}>
                                <FormikField
                                  labelText={"Štatutár je zároveň predseda"}
                                  type="checkbox"
                                  name="isStatutoryPresident"
                                  formikProps={formikProps}
                                  highlightChange={false}
                                  disabled={closed}
                                  readMode={readMode}
                                />
                              </Grid>
                            }
                          </Grid>
                          :
                          <>
                            <Grid item xs={6}>
                              <FormikField
                                name="presidentFirstName"
                                labelText={'Meno'}
                                formikProps={formikProps}
                                validateParam={validateParam}
                                disabled={closed}
                                readMode={readMode}
                                highlightChange={highlightChange}
                                mandatory={true}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormikField
                                name="presidentLastName"
                                labelText={'Priezvisko'}
                                formikProps={formikProps}
                                validateParam={validateParam}
                                disabled={closed}
                                readMode={readMode}
                                options={legalEntityFormEnum(intl)}
                                highlightChange={highlightChange}
                                mandatory={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormikField
                                name="presidentPhone"
                                labelText={'Telefónne číslo'}
                                formikProps={formikProps}
                                validateParam={validateParam}
                                disabled={closed}
                                readMode={readMode}
                                highlightChange={highlightChange}
                                mandatory={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormikField
                                name="presidentEmail"
                                labelText={'E-mail'}
                                readMode={readMode}
                                formikProps={formikProps}
                                validateParam={validateParam}
                                highlightChange={highlightChange}
                                disabled={closed}
                                mandatory={true}
                              />
                            </Grid>
                          </>
                        }
                      </>
                      }
                      {(role === RoleEnum.STATUTORY) &&
                        <>
                          {(!changePresident) ? <>
                            <Grid item xs={12}>
                              <Box className={classes.floatRight}>
                                <Button
                                  color="primary"
                                  size="sm"
                                  round
                                  table
                                  onClick={() => { setChangePresident(true) }}
                                >
                                  <CachedIcon /> Zmeniť predsedu
                                </Button>
                              </Box>
                            </Grid>
                          </>
                            :
                            <>
                              <Grid item xs={12} sm={12}>
                                <FormikField
                                  name="newPresident"
                                  labelText={'Vyberte nového predsedu'}
                                  type="select"
                                  options={options}
                                  readMode={false}
                                  formikProps={formikProps}
                                  validateParam={validateParam}
                                  highlightChange={highlightChange}
                                  disabled={closed}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Box className={classes.readOnlyGrid}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                      <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                        <span >Meno a priezvisko:</span>
                                        <Box className={classes.floatRight}>
                                          <span style={{ color: "white" }}>{formikProps?.values?.presidentFirstName + " " + formikProps?.values?.presidentLastName} </span>
                                        </Box>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                        <span >E-mail:</span>
                                        <Box className={classes.floatRight}>
                                          <span style={{ color: "white" }} >{formikProps?.values?.presidentEmail} </span>
                                        </Box>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                        <span >Telefón:</span>
                                        <Box className={classes.floatRight}>
                                          <span style={{ color: "white" }}> {formikProps?.values?.presidentPhone} </span>
                                        </Box>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>

                              <Grid item xs={12}>
                                <Box className={classes.floatRight}>
                                  <Button
                                    color="primary"
                                    size="sm"
                                    disabled={disableButton}
                                    round
                                    table
                                    onClick={() => { handleClubPresidentChange(formikProps?.values?.newPresident); setChangePresident(false) }}
                                  >
                                    <SaveAltIcon /> Potvrdiť
                                  </Button>
                                  <Button
                                    size="sm"
                                    round
                                    table
                                    onClick={() => { setChangePresident(false) }}
                                  >
                                    <BlockIcon /> Zrušiť
                                  </Button>
                                </Box>
                              </Grid>
                            </>
                          }
                        </>
                      }


                    </Grid>
                  </Grid>
                  :
                  <Grid item xs={12} md={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{'Štatutár'}</Typography></Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikField
                          name="newStatutory"
                          labelText={'Vyberte nového štatutára'}
                          readMode={readMode}
                          type="select"
                          options={options}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          highlightChange={highlightChange}
                          disabled={closed}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box className={classes.readOnlyGrid}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                <span >Meno a priezvisko: </span>
                                <Box className={classes.floatRight}>
                                  <span style={{ color: "white" }}>{formikProps?.values?.statutoryFirstName + " " + formikProps?.values?.statutoryLastName} </span>
                                </Box>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                <span >E-mail: </span>
                                <Box className={classes.floatRight}>
                                  <span style={{ color: "white" }}>{formikProps?.values?.statutoryEmail} </span>
                                </Box>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                <span >Telefón: </span>
                                <Box className={classes.floatRight}>
                                  <span style={{ color: "white" }}>{formikProps?.values?.statutoryPhone} </span>
                                </Box>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}><br /><Typography variant="body1" style={{ color: colors.primary }}>{'Predseda'}</Typography></Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikField
                          name="newPresident"
                          labelText={'Vyberte nového predsedu'}
                          type="select"
                          options={options}
                          readMode={readMode}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          highlightChange={highlightChange}
                          disabled={closed}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box className={classes.readOnlyGrid}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                <span >Meno a priezvisko:</span>
                                <Box className={classes.floatRight}>
                                  <span style={{ color: "white" }}>{formikProps?.values?.presidentFirstName + " " + formikProps?.values?.presidentLastName} </span>
                                </Box>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                <span >E-mail:</span>
                                <Box className={classes.floatRight}>
                                  <span style={{ color: "white" }} >{formikProps?.values?.presidentEmail} </span>
                                </Box>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body2" style={{ fontSize: "0.9em", color: colors.primary }}>
                                <span >Telefón:</span>
                                <Box className={classes.floatRight}>
                                  <span style={{ color: "white" }}> {formikProps?.values?.presidentPhone} </span>
                                </Box>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </>
            }
          </>
        }
        {
          !request &&
          <>
            <Grid item xs={12} >
              <Divider style={{ backgroundColor: 'rgba(60,72,88, 0.4)' }} />
            </Grid>
            <Grid item xs={12} >
              {button}
            </Grid>
          </>
        }
      </Grid >
    )
  }

  if (loading) return <Loading />

  return (
    <>
      <IconCard title={(title) && intl.formatMessage({ id: 'club.legalEntityCard.title' })}>
        {renderContent()}
      </IconCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  allLocations: state.allLocations,
  role: state.role,
});

export default connect(mapStateToProps)(injectIntl(LegalEntityCard));

