import gql from 'graphql-tag';

const CLUB_TRANSFER_REQUEST_CREATE= gql`
mutation clubTransferRequestCreate($input: ClubTransferRequestCreateInput!) { 
  clubTransferRequestCreate(input: $input) {
    id
    bid
   }
} 
`;

export default CLUB_TRANSFER_REQUEST_CREATE;