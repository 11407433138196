import gql from 'graphql-tag';

const CLUB_MEMBERS = gql`
query clubMembers($offset: Int, $limit: Int, $sort: [ClubMemberSortInput!], $filter: [ClubMemberFilterInput]) {
	clubMembers (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
		totalCount,
    items {
      id
      uciId
      bid
      isSuspended
      person {
        profile {
          firstName
          lastName
          photoUrl
          birth
          email
          personalId
        }
      }
      club {
        id 
      }
    }
  }
}
`;

export default CLUB_MEMBERS;

