import gql from 'graphql-tag';

const MEMBER_LICENSE_OPTIONS = gql`
query memberLicenseOptions($input: MemberLicenseOptionsInput!) {
	memberLicenseOptions (input: $input) {
		member {
      id
      bid
      uciId
      person{
        profile{
          firstName
          lastName
          birth
          personalId
        }
      }
    }
    missingRequirements{
        ... on MemberLicensePhotoRequirement {
       		photo
        }
        ... on MemberLicenseDocumentRequirement {
       		document
        }
      }
    functions {
      id
      name
      missingRequirements{
        ... on MemberLicensePhotoRequirement {
       		photo
        }
        ... on MemberLicenseDocumentRequirement {
       		document
        }
      }
      disciplines {
        id
        name
        categories {
          id
          name
          missingRequirements{
        	... on MemberLicensePhotoRequirement {
       				photo
        	}
        	... on MemberLicenseDocumentRequirement {
       			document
        	}
      }
        }
      }
    }
  }
}
`;

export default MEMBER_LICENSE_OPTIONS;