import gql from 'graphql-tag';

const CLUB_HOSTING_REQUEST_RESPOND_AS_CLUB= gql`
mutation clubHostingRequestRespondAsClub($id: ID!, $clubId: ID!, $result:ResponseResult ) {
  clubHostingRequestRespondAsClub(id: $id, clubId: $clubId, result: $result) {
    id
    bid
  }
}
`;

export default CLUB_HOSTING_REQUEST_RESPOND_AS_CLUB;