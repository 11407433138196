import { Grid } from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { widgets, colors, } from 'common/styles/configLayout';
import Loading from "components-lib/Loading/Loading";
import EditIcon from '@material-ui/icons/Edit';
import defaultAvatar from "assets/img/placeholder.jpg";
import { StyledTableCell, StyledTableRow } from 'common/styles/muiDesign'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import moment from "moment";
import AddCircleIcon  from '@material-ui/icons/AddCircle';

const useStyles = makeStyles({
    readOnlyGrid: {
        backgroundColor: 'rgba(60,72,88, 0.4)',
        borderRadius: "4px",
        padding: "8px"
    },
    floatRight: {
        float: "right"
    },
});

const PaymentCard = (props) => {
    const { intl, readData, formikProps, loading, paymentData, companyUpdate, newCompany, title = true, problemBlock = null, validateParam, readMode = false, closed = false, setReadMode } = props;
    const classes = useStyles();

    const getSendButton = () => {
        //setValidateParam(true) 
        return (<Box className={classes.floatRight}>
            {(formikProps?.values?.status === "Pending") &&
                <>
                <br />
                    <Button
                        color="primary"
                        size="sm"
                        round
                        //onClick={() => handlePaymentPay()}
                    > <CreditCardIcon /> Online platba
                    </Button>
                   
                        <Button
                            color="primary"
                            size="sm"
                            round
                            //onClick={() => setModalOpen(true)}
                        > 
                        <AddCircleIcon  /> Zadať platbu
                        </Button>
                    
                </>
            }
        </Box >)
    }


    const renderLicense = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                    </Grid>
                        <Table aria-label="customized table">
                            <TableHead style={{ borderBottom: "none" }}>
                                <TableRow style={{ backgroundColor: 'rgba(60,72,88, 0.4)', borderRadius: "5px" }} >
                                    <StyledTableCell style={{ borderBottom: "none" }}>Druh licencie</StyledTableCell>
                                    <StyledTableCell style={{ borderBottom: "none" }}>Poplatok</StyledTableCell>
                                    <StyledTableCell style={{ borderBottom: "none" }}>Počet</StyledTableCell>
                                    <StyledTableCell style={{ borderBottom: "none" }}>Spolu</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell>Štandardný klub</StyledTableCell>
                                    <StyledTableCell>35,00 €</StyledTableCell>
                                    <StyledTableCell>1x</StyledTableCell>
                                    <StyledTableCell>35,00 €</StyledTableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    <Grid item xs={12} >
                        <Grid item xs={12} >
                            <Divider style={{ backgroundColor: 'rgba(60,72,88, 0.4)' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ marginBottom: "8px" }} className={classes.readOnlyGrid}>
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{ fontSize: "1.2em" }}>
                                        <span style={{ color: colors.warning }} >Poplatky celkom: </span>
                                        <Box className={classes.floatRight}>
                                            <span>35,00 €</span>
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderInfo = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box style={{ marginBottom: "8px" }} className={classes.readOnlyGrid}>
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{ fontSize: "1.2em" }}>
                                        <span style={{ color: colors.warning }} >Suma: </span>
                                        <Box className={classes.floatRight}>
                                        <span>{paymentData?.amount} €</span>
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Box>
                            <Box className={classes.readOnlyGrid}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "1em", color: colors.warning }}>
                                            <span >Účet:</span>
                                            <Box className={classes.floatRight}>
                                            <span>{paymentData?.bankIBAN}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    {/*<Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                            <span>Variabilný symbol:</span>
                                            <Box className={classes.floatRight}>
                                            <span>{paymentData?.variableSymbol}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>*/}
                                    <Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                            <span>Dátum fakturácie:</span>
                                            <Box className={classes.floatRight}>
                                            <span>{moment(paymentData?.createdAt).format("DD.MM.YYYY")}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                            <span>Dátum splatnosti: </span>
                                            <Box className={classes.floatRight}>
                                            <span>{moment(paymentData?.dueDate).format("DD.MM.YYYY")}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className="fileinput text-center">
                                <div className={"thumbnail"}>
                                    <img src={paymentData?.payBySquare} width="100%" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }

    if (loading) return <Loading />

    return (<>
        <IconCard title={<span style={{ color: colors.warning }}>Licenčné poplatky</span>}>
            {renderLicense()}
        </IconCard>
        <IconCard title={<span style={{ color: colors.warning }}>Platobné údaje</span>}>
            {renderInfo()}
            {getSendButton()}
        </IconCard>

    </>)
}

export default injectIntl(PaymentCard);