import gql from 'graphql-tag';

const PROFILE_DOCUMENT_UPLOAD= gql`
mutation ProfileDocumentUpload($documents: [PersonDocumentUploadInput!]!) { 
  profileDocumentUpload(documents: $documents) { 
      id 
      bid 
      type 
      status 
      name 
      createdAt 
      updatedAt 
      deletedAt 
      files { 
          size 
          format 
      } 
  } 
}
`;

export default PROFILE_DOCUMENT_UPLOAD;