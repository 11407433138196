import {
  primaryColor,
  primaryBoxShadow,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
import { colors, buttons, widgets } from "common/styles/configLayout.js";

const customSelectStyle = {
  select: {
    padding: "16px 2px 7px",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: "1.42857",
    textDecoration: "none",
    color: 'white',
    letterSpacing: "0",
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)"
    },
    "& + input + svg": {
      transition: "all 300ms linear"
    }
  },
  selectFormControl: {
    marginTop: "-4px",//TODO: Milan edited
    marginBottom: "-4px",//TODO: Milan edited
   // marginTop: "-4px 0 17px -4px !important",
  },
  selectLabel: {
    fontSize: "14px",
    fontFamily: 'Roboto',
    color: colors.textBody,
    top: "8px"
  },
  selectLabelError: {
    color: 'red !important'
  },
  selectMenu: {
    "& > div > ul": {
      fontFamily: 'Roboto',
      border: "0",
      padding: "5px 0",
      margin: "0",
      boxShadow: "none",
      minWidth: "100%",
      borderRadius: "4px",
      boxSizing: "border-box",
      display: "block",
      fontSize: "14px",
      textAlign: "left",
      listStyle: "none",
      backgroundColor: 'transparent',
      backgroundClip: "padding-box"
    },
    "& $selectPaper $selectMenuItemSelectedMultiple": {
      backgroundColor: "inherit"
    },
    "& > div + div": {
      maxHeight: "266px !important"
    }
  },
  selectMenuItem: {
    backgroundColor: colors.backgroundReadInputs,
    fontSize: "14px",
    //padding: "10px 20px",
    margin: "-5px -5px",
    borderRadius: "2px",
    fontFamily: 'SofiaProRegular',
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "2",
    whiteSpace: "nowrap",
    color: colors.textBody,
  },
  selectMenuItemSelected: {
    backgroundColor:colors.backgroundReadInputs,
    color: colors.textBody,
  },
  selectMenuItemSelectedMultiple: {
    backgroundColor: "transparent !important",
    color: colors.textBody,
    "&:hover": {
      backgroundColor:colors.backgroundReadInputs,
      color: whiteColor,
      ...primaryBoxShadow,
      "&:after": {
        color: colors.textBody,
      }
    },
    "&:after": {
      top: "16px",
      right: "12px",
      width: "12px",
      height: "5px",
      borderLeft: "2px solid currentColor",
      transform: "rotate(-45deg)",
      opacity: "1",
      color: colors.textBody,
      position: "absolute",
      content: "''",
      borderBottom: "2px solid currentColor",
      transition: "opacity 90ms cubic-bezier(0,0,.2,.1)"
    }
  },
  selectPaper: {
    boxSizing: "borderBox",
    borderRadius: "4px",
    padding: "0",
    minWidth: "100%",
    display: "block",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
    backgroundClip: "padding-box",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "transparent",
    maxHeight: "266px"
  }
};

export default customSelectStyle;
