import gql from 'graphql-tag';

const CLUB_LICENSE_REQUEST_CANCEL = gql`
mutation clubLicenseRequestCancel($id: ID!) {
  clubLicenseRequestCancel(id: $id) {
    year
  }
}
`;

export default CLUB_LICENSE_REQUEST_CANCEL;