const ActivityTypesEnum = {
    MEMBERSHIP: 'Membership',
    LICENSES: 'Licenses',
    PAYMENTS: 'Payments',
    DOCUMENTS: 'Documents',
    CLUB: 'Club',
    CLUB_TRANSFER: 'ClubTransfer',
    CLUB_HOSTING: 'ClubHosting'
}

export default ActivityTypesEnum;
