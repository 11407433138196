import gql from 'graphql-tag';

const CLUB_REGISTER_REQUEST_REVIEW= gql`
mutation clubRegisterRequestReview($id: ID! $description: String!  $input: ClubRegisterRequestInput!) { 
  clubRegisterRequestReview(id: $id description: $description input: $input) {
    id
    bid
    type
    status
    createdAt
  }
} 
`;

export default CLUB_REGISTER_REQUEST_REVIEW;