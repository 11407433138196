import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import { widgets, colors } from "common/styles/configLayout.js";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Modal = (props) => {
    const { children, title, open, onClose, actions, maxWidth = 'sm', fullWidth = false, rightPosition } = props;
    const classes = useStyles();
    //TODO: spravit nas modal
    return (
        <Dialog
            className={rightPosition}
            open={open}
            transition={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            //style={{background: "rgba(0, 69, 255, 0.4)"}}
            PaperProps={{
                style: { borderRadius: 16, background: colors.backgroundWidgets }
            }}
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => onClose()}
                >
                    <Close className={classes.modalClose} />
                </Button>
                <Typography variant="body1" style={{ color: colors.textBody, fontSize: '1.4em' }}>{title}</Typography>
            </DialogTitle>
            <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}>
                <Typography variant="body2" style={{ color: colors.textBody }}>{children}</Typography>
            </DialogContent>
            <DialogActions style={{padding: '20px' }}>
                {<span >{actions}</span>}
            </DialogActions>
        </Dialog >
    )
}

export default Modal;