import gql from 'graphql-tag';

const MEMBER_LICENSE_REQUEST_CANCEL = gql`
mutation memberLicenseRequestCancel($id: ID!) {
	memberLicenseRequestCancel (id: $id) {
		id
    bid
  }
}
`;

export default MEMBER_LICENSE_REQUEST_CANCEL;