import gql from 'graphql-tag';

const CLUB_LICENSE_REQUEST_CREATE = gql`
mutation clubLicenseRequestCreate($input: ClubLicenseRequestInput!) {
  clubLicenseRequestCreate(input: $input) {
    year
  }
}
`;

export default CLUB_LICENSE_REQUEST_CREATE;