import gql from 'graphql-tag';

const CLUB_DOCUMENTS_TABLE_BY_CLUB= gql`
query clubDocumentsTableByClub($clubId: ID!, $offset: Int, $limit: Int, $sort: [ClubDocumentsTableSortInput!], $filter: [ClubDocumentsTableFilterInput]) {
  clubDocumentsTableByClub (clubId:$clubId, offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
       	id
        bid
        name
        type
        status
        validFrom
        validTo
        name
        files
        createdAt
        updatedAt
        deletedAt
        createdById
        createdBy
        downloadUrl
      }
  }
}
`;

export default CLUB_DOCUMENTS_TABLE_BY_CLUB;