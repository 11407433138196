import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import CLUB_MEMBER from 'queries/MembersQueries/clubMember';
import Grid from '@material-ui/core/Grid';
import PersonalDataCard from '../Profile/DetailCards/PersonalDataCard';
import DocumentsCard from '../Profile/DetailCards/DocumentsCard';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import IconCard from 'components-lib/IconCard/IconCard';
import Loading from "components-lib/Loading/Loading";
import Typography from "@material-ui/core/Typography"
import AvatarCard from '../Profile/DetailCards/AvatarCard';
import ClubCard from '../Profile//DetailCards/ClubCard';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DescriptionIcon from '@material-ui/icons/Description';
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import PageHeader from "components-lib/PageHeader/PageHeader";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LicenseCard from '../Profile/DetailCards/LicenseCard'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CLUB_MEMBER_UPDATE from 'queries/MembersQueries/clubMemberUpdate'
import CLUB_MEMBER_SUSPEND from 'queries/MembersQueries/clubMemberSuspend'
import CLUB_MEMBER_RESUME from 'queries/MembersQueries/clubMemberResume'
import CHANGE_CLUB from 'queries/MembersQueries/changeClub'
import PERSON_INVITATION_SEND from 'queries/AuthQueries/personInvitationSend'
import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
    getValidationSchemaMemberDetail,
    getValidationSchemaMemberDetailForeign,
    getValidationSchemaChangeClub
} from "./validationSchema";
import DISCIPLINES from "queries/ClubsQueries/disciplines";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { connect } from 'react-redux';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import USER_PASSWORD_RESET from 'queries/AuthQueries/userPasswordReset';
import InfoIcon from '@material-ui/icons/Info';
import CLUBS from "queries/ClubsQueries/clubs";
import Button from "components/CustomButtons/Button.js";
import Modal from "components-lib/Modal/Modal";
import FormikField from "components-lib/FormikField/FormikField";
import { colors, variables } from "common/styles/configLayout.js";
import RoleEnum from 'common/enums/RoleEnum';
import FunctionsEnum from 'common/enums/FunctionsEnum';
import SendIcon from '@material-ui/icons/Send';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";

const useStyles = makeStyles((theme) => styles(theme))

const MemberDetailPage = (props) => {
    const { intl, history, role } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    var url_string = window.location.href
    var url = new URL(url_string);
    var tabNumber = Number(url.searchParams.get("tab"));
    const memberId = props?.match?.params?.memberId
    const { data: licensingInfoData } = useQuery(LICENSING_INFO);


    const { loading, data, refetch: refetchData } = useQuery(CLUB_MEMBER, { variables: { id: memberId } });
    const { data: clubsData } = useQuery(CLUBS);
    const [clubMemberUpdate] = useMutation(CLUB_MEMBER_UPDATE);
    const [clubMemberSuspend] = useMutation(CLUB_MEMBER_SUSPEND);
    const [clubMemberResume] = useMutation(CLUB_MEMBER_RESUME);
    const [userPasswordReset] = useMutation(USER_PASSWORD_RESET);
    const [personInvitationSend] = useMutation(PERSON_INVITATION_SEND);
    const [changeClub] = useMutation(CHANGE_CLUB);

    const [tab, setTab] = useState(0);
    const [userData, setUserData] = useState({});
    const [membersCount, setMembersCount] = useState(0);
    const [memberFunctions, setMemberFunctions] = useState([]);
    const [memberDisciplines, setMemberDisciplines] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [validateParam, setValidateParam] = useState(false)
    const [countryOfBirth, setCountryOfBirth] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);
    const [clubs, setClubs] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [readMode, setReadMode] = useState(true)
    const [photo, setPhoto] = useState(null)

    useEffect(() => {
        let allClubs = pathOr([], "clubs.items", clubsData)?.map((item) => {
            return {
                value: item.id,
                label: item?.profile?.name
            }
        });

        setClubs(allClubs);
    }, [clubsData]);

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    onClick={() => formikProps.handleSubmit()}
                    color="primary"
                    round
                    table
                    size="sm">
                    <DirectionsBikeIcon /> {intl.formatMessage({ id: "member.detail.modal.button.memberResume" })}
                </Button>
            </>
        );
    };

    useEffect(() => {
        setTab(Number(tabNumber))
    }, []);

    useEffect(() => {
        setUserData(pathOr('', ['clubMember', 'person', 'profile'], data));
        setLicenses(pathOr('', ['clubMember', 'licenses'], data));
        setMembersCount(Object.values(pathOr('', ['clubMember', 'club', 'members'], data))?.map((x, index) => { return index })?.length);
    }, [data]);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleMemberUpdate = (values) => {
        const result = { ...values };
        delete result.__typename;
        //delete result.email;
        delete result.birth
        delete result.photoUrl;
        delete result.isYouthWorker;
        let tempDate = moment(values?.birth).format('YYYY-MM-DD');
        let functions = []
        if ((memberFunctions?.find(item => item.value === FunctionsEnum.RACER.value || item.value === FunctionsEnum.RACER_WITH_CONTRACT.value)) && (!memberDisciplines || memberDisciplines?.length === 0)) {
            enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.warning.discipline" }), { variant: 'warning' });
        }
        if ((memberFunctions?.find(item => item.value === FunctionsEnum.RACER.value) && memberFunctions?.find(item => item.value === FunctionsEnum.RACER_WITH_CONTRACT.value))) {
            enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.warning.functions" }), { variant: 'warning' });
        } else {
            if (memberId) {
                setDisableButton(true);
                for (let i = 0; i < memberFunctions.length; i++) {
                    if (memberFunctions[i] !== undefined && memberFunctions[i]?.value !== '')
                        functions.push({
                            functionId: Number(memberFunctions?.[i]?.value),
                            disciplineIds: (memberDisciplines?.length > 0) ? (memberDisciplines)?.map(disc => disc?.value) : [],
                        });
                }
                clubMemberUpdate({
                    variables: {
                        id: memberId,
                        input: {
                            isYouthWorker: (values?.isYouthWorker) ? true : false,
                            person: {
                                ...result,
                                birth: (values?.birth) ? tempDate : undefined,
                                photo: (photo) ? photo : undefined
                            },
                            functions: (functions.length > 0) ? functions : undefined
                        }
                    }
                }).then((response) => {
                    enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.success.update" }), { variant: 'success' });
                    setReadMode(true);
                    setDisableButton(false)
                    refetchData();
                }).catch((err) => {
                    console.log('[Error]: ', err);
                    setDisableButton(false);
                });
                refetchData();
            }
        }
    }

    const handleClubMemberSuspend = () => {
        setDisableButton(true);
        clubMemberSuspend({
            variables: {
                id: memberId,
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.success.memberSuspended" }), { variant: 'success' });
            refetchData();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    const handleClubMemberResume = () => {
        setDisableButton(true);
        clubMemberResume({
            variables: {
                id: memberId,
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.success.memberResume" }), { variant: 'success' });
            refetchData();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    const handleUserPasswordReset = (userId) => {
        setDisableButton(true);
        userPasswordReset({
            variables: {
                id: userId,
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.success.passwordReset" }), { variant: 'success' });
            refetchData();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    const handleChangeClub = (values, resetForm) => {
        setDisableButton(true);
        changeClub({
            variables: {
                id: memberId,
                clubId: values?.clubId
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.success.invitationSend" }), { variant: 'success' });
            refetchData();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
        resetForm();
        setModalOpen(false)
    }

    const handlePersonInvitationSend = () => {
        setDisableButton(true);
        if (pathOr('', ['clubMember', 'person', 'profile', 'email'], data)) {
            personInvitationSend({
                variables: {
                    ids: [pathOr('', ['clubMember', 'person', 'id'], data)]
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.success.invitationSend" }, [pathOr('', ['clubMember', 'person', 'profile', 'email'], data)]), { variant: 'success' });
                refetchData();
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        } else {
            enqueueSnackbar(intl.formatMessage({ id: "member.snackbar.warning.invitationSend" }), { variant: 'warning' });
        }
    }

    const getActions = () => {
        let array = [];
        if (role === RoleEnum.ADMIN) {
            if (pathOr('', ['clubMember', 'isSuspended'], data))
                array.push({
                    title: intl.formatMessage({ id: "member.detail.action.button.memberResume" }),
                    disabled: disableButton,
                    icon: <SettingsBackupRestoreIcon />,
                    onClick: () => handleClubMemberResume(),
                })
            else
                array.push({
                    title: intl.formatMessage({ id: "member.detail.action.button.memberSuspended" }),
                    color: "warning",
                    disabled: disableButton,
                    icon: <ErrorOutlineIcon />,
                    onClick: () => handleClubMemberSuspend(),
                })
            if (pathOr('', ['clubMember', 'person', 'user', 'id'], data))
                array.push({
                    title: intl.formatMessage({ id: "member.detail.action.button.passwordReset" }),
                    icon: <RotateLeftIcon />,
                    disabled: disableButton,
                    onClick: () => handleUserPasswordReset(pathOr('', ['clubMember', 'person', 'user', 'id'], data)),
                })
            if (!pathOr('', ['clubMember', 'person', 'user', 'id'], data))
                array.push({
                    title: intl.formatMessage({ id: "member.detail.action.button.invitationSend" }),
                    icon: <SendIcon />,
                    disabled: disableButton,
                    onClick: () => handlePersonInvitationSend(),
                })
            if (pathOr('', ['clubMember', 'licenses', [0], 'year'], data) !== licensingInfoData?.licensingInfo?.periods?.current?.year)
                array.push({
                    title: intl.formatMessage({ id: "member.detail.action.button.changeClub" }),
                    icon: <DirectionsBikeIcon />,
                    onClick: () => setModalOpen(true),
                })

        }
        return array;

    };

    if (loading) return <Loading />;
    return (
        <>
            <Formik
                onSubmit={(values) => handleMemberUpdate(values)}
                initialValues={{ ...userData, isYouthWorker: pathOr('', ['clubMember', 'isYouthWorker'], data) }}
                enableReinitialize
                //validationSchema={getValidationSchemaMemberDetail(intl)}
                validationSchema={(countryOfBirth === variables.defaultCountry) ? getValidationSchemaMemberDetail(intl) : getValidationSchemaMemberDetailForeign(intl)}
            >
                {(formikProps) => (
                    <>
                        {setCountryOfBirth(formikProps?.values?.countryOfBirth)}
                        <PageHeader
                            withBackButton={false}
                            actions={getActions()}
                            title={<>{pathOr('', ['preNominals'], userData)} {pathOr('', ['firstName'], userData)} {pathOr('', ['lastName'], userData)}{(pathOr('', ['postNominals'], userData)) && <>, {(pathOr('', ['postNominals'], userData))}</>}</>}
                            subTitle={pathOr('', ['clubMember', 'bid'], data)}
                            tabs={
                                <AntTabs
                                    onChange={handleChange}
                                    value={tab}
                                    aria-label="Tabs where each tab needs to be selected manually"
                                    variant="scrollable"
                                >
                                    <AntTab label={<Typography variant="body1" ><InfoIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "profile.tab.personalInformation" })}</Typography>} />
                                    <AntTab label={<Typography variant="body1" ><DirectionsBikeIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "profile.tab.club" })}</Typography>} />
                                    <AntTab label={<Typography variant="body1" ><DescriptionIcon className={classes.tabsIcon} /> {intl.formatMessage({ id: "profile.tab.documents" })}</Typography>} />
                                    <AntTab label={<Typography variant="body1" ><AssignmentIndIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "profile.tab.licenses" })}</Typography>} />
                                </AntTabs>

                            }
                        />
                        <Grid container spacing={1}>
                            {tab === 0 &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4} lg={(pathOr('', ['clubMember', 'person', 'pendingProfileRequest', 'data', 'profile', 'photoUrl'], data) && pathOr('', ['clubMember', 'person', 'profile', 'photoUrl'], data)) ? 4 : 2}>
                                        <AvatarCard
                                            formikProps={formikProps}
                                            photo={pathOr('', ['clubMember', 'person', 'profile', 'photoUrl'], data)}
                                            photoRequest={pathOr('', ['clubMember', 'person', 'pendingProfileRequest', 'data', 'profile', 'photoUrl'], data)}
                                            requestId={pathOr('', ['clubMember', 'person', 'pendingProfileRequest', 'id'], data)}
                                            setPhoto={(newValue) => setPhoto(newValue)}
                                            refetch={refetchData}
                                            memberId={memberId}
                                            history={history}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <IconCard>
                                            <PersonalDataCard
                                                formikProps={formikProps}
                                                readMode={readMode}
                                                setReadMode={(newValue) => setReadMode(newValue)}
                                                validateParam={validateParam}
                                                setValidateParam={(newValue) => setValidateParam(newValue)}
                                                identifierData={pathOr('', ['clubMember'], data)}
                                                refetch={refetchData}
                                                disableButton={disableButton}
                                            />
                                        </IconCard>
                                    </Grid>
                                </Grid>
                            }
                            {tab === 1 &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <ClubCard
                                            hosting={pathOr('', ['clubMember', 'hosting', 'club', 'profile', 'name'], data)}
                                            userData={pathOr('', ['clubMember', 'club'], data)}
                                            functionData={pathOr('', ['clubMember', 'functions'], data)}
                                            isSuspended={pathOr('', ['clubMember', 'isSuspended'], data)}
                                            federationId={pathOr('', ['clubMember', 'federationId'], data)}
                                            setMemberFunctions={(newValue) => setMemberFunctions(newValue)}
                                            setMemberDisciplines={(newValue) => setMemberDisciplines(newValue)}
                                            memberFunctions={memberFunctions}
                                            memberDisciplines={memberDisciplines}
                                            formikProps={formikProps}
                                            refetchData={refetchData}
                                            membersCount={membersCount}
                                            readMode={readMode}
                                            setReadMode={(newValue) => setReadMode(newValue)}
                                            history={history}
                                            disableButton={disableButton}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {tab === 2 &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <DocumentsCard
                                            formikProps={formikProps}
                                            memberId={memberId}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {tab === 3 &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <LicenseCard
                                            formikProps={formikProps}
                                            licenses={licenses}
                                            refetch={refetchData}
                                            role={role}
                                            currentYear={licensingInfoData?.licensingInfo?.periods?.current?.year}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </>
                )}
            </Formik >
            <Formik
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={getValidationSchemaChangeClub(intl)}
                onSubmit={(values, { resetForm }) => handleChangeClub(values, resetForm)}
                initialValues={{}}
                enableReinitialize
            >
                {(formikProps) => (
                    <>
                        <Modal
                            title={intl.formatMessage({ id: "member.detail.modal.changeClub.title" })}
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            actions={renderModalButtons(formikProps)}
                            fullWidth
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <br />
                                    <Typography variant="body2" style={{ color: colors.textBody }}>{intl.formatMessage({ id: "member.detail.modal.changeClub.body" })}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikField
                                        name="clubId"
                                        labelText={intl.formatMessage({ id: "member.detail.modal.changeClub.clubId" })}
                                        mandatory={true}
                                        formikProps={formikProps}
                                        validateParam={true}
                                        highlightChange={false}
                                        type="autocomplete"
                                        options={clubs}
                                    />
                                </Grid>
                            </Grid>
                        </Modal>
                    </>
                )}
            </Formik >
        </>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(MemberDetailPage));
