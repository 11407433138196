import * as Yup from 'yup';

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        method: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaExport = (intl) =>
    Yup.object().shape({
        from: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        to: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });
