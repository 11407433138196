import gql from 'graphql-tag';

const CLUB_UPDATE_REQUEST_CREATE= gql`
mutation clubUpdateRequestCreate($clubId: ID! $input: ClubUpdateRequestInput!) { 
  clubUpdateRequestCreate(clubId: $clubId input: $input) {
    id
  }
} 
`;

export default CLUB_UPDATE_REQUEST_CREATE;