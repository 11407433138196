import gql from 'graphql-tag';

const CLUB_REGISTER_REQUEST_CREATE= gql`
mutation clubRegisterRequestCreate($input: ClubRegisterRequestInput!) { 
  clubRegisterRequestCreate(input: $input) {
    id
    bid
    type
    status
    createdAt
  }
} 
`;

export default CLUB_REGISTER_REQUEST_CREATE;