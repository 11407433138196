import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { pathOr } from 'rambda';

import logoWhite from '../../assets/img/logo-whitee.png';
import IconCard from 'components-lib/IconCard/IconCard';
import defaultAvatar from "assets/img/faces/avatar.jpg";
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { colors, widgets } from "common/styles/configLayout.js";
import paths from "paths";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { generatePath } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import REQUESTS from 'queries/RequestsQueries/requests';
import MEMBER_LICENSE_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseRequestsTable';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box"
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import { changeUserRoleAction, changeAllUserRolesAction, changePendingRequestsNumberAction, changeLicensesNumberAction } from 'redux/actions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styles from "common/styles/widgets.js";
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import LogoutPage from 'pages/AuthPages/LogoutPage';
import { processLogout } from '../../helpers/helpers';
import RoleEnum from 'common/enums/RoleEnum';

const StyledBadge = withStyles((theme) => ({
    badge: {
        bottom: "3px",
        right: "1px",
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const useStyles = makeStyles((theme) => styles(theme))


const HeadPage = (props) => {
    const { history, changeUserRoleAction, changeAllUserRolesAction, changePendingRequestsNumberAction, changeLicensesNumberAction, role, allRoles, userProfile } = props
    const classes = useStyles();

    const { loading: requestsTableLoading, data: requestsTableData } = useQuery(REQUESTS);
    const { loading: licensesTableLoading, data: licensesTableData } = useQuery(MEMBER_LICENSE_REQUESTS_TABLE, { skip: role !== RoleEnum.ADMIN || role !== RoleEnum.STATUTORY || role !== RoleEnum.PRESIDENT });

    let fullName = pathOr('', ['me', 'person', 'profile', 'firstName'], userProfile) + ' ' + pathOr('', ['me', 'person', 'profile', 'lastName'], userProfile)
    const [openAccordion, setOpenAccordion] = useState("");

    const handleChange = (value) => {
        let tempAllRoles = [...allRoles, role];
        tempAllRoles.map((item, idx) => {
            if (item === value)
                tempAllRoles.splice(idx, 1)
        });

        changeUserRoleAction(value)
        changeAllUserRolesAction(tempAllRoles)
    };

    const getLabel = (role) => {
        if (role === RoleEnum.ADMIN) return "Administrátor";
        if (role === RoleEnum.STATUTORY) return "Štatutár";
        if (role === RoleEnum.PRESIDENT) return "Predseda";
        if (role === RoleEnum.MEMBER) return "Člen klubu";
        if (role === RoleEnum.USER) return "Nečlen";
        else return role;
    };

    const handleLogout = () => {
        processLogout();
        window.location.reload()
    }

    function handleAccordionClick(status) {
        if (openAccordion === status) setOpenAccordion("")
        if (openAccordion !== status) setOpenAccordion(status)
    }

    return (
        <>
            <Accordion
                expanded={"accordionStatus" === openAccordion}
                onClick={() => handleAccordionClick("accordionStatus")}
                style={{ backgroundColor: colors.backgroundReadInputs, marginTop: "20px", borderRadius: "16px" }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: colors.textBody }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                variant="dot"
                            >
                                <Avatar alt={pathOr('', ['me', 'person', 'profile', 'photoUrl'], userProfile)} src={pathOr('', ['me', 'person', 'profile', 'photoUrl'], userProfile)} />
                            </StyledBadge>
                        </Grid>
                        <Grid item xs={8} style={{ paddingLeft: "7px" }}>
                            <Typography variant="body2" style={{ color: "white", fontSize: "0.85em" }} >{fullName}</Typography>
                            <Typography variant="body2" style={{ color: colors.primary }}>{getLabel(role)}</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails >
                    <Grid container spacing={1}>
                        {allRoles?.map(item =>
                            <Grid container spacing={1} onClick={(e) => handleChange(item)} className={classes.pointer} style={{
                                "&:hover": {
                                    backgroundColor: 'red',
                                }
                            }}>
                                <Grid item xs={3} style={{ paddingLeft: "8px" }}>
                                    <Avatar style={{ /*backgroundColor: 'red'*/ }}><ImportExportIcon /></Avatar>
                                </Grid>
                                <Grid item xs={9} style={{ paddingLeft: "12px" }}>
                                    <Typography variant="body2" style={{ color: "white", fontSize: "0.85em" }} >{fullName}</Typography>
                                    <Typography variant="body2" style={{ color: colors.primary }}>{getLabel(item)}</Typography>
                                </Grid>
                                &nbsp;
                            </Grid>
                        )}
                        <Grid container spacing={1} className={classes.pointer}>
                            <Grid item xs={12} >
                                <Divider style={{ backgroundColor: 'rgba(60,72,88)' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography onClick={(e) => handleLogout()} variant="body2" style={{ color: "white", fontSize: "0.85em" }} ><ExitToAppIcon className={classes.attachIcons} />Odhlásiť sa</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

const mapStateToProps = (state) => ({
    role: state.role,
    allRoles: state.allRoles,
    userProfile: state.userProfile
});

const mapDispatchToProps = (dispatch) => ({
    changeUserRoleAction: (value) => dispatch(changeUserRoleAction(value)),
    changeAllUserRolesAction: (value) => dispatch(changeAllUserRolesAction(value)),
    changePendingRequestsNumberAction: (value) => dispatch(changePendingRequestsNumberAction(value)),
    changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeadPage);
