
import React, { useEffect } from 'react';
import TransferTable from "./TransferTable";
import HostingTable from "./HostingTable";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import paths from "paths";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import CLUB_TRANSFER_REQUEST_TABLE from 'queries/TransferAndHostingQueries/clubTransferRequestTable';
import CLUB_HOSTING_REQUEST_TABLE from 'queries/TransferAndHostingQueries/clubHostingRequestTable';
import { useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Typography from "@material-ui/core/Typography"
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import { connect } from 'react-redux';
import { pathOr } from 'rambda';
import { useSnackbar } from 'notistack';
import { getApolloClient } from "configFiles/apollo";
import { changePendingTransfersAndHostingNumberAction } from 'redux/actions';
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import TransfersAndHostingStatusesEnum from 'common/enums/TransfersAndHostingStatusesEnum';
import PROFILE from 'queries/ProfileQueries/profile';
import { useQuery } from '@apollo/client';
import RoleEnum from 'common/enums/RoleEnum';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';

const useStyles = makeStyles((theme) => styles(theme))

const TransferListPage = (props) => {
  const { intl, history, role, changePendingTransfersAndHostingNumberAction } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
  const classes = useStyles();
  const { data: userProfile } = useQuery(PROFILE);
  const { enqueueSnackbar } = useSnackbar();
  const client = getApolloClient(enqueueSnackbar, history, intl);
  
  const getPendingRequestsNumber = async () => {
    try {
      const result = await client.query({
        query: REQUESTS_TABLE,
        variables: {
          "filter": [
            {
              "status": {
                "in": [TransfersAndHostingStatusesEnum.AWAITING, TransfersAndHostingStatusesEnum.PAID]
              },
              "type": {
                "in": [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING]
              },
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changePendingTransfersAndHostingNumberAction(pathOr('', ['data', 'requestsTable', 'totalCount'], result))
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getPendingRequestsNumber();
  }, []);

  const getActions = () => {
    if (role !== RoleEnum.MEMBER) {
      if (tab === 0)
        return [
          {
            title: intl.formatMessage({ id: 'transfers.action.new' }),
            icon: <AddIcon />,
            onClick: () => (role === RoleEnum.ADMIN || (role === RoleEnum.STATUTORY && pathOr('', ['me', 'member', 'club', 'licenses', 'current', 'isLicensed'], userProfile)))
              ? history.push({
                pathname: `/admin${generatePath(paths.transfers.new)}`,
                state: "transfer",
              })
              : enqueueSnackbar(intl.formatMessage({ id: 'transfers.snackbar.withoutLicense' }), { variant: 'warning' })
          },
        ];
      else {
        return [
          {
            title: intl.formatMessage({ id: 'hosting.action.new' }),
            icon: <AddIcon />,
            onClick: () => (role === RoleEnum.ADMIN || (role === RoleEnum.STATUTORY && pathOr('', ['me', 'member', 'club', 'licenses', 'current', 'isLicensed'], userProfile)))
              ? history.push({
                pathname: `/admin${generatePath(paths.hosting.new)}`,
                state: "hosting",
              })
              : enqueueSnackbar(intl.formatMessage({ id: 'hosting.snackbar.withoutLicense' }), { variant: 'warning' })
          },
        ]
      }
    }
  };

  const [tab, setTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const renderTables = () => {
    return (
      <div>
        {tab === 0 &&
          <TransferTable
            query={CLUB_TRANSFER_REQUEST_TABLE}
            defaultFilter={defaultFilter}
            queryDataPath={['clubTransferRequestsTable', 'items']}
            history={history}
          />
        }
        {tab === 1 &&
          <HostingTable
            query={CLUB_HOSTING_REQUEST_TABLE}
            defaultFilter={defaultFilter}
            history={history}
            queryDataPath={['clubHostingRequestsTable', 'items']}
          />
        }
      </div>
    );
  };

  return (
    <>
      <PageHeader
        actions={getActions()}
        withBackButton={false}
        tabs={
          <AntTabs
            onChange={handleChangeTab}
            value={tab}
            aria-label="Tabs where each tab needs to be selected manually"
          >
            <AntTab label={<Typography variant="body1" ><ArrowRightAltIcon className={classes.tabsIcon} />{intl.formatMessage({ id: 'transfers.tab.title' })}</Typography>} />
            <AntTab label={<Typography variant="body1" ><SwapHorizIcon className={classes.tabsIcon} />{intl.formatMessage({ id: 'hosting.tab.title' })}</Typography>} />
          </AntTabs>
        }
      />
      <IconCard>
        {renderTables()}
      </IconCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  changePendingTransfersAndHostingNumberAction: (value) => dispatch(changePendingTransfersAndHostingNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TransferListPage));
