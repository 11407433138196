import gql from 'graphql-tag';

const PERSON_PROFILE_UPDATE_REQUEST_REJECT= gql`
mutation personProfileUpdateRequestReject($id: ID!) { 
  personProfileUpdateRequestReject(id: $id) {
    id
    bid
    type
    status
    createdAt
  }
} 
`;

export default PERSON_PROFILE_UPDATE_REQUEST_REJECT;