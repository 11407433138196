import gql from 'graphql-tag';

const CLUB_UPDATE_REQUEST_UPDATE= gql`
mutation clubUpdateRequestUpdate($id: ID! $input: ClubUpdateRequestInput!) { 
  clubUpdateRequestUpdate(id: $id input: $input) {
    id
  }
} 
`;

export default CLUB_UPDATE_REQUEST_UPDATE;