import gql from 'graphql-tag';

const CLUB_MEMBERS_BY_CLUB = gql`
query clubMembersByClub($clubId:ID!,$offset: Int, $limit: Int, $sort: [ClubMemberSortInput!], $filter: [ClubMemberFilterInput]) {
  clubMembersByClub (clubId:$clubId,offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
        id
        bid
        isSuspended
        person {
          profile {
            firstName
            birth
            lastName
            photoUrl
          }
        }
      }
  }
}
`;

export default CLUB_MEMBERS_BY_CLUB;

