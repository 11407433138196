import gql from 'graphql-tag';

const PAYMENT_PAY = gql`
mutation paymentPay($id: ID! ) {
  paymentPay(id: $id) {
    id
    checkoutId
    checkoutUrl
  }
}
`;

export default PAYMENT_PAY;