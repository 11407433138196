import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import { colors } from "common/styles/configLayout.js";
import Typography from "@material-ui/core/Typography";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Grid from '@material-ui/core/Grid';
import CompareDocumentCard from 'pages/Profile/DetailCards/CompareDocumentCard';
import CompareClubDocumentsCard from 'pages/Clubs/ClubCards/CompareClubDocumentsCard';
import IconCard from 'components-lib/IconCard/IconCard';


const useStyles = makeStyles({
    /*dialog: {
        position: 'absolute',
        right: 10,
        top: 0
    }*/
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DocModal = (props) => {
    const { open, onClose, docs, userData, clubData, documentId, refetch, infoBar = true, documentStatus, textColor } = props;
    const classes = useStyles();
    //TODO: spravit nas modal

    return (
        <>
            <Dialog
                classes={{
                    paper: classes.dialog
                }}
                //className={rightPosition}
                open={open}
                //transition={Transition}
                keepMounted
                onClose={onClose}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                maxWidth={'lg'}
                fullWidth={true}
                //style={{background: "rgba(0, 69, 255, 0.4)"}}
                PaperProps={{
                    style: { borderRadius: 16, background: colors.backgroundWidgets, overflow: "hidden" }
                }}
            >
                <Grid container spacing={1}>
                    <Grid item sm={4}>
                        <Typography style={{ color: textColor, fontSize: "1.4em", textAlign: "center", paddingTop: "10px" }}>
                            {userData ? "Informácie o používateľovi" : "Informácie o klube"} </Typography>
                        <br />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <IconCard inside style={{ color: "white" }}>
                                    {userData ?
                                        <CompareDocumentCard
                                            documentId={documentId}
                                            documentStatus={documentStatus}
                                            textColor={textColor}
                                            userData={userData}
                                            refetch={refetch}
                                            onClose={onClose}
                                        />
                                        :
                                        <CompareClubDocumentsCard
                                            documentId={documentId}
                                            documentStatus={documentStatus}
                                            textColor={textColor}
                                            clubData={clubData?.values}
                                            refetch={refetch}
                                            onClose={onClose}
                                        />
                                    }
                                </IconCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={8}>
                        <Typography style={{ color: "white" }}>
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                documents={docs}
                                theme={{
                                    primary: textColor,
                                    secondary: textColor,
                                    tertiary: textColor,
                                    textPrimary: colors.backgroundWidgets,
                                    textSecondary: colors.backgroundWidgets,
                                    textTertiary: colors.backgroundWidgets,
                                    disableThemeScrollbar: true,
                                }}
                                style={{ minHeight: 1000, height: "100px" }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Dialog >

        </>
    )
}

export default DocModal;