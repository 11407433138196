import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import { colors } from "common/styles/configLayout.js";
import Typography from "@material-ui/core/Typography";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Grid from '@material-ui/core/Grid';
import CompareDocumentCard from 'pages/Profile/DetailCards/CompareDocumentCard';
import CompareClubDocumentsCard from 'pages/Clubs/ClubCards/CompareClubDocumentsCard';
import IconCard from 'components-lib/IconCard/IconCard';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => styles(theme))

const DocumentViewer = (props) => {
    const { intl, open, onClose, docs, data, documentData, type, documentId, closed, refetch, documentStatus, textColor, callbackDocumentApprove, callbackDocumentReject } = props;
    const classes = useStyles();
    //TODO: spravit nas modal

    return (
        <>
            <Dialog
                classes={{
                    paper: classes.dialog
                }}
                //className={rightPosition}
                open={open}
                //transition={Transition}
                keepMounted
                onClose={onClose}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                maxWidth={'lg'}
                fullWidth={true}
                PaperProps={{
                    style: { borderRadius: 16, background: colors.backgroundWidgets, /*overflow: "hidden"*/ }
                }}
            >
                {type !== 'uploadRequest' ?
                    <Grid container>
                        <Grid item xs={12} md={4} style={{ padding: 10 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={{ color: colors.textBody, fontSize: "1.4em", padding: "10px" }}>
                                        {documentData?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {(type === "Profile") ?
                                <CompareDocumentCard
                                    documentId={documentId}
                                    documentStatus={documentStatus}
                                    textColor={textColor}
                                    personData={data}
                                    refetch={refetch}
                                    documentData={documentData}
                                    onClose={onClose}
                                />
                                :
                                <CompareClubDocumentsCard
                                    documentId={documentId}
                                    documentStatus={documentStatus}
                                    textColor={textColor}
                                    clubData={data}
                                    documentData={documentData}
                                    refetch={refetch}
                                    onClose={onClose}
                                    closed={closed}
                                    type={type}
                                    callbackDocumentReject={callbackDocumentReject}
                                    callbackDocumentApprove={callbackDocumentApprove}
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                documents={docs}
                                config={{
                                    header: {
                                        disableHeader: false,
                                        disableFileName: true,
                                        retainURLParams: false
                                    }
                                }}
                                /*theme={{
                                    primary: textColor,
                                    secondary: textColor,
                                    tertiary: widgets.orange,
                                    textPrimary: colors.backgroundWidgets,
                                    textSecondary: colors.backgroundWidgets,
                                    textTertiary: colors.backgroundWidgets,
                                    disableThemeScrollbar: true,
                                }}*/
                                style={{ height: window.innerHeight - 72, borderRadius: '20px' }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                documents={docs}
                                config={{
                                    header: {
                                        disableHeader: false,
                                        disableFileName: true,
                                        retainURLParams: false
                                    }
                                }}
                                style={{ height: window.innerHeight - 72, borderRadius: '20px' }}
                            />
                        </Grid>
                    </Grid>
                }
            </Dialog >

        </>
    )
}

export default DocumentViewer;