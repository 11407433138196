import gql from 'graphql-tag';

const CLUB_UPDATE= gql`
mutation clubUpdate($id: ID! $input: ClubUpdateInput!) { 
  clubUpdate(id: $id input: $input) {
    id
    bid
  }
} 
`;

export default CLUB_UPDATE;