import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import paths from "paths";
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import RequestTable from "./RequestTable";
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import REQUESTS from 'queries/RequestsQueries/requests';
import ACTION_INVITATIONS from 'queries/AuthQueries/actionInvitations';
import { useLocation } from 'react-router-dom';
import { clubCreate } from 'configFiles/roles'
import Typography from "@material-ui/core/Typography"
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { pathOr } from 'rambda';
import Loading from "components-lib/Loading/Loading";
import { changePendingRequestsNumberAction } from 'redux/actions';
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from "notistack";
import RequestStatusesEnum from 'common/enums/RequestStatusesEnum';
import RoleEnum from 'common/enums/RoleEnum';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';

const useStyles = makeStyles((theme) => styles(theme))

const RequestListPage = (props) => {
  const { intl, history, role, changePendingRequestsNumberAction } = props;
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const client = getApolloClient(enqueueSnackbar, history, intl);
  const classes = useStyles();
  const [request, setRequest] = useState(null)
  const { loading, data: requestData, refetch: refetch } = useQuery(REQUESTS, {
    variables: {
      filter: {
        type: {
          eq: RequestTypesEnum.CLUB_REGISTRATION
        },
      }
    }, skip: role === RoleEnum.ADMIN || role === RoleEnum.STATUTORY
  });
  const { loading: actionInvitationsLoading, data: actionInvitationsData } = useQuery(ACTION_INVITATIONS, { skip: role !== RoleEnum.USER });

  const getPendingRequestsNumber = async () => {
    try {
      const result = await client.query({
        query: REQUESTS_TABLE,
        variables: {
          "filter": [
            {
              "status": {
                "eq": (role === RoleEnum.ADMIN) ? RequestStatusesEnum.AWAITING : RequestStatusesEnum.RETURNED
              },
              "type": {
                "notIn": [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING]
              },
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changePendingRequestsNumberAction(pathOr('', ['data', 'requestsTable', 'totalCount'], result))
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (role !== RoleEnum.USER)
    getPendingRequestsNumber()
  }, []);

  useEffect(() => {
    // history.push(`/admin${generatePath(paths.requests.detail, { requestId: pathOr('', ['requests', 'items', [pathOr('', ['requests', 'totalCount'], requestData) - 1], 'id'], requestData) })}`)
    if (!loading && !actionInvitationsLoading) {
      if (role === RoleEnum.USER) {
        if (Object.values((pathOr('', ['actionInvitations', 'items'], actionInvitationsData))).find(item => item.type === RequestTypesEnum.CLUB_REGISTRATION && item.usedAt === null))
          history.push(`/admin${generatePath(paths.requests.new)}`)
        else
          if (pathOr('', ['requests', 'totalCount'], requestData) >= 1)
            history.push(`/admin${generatePath(paths.requests.detail, { requestId: pathOr('', ['requests', 'items', [pathOr('', ['requests', 'totalCount'], requestData) - 1], 'id'], requestData) })}`)
          else
            history.push(`/admin${generatePath(paths.profile)}`)
      }
    }
  }, [requestData, actionInvitationsData]);


  const getActions = () => {
    if (role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT)
      return [
        {
          title: "Nová žiadosť (Temp)",
          onClick: () => history.push(`/ admin${generatePath(paths.requests.new)}`),
        },
      ];
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTables = () => {
    return (
      <div>
        {value === 0 &&
          <RequestTable
            query={REQUESTS_TABLE}
            defaultFilter={{ type: { notIn: [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING] }, status: { in: ["Awaiting", "Returned"] } }}
            history={history}
            queryDataPath={['requestsTable', 'items']}
            role={role}
          />
        }
        {value === 1 &&
          <RequestTable
            query={REQUESTS_TABLE}
            defaultFilter={{ type: { notIn: [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING] }, status: { in: ["Approved", "Rejected", "Canceled"] } }}
            history={history}
            queryDataPath={['requestsTable', 'items']}
            role={role}
          />
        }
      </div>
    );
  };


  if (role === RoleEnum.USER && loading && actionInvitationsLoading) return <Loading />
  return (
    <>
      <PageHeader
        actions={getActions()}
        withBackButton={false}
        tabs={
          <AntTabs
            onChange={handleChange}
            value={value}
            aria-label="Tabs where each tab needs to be selected manually"
          >
            <AntTab label={<Typography variant="body1" ><AssignmentLateIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "requests.tab.status.notEquipped" })}</Typography>} />
            <AntTab label={<Typography variant="body1" ><AssignmentTurnedInIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "requests.tab.status.equipped" })}</Typography>} />
          </AntTabs>
        }
      />
      <IconCard
      >
        {renderTables()}
      </IconCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});



const mapDispatchToProps = (dispatch) => ({
  changePendingRequestsNumberAction: (value) => dispatch(changePendingRequestsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RequestListPage));
