import gql from 'graphql-tag';

const LOGIN = gql`
mutation login ($email: EmailAddress!, $password: String!, $keepLoggedIn: Boolean) {
  login(email: $email, password: $password, keepLoggedIn: $keepLoggedIn) {
    permissions
    token
    user {
      id
      groups{
        id
        name
        permissions
      }
      preferences {
        language
      }
    }
    expiresAt
  }
}
`;

export default LOGIN;

