import { Grid } from "@material-ui/core";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { parameters, colors } from 'common/styles/configLayout';
import { StyledTableCell } from 'common/styles/muiDesign'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => styles(theme))

const PaymentLicenseTableCard = (props) => {
    const { intl, data, detail } = props;
    const classes = useStyles();

    const renderContent = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                    </Grid>
                    <Table aria-label="customized table">
                        <TableHead style={{ borderBottom: "none" }}>
                            <TableRow >
                                <StyledTableCell style={{ borderBottom: "none", borderTopLeftRadius: parameters.tablesRounded, borderBottomLeftRadius: parameters.tablesRounded }}>{intl.formatMessage({ id: `payments.licenseTableCard.type` })}</StyledTableCell>
                                <StyledTableCell style={{ borderBottom: "none" }}>{intl.formatMessage({ id: `payments.licenseTableCard.amount` })}</StyledTableCell>
                                <StyledTableCell style={{ borderBottom: "none" }}>{intl.formatMessage({ id: `payments.licenseTableCard.count` })}</StyledTableCell>
                                <StyledTableCell style={{ borderBottom: "none", borderTopRightRadius: parameters.tablesRounded, borderBottomRightRadius: parameters.tablesRounded }}>{intl.formatMessage({ id: `payments.licenseTableCard.sum` })}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <StyledTableCell>{intl.formatMessage({ id: `payments.licenseTableCard.standardClub` })}</StyledTableCell>
                                <StyledTableCell>{data?.amount} €</StyledTableCell>
                                <StyledTableCell>{intl.formatMessage({ id: `payments.licenseTableCard.oneItem` })}</StyledTableCell>
                                <StyledTableCell>{data?.amount} €</StyledTableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                    <Grid item xs={12} >
                        <Grid container spacing={1}>
                            <Grid item xs={12} ><br /></Grid>
                            <Grid item xs={12}>
                                <Box style={{ marginBottom: "8px" }} className={classes.readOnlyGrid}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "1.2em" }}>
                                            <span style={{ color: colors.warning }} >{intl.formatMessage({ id: `payments.licenseTableCard.summary` })}: </span>
                                            <Box className={classes.floatRight}>
                                                <span>{data?.amount} €</span>
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <IconCard title={intl.formatMessage({ id: `payments.licenseTableCard.title` })} inside={(detail === "memberLicense" ? true : false)}>
            {renderContent()}
        </IconCard>
    )
}

export default injectIntl(PaymentLicenseTableCard);