import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import FormikField from "components-lib/FormikField/FormikField";
import Typography from "@material-ui/core/Typography";
import { colors, variables } from "common/styles/configLayout.js";
import Divider from '@material-ui/core/Divider';
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.js";
import CLUB_LICENSE_REQUEST_CREATE from "queries/LicensesQueries/clubLicenseRequestCreate";
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import moment from 'moment'
import CLUB_LICENSE_REQUEST_CANCEL from "queries/LicensesQueries/clubLicenseRequestCancel";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BlockIcon from '@material-ui/icons/Block';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { connect } from 'react-redux';
import ISS_SYNC_CLUB from 'queries/ClubsQueries/issSyncClub';
import RepeatIcon from '@material-ui/icons/Repeat';
import RoleEnum from 'common/enums/RoleEnum';
import Tooltip from '@material-ui/core/Tooltip';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";

const useStyles = makeStyles((theme) => styles(theme))

const ClubCard = (props) => {
    const { intl, role, formikProps, title = true, validateParam,
        highlightChange, readMode = false, closed = false, inside = false, setPhoto, photo,
        refetch, request, button, detail = false, clubRequest, newClub, renewLicense, refetchClub, clubId, setPaymentData, issSyncedAt } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const {data: licensingInfoData} = useQuery(LICENSING_INFO);
    const [clubLicenseRequestCreate] = useMutation(CLUB_LICENSE_REQUEST_CREATE)
    const [clubLicenseRequestCancel] = useMutation(CLUB_LICENSE_REQUEST_CANCEL)
    const [issSyncClub] = useMutation(ISS_SYNC_CLUB)
    const [isNextYearAvailable, setIsNextYearAvailable] = useState(false)

    useEffect(() => {
      setIsNextYearAvailable(licensingInfoData?.licensingInfo?.periods?.next?.isPurchasable)
    }, [licensingInfoData]);

    const handleIssSyncClub = () => {
        issSyncClub({
            variables: {
                clubId: clubId,
                background: false
            },
        }).then((response) => {
            enqueueSnackbar('Žiadosť o zápis klubu do ISŠ prebehla úspešne', { variant: 'success' });
            refetchClub();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const handleClubLicenseRequestCreate = (year) => {
        clubLicenseRequestCreate({
            variables: {
                input: {
                    clubId: clubId,
                    year: year
                }
            }
        }).then((response) => {
            enqueueSnackbar(`Žiadosť o licenciu na rok ${response?.data?.clubLicenseRequestCreate?.year} bola vytvorená`, { variant: 'success' });
            refetchClub();
            setPaymentData(response?.data?.clubLicenseRequestCreate);
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const handleClubLicenseRequestCancel = (id) => {
        clubLicenseRequestCancel({
            variables: {
                id: id,
            }
        }).then((response) => {
            //setPaymentData(response?.data?.clubLicenseRequestCreate)
            enqueueSnackbar(`Žiadosť o licenciu na rok ${response?.data?.clubLicenseRequestCancel?.year} bola zrušená`, { variant: 'success' });
            refetchClub();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const renderContent = () => {
        return (<>
            <Grid item xs={12}>
                <FormikField
                    name="name"
                    labelText={intl.formatMessage({ id: 'club.clubCard.clubName' })}
                    formikProps={formikProps}
                    validateParam={validateParam}
                    highlightChange={highlightChange}
                    disabled={closed}
                    readMode={readMode}
                    mandatory={true}
                />
            </Grid>
            {formikProps?.values?.bid &&
                <Grid item xs={12}>
                    <FormikField
                        name="bid"
                        labelText={"ID klubu"}
                        formikProps={formikProps}
                        validateParam={validateParam}
                        highlightChange={highlightChange}
                        disabled={closed}
                        readMode={true}
                    />
                </Grid>
            }
            {(request) ? (newClub) &&
                <>
                    <Grid item xs={12}>
                        <FormikField
                            name="type"
                            labelText={"Typ klubu"}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={highlightChange}
                            disabled={closed}
                            type="select"
                            options={[
                                {
                                    value: "StandardDomestic",
                                    label: "štandardný domáci"
                                },
                                {
                                    value: "StandardForeign",
                                    label: "štandardný zahraničný"
                                },
                                {
                                    value: "UCIDomestic",
                                    label: "UCI tím domáci"
                                },
                                {
                                    value: "UCIForeign",
                                    label: "UCI tím zahraničný"
                                }
                            ]}
                            readMode={readMode}
                            mandatory={true}
                        />
                    </Grid>
                </>

                :
                <>
                    <Grid item xs={12}>
                        <FormikField
                            name="members"
                            labelText={"Počet členov"}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={highlightChange}
                            disabled={closed}
                            readMode={true}
                        />
                    </Grid>
                </>
            }
        </>
        )
    }

    return (
        <IconCard inside={inside} title={(title) && intl.formatMessage({ id: 'club.clubCard.title' })}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3} md={3} lg={3} align={'center'}>
                    <ImageUpload
                        addButton={<><PhotoCameraIcon /> {intl.formatMessage({ id: 'club.clubCard.button.add' })} </>}
                        changeButton={intl.formatMessage({ id: "profile.avatarCard.changeButton" })}
                        removeButton={intl.formatMessage({ id: "profile.avatarCard.deleteButton" })}
                        //imageWidth={"150px"}
                        hideButtons={
                            (role === RoleEnum.ADMIN && !clubRequest)
                                ? (renewLicense) ? true : false
                                : (clubRequest)
                                    ? (readMode)
                                        ? true
                                        : false
                                    : true
                        }
                        callbackFile={(newValue) => { setPhoto(newValue); (detail) && formikProps.handleSubmit() }}
                        refetch={refetch}
                        photo={photo}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={5}>
                    <Grid container spacing={1}>
                        {renderContent()}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                    <Grid container spacing={1}>
                        {request ?
                            <>
                                <Grid item xs={12}>
                                    <FormikField
                                        name="email"
                                        labelText={"Klubový e-mail"}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={readMode}
                                        mandatory={((formikProps?.values?.type !== "StandardForeign") && (formikProps?.values?.type !== "UCIForeign"))} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikField
                                        name="site"
                                        labelText={"Klubový web"}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={readMode}
                                    />
                                </Grid>
                                {/*<Grid item xs={12}>
                                    <FormikField
                                        name="social"
                                        labelText={"Klubová sociánla sieť"}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={readMode}
                                    />
                                </Grid>*/}
                            </>
                            :
                            <>
                                <Grid item xs={12}>
                                    <FormikField
                                        name="type"
                                        labelText={"Typ klubu"}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        type="select"
                                        options={((formikProps?.values?.type === "StandardDomestic") || (formikProps?.values?.type === "UCIDomestic"))
                                            ? [
                                                {
                                                    value: "StandardDomestic",
                                                    label: "štandardný domáci"
                                                },
                                                {
                                                    value: "UCIDomestic",
                                                    label: "UCI tím domáci"
                                                },
                                            ] : [
                                                {
                                                    value: "StandardForeign",
                                                    label: "štandardný zahraničný"
                                                },
                                                {
                                                    value: "UCIForeign",
                                                    label: "UCI tím zahraničný"
                                                }
                                            ]
                                        }
                                        readMode={readMode}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={(isNextYearAvailable) ? 6 : 12}>
                                    <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
                                        <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs, marginBottom: '5px' }}>{`Licencia ${formikProps?.values?.licenses?.current?.year}`}</Typography>
                                        <Typography variant="body2" style={{ fontSize: "1.1em", marginLeft: 2, marginTop: "-2px" }}>
                                            {(!formikProps?.values?.licenses?.current?.isLicensed) ?
                                                <Grid item xs={12}>
                                                    {(role !== RoleEnum.MEMBER) ?
                                                        (formikProps?.values?.licenses?.current?.request?.status !== "Pending") ? <>
                                                            {(formikProps?.values?.licenses?.next?.request?.status === "Pending") ?
                                                                <Tooltip title={"V čase môže byť otvorená iba jedna žiadosť o obnovu licencie"}>
                                                                    <Box style={{ textAlign: "center" }}>
                                                                        <Button
                                                                            disabled={(formikProps?.values?.licenses?.next?.request?.status === "Pending")}
                                                                            color="primary"
                                                                            size="sm"
                                                                            round
                                                                            table
                                                                            onClick={() => { handleClubLicenseRequestCreate(formikProps?.values?.licenses?.current?.year) }}
                                                                        >
                                                                            <VerifiedUserIcon /> Obnoviť {formikProps?.values?.licenses?.current?.year}
                                                                        </Button>
                                                                    </Box>
                                                                </Tooltip>
                                                                :
                                                                <Box style={{ textAlign: "center" }}>
                                                                    <Button
                                                                        disabled={(formikProps?.values?.licenses?.next?.request?.status === "Pending")}
                                                                        color="primary"
                                                                        size="sm"
                                                                        round
                                                                        table
                                                                        onClick={() => { handleClubLicenseRequestCreate(formikProps?.values?.licenses?.current?.year) }}
                                                                    >
                                                                        <VerifiedUserIcon /> Obnoviť {formikProps?.values?.licenses?.current?.year}
                                                                    </Button>
                                                                </Box>
                                                            }
                                                        </>

                                                            :
                                                            <Box style={{ textAlign: "center" }}>
                                                                <Button
                                                                    size="sm"
                                                                    round
                                                                    table
                                                                    onClick={() => { handleClubLicenseRequestCancel(formikProps?.values?.licenses?.current?.request?.id) }}
                                                                >
                                                                    <BlockIcon /> Zrušiť žiadosť
                                                                </Button>
                                                            </Box>
                                                        :
                                                        <span>Neaktívna</span>
                                                    }
                                                </Grid>
                                                :
                                                <span style={{ color: colors.primary }}>Aktívna</span>
                                            }
                                        </Typography>
                                    </Box>
                                </Grid>
                                {(isNextYearAvailable) &&
                                    <Grid item xs={12} xl={6}>
                                        <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
                                            <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs, marginBottom: '5px' }}>{`Licencia ${formikProps?.values?.licenses?.next?.year}`}</Typography>
                                            <Typography variant="body2" style={{ fontSize: "1.1em", marginLeft: 2, marginTop: "-2px" }}>
                                                {(!formikProps?.values?.licenses?.next?.isLicensed) ?
                                                    <Grid item xs={12}>
                                                        {(role !== RoleEnum.MEMBER) ?
                                                            (formikProps?.values?.licenses?.next?.request?.status !== "Pending") ? <>
                                                                {(formikProps?.values?.licenses?.current?.request?.status === "Pending") ?
                                                                    <Tooltip title={"V čase môže byť otvorená iba jedna žiadosť o obnovu licencie"}>
                                                                        <Box style={{ textAlign: "center" }}>
                                                                            <Button
                                                                                disabled={(formikProps?.values?.licenses?.current?.request?.status === "Pending")}
                                                                                color="primary"
                                                                                size="sm"
                                                                                round
                                                                                table
                                                                                onClick={() => { handleClubLicenseRequestCreate(formikProps?.values?.licenses?.next?.year) }}
                                                                            >
                                                                                <VerifiedUserIcon /> Obnoviť {formikProps?.values?.licenses?.next?.year}
                                                                            </Button>
                                                                        </Box>
                                                                    </Tooltip>
                                                                    :
                                                                    <Box style={{ textAlign: "center" }}>
                                                                        <Button
                                                                            disabled={(formikProps?.values?.licenses?.current?.request?.status === "Pending")}
                                                                            color="primary"
                                                                            size="sm"
                                                                            round
                                                                            table
                                                                            onClick={() => { handleClubLicenseRequestCreate(formikProps?.values?.licenses?.next?.year) }}
                                                                        >
                                                                            <VerifiedUserIcon /> Obnoviť {formikProps?.values?.licenses?.next?.year}
                                                                        </Button>
                                                                    </Box>
                                                                }
                                                            </>
                                                                :
                                                                <Box style={{ textAlign: "center" }}>
                                                                    <Button
                                                                        size="sm"
                                                                        round
                                                                        table
                                                                        onClick={() => { handleClubLicenseRequestCancel(formikProps?.values?.licenses?.next?.request?.id) }}
                                                                    >
                                                                        <BlockIcon /> Zrušiť žiadosť
                                                                    </Button>
                                                                </Box>
                                                            :
                                                            <span style={{ color: colors.primary }}>Neaktívna</span>
                                                        }
                                                    </Grid>
                                                    :
                                                    <span style={{ color: colors.primary }}>Aktívna</span>
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
                                        <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>Posledná synchronizácia ISŠ</Typography>
                                        <Typography variant="body2" style={{ fontSize: "1em" }}>{(issSyncedAt) ? (moment(issSyncedAt).format("DD.MM.YYYY HH:mm:ss")) : "-"}</Typography>
                                        {(role === RoleEnum.ADMIN) &&
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} align="center" style={{ padding: 0 }}>
                                                    <Button table onClick={() => handleIssSyncClub()} round color="primary" ><RepeatIcon /> Zápis do ISŠ</Button>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Box>
                                </Grid>
                                {/*!renewLicense &&
                                    <Grid item xs={12}>
                                        {!formikProps?.values?.license?.year &&
                                            <Box className={classes.floatRight}>
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    round
                                                    onClick={() => { handleClubLicenseRequestCreate() }}
                                                >
                                                    Obnoviť licenciu
                                                </Button>
                                            </Box>
                                        }
                                    </Grid>
                                    */}
                            </>
                        }
                    </Grid>
                </Grid>
                {!request && <>
                    <Grid item xs={12} >
                        <Divider style={{ backgroundColor: 'rgba(60,72,88, 0.4)' }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {(formikProps?.values?.useCorrespondenceAddress) ?
                            <Grid container spacing={1}>
                                <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >Korešpondenčná adresa</Typography></Grid>
                                <Grid item xs={12} sm={8}>
                                    <FormikField
                                        name="address1"
                                        labelText={intl.formatMessage({ id: 'address.street' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormikField
                                        name="address2"
                                        labelText={intl.formatMessage({ id: 'address.streetNumber' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormikField
                                        name="postal"
                                        labelText={intl.formatMessage({ id: 'address.postal' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8} >
                                    <FormikField
                                        name="city"
                                        disabled={!formikProps?.values?.country}
                                        labelText={intl.formatMessage({ id: 'address.city' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikField
                                        name="country"
                                        labelText={intl.formatMessage({ id: 'address.country' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikField
                                        name="district"
                                        disabled={!formikProps?.values?.country}
                                        labelText="Okres"
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={1}>
                                <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >Korešpondenčná adresa</Typography></Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikField
                                        name="correspondenceCountry"
                                        labelText={intl.formatMessage({ id: 'address.country' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikField
                                        name="correspondenceDistrict"
                                        labelText="Okres"
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8} >
                                    <FormikField
                                        name="correspondenceCity"
                                        labelText={intl.formatMessage({ id: 'address.city' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormikField
                                        name="correspondencePostal"
                                        labelText={intl.formatMessage({ id: 'address.postal' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        type="number"
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FormikField
                                        name="correspondenceAddress1"
                                        labelText={intl.formatMessage({ id: 'address.street' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormikField
                                        name="correspondenceAddress2"
                                        labelText={intl.formatMessage({ id: 'address.streetNumber' })}
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        highlightChange={highlightChange}
                                        disabled={closed}
                                        readMode={true}
                                        mandatory={true}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }} >Kontakt klubu</Typography></Grid>
                            <Grid item xs={12}>
                                <FormikField
                                    name="email"
                                    labelText={"Klubový e-mail"}
                                    formikProps={formikProps}
                                    validateParam={validateParam}
                                    highlightChange={highlightChange}
                                    disabled={closed}
                                    readMode={readMode}
                                    mandatory={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikField
                                    name="site"
                                    labelText={"Web stránka klubu"}
                                    formikProps={formikProps}
                                    validateParam={validateParam}
                                    link={"Choď na web klubu"}
                                    highlightChange={highlightChange}
                                    disabled={closed}
                                    readMode={readMode}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>
                                <FormikField
                                    name="social"
                                    labelText={"Sociálna sieť"}
                                    formikProps={formikProps}
                                    validateParam={validateParam}
                                    highlightChange={highlightChange}
                                    disabled={closed}
                                    link={"Choď na sociálnu sieť klubu"}
                                    readMode={readMode}
                                />
                             </Grid>*/}
                        </Grid>
                    </Grid>
                    {!renewLicense && <>
                        <Grid item xs={12} >
                            <Divider style={{ backgroundColor: 'rgba(60,72,88, 0.4)' }} />
                        </Grid>
                        <Grid item xs={12} >
                            {button}
                        </Grid>
                    </>
                    }
                </>
                }
            </Grid>
        </IconCard >
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(ClubCard));
