import gql from 'graphql-tag';

const PAYMENTS_TABLE = gql`
query paymentsTable($offset: Int, $limit: Int, $sort: [PaymentsTableSortInput!], $filter: [PaymentsTableFilterInput]) {
	paymentsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
  	totalCount,
    	items {
      	id
				bid
        club
        status
        purpose
        method
        amount
        variableSymbol
        invoiceNumber
        payBySquare
        createdAt
        dueDate
        paidAt
        description
      }
	}
}
`;

export default PAYMENTS_TABLE;