const TransfersAndHostingStatusesEnum = {
    AWAITING: 'Awaiting',
    APPROVED: "Approved",
    REJECTED: 'Rejected',
    CANCELED: "Canceled",
    EXPIRED: "Expired",
    UNPAID: "Unpaid",
    UNEXPRESSED: "Unexpressed",
    SUCCEEDED: 'Succeeded',
    RETURNED: 'Returned',
    PAID: 'Paid'
}
export default TransfersAndHostingStatusesEnum;
