import gql from 'graphql-tag';

const PERSON_INVITATION_SEND = gql`
mutation personInvitationSend($ids: [ID!]!){
  personInvitationSend(ids: $ids)  
}
`;

export default PERSON_INVITATION_SEND;

