import gql from 'graphql-tag';

const CLUB_UPDATE_REQUEST_APPROVE= gql`
mutation clubUpdateRequestApprove($id: ID! $input: ClubUpdateRequestInput!) { 
  clubUpdateRequestApprove(id: $id input: $input) {
  	id
  }
} 
`;

export default CLUB_UPDATE_REQUEST_APPROVE;