import { Grid } from "@material-ui/core";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import ALL_GROUPS from 'queries/GroupsQueries/allGroups';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import { widgets, colors } from 'common/styles/configLayout';
import { useQuery, useMutation } from '@apollo/client';
import { pathOr } from 'rambda';
import { variables } from "common/styles/configLayout";
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => styles(theme));

const BillingInfoCard = (props) => {
  const { intl, history, formikProps, allLocations, readData, companyUpdate, newCompany, validateParam, title = true, detail = false, readMode = false, closed = false, readModeYouthWorker } = props;
  const classes = useStyles();
  const [addStatutory, setAddStatutory] = useState(false)

  const renderContent = (index) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary, opacity: (closed) && 0.5 }}>{intl.formatMessage({ id: 'club.presidentCard.basicInformation' })}</Typography></Grid>
            <Grid item xs={12}>
              <FormikField
                name={"statutoryEmail"}
                labelText={intl.formatMessage({ id: 'personal.email' })}
                formikProps={formikProps}
                disabled={closed}
                validateParam={validateParam}
                highlightChange={false}
                readMode={readMode}
                mandatory={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name={"statutoryFirstName"}
                labelText={intl.formatMessage({ id: 'personal.firstName' })}
                formikProps={formikProps}
                disabled={closed}
                highlightChange={false}
                validateParam={validateParam}
                readMode={readMode}
                mandatory={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name={"statutoryLastName"}
                labelText={intl.formatMessage({ id: 'personal.lastName' })}
                formikProps={formikProps}
                disabled={closed}
                highlightChange={false}
                validateParam={validateParam}
                readMode={readMode}
                mandatory={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name={"statutoryPhone"}
                labelText={intl.formatMessage({ id: 'personal.phone' })}
                formikProps={formikProps}
                disabled={closed}
                highlightChange={false}
                validateParam={validateParam}
                readMode={readMode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary, opacity: (closed) && 0.5 }}>{intl.formatMessage({ id: 'club.statutoryCard.address' })}</Typography></Grid>
            <Grid item xs={12} sm={8}>
              <FormikField
                name="statutoryAddress1"
                labelText={intl.formatMessage({ id: 'address.street' })}
                formikProps={formikProps}
                disabled={closed}
                highlightChange={false}
                validateParam={validateParam}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormikField
                name="statutoryAddress2"
                labelText={intl.formatMessage({ id: 'address.streetNumber' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormikField
                name="statutoryPostal"
                labelText={intl.formatMessage({ id: 'address.postal' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={8} >
              <FormikField
                name="statutoryCity"
                disabled={!formikProps?.values?.statutoryCountry}
                labelText={intl.formatMessage({ id: 'address.city' })}
                type={(formikProps?.values?.statutoryCountry === variables.defaultCountry) ? "autocomplete" : ''}
                options={(formikProps?.values?.statutoryCountry === variables.defaultCountry) ?  allLocations.cities : []}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="statutoryCountry"
                labelText={intl.formatMessage({ id: 'address.country' })}
                type="autocomplete"
                options={allLocations.countries}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="statutoryDistrict"
                disabled={!formikProps?.values?.statutoryCountry}
                labelText="Okres"
                type={(formikProps?.values?.statutoryCountry === variables.defaultCountry) ? "autocomplete" : ''}
                options={(formikProps?.values?.statutoryCountry === variables.defaultCountry) ? allLocations.districts : []}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                readMode={readMode}
              />
            </Grid>
            {/*<Grid item xs={12}>
              <DocumentNewTable
                callbackRows={callbackRows}
                rows={rows}
                closed={closed}
                role={role}
                requestStatus={status}
                removeDocuments={removeDocuments}
                callbackRemoveDocuments={callbackRemoveDocuments}
                approveDocuments={approveDocuments}
                callbackApproveDocuments={callbackApproveDocuments}
                rejectDocuments={rejectDocuments}
                callbackRejectDocuments={callbackRejectDocuments}
              />
          </Grid>*/}
          </Grid>
        </Grid >
        <Grid item xs={6}>
          <FormikField
            name="isYouthWorker"
            labelText="Osoba pracuje s mládežou"
            formikProps={formikProps}
            validateParam={validateParam}
            type="select"
            options={[
              {
                value: true,
                label: 'Áno'
              },
              {
                value: false,
                label: 'Nie'
              },

            ]}
            highlightChange={false}
            readMode={readModeYouthWorker}
          />
        </Grid>
      </Grid>
    )
  }


  return (
    <IconCard title={(title) && <span style={{ opacity: (closed) && 0.5 }}>Štatutár / Predseda</span>}>
      {renderContent()}
    </IconCard>
  );
};

const mapStateToProps = (state) => ({
  allLocations: state.allLocations,
  role: state.role,
});

export default connect(mapStateToProps)(withRouter(injectIntl(BillingInfoCard)));
