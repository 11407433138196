import gql from 'graphql-tag';

const ACTIVATION_DETAILS = gql`
query activationDetails ($token: String!) {
  activationDetails(token: $token) {
    requirements{
      fullName
      gender
      birth
      phone
      personalId
      address
      password
    }
        profile{
          email
          nationality
          firstName
          lastName
          gender
          birth
          phone
          personalId
          address1
          address2
          postal
          city
          country
          district
        }
      }
}
`;

export default ACTIVATION_DETAILS;

