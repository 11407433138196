

const initialState = {
    role: null,
    allRoles: null,
    userProfile: null,
    address: null,
    lastUrl: null,
    pendingRequestsNumber: null,
    licensesNumber: null,
    documentsNumber: null,
    pendingTransfersAndHostingNumber: null,
    allLocations: {
        countries: null,
        districts: null,
        cities: null
    }
};

function addReducer(state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_USER_ROLE':
            return { ...state, role: action.value };
        case 'CHANGE_ALL_USER_ROLES':
            return { ...state, allRoles: action.value };
        case 'CHANGE_USER_PROFILE':
            return { ...state, userProfile: action.value };
        case 'CHANGE_ADDRESS':
            return { ...state, address: action.value };
        case 'CHANGE_LAST_URL':
            return { ...state, lastUrl: action.value };
        case 'CHANGE_PENDING_REQUESTS_NUMBER':
            return { ...state, pendingRequestsNumber: action.value };
        case 'CHANGE_LICENSES_NUMBER':
            return { ...state, licensesNumber: action.value };
        case 'CHANGE_DOCUMENTS_NUMBER':
            return { ...state, documentsNumber: action.value };
        case 'CHANGE_PENDING_TRANSFERS_AND_HOSTING_NUMBER':
            return { ...state, pendingTransfersAndHostingNumber: action.value };
        case 'CHANGE_LOCATION':
            return {
                ...state,
                allLocations: {
                    countries: action.value.countries,
                    districts: action.value.districts,
                    cities: action.value.cities
                }
            };
        default:
            return state;
    }
}

export default addReducer;