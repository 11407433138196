import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";
import paths from "paths";
import { Formik } from 'formik';
import FormikField from 'components-lib/FormikField/FormikField';
import { getValidationSchemaForgottenPassword } from './validationSchema';
import FORGOTTEN_PASSWORD from 'queries/AuthQueries/forgottenPassword'
import Typography from '@material-ui/core/Typography';
import { colors } from "common/styles/configLayout.js";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(styles);

const ForgottenPassword = (props) => {
    const { history, intl } = props;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [cardAnimaton, setCardAnimation] = useState("cardHidden");
    const [validateParam, setValidateParam] = useState(false)
    const [resetPassword] = useMutation(FORGOTTEN_PASSWORD);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const handlePasswordReset = (values) => {
        setDisableButton(true);
        resetPassword({
            variables: {
                email: values.email,
            }
        }).then(response => {
            enqueueSnackbar(intl.formatMessage({ id: 'forgottenPassword.snackbar.success' }), {
                variant: 'success'
            });
            setDisableButton(false);
        }).catch(err => {
            console.log('[err]', err);
            setDisableButton(false);
        })
    };

    return (
        <Grid container justify="center">
            <Grid item xs={12} sm={6} md={3}>
            <Grid login className={classes[cardAnimaton]} style={{ backgroundColor: colors.backgroundWidgets, borderRadius: "16px" }}>
                    <Formik
                        initialValues={{ email: '@' }}
                        onSubmit={(values) => { handlePasswordReset(values); }}
                        validationSchema={getValidationSchemaForgottenPassword(intl)}
                    >
                        {(formikProps) => (
                            <>
                                <CardBody>
                                    <Typography variant="h5" align="center" style={{ color: colors.primary}}> {intl.formatMessage({ id: 'forgottenPassword.title' })}</Typography>
                                    <br />
                                    <Typography variant="body2" align="center">{intl.formatMessage({ id: 'forgottenPassword.info' })}</Typography>
                                    <br />
                                    <FormikField
                                        highlightChange={false}
                                        name="email"
                                        formikProps={formikProps}
                                        validateParam={validateParam}
                                        labelText={intl.formatMessage({ id: 'forgottenPassword.email' })}
                                        mandatory={true}
                                    />
                                </CardBody>
                                <CardFooter
                                    className={classes.justifyContentCenter}
                                    style={{
                                        width: '100%',
                                        margin: '0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        padding: '20px'
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        size="sm"
                                        disabled={disableButton}
                                        round
                                        onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                                    >
                                        {intl.formatMessage({ id: 'forgottenPassword.button.submit' })}
                                    </Button>
                                    <Button
                                        simple
                                        color="primary"
                                        size="sm"
                                        round
                                        onClick={() => {
                                            history.push(`/auth${paths.auth.login}`)
                                        }}
                                    >
                                        {intl.formatMessage({ id: 'forgottenPassword.button.back' })}
                                    </Button>
                                </CardFooter>
                            </>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default injectIntl(withRouter(ForgottenPassword));
