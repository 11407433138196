import gql from 'graphql-tag';

const CLUB_REGISTER_REQUEST_REJECT= gql`
mutation clubRegisterRequestReject($id: ID!) { 
  clubRegisterRequestReject(id: $id) {
    id
    bid
    type
    status
    createdAt
  }
} 
`;

export default CLUB_REGISTER_REQUEST_REJECT;