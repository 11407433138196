import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import paths from "paths";
import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import ClubTable from "./ClubTable";
import CLUBS_TABLE from 'queries/ClubsQueries/clubsTable';
import { useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useQuery } from '@apollo/client';
import { pathOr } from 'rambda';
import Loading from "components-lib/Loading/Loading";
import { connect } from 'react-redux';
import PROFILE from 'queries/ProfileQueries/profile';
import RoleEnum from 'common/enums/RoleEnum';
import { changePendingRequestsNumberAction } from 'redux/actions';
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from "notistack";
import RequestStatusesEnum from 'common/enums/RequestStatusesEnum';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';

const ClubListPage = (props) => {
  const { intl, history, role, changePendingRequestsNumberAction } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
  const { data: userProfile } = useQuery(PROFILE);
  const { enqueueSnackbar } = useSnackbar();
  const client = getApolloClient(enqueueSnackbar, history, intl);

  useEffect(() => {
    if (role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT || role === RoleEnum.MEMBER) {
      if (pathOr('', ['me', 'member', 'club'], userProfile))
        history.push(`/admin${generatePath(paths.clubs.detail, { clubId: pathOr('', ['me', 'member', 'club', 'id'], userProfile) })}`)
    }
  }, [userProfile]);


  const getPendingRequestsNumber = async () => {
    try {
      const result = await client.query({
        query: REQUESTS_TABLE,
        variables: {
          "filter": [
            {
              "status": {
                "eq": (role === RoleEnum.ADMIN) ? RequestStatusesEnum.AWAITING : RequestStatusesEnum.RETURNED
              },
              "type": {
                "notIn": [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING]
              },
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changePendingRequestsNumberAction(pathOr('', ['data', 'requestsTable', 'totalCount'], result))
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (role === RoleEnum.STATUTORY)
    getPendingRequestsNumber()
  }, []);


  const getActions = () => {
    return [{
      title: intl.formatMessage({ id: "club.new.button" }),
      icon: <AddIcon />,
      onClick: () => history.push(`/admin${generatePath(paths.clubs.new)}`),
    }];
  };

  return (
    <>
      <PageHeader
        title={null}
        actions={getActions()}
        withBackButton={false}
      />
      <IconCard>
        <ClubTable
          query={CLUBS_TABLE}
          defaultFilter={defaultFilter}
          history={history}
          queryDataPath={['clubsTable', 'items']}
        />
      </IconCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  changePendingRequestsNumberAction: (value) => dispatch(changePendingRequestsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ClubListPage));