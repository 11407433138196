import gql from 'graphql-tag';

const CLUB_UPDATE_REQUEST_REJECT= gql`
mutation clubUpdateRequestReject($id: ID!) { 
  clubUpdateRequestReject(id: $id) {
    id
  }
} 
`;

export default CLUB_UPDATE_REQUEST_REJECT;