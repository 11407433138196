
import * as Yup from 'yup';

export const getValidationSchemaChangePassword = (intl) =>
    Yup.object().shape({
        newPassword: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        newPasswordVerify: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: 'validation.password.match' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
    });


