import { scope, action } from "common/enums/GroupsPermissionEnum"
class AuthGuard {
    grants = [];
    constructor(permissions) {
        this.grants = this.parse(permissions);
    }
    parse(permissions) {
        return permissions.map(p => {
            const match = p.match(/((?:[\w\-_]+)|\*):((?:[\w\-_]+)|\*):((?:all|own|\*))/);
            return (match) ? { resource: match[1], action: match[2], scope: match[3] } : null;
        })
            .filter(p => !!p)
            .sort((a, b) => this.compareGrants(a.resource, b.resource))
            .sort((a, b) => this.compareGrants(a.action, b.action))
            .sort((a, b) => this.compareGrants(a.scope, b.scope))
            .map(p => ({
                resource: new RegExp(p.resource.replace(/\*|all/, '.*')),
                action: new RegExp(p.action.replace(/\*|all/, '.*')),
                scope: ['all', '*'].includes(p.scope) ? 'all' : 'own'
            }))
    }
    compareGrants(valueA, valueB) {
        const isAnyA = ['all', '*'].includes(valueA);
        const isAnyB = ['all', '*'].includes(valueB);
        if (isAnyA && !isAnyB) return -1;
        if (!isAnyA && isAnyB) return 1;
        return 0
    }
    hasPermission(resource, action) {
        const grant = this.grants.find(g => g.resource.test(resource) && g.action.test(action));
        return (grant) ? grant.scope : null;
    }
}

let actions
if (window.localStorage.getItem("permissions")) {
    let permissionLocalStorage = window.localStorage.getItem("permissions");
    let helpPermission = (permissionLocalStorage)?.substring((permissionLocalStorage).indexOf("[") + 1)
    actions = helpPermission.split(',');
} else {
    actions = [
        "profile:list:own"
    ]
}

const ac = new AuthGuard(actions)
export const clubList = true;
export const clubDetail = true;
export const clubCreate = true;
export const clubUpdate = true;
export const clubDelete = true;
export const clubManageMembers = true;
export const deviceList = true;
export const deviceAssign = true;
export const deviceDisc = true;
export const deviceDetail = true;
export const deviceRestart = true;
export const deviceEn = true;
export const deviceDis = true;
export const deviceIdentify = true;
export const deviceTerminal = true;
export const storeList = true;
export const storeDetail = true;
export const storeCreate = true;
export const storeUpdate = true;
export const storeDel = true;
export const storeUpdateCampaigns = true;
export const storeUpdateStreams = true;
export const storeUpdateJingle = true;
export const commercialCampaignList = true;
export const commercialCampaignDetail = true;
export const commercialCampaignCreate = true;
export const commercialCampaignUpdate = true;
export const commercialCampaignDelete = true;
export const commercialCampaignApp = true;
export const commercialCampaignRej = true;
export const commercialCampaignCan = true;
export const internalCampaignList = true;
export const internalCampaignDetail = true;
export const internalCampaignCreate = true;
export const internalCampaignUpdate = true;
export const internalCampaignDelete = true;
export const internalCampaignApp = true;
export const internalCampaignRej = true;
export const internalCampaignCan = true;
export const streamList = true;
export const streamDisc = true;
export const mediaList = true;
export const mediaUpload = true;
export const mediaDownload = true;
export const mediaApp = true;
export const mediaRej = true;
export const mediaDel = true;
export const userList = true;
export const userCreate = true;
export const userDetail = true;
export const userUpdate = true;
export const userBlock = true;
export const userUnblock = true;
export const userPasswordReset = true;
export const invoiceList = true;
export const invoiceDetail = true;
export const invoiceCreate = true;
export const payoutList = true;
export const payoutDetail = true;
export const payoutCreate = true;
export const commissionList = true;
export const commissionExport = true;
export const commissionDetail = true;
export const dashboardList = true;
export const operationList = true;
export const groupList = true;
export const groupDetail = true;
export const groupCreate = true;
export const groupUpdate = true;
export const groupDelete = true;
export const settingsDetail = true;
export const settingsUpdate = true;
export const segmentList = true;

/*const getPrivilegion = (actionParam) => {
    let actions

    if (window.localStorage.getItem("permissions")) {
        let permissionLocalStorage = window.localStorage.getItem("permissions");
        let helpPermission = (permissionLocalStorage)?.substring((permissionLocalStorage).indexOf("[") + 1)
        actions = helpPermission.split(',');
    } else {
        actions = [
            "profile:list:own"
        ]
    }
    let permission = null
    actions.filter(action => action.includes(actionParam)).map(item => (
        permission = item.substr(item.length - 3)
    ))
    return permission
}*/

