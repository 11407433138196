import { colors, parameters } from "common/styles/configLayout.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({

    readOnlyGrid: {
        backgroundColor: colors.backgroundReadInputs,
        borderRadius: parameters.readInputsRounded,
        padding: "4px 14px",
        color: colors.textReadInputs
        //margin: "2px 0px 2px 0px"
    },
    selectAutocomplete: {
        color: colors.textReadInputs,
        //width: '800px', //TODO Set width for autocomplete select
        //width: '100%',
        minWidth: '300px',
        //maxWidth: '800px',
        overflow: 'auto',
        "& ul": {
            backgroundColor: colors.backgroundReadInputs,
            overflow: 'auto'
        },
        "& li": {
            margin: 2,
            fontSize: 13,
        },
    },

    label: {
        backgroundColor: "white"
    },
    inputLabelNoShrink: {
        marginTop: "-5.3px",
        '& label.Mui-focused': {
            backgroundColor: 'yellow',
            marginTop: "80px",

        }
    },

    inputLabelNoShrinkAndFocused: {
        marginTop: 0,
        //backgroundColor: 'red'
    },
    selectIcon: {
        color: colors.labelAndBorderInputs,
        "&:hover": {
            color: colors.textBody,
        }
    },


    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    disabledInput: {

        "& .MuiInputBase-root.Mui-disabled": {
            borderColor: 'red',
            color: "gray",
        },
        '& label': {
            color: 'gray',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
    },

    root: {
        color: 'white',
        // margin: "0px 0px",

        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(255, 255, 255, 0.3)",
            borderColor: 'red'
        },


        '& label.Mui-disabled': {
            color: "rgba(255, 255, 255, 0.3)",
        },

        '& input.Mui-disabled': {
            color: "rgba(255, 255, 255, 0.8)",
        },

        '& label': {
            color: colors.labelAndBorderInputs,
            //backgroundColor: colors.backgroundWidgets,
            //padding: '0 3px',
            //borderRadius: '50%',
            //paddingTop: "12px"

        },
        '& label.Mui-focused': {
            color: colors.primary,
            //paddingTop: "8px",

        },

        '& .MuiInput-underline:after': {
            borderBottomColor: colors.primary,
        },
        '& input': {
            //marginLeft: '6px',
            color: "white",

            '&:-webkit-autofill': {
                transition: "background-color 5000000s ease-in-out 0s",
                WebkitTextFillColor: 'white',
                caretColor: "white"
            },

            /*"&:-webkit-autofill": {
                //WebkitBoxShadow: `0 0 0 1000px rgb(22, 28, 36) inset`,
                //WebkitBoxShadow: `0 0 0 1000px #1f6b86 inset`,
                //WebkitBoxShadow: `0 0 0 1000px ${colors.backgroundWidgets} inset`,
                // backgroundColor: 'red !important',
                WebkitTextFillColor: 'white !important',
                //fontSize: 12
            },*/

        },

        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.labelAndBorderInputs,
                borderRadius: parameters.inputsRounded,
                //backgroundColor: colors.backgroundReadInputs,
            },
            '&:hover fieldset': {
                borderColor: 'white',
                '&:disabled': {
                    borderColor: 'red'
                },
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.primary,
            },
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.labelAndBorderInputs,
        },

        "& .MuiPickersDay-daySelected": {
            color: "#fff",
            fontWeight: 500,
            backgroundColor: 'red'
        },


        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& fieldset': {
                borderColor: "rgba(255, 255, 255, 0.3)",
            },
            '&:hover fieldset': {
                borderColor: "rgba(255, 255, 255, 0.3)",

            },
            '&.Mui-focused fieldset': {
                borderColor: "rgba(255, 255, 255, 0.3)",
            },
        },


    },
});
export default useStyles;
