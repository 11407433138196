import gql from 'graphql-tag';

const CLUB_HOSTING_REQUEST_TABLE= gql`
query clubHostingRequestsTable($offset: Int, $limit: Int, $sort: [ClubHostingRequestsTableSortInput!], $filter: [ClubHostingRequestsTableFilterInput]) {
  clubHostingRequestsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
        id
        bid
        status
        year
        member
        sourceClub
        targetClub
        memberId
        sourceClubId
        targetClubId
        memberResponse
        sourceClubResponse
        targetClubResponse
        expiresIn
      }
  }
}
`;

export default CLUB_HOSTING_REQUEST_TABLE;