import gql from 'graphql-tag';

const LICENSING_INFO = gql`
query licensingInfo {
  licensingInfo {
    periods {
      current {
        year
        isPurchasable
        availableFrom
      }
        next {
        year
        isPurchasable
        availableFrom
      }
    }
  }
}
`;

export default LICENSING_INFO;