import gql from 'graphql-tag';

const PAYMENT_CONFIRM = gql`
mutation paymentConfirm($id: ID!, $input:PaymentConfirmInput! ) {
  paymentConfirm(id: $id, input: $input) {
    id
  }
}
`;

export default PAYMENT_CONFIRM;