import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import paths from "paths";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import MemberTable from "./MemberTable";
import CLUB_MEMBERS_TABLE from 'queries/MembersQueries/clubMembersTable';
import { useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { getAPIUrl } from "configFiles/config";
import { getUserToken } from "helpers/helpers";
import { Grid } from '@material-ui/core';
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { getValidationSchemaMonths } from "./validationSchema";
import Typography from "@material-ui/core/Typography";
import { colors } from "common/styles/configLayout.js";
import RoleEnum from 'common/enums/RoleEnum';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";

const MemberListPage = (props) => {
  const { intl, history, role } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
  const { data: licensingInfoData } = useQuery(LICENSING_INFO);

  const [modalOpen, setModalOpen] = useState(false);

  const handleGetExportInsuranceCompany = async (month, year, resetForm) => {
    let url = `${getAPIUrl('rest')}/export/insuranceCompany?month=${month}&year=${year}`
    const result = await fetch(url, {
      headers: {
        authorization: `Bearer ${(getUserToken() || "")}`,
      }
    })
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    var downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `${intl.formatMessage({ id: 'member.insuranceExport.file' })}${month}-${year}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    setModalOpen(false)
    resetForm();
  }

  const handleGetExportReferee = async () => {
    let url = `${getAPIUrl('rest')}/export/referee`
    const result = await fetch(url, {
      headers: {
        authorization: `Bearer ${(getUserToken() || "")}`,
      }
    })
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    var downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `${intl.formatMessage({ id: 'member.refereeExport.file' })}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const renderModalButtons = (formikProps) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => formikProps.handleSubmit()}
          color="primary"
          round
          table
          size="sm">
          <SystemUpdateAltIcon /> {intl.formatMessage({ id: 'member.insuranceExport.button' })}
        </Button>
      </>
    );
  };

  const getActions = () => {
    let array = [];
    if (role === RoleEnum.ADMIN) {
      array.push({
        title: intl.formatMessage({ id: 'member.action.button.statutoryInvite' }),
        icon: <AddIcon />,
        onClick: () => history.push(`/admin${generatePath(paths.members.invite)}`),
      })
    }
    if (role !== RoleEnum.MEMBER) {
      array.push({
        title: intl.formatMessage({ id: 'member.action.button.memberInvite' }),
        icon: <AddIcon />,
        onClick: () => history.push(`/admin${generatePath(paths.clubs.invite)}`),
      })
    }

    if (role === RoleEnum.ADMIN) {
      array.push({
        title: intl.formatMessage({ id: 'member.action.button.insuranceExport' }),
        icon: <SystemUpdateAltIcon />,
        onClick: () => setModalOpen(true),
      })
      array.push({
        title: intl.formatMessage({ id: 'member.action.button.refereeExport' }),
        icon: <SystemUpdateAltIcon />,
        onClick: () => handleGetExportReferee(),
      })
    }

    return array;
  };

  const getMonths = () => {
    return [
      { value: 1, label: intl.formatMessage({ id: 'month.january' }) },
      { value: 2, label: intl.formatMessage({ id: 'month.february' }) },
      { value: 3, label: intl.formatMessage({ id: 'month.march' }) },
      { value: 4, label: intl.formatMessage({ id: 'month.april' }) },
      { value: 5, label: intl.formatMessage({ id: 'month.may' }) },
      { value: 6, label: intl.formatMessage({ id: 'month.june' }) },
      { value: 7, label: intl.formatMessage({ id: 'month.july' }) },
      { value: 8, label: intl.formatMessage({ id: 'month.august' }) },
      { value: 9, label: intl.formatMessage({ id: 'month.september' }) },
      { value: 10, label: intl.formatMessage({ id: 'month.october' }) },
      { value: 11, label: intl.formatMessage({ id: 'month.november' }) },
      { value: 12, label: intl.formatMessage({ id: 'month.december' }) },
    ]
  }

  const renderTables = () => {
    return (
      <div>
        <MemberTable
          query={CLUB_MEMBERS_TABLE}
          defaultFilter={defaultFilter}
          history={history}
          queryDataPath={['clubMembersTable', 'items']}
          currentYear={licensingInfoData?.licensingInfo?.periods?.current?.year}
          nextYear={licensingInfoData?.licensingInfo?.periods?.next?.year}
        />
      </div>
    );
  };
  return (
    <>
      <PageHeader
        title={null}
        actions={getActions()}
        withBackButton={false}
      />
      <IconCard>
        {renderTables()}
      </IconCard>
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values, { resetForm }) => handleGetExportInsuranceCompany(values?.month, values?.year, resetForm)}
        validationSchema={getValidationSchemaMonths(intl)}
        initialValues={{}}
        enableReinitialize
      >
        {(formikProps) => (
          <>
            <Modal
              title={intl.formatMessage({ id: 'member.insuranceExport.modal.title' })}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              actions={renderModalButtons(formikProps)}
              fullWidth
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ color: colors.textBody }}>{intl.formatMessage({ id: 'member.insuranceExport.modal.body' })}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormikField
                    name="year"
                    labelText={intl.formatMessage({ id: 'table.year' })}
                    mandatory={true}
                    formikProps={formikProps}
                    validateParam={true}
                    highlightChange={false}
                    type="select"
                    options={[
                      { value: licensingInfoData?.licensingInfo?.periods?.current?.year, label:  licensingInfoData?.licensingInfo?.periods?.current?.year},
                      { value: licensingInfoData?.licensingInfo?.periods?.next?.year, label:  licensingInfoData?.licensingInfo?.periods?.next?.year},
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormikField
                    name="month"
                    labelText={intl.formatMessage({ id: 'table.month' })}
                    mandatory={true}
                    formikProps={formikProps}
                    validateParam={true}
                    highlightChange={false}
                    type="select"
                    options={getMonths()}
                  />
                </Grid>
              </Grid>
            </Modal>
          </>
        )}
      </Formik >
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps)(injectIntl(MemberListPage));