export let variables = {
    systemName: 'Cyklopas',
    defaultCountry: "Slovenská republika",
    isNextYearAvailable: true
};

export let colors = {
    //main colors
    globalBackground: 'rgb(22, 28, 36)',
    textButtons: '#1A1D1D',
    textBody:"#E1ECFA",
    textReadInputs:"#F5F6F6",
    labelAndBorderInputs: "#60738C",
    backgroundWidgets: "#2C3947",
    tableWidgetsHover: "#273340",
    backgroundSidebar: '#2C3947',
    backgroundReadInputs: "#364759",
    sidebarButtons: '28,202,255', //just RGB parameters 'r,g,b
    sidebarHiddenShadow: "rgb(22, 28, 36)",
    strokeColor: "#4A596B",

    //state colors
    primary: "#64D6ED",
    primaryAlpha: "#367380",
    primaryHover: "#A6E1ED",
    primaryBackground: "#1ccaff10",   
    secondary: "#9264ED",
    secondaryAlpha: "#4F3680",
    secondaryHover: "#BEA6ED",
    secondaryBackground: "#7635DC",
    success: "#5AD47E",
    successAlpha: "#36804C",
    successHover: "#A6EDBB",
    successBackground: "#00ab5510",
    warning: "#EDAA47",
    warningAlpha: "#805B26",
    warningHover: "#EDCFA6",
    warningBackground: "#fda92d10",
    error: "#EE504A",
    errorAlpha: "#803836",
    errorHover: "#EEA9A7",
    errorBackground: "#ff484210",
    disabled: "#C3D2E6",
    disabledAlpha: "#3c4858", //Not set
    disabledHover: "#DDECFF",
    disabledBackground: "#2F3D4D", //Not set
}

export let parameters = {
    buttonsRounded: '20px',
    widgetsRounded: '20px',
    tablesRounded: "10px",
    inputsRounded: "10px",
    readInputsRounded: "5px",
    shadow: false,
}