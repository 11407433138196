import { pathOr } from "rambda";
import RoleEnum from '../common/enums/RoleEnum';

export const phoneRegex = /^(?:\+\d{1,3}[ \-]?)?(?:\(?\d+\)?[ \-\/\.]?)+$/;
export const ibanRegex = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/;

export function isStrongPassword(password) {
    const length = password.length;
    const hasLC = /[a-z]+/.test(password);
    const hasUC = /[A-Z]+/.test(password);
    const hasNum = /[0-9]+/.test(password);
    return length >= 8 && hasLC && hasUC && hasNum;
}


export const getLoggedUser = () => window.localStorage.getItem('user');

export const getLoggedUserRole = () => pathOr('', ['role'], JSON.parse(window.localStorage.getItem('user')));
export const getLoggedUserGroups = () => pathOr([], ['goups'], JSON.parse(window.localStorage.getItem('user')));

export const isInRoles = (roles, role) => {
    return roles.includes(role);
}

export const isInGroups = (groups) => {
    return groups.includes(getLoggedUserGroups());
}

export const getUserToken = () => window.localStorage.getItem('token');

export const processLogin = (data) => {
    const token = pathOr('', ['data', 'login', 'token'], data);
    const user = pathOr('', ['data', 'login', 'user'], data);
    const permissions = pathOr([], ['data', 'login', 'permissions'], data);
    const lang = pathOr('sk', ['data', 'login', 'user', 'preferences', 'language'], data);

    window.localStorage.setItem('token', token);
    window.localStorage.setItem('lang', lang);
    window.localStorage.setItem('user', JSON.stringify(user));
    window.localStorage.setItem('permissions', JSON.stringify(permissions));
};

export const processLogout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('groups');
    window.localStorage.removeItem('role');
    window.localStorage.removeItem('request');
};

export const userIsLogged = () => !!window.localStorage.getItem('user');

export const getRoleEnumBySlug = (slug) => RoleEnum[slug.toUpperCase()];

export const removeTypenameFromObject = (values) => {
    Object.keys(values).map((key) => {
        if (typeof values[key] === 'object' && values[key] !== null && !Array.isArray(values[key])) {
            removeTypenameFromObject(values[key]);
        } else if (typeof values[key] === 'object' && values[key] !== null && Array.isArray(values[key])) {
            //process array of objects
        } else if (key === '__typename') {
            delete values[key];
        }
        return ""
    });

    return values;
};

export const getSelectedLanguage = () => window.localStorage.getItem('lang') || 'sk';

export const setPreferedLanguage = (lang) => window.localStorage.setItem('lang', lang);

export const getErrorMessageByErrorCode = (errorCode, intl) => intl.formatMessage({ id: `apiErrors.${errorCode}` });
    // intl.formatMessage({ id: '' });
