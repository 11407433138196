import gql from 'graphql-tag';

const DOCUMENT= gql`
query document($id: ID!){
  document(id: $id)  {
   files{
    downloadUrl
  },
    name,
    type,
    validFrom,
    validTo,
    createdAt
  }
}
`;

export default DOCUMENT;