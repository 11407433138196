import { Grid } from "@material-ui/core";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { colors, } from 'common/styles/configLayout';

const useStyles = makeStyles((theme) => styles(theme))

const PaymentInfoCard = (props) => {
    const { intl, data } = props;
    const classes = useStyles();

    return (
        <IconCard
            //background={(data?.status === "Pending") ? colors.warningBackground : (data?.status === 'Canceled') ? colors.errorBackground : colors.successBackground}
            title={<span style={{ color: (data?.status === "Pending") ? colors.warning : (data?.status === 'Canceled') ? colors.error : colors.success }}>{intl.formatMessage({ id: `payments.infoCard.title` })}</span>}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box >
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{ fontSize: "1.2em" }}>
                                <span >{intl.formatMessage({ id: `payments.infoCard.amount` })}: </span>
                                <Box className={classes.floatRight}>
                                    <span>{data?.amount} €</span>
                                </Box>
                            </Typography>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ fontSize: "1em", color: (data?.status === "Pending") ? colors.warning : (data?.status === 'Canceled') ? colors.error : colors.success }}>
                                    <span >{intl.formatMessage({ id: `payments.infoCard.status` })}:</span>
                                    <Box className={classes.floatRight}>
                                        <span>{(data?.status) ? intl.formatMessage({ id: `payments.status.${data?.status}` }) : "-"}</span>
                                    </Box>
                                </Typography>
                            </Grid>
                            {(data?.status === "Succeeded") &&
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                        <span >{intl.formatMessage({ id: `payments.infoCard.method` })}:</span>
                                        <Box className={classes.floatRight}>
                                            <span>{(data?.method) ? intl.formatMessage({ id: `payments.method.${data?.method}` }) : "-"}</span>
                                        </Box>
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                    <span>{intl.formatMessage({ id: `payments.infoCard.payBy` })}:</span>
                                    <Box className={classes.floatRight}>
                                        <span>{data?.club?.profile?.name}</span>
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                    <span>{intl.formatMessage({ id: `payments.infoCard.purpose` })}:</span>
                                    <Box className={classes.floatRight}>
                                        <span>{(data?.purpose) ? intl.formatMessage({ id: `payments.purpose.${data?.purpose}` }) : "-"}</span>
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                    <span>{intl.formatMessage({ id: `payments.infoCard.paymentId` })}: </span>
                                    <Box className={classes.floatRight}>
                                        <span>{data?.bid}</span>
                                    </Box>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </IconCard>
    )
}

export default injectIntl(PaymentInfoCard);