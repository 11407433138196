import { Switch, Route, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import { useQuery } from '@apollo/client';
import { pathOr } from 'rambda';
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import getProjectRoutes from "routes.js";
import { injectIntl } from 'react-intl';
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import backgroundImage from "assets/img/backgroundImage.jpg";
import paths from 'paths';
import Grid from '@material-ui/core/Grid';
import logo from 'assets/img/cyklopas-logo-etext-cinv-horizontal.svg';
import Typography from '@material-ui/core/Typography';
import { colors } from "common/styles/configLayout.js";
import COUNTRIES from "queries/GenerallyQueries/countries";
import DISTRICTS from "queries/GenerallyQueries/districts";
import CITIES from "queries/GenerallyQueries/cities";
import { changeLocation } from 'redux/actions';
import { connect } from 'react-redux';

const useStyles = makeStyles(styles);

const Auth = (props) => {
  const { intl,
    history,
    changeLocation,
  } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();

  const routes = getProjectRoutes(intl);
  const { data: countriesData } = useQuery(COUNTRIES, {
    variables: {
      sort: [{
        field: "order",
        order: "ASC"
      }]
    }
  });
  const { data: districtsData } = useQuery(DISTRICTS);
  const { data: citiesData } = useQuery(CITIES);

  useEffect(() => {
    let countries = pathOr([], "countries.items", countriesData).map((item) => {
      return {
        value: item.name,
        label: item.name
      };
    });

    let districts = pathOr([], "districts.items", districtsData).map((item) => {
      return {
        value: item.name,
        label: item.name
      };
    });
    let cities = pathOr([], "cities.items", citiesData).map((item) => {
      return {
        value: item.name,
        label: item.name
      };
    });
    changeLocation(countries, districts, cities);
  }, [countriesData, districtsData, citiesData]);

  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getPublicRoutType = () => {
    if (window.location.pathname.indexOf(`${paths.auth.clubs}`) !== -1) {
      return 'publicQuery';
    } else if (window.location.pathname.indexOf(`${paths.auth.members}`) !== -1) {
      return 'publicQuery';
    } else {
      return 'authPages';
    }
  };

  const getBgImage = () => {
    return backgroundImage;
  };

  /*const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };*/

  return (
    <div>
      {getPublicRoutType() === 'authPages' ?
        <div ref={wrapper}>
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + getBgImage() + ")" }}
          >
            <Grid container style={{ zIndex: 1000, margin: "20px" }}>
              {/*<div style={{ position: "fixed", top: 15, left: 15 }}>
                <Grid item>
                  <img src={logo} alt={logo} style={{ width: '150px', minWidth: "50px", paddingTop: '10px', paddingLeft: '10px', display: 'inline-block', }} />
                </Grid>
              </div>*/}
              <Grid container justify="center" direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/auth" to={`/auth${paths.auth.login}`} />
                </Switch>
                <br />
              </Grid>

              <div style={{ position: "absolute", bottom: 22, transform: 'translate(-50%, -50%)', left: '50%' }}>
                <Typography variant="body2" style={{ fontSize: '0.9em', color: colors.textBody }}>Cyklopas prevádzkuje Slovenský zväz cyklistiky.</Typography>
              </div>
              <div style={{ position: "absolute", bottom: 0, transform: 'translate(-50%, -50%)', left: '50%' }}>
                <Typography variant="body2" style={{ fontSize: '0.9em', color: colors.textBody }}>Kukuričná 13, 831 03 Bratislava</Typography>
              </div>

            </Grid>
          </div>
        </div >
        :

        <Switch>
          {getRoutes(routes)}
          <Redirect from="/auth" to={`/auth${paths.auth.login}`} />
        </Switch>

      }
    </div >
  );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  changeLocation: (countries, districts, cities) => dispatch(changeLocation(countries, districts, cities)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Auth)));
