import gql from 'graphql-tag';

const ACTION_INVITATIONS = gql`
query actionInvitations($offset: Int, $limit: Int, $sort: [ActionInvitationSortInput!], $filter: [ActionInvitationFilterInput]) {
  actionInvitations (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
  		id
		bid
		type
		usedAt
		createdAt
		updatedAt
		deletedAt
		data{
			... on ClubRegisterInvitation { 
				isYouthWorker
			}
		}
		person{
			member{
				isYouthWorker
			}
		}
      }
  }
}
`;

export default ACTION_INVITATIONS;

