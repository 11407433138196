import gql from 'graphql-tag';

const CLUB_CREATE= gql`
mutation clubCreate($input: ClubCreateInput!) { 
  clubCreate(input: $input) {
    id
    bid
   }
} 
`;

export default CLUB_CREATE;