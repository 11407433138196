import React from 'react';
import ReactLoading from "react-loading";
import { Section, Article, Prop, list } from "./generic";
import { injectIntl } from 'react-intl';

const Loading = (props) => {
    const { intl } = props;
    return (
        <Section>
            {list.map(l => (
                <Article key={l.prop}>
                    <ReactLoading type={l.prop} color="white" />
                    <Prop style={{ color: "white" }} >{intl.formatMessage({ id: 'routes.loading' })}</Prop>
                </Article>
            ))}
        </Section>
    );
};

export default injectIntl(Loading);