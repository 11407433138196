import gql from 'graphql-tag';

const PASSWORD_RESET_DETAILS = gql`
query passwordResetDetails ($token: String!) {
  passwordResetDetails(token: $token) {
   email
  }
}
`;

export default PASSWORD_RESET_DETAILS;

