import gql from 'graphql-tag';

const CLUB_MEMBER_SUSPEND = gql`
mutation clubMemberSuspend($id: ID!) {
  clubMemberSuspend(id: $id) 
}
`;

export default CLUB_MEMBER_SUSPEND;

