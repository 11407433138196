import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";
const currentYear = new Date();
const validateYear = ((currentYear.getMonth() + 1) + '/' + (currentYear.getDate()) + '/' + (currentYear.getFullYear() - 1));

const getInvitationSchema = (intl) => {
    return ({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        phone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
        emergencyPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
        isYouthWorker: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        firstName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        lastName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        personalId: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        birth: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .max(validateYear, `Minimálny vek používateľa je 1 rok`)
            .nullable(),
        nationality: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        countryOfBirth: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' })),
        gender: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        address1: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        address2: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        city: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        district: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        country: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    })
}


export const getValidationSchemaMemberDetail = (intl) =>
    Yup.object().shape({
        ...getInvitationSchema(intl)
    });

export const getValidationSchemaMemberDetailForeign = (intl) =>
    Yup.object().shape({
        ...getInvitationSchema(intl)
    });

export const getValidationSchemaInviteStatutory = (intl) =>
    Yup.object().shape({
        ...getInvitationSchema(intl)
    });

export const getValidationSchemaInviteMember = (intl) =>
    Yup.object().shape({
        ...getInvitationSchema(intl)
    });

export const getValidationSchemaInviteMemberWithClub = (intl) =>
    Yup.object().shape({
        clubs: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        ...getInvitationSchema(intl)
    });

export const getValidationSchemaMonths = (intl) =>
    Yup.object().shape({
        month: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        year: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });

export const getValidationSchemaChangeClub = (intl) =>
    Yup.object().shape({
        clubId: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });
