import { createMuiTheme } from '@material-ui/core/styles';
import { colors, parameters } from "common/styles/configLayout.js";

const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: colors.backgroundWidgets,
            light: '#2196f3',
            dark: '#283593',
        },
        secondary: {
            main: '#78909c',
        },
    },
    overrides: {
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colors.disabledBackground,
                color: 'white'
            }
        },
        MuiButton: {
            root: {
                color: 'white',
                "&:hover": {
                    color: colors.primary
                }
            },
            label: {
                color: 'white'
            }
        },
        MuiPickersDay: {
            day: {
                color: "white",
                backgroundColor: colors.disabledBackground,
            },
            hour: {
                color: "white",
                backgroundColor: colors.disabledBackground,
            },
            container: {
                color: 'red',
                backgroundColor: colors.disabledBackground,
            },
            dayDisabled: {
                color: "black",
            },
            current: {
                color: colors.primary,
            },
        },

        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: colors.disabledBackground,
            },
            dayLabel: {
                color: 'white',
            },
        },

        MuiDialogActions: {
            root: {
                backgroundColor: colors.backgroundWidgets,
                color: 'white'
            }
        },

        MuiOutlinedInput: {
            root: {
                '& fieldset': {
                    borderColor: colors.labelAndBorderInputs,
                    borderRadius: parameters.inputsRounded,
                },
                "&:hover $notchedOutline": {
                    borderColor: "white"
                  },   
                  "&$focused $notchedOutline": {
                    borderColor: colors.primary
                  },
            }
        }

    },
});

export default materialTheme;