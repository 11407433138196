import gql from 'graphql-tag';

const INVITATION_DETAILS = gql`
query invitationDetails ($token: String!) {
  invitationDetails(token: $token) {
    action{
      type
      data {
        ... on ClubJoinInvitation {
          club   
        }
      }
    }
    requirements{
      fullName
      gender
      birth
      phone
      personalId
      address
      password
    }
    profile{
    	email
      firstName
      lastName
      countryOfBirth
      nationality
      preNominals
      postNominals
      gender
      birth
      phone
      personalId
      address1
      address2
      postal
      city
      country
      district
      emergencyFirstName
      emergencyLastName
      emergencyPhone
    }
  }
}
`;

export default INVITATION_DETAILS;

