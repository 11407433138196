import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import React from "react";
import { injectIntl } from "react-intl";
import CLUB_MEMBERS_PUBLIC_TABLE from 'queries/PublicQueries/clubMembersPublicTable';
import Grid from '@material-ui/core/Grid';
import PublicMembersTable from './PublicMembersTable';
import { colors } from 'common/styles/configLayout';

const PublicMemberListPage = (props) => {
  const { intl } = props;
  return (
    <Grid container style={{ padding: '20px 30px 20px 30px', zIndex: 1000 }}>
      <Grid item xs={12}>
        <PageHeader
          title={<span style={{ color: colors.backgroundReadInputs }}>{intl.formatMessage({ id: 'publicPages.members.title' })}</span>}
          withBackButton={false}
        />
        <IconCard>
          <PublicMembersTable
            query={CLUB_MEMBERS_PUBLIC_TABLE}
            queryDataPath={['clubMembersPublicTable', 'items']}
          />
        </IconCard>
      </Grid>
    </Grid>
  );
};


export default injectIntl(PublicMemberListPage);