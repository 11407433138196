import * as Yup from 'yup';

export const getValidationSchemaTransfer = (intl) =>
    Yup.object().shape({
        from: Yup.date().
            required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });

export const getValidationSchemaHosting = (intl) =>
    Yup.object().shape({
        from: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        to: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .min(Yup.ref('from'), intl.formatMessage({ id: 'validation.graterDate' }))
            .nullable(),
    });