import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { colors } from 'common/styles/configLayout';
import { connect } from 'react-redux';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => styles(theme))

export const ClubTable = (props) => {
    const { history, queryVariables, query, intl, defaultFilter = null, queryDataPath } = props
    const classes = useStyles();
    const { data: licensingInfoData } = useQuery(LICENSING_INFO);

    const onClubDetail = (id) => {
        history.push(`/admin${generatePath(paths.clubs.detail, { clubId: id })}`);
    }

    return (<>
        <QueryTable
            query={query}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            columnsWidth={['150px', undefined, undefined, '130px', undefined, undefined, '130px', '40px']}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'table.bid' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.locality' }),
                    accessor: "location",
                    sortKey: "location",
                    filterKey: 'location',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.members' }),
                    accessor: "members",
                    sortKey: "members",
                    filterKey: "members",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: 'table.email' }),
                    accessor: (rowData) => rowData.email ? rowData.email : "-",
                    sortKey: "email",
                    filterKey: "email",
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.functionary' }),
                    accessor: (rowData) => rowData.functionary ? rowData.functionary : "-",
                    sortKey: "functionary",
                    filterKey: "functionary",
                    filterOperator: 'contains'

                },
                {
                    Header: intl.formatMessage({ id: 'clubs.table.license' }),
                    accessor: (rowData) => (rowData.license)
                        ? <Box style={{ color: (rowData.license >= licensingInfoData?.licensingInfo?.periods?.current?.year) ? colors.success : '' }} > {rowData.license} </Box>
                        : "-",
                    sortKey: "license",
                    filterKey: 'license',
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: ' ',
                    accessor: (rowData) => <Box className={classes.floatRight}><Button round table size="sm" color="primary"
                        onClick={() => onClubDetail(rowData.id)}>{intl.formatMessage({ id: 'table.detail' })}</Button></Box>
                }
            ]}
        />
    </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(ClubTable));
