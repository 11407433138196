import gql from 'graphql-tag';

const CLUB_MEMBERS_TABLE = gql`
query clubMembersTable($offset: Int, $limit: Int, $sort: [ClubMembersTableSortInput!], $filter: [ClubMembersTableFilterInput]) {
  clubMembersTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
        id
        bid
        club
        firstName
        lastName
        functions
        birth
        gender
        license
        disciplines
        categories
        registeredAt
        clubId
        isLicensePurchasable
      }
  }
}
`;

export default CLUB_MEMBERS_TABLE;

