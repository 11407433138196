import gql from 'graphql-tag';

const DISCIPLINES = gql`
query disciplines($offset: Int, $limit: Int, $sort: [DisciplineSortInput!], $filter: [DisciplineFilterInput]) {
  disciplines(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount
      items {
          id
          name
      }
  }
}
`;

export default DISCIPLINES;