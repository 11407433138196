import gql from 'graphql-tag';

const REQUESTS= gql`
query requests($offset: Int, $limit: Int, $sort: [RequestSortInput!], $filter: [RequestFilterInput]) {
  requests (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
  			id
        bid
        type
        status
        createdAt
        updatedAt
        deletedAt
      }
  }
}
`;

export default REQUESTS;