import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import LANGUAGE_CHANGE from 'queries/ProfileQueries/languageChange';
import CHANGE_PASSWORD from 'queries/ProfileQueries/changePassword';
import Grid from '@material-ui/core/Grid';
import PersonalDataCard from '../Profile/DetailCards/PersonalDataCard';
import DocumentsCard from '../Clubs/ClubCards/DocumentsCard';
import RoleEnum from 'common/enums/RoleEnum';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import LanguageIcon from '@material-ui/icons/Language';
import CustomSelect from 'components-lib/CustomSelect/CustomSelect';
import { IntlContext } from "index";
import { setPreferedLanguage } from 'helpers/helpers';
import Loading from "components-lib/Loading/Loading";
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { styled } from '@material-ui/core/styles';
import { widgets, colors, } from 'common/styles/configLayout';
import AvatarCard from '../Profile/DetailCards/AvatarCard';
import ClubCard from '../Clubs/ClubCards/ClubCard';
import ChangePasswordCard from '../Profile/DetailCards/ChangePasswordCard';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InfoIcon from '@material-ui/icons/Info';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PeopleIcon from '@material-ui/icons/People';
import Container from '@material-ui/core/Container';
import FormikField from "components-lib/FormikField/FormikField";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import defaultAvatar from "assets/img/faces/avatar.jpg";
import Avatar from '@material-ui/core/Avatar';
import { getValidationSchema } from "./validationSchema";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useLocation } from 'react-router-dom';
import LicenseDetailTable from './LicenseCards/LicenseDetailTable';
import ALL_GROUPS from 'queries/GroupsQueries/allGroups';
import MEMBER_LICENSE_REQUEST from 'queries/LicensesQueries/memberLicenseRequest';
import moment from "moment";
import PaymentCard from '../Payments/PaymentCards/PaymentCard';
import PaymentLicenseTableCard from '../Payments/PaymentCards/PaymentLicenseTableCard';
import MEMBER_LICENSE_REQUEST_DOWNLOAD from 'queries/LicensesQueries/memberLicenseRequestDownload'
import MEMBER_LICENSE_REQUEST_CONFIRM from 'queries/LicensesQueries/memberLicenseRequestConfirm'
import MEMBER_LICENSE_REQUEST_CANCEL from 'queries/LicensesQueries/memberLicenseRequestCancel'
import MEMBER_LICENSE_REQUEST_SUMMARY from "queries/LicensesQueries/memberLicenseRequestSummary";
import { getApolloClient } from "configFiles/apollo";
import { changeLicensesNumberAction } from 'redux/actions';
import MEMBER_LICENSE_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseRequestsTable';
import BlockIcon from '@material-ui/icons/Block';

import { connect } from 'react-redux';
import LicensePaymentTable from './LicenseCards/LicensePaymentTable';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => styles(theme))

const LicenseDetailPage = (props) => {
    const { intl, history, role, changeLicensesNumberAction } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const [licensesCount, setLicensesCount] = useState(null)
    const [licenseSummary, setLicenseSummary] = useState(null)
    const [disableButton, setDisableButton] = useState(false);

    const licenseId = (props?.match?.params?.licenseId) ? props?.match?.params?.licenseId : undefined
    const { loading, data: memberLicenseRequest, refetch: memberLicenseRefetch } = useQuery(MEMBER_LICENSE_REQUEST, { variables: { id: licenseId } });
    const [memberLicenseRequestDownload] = useMutation(MEMBER_LICENSE_REQUEST_DOWNLOAD)
    const [memberLicenseRequestConfirm] = useMutation(MEMBER_LICENSE_REQUEST_CONFIRM)
    const [memberLicenseRequestCancel] = useMutation(MEMBER_LICENSE_REQUEST_CANCEL)

    const getLicensesNumber = async () => {
        try {
            const result = await client.query({
                query: MEMBER_LICENSE_REQUESTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "in": (role === RoleEnum.ADMIN) ? ["Ready", "Downloaded"] : ["Pending"]
                            }
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changeLicensesNumberAction(pathOr('', ['data', 'memberLicenseRequestsTable', 'totalCount'], result));
        } catch (error) {
            throw error;
        }
    }

    /*const getLicenseSummary = async (year, clubId, licenses) => {
        if (licenses.length > 0) {
            try {
                const result = await client.query({
                    query: MEMBER_LICENSE_REQUEST_SUMMARY,
                    variables: {
                        input: {
                            year: year,
                            clubId: clubId,
                            licenses: licenses
                        }
                    }
                });
                if (result.error) {
                    throw result.error;
                }
                if (result.errors) {
                    throw result.errors[0];
                }
                setLicenseSummary(result?.data?.memberLicenseRequestSummary)
            } catch (error) {
                throw error;
            }
        }
    }*/


    useEffect(() => {
        setLicensesCount(Object.values(pathOr('', ['memberLicenseRequest', 'licenses'], memberLicenseRequest))?.map((x, index) => { return index })?.length);
        // getLicenseSummary(pathOr('', ['memberLicenseRequest', 'year'], memberLicenseRequest),
        //     pathOr('', ['memberLicenseRequest', 'club', 'id'], memberLicenseRequest),
        //     pathOr('', ['memberLicenseRequest', 'licenses'], memberLicenseRequest))
        //setStatus(Object.values(pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest)));
    }, [memberLicenseRequest]);

    function downloadURI(url) {
        var link = document.createElement("a");
        link.download = url;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleConfirmLicense = () => {
        setDisableButton(true);
        memberLicenseRequestConfirm({
            variables: {
                id: licenseId
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie bola potvrdená", { variant: 'success' });
            getLicensesNumber();
            memberLicenseRefetch();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    const handleDownloadLicense = () => {
        setDisableButton(true);
        memberLicenseRequestDownload({
            variables: {
                id: licenseId
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie vo formáte PDF bola stiahnutá", { variant: 'success' });
            downloadURI(response?.data?.memberLicenseRequestDownload?.url);
            memberLicenseRefetch();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    const handleCancelLicense = () => {
        setDisableButton(true);
        memberLicenseRequestCancel({
            variables: {
                id: licenseId
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie bola zrušená", { variant: 'success' });
            memberLicenseRefetch();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }



    const getActions = () => {
        let action = [];
        if (role === RoleEnum.ADMIN) {
            if ((pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Downloaded"))
                action.push({
                    title: "Označiť ako vytlačené",
                    icon: <CheckIcon />,
                    disabled: disableButton,
                    onClick: () => handleConfirmLicense(),
                });
            if ((pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Pending"))
                action.push({
                    title: "Zrušiť žiadosť",
                    icon: <BlockIcon />,
                    color: "warning",
                    disabled: disableButton,
                    onClick: () => handleCancelLicense(),
                })
        }
        return action;
    };

    const getStatus = (status) => {
        if (status === "Pending") return <Box style={{ color: colors.warning }} >Žiadosť o licencie (čaká na platbu)</Box>
        else if (status === "Paid") return <Box>Žiadosť o licencie (zaplatená)</Box>
        else if (status === "Ready") return <Box style={{ color: colors.warning }}>Žiadosť o licencie (čaká na výrobu)</Box>
        else if (status === "PartialReady") return <Box style={{ color: colors.warning }}>Žiadosť o licencie (čiastočne spracovaná)</Box>
        else if (status === "Downloaded") return <Box style={{ color: colors.secondary }}>Žiadosť o licencie (stiahnuté PDF)</Box>
        else if (status === "Completed") return <Box style={{ color: colors.success }}>Žiadosť o licencie (dokončená)</Box>
        else if (status === "Canceled") return <Box style={{ color: colors.error }}>Žiadosť o licencie (zrušená)</Box>
        else return <Box>{status}</Box>
    }

    const getBackground = (status) => {
        if (status === "Pending") return colors.warningBackground;
        else if (status === "Ready") return colors.warningBackground;
        else if (status === "PartialReady") return colors.warningBackground;
        else if (status === "Completed") return colors.successBackground;
        else if (status === "Downloaded") return colors.secondaryBackground;
        else if (status === "Canceled") return colors.errorBackground;
    }

    const getColor = (status) => {
        if (status === "Pending") return colors.warning;
        else if (status === "Ready") return colors.warning;
        else if (status === "PartialReady") return colors.warning;
        else if (status === "Downloaded") return colors.secondary;
        else if (status === "Completed") return colors.success;
        else if (status === "Canceled") return colors.error;
    }


    if (loading) return <Loading />;
    return (<>
        <PageHeader
            title={getStatus(pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest))}
            subTitle={getStatus(pathOr('', ['memberLicenseRequest', 'bid'], memberLicenseRequest))}
            actions={getActions()}
            withBackButton={false}
        />
        <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
                <Grid item xs={12}>
                    <IconCard title={""} inside>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box className={classes.readOnlyGrid}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Klub:</span>
                                                <Box className={classes.floatRight}>
                                                    <span style={{ fontSize: "1em", color: getColor(pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest)) }} >{pathOr('', ['memberLicenseRequest', 'club', 'profile', 'name'], memberLicenseRequest)}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Dátum podania:</span>
                                                <Box className={classes.floatRight}>
                                                    <span>{moment(pathOr('', ['memberLicenseRequest', 'createdAt'], memberLicenseRequest)).format("DD.MM.YYYY")}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Počet licencií: </span>
                                                <Box className={classes.floatRight}>
                                                    <span>{licensesCount}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                {((role === RoleEnum.ADMIN) && (pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Ready" || pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Downloaded" || pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Completed")) &&
                                    <Box className={classes.floatRight}>
                                        <Button round table disabled={disableButton} color="primary" onClick={() => handleDownloadLicense()}><GetAppIcon /> Stiahnuť PDF na tlač</Button>
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <LicenseDetailTable
                                    rows={pathOr('', ['memberLicenseRequest', 'licenses'], memberLicenseRequest)}
                                    role={role}
                                    status={pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest)}
                                    defaultFilter={null}
                                    refetch={memberLicenseRefetch}
                                    history={history}
                                />
                            </Grid>
                        </Grid>
                    </IconCard>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
                <IconCard title={"Licenčné poplatky"} inside>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <LicensePaymentTable
                                licenseSummary={pathOr('', ['memberLicenseRequest', 'summary'], memberLicenseRequest)}
                            />
                        </Grid>
                    </Grid>
                </IconCard>
                <PaymentCard
                    data={pathOr('', ['memberLicenseRequest', 'payment'], memberLicenseRequest)}
                    detail="memberLicenseAwaiting"
                    history={history}
                    getLicensesNumber={getLicensesNumber()}
                    refetch={memberLicenseRefetch}
                />
            </Grid>
        </Grid>
    </>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LicenseDetailPage));


