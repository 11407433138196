import gql from 'graphql-tag';

const USER_INVITE = gql`
mutation UserInvite($email: EmailAddress!, $input: UserInviteInput) {
  userInvite(email: $email, input: $input)
}
`;

export default USER_INVITE;

