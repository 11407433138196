import gql from 'graphql-tag';

const CLUB_UPDATE_REQUEST_REVIEW= gql`
mutation clubUpdateRequestReview($id: ID! $description: String!  $input: ClubUpdateRequestInput!) { 
  clubUpdateRequestReview(id: $id description: $description input: $input) {
    id
  }
} 
`;

export default CLUB_UPDATE_REQUEST_REVIEW;