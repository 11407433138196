import gql from 'graphql-tag';

const CLUB_HOSTING_REQUEST_CANCEL= gql`
mutation clubHostingRequestCancel($id:ID!) { 
  clubHostingRequestCancel(id: $id) {
    id
    bid
   }
} 
`;

export default CLUB_HOSTING_REQUEST_CANCEL;