import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import FormikField from "components-lib/FormikField/FormikField";
import { DropzoneAreaBase, DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import Typography from "@material-ui/core/Typography"
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import Divider from '@material-ui/core/Divider';
import { AttachFile, AudioTrack, Description, PictureAsPdf, Theaters } from '@material-ui/icons';
import { colors } from "common/styles/configLayout.js";

const useStyles = makeStyles((theme) => styles(theme))
const useStyles2 = makeStyles(() => ({
    dropZone: {
        height: '100%',
        fullWidth: 'false',
    },
    previewContainer: {
        container: 'true',
        width: '100%',
        height: '100%',
    },
    preview: {
        width: '100%',
        height: '100%',
        item: 'true',
        xs: '12',
    },
    previewImg: {
        height: '100%',
        width: '100%',
    },
    fileDropzone: {
        "& .MuiFab-root": {
            boxShadow: 'none'
        },
        "& .MuiDropzonePreviewList-removeButton": {
            opacity: 100,
            height: '30px',
            width: '30px',
            top: 0,
            right: 0,
            backgroundColor: 'transparent',
            borderRadius: "4px",
            border: 'none',
            background: 'none',
            color: colors.textBody
        },
    },

}));
export const DocumentUploadCard = (props) => {
    const { history, intl, formikProps, callbackUploadedFile, validateParam, uploadedFile, documentOptions, open, setOpen, submit } = props
    const classes = useStyles();
    const classes2 = useStyles2();
    const { enqueueSnackbar } = useSnackbar();
    const getIcon = (type) => {
        switch (type) {
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return <Description className={classes.attachIcons} style={{ color: colors.textBody }} />
            case "application/pdf":
                return <PictureAsPdf className={classes.attachIcons} style={{ color: colors.textBody }} />
            default:
                return <AttachFile className={classes.attachIcons} style={{ color: colors.textBody }} />
        }
    }

    const handlePreviewIcon = (fileObject, classes) => {
        const { type } = fileObject.file
        const iconProps = {
            className: classes.image,
        }
        return <Grid container style={{ textAlign: 'left' }}>
            <Grid item xs={12}>
                <p style={{ color: colors.textBody }}>{getIcon(type)} {fileObject.file.name}</p>
            </Grid>
        </Grid>
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} className={classes2.fileDropzone}>
                <DropzoneAreaBase
                    fileObjects={uploadedFile}
                    onAdd={newFileObjs => { callbackUploadedFile([...uploadedFile, ...newFileObjs]); }}
                    onDelete={deleteFileObj => { callbackUploadedFile(uploadedFile.filter((f) => f !== deleteFileObj)); }}
                    getPreviewIcon={handlePreviewIcon}
                    showPreviewsInDropzone={false}
                    filesLimit={6}
                    //showFileNamesInPreview={true}
                    showPreviews={true}
                    //maxFileSize={9000000}
                    showAlerts={false}
                    //useChipsForPreview={true}
                    removeButton={{ textAlign: 'left', container: { spacing: 1, direction: 'row', textAlign: 'left' }, item: { xs: 12, textAlign: 'left' } }}
                    dropzoneClass={classes.dropZone}
                    dropzoneText={<Typography align={'center'} variant="body1" style={{ marginTop: "-7.5px", color: colors.textBody }} >{<CloudUploadIcon className={classes.circleSelect} />}{intl.formatMessage({ id: "documents.dropzone.body" })}</Typography>}
                    dropzone
                    previewGridProps={{ textAlign: 'left', container: { spacing: 1, direction: 'row', textAlign: 'left' }, item: { xs: 12, textAlign: 'left' } }}
                    //previewChipProps={{ classes: { root: classes.previewChip } }}
                    previewText={<><Divider style={{ backgroundColor: 'rgba(60,72,88, 0.4)' }} /><Typography variant="body2" style={{ margin: "6px", color: colors.textBody }}  >{intl.formatMessage({ id: "documents.dropzone.title" })}: </Typography></>}
                //filesLimit={5}
                />

                {/*<DropzoneArea
                    dropzoneClass={classes.dropZone}
                    showPreviews={(uploadedFile?.length > 0) ? true : false}
                    showPreviewsInDropzone={false}
                    initialFiles={uploadedFile}
                    value={uploadedFile}
                    open={open}
                    useChipsForPreview
                    dropzoneText={<Typography variant="body1" style={{ marginTop: "-15px" }} ><CloudUploadIcon className={classes.circleSelect} />{intl.formatMessage({ id: "documents.dropzone.body" })}</Typography>}
                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                    previewChipProps={{ classes: { root: classes.previewChip } }}
                    previewText={<Typography variant="body1" style={{ color: "white", margin: "3px" }}  >{intl.formatMessage({ id: "documents.dropzone.title" })}: </Typography>}
                    filesLimit={5}
                    onChange={(files) => { callbackUploadedFile(files) }}
                    onClose={() => callbackUploadedFile(null)}
                    clearOnUnmount
                    onClose={() => setOpen(false)}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    onDrop={(files) => callbackUploadedFile(files)}
                />*/}

            </Grid>
            <Grid item xs={12}>
                <Divider style={{ backgroundColor: 'rgba(60,72,88, 0.4)' }} />
            </Grid>
            {documentOptions &&
                <Grid item xs={12}>
                    <FormikField
                        name="type"
                        type="select"
                        options={documentOptions}
                        labelText={intl.formatMessage({ id: "documents.type" })}
                        formikProps={formikProps}
                        highlightChange={false}
                        validateParam={validateParam}
                    />
                </Grid>
            }
            <Grid item xs={12} sm={6}>
                <FormikField
                    name="validFrom"
                    labelText={intl.formatMessage({ id: "documents.validFrom" })}
                    formikProps={formikProps}
                    type="datePicker"
                    highlightChange={false}
                    validateParam={validateParam}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormikField
                    name="validTo"
                    labelText={intl.formatMessage({ id: "documents.validTo" })}
                    formikProps={formikProps}
                    type="datePicker"
                    highlightChange={false}
                    validateParam={validateParam}
                />
            </Grid>
        </Grid>

    )
}

export default injectIntl(DocumentUploadCard);