import gql from 'graphql-tag';

const ACTIVATE = gql`
mutation activate ($token: String!, $input: UserActivateInput) {
  activate(token: $token, input:$input) {
   user{
    id
  }
 }
}
`;

export default ACTIVATE;

