import React, { useState, useEffect } from 'react';
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import DocumentsTable from 'pages/Documents/DocumentTables/DocumentsTable';
import DocumentRequestTable from 'pages/Documents/DocumentTables/DocumentRequestTable';
import CLUB_DOCUMENTS_TABLE_BY_CLUB from 'queries/DocumentsQueries/clubDocumentsTableByClub';
import CLUB_DOCUMENT_UPLOAD from 'queries/DocumentsQueries/clubDocumentUpload';
import CLUB_DOCUMENT_DELETE from 'queries/DocumentsQueries/clubDocumentDelete';
import { useLazyQuery } from '@apollo/client';
import ClubDocumentsEnum from 'common/enums/ClubDocumentsEnum';
import { connect } from 'react-redux';

const DocumentsCard = (props) => {
    const { intl, role, formikProps, history, readMode = false, detail, callbackRows, rows, closed, status, clubId,
        callbackRemoveDocuments, removeDocuments, callbackApproveDocuments, approveDocuments, callbackRejectDocuments, rejectDocuments, updateClub } = props;
    const [loadDocuments, { data: dataDocuments, refetch: refetchDocuments }] = useLazyQuery(CLUB_DOCUMENTS_TABLE_BY_CLUB, { variables: { clubId: clubId } });
    const [documentOptions, setDocumentOptions] = useState({})

    useEffect(() => {
        let options = [];
        options = Object.values(ClubDocumentsEnum).map((item) => (
            {
                value: item,
                label: intl.formatMessage({ id: `documents.club.${item}` })
            }
        ));
        setDocumentOptions(options);
    }, []);

    return (
        <IconCard title={(!detail) && intl.formatMessage({ id: 'club.documentsCard.newTitle' })}>
            {detail ?
                <DocumentsTable
                    data={dataDocuments}
                    loadData={loadDocuments}
                    refetch={refetchDocuments}
                    formikProps={formikProps}
                    queryDataPath={['clubDocumentsTableByClub', 'items']}
                    documentOptions={documentOptions}
                    documentUploadMutation={CLUB_DOCUMENT_UPLOAD}
                    documentDeleteMutation={CLUB_DOCUMENT_DELETE}
                    parentId={clubId}
                    documentType={"Club"}
                />
                :
                <DocumentRequestTable
                    callbackRows={callbackRows}
                    rows={rows}
                    closed={closed}
                    role={role}
                    formikProps={formikProps}
                    requestStatus={status}
                    updateClub={(updateClub) ? updateClub : null}
                    documentOptions={(updateClub) ? documentOptions : null}
                    removeDocuments={removeDocuments}
                    callbackRemoveDocuments={callbackRemoveDocuments}
                    approveDocuments={approveDocuments}
                    callbackApproveDocuments={callbackApproveDocuments}
                    rejectDocuments={rejectDocuments}
                    callbackRejectDocuments={callbackRejectDocuments}
                />
            }
        </IconCard >
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(DocumentsCard));
