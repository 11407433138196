import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useMutation, useQuery } from '@apollo/client';
import { withRouter } from "react-router-dom";
import paths from "../../paths";
import { getValidationSchemaRegisterClub, getValidationSchemaRegisterClubForeign } from "./validationSchema";
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import { generatePath } from 'react-router-dom';
import REGISTER_WITH_INVITATION from 'queries/AuthQueries/registerWithInvitation'
import Typography from '@material-ui/core/Typography';
import { colors, variables } from "common/styles/configLayout.js";
import IconCard from 'components-lib/IconCard/IconCard';
import { pathOr } from 'rambda';
import moment from 'moment';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';
import { connect } from 'react-redux';

const useStyles = makeStyles(styles);

const RegisterInvitationPage = (props) => {
  const { history, intl, data, registrationType, token, allLocations } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [validateParam, setValidateParam] = useState(false)
  const [disableButton, setDisableButton] = useState(false);

  const [register] = useMutation(REGISTER_WITH_INVITATION);

  const [userData, setUserData] = useState({})
  const [countryOfBirth, setCountryOfBirth] = useState(null)




  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const handleActivateUser = (values) => {
    setDisableButton(true);
    const result = { ...values };
    delete result.gdpr;
    delete result.szcConfirmation;
    delete result.termsOfUse;
    delete result.email;
    delete result.passwordRepeat;
    delete result.__typename;
    delete result.birth;
    let tempDate = moment(values?.birth).format('YYYY-MM-DD');

    register({
      variables: {
        token: token,
        input: {
          ...result,
          birth: (values?.birth) ? tempDate : undefined,
        }
      }
    }).then(response => {
      (registrationType === RequestTypesEnum.CLUB_REGISTRATION)
        ? enqueueSnackbar(intl.formatMessage({ id: 'registerNewClub.snackbar.success' }), { variant: 'success' })
        : enqueueSnackbar(intl.formatMessage({ id: 'registerInvitationClub.snackbar.success' }), { variant: 'success' });

      history.push({
        pathname: `/auth${generatePath(paths.auth.confirm)}`,
        state: {
          type: (registrationType) ? registrationType : 'ClassicRegistration',
          email: data?.invitationDetails?.profile?.email,
          password: values?.password,
          //token: response?.data?.registerWithInvitation?.token
        }
      })
      setDisableButton(false);
    }).catch((err) => {
      console.log('[error]', err);
      setDisableButton(false);
    });
  };




  useEffect(() => {
    setUserData(pathOr('', ['invitationDetails', 'profile'], data));
  }, [data]);

  return (

    <Grid item xs={12} sm={6} md={6}>
      <form>
        <IconCard
          title={<Typography variant="h5" align="center" style={{ color: colors.primary, marginBottom: "10px"}}>
            {(registrationType === RequestTypesEnum.CLUB_REGISTRATION)
              ? intl.formatMessage({ id: 'registerNewClub.title' })
              : intl.formatMessage({ id: 'registerInvitationClub.title' })
            }</Typography>}
          className={classes[cardAnimaton]}
        >
          {/*<div style={{ textAlign: 'center' }}>
            <img src={logoVertical} alt={logoVertical} style={{ textAlign: 'center', marginTop: '-240px' }} width="100px" />
          </div>*/}
          <Formik
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize
            validationSchema={countryOfBirth === variables.defaultCountry
              ? getValidationSchemaRegisterClub(intl)
              : getValidationSchemaRegisterClubForeign(intl)
            }
            onSubmit={(values) => { handleActivateUser(values); }}
            initialValues={(userData) ? userData : {}}
          >
            {(formikProps) => (
              <>
                {setCountryOfBirth(formikProps?.values?.countryOfBirth)}
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center" style={{ marginTop: "-13px", color: colors.textReadInputs }} >{(registrationType === RequestTypesEnum.CLUB_REGISTRATION)
                      ? intl.formatMessage({ id: 'registerNewClub.subtitle' })
                      : (registrationType === RequestTypesEnum.CLUB_REGISTRATION)
                        ? intl.formatMessage({ id: 'registerInvitationClub.subtitle' }, [data?.invitationDetails?.action?.data?.club])
                        : intl.formatMessage({ id: 'register.subtitle' })
                    }
                    </Typography>
                    <Typography variant="body2" align="center" style={{ color: colors.primary }}>{pathOr('', ['email'], userData)}</Typography>
                    <Typography variant="body2" align="center" style={{ color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'registerClub.body' })}</Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'profile.personalDataCard.personalTitle' })}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="firstName"
                            labelText={intl.formatMessage({ id: 'personal.firstName' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="lastName"
                            labelText={intl.formatMessage({ id: 'personal.lastName' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="preNominals"
                            labelText={intl.formatMessage({ id: 'personal.preNominals' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="postNominals"
                            labelText={intl.formatMessage({ id: 'personal.postNominals' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="birth"
                            labelText={intl.formatMessage({ id: 'personal.birth' })}
                            formikProps={formikProps}
                            type="datePicker"
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                          <FormikField
                            name="personalId"
                            labelText={intl.formatMessage({ id: 'profile.personalDataCard.idNumber' })}
                            formikProps={formikProps}
                            type="number"
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={(formikProps?.values?.countryOfBirth === variables.defaultCountry)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="gender"
                            labelText={intl.formatMessage({ id: 'personal.gender' })}
                            mandatory={true}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            type="select"
                            options={[
                              {
                                value: 'Male',
                                label: intl.formatMessage({ id: 'select.Male' })
                              },
                              {
                                value: 'Female',
                                label: intl.formatMessage({ id: 'select.Female' })
                              },

                            ]}
                            highlightChange={false}
                          />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="phone"
                            labelText={intl.formatMessage({ id: 'personal.phone' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="countryOfBirth"
                            labelText={intl.formatMessage({ id: 'personal.countryOfBirth' })}
                            mandatory={true}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            type="autocomplete"
                            options={allLocations.countries}
                            highlightChange={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="nationality"
                            labelText={intl.formatMessage({ id: 'personal.nationality' })}
                            mandatory={true}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            type="autocomplete"
                            options={allLocations.countries}
                            highlightChange={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }} >
                          <br />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'profile.personalDataCard.address.title' })}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <FormikField
                            name="address1"
                            labelText={intl.formatMessage({ id: 'address.street' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormikField
                            name="address2"
                            labelText={intl.formatMessage({ id: 'address.streetNumber' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormikField
                            name="postal"
                            labelText={intl.formatMessage({ id: 'address.postal' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} >
                          <FormikField
                            name="city"
                            disabled={!formikProps?.values?.country}
                            labelText={intl.formatMessage({ id: 'address.city' })}
                            type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
                            options={(formikProps?.values?.country === variables.defaultCountry) ? allLocations.cities : []}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="country"
                            labelText={intl.formatMessage({ id: 'address.country' })}
                            type="autocomplete"
                            options={allLocations.countries}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikField
                            name="district"
                            disabled={!formikProps?.values?.country}
                            labelText={intl.formatMessage({ id: 'personal.district' })}
                            type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
                            options={(formikProps?.values?.country === variables.defaultCountry) ? allLocations.districts : []}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'profile.personalDataCard.emergencyContactTitle' })}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormikField
                            name="emergencyFirstName"
                            labelText={intl.formatMessage({ id: 'personal.firstName' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormikField
                            name="emergencyLastName"
                            labelText={intl.formatMessage({ id: 'personal.lastName' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikField
                            name="emergencyPhone"
                            labelText={intl.formatMessage({ id: 'personal.phone' })}
                            formikProps={formikProps}
                            validateParam={validateParam}
                            highlightChange={false}
                            mandatory={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ color: colors.warning }}>{intl.formatMessage({ id: 'register.newPassword.title' })}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormikField
                      name="password"
                      labelText={intl.formatMessage({ id: 'passwordReset.newPassword' })}
                      formikProps={formikProps}
                      validateParam={validateParam}
                      type="password"
                      highlightChange={false}
                      mandatory={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikField
                      name="passwordRepeat"
                      labelText={intl.formatMessage({ id: 'passwordReset.repeatPassword' })}
                      formikProps={formikProps}
                      validateParam={validateParam}
                      type="password"
                      highlightChange={false}
                      mandatory={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >Súhlasím s&nbsp;
                            <a /*onClick={() => alert('PDF s podmienkami ochrany údajov')}*/ target="_blank" style={{ textDecoration: 'underline', color: colors.primary }}>podmienkami ochrany údajov</a>
                          </span>}
                          smallDescription={true}
                          type="checkbox"
                          name="gdpr"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '-10px' }}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >Súhlasím s&nbsp;
                            <a /*onClick={() => alert('PDF s podmienkami používania')}*/ style={{ textDecoration: 'underline', color: colors.primary }}>podmienkami používania</a>
                          </span>}
                          smallDescription={true}
                          type="checkbox"
                          name="termsOfUse"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '-10px' }}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >{intl.formatMessage({ id: 'register.szcConfirmation' })}</span>}
                          smallDescription={true}
                          type="checkbox"
                          name="szcConfirmation"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <CardFooter
                  className={classes.justifyContentCenter}
                  style={{
                    width: '100%',
                    margin: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    color="primary"
                    disabled={disableButton}
                    size="sm"
                    round
                    onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                  >
                    {(registrationType === RequestTypesEnum.CLUB_REGISTRATION) ? intl.formatMessage({ id: 'registerClub.button.body' }) : intl.formatMessage({ id: 'registerInvitationClub.button.body' })}
                  </Button>
                </CardFooter>
              </>
            )}
          </Formik>
        </IconCard>
      </form>
    </Grid>
  );
};


const mapStateToProps = (state) => ({
  allLocations: state.allLocations,
  role: state.role,
});

export default connect(mapStateToProps)(withRouter(injectIntl(RegisterInvitationPage)));

