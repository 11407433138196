import gql from 'graphql-tag';

const CLUB_MEMBERS_PUBLIC = gql`
query clubMembersPublic($offset: Int, $limit: Int, $sort: [ClubMemberPublicSortInput!], $filter: [ClubMemberPublicFilterInput]) {
	clubMembersPublic (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
		totalCount,
    items {
      id
      bid
			uciId
      federationId
      birth
			firstName
			lastName
      fullName
      isFunctionary
      license
			functions {
        function {
          id
          name
        }
        disciplines{
          id
          name
        }
      }
			club{
        id
        bid
        chairman{
          id
          bid
          person{
            profile{
              firstName
              lastName
              phone
              email
            }
          }
        }
        statutory{
          id
          bid
          person{
            profile{
              firstName
              lastName
              phone
              email
            }
          }
        }
        profile{
          name
          address1
          address2
          postal
          city
          district
          country
          
        }
      }
    }
  }
}
`;

export default CLUB_MEMBERS_PUBLIC;

