import gql from 'graphql-tag';

const CITIES = gql`
query cities( $offset: Int, $limit: Int, $sort: [CitySortInput!], $filter: [CityFilterInput]){
  cities(offset: $offset, limit: $limit, sort:$sort, filter: $filter ) {
    items {
      id
      code
      name
      order
    }
  }
}
`;

export default CITIES;