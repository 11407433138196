import gql from 'graphql-tag';

const MEMBER_LICENSE_REQUEST_SUMMARY= gql`
query memberLicenseRequestSummary($input:MemberLicenseRequestInput!){
  memberLicenseRequestSummary(input:$input) {
    products {
      name
      unitPrice
      quantity
      price
    }
    total
  }
}
`;

export default MEMBER_LICENSE_REQUEST_SUMMARY;