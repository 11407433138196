import Axios from "axios";
import { getUserToken } from "../helpers/helpers";
import { getAPIUrl } from "./config";

export const getAxiosClient = () => {
    const token = getUserToken();

    return Axios.create({
        baseURL: getAPIUrl('rest'),
        headers: {
           Authorization: `Bearer ${(token || "")}`
           //Authorization: token
        }
    });
}

