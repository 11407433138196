import gql from 'graphql-tag';

const CLUB_HOSTING_REQUEST_REJECT= gql`
mutation clubHostingRequestReject($id:ID!) { 
  clubHostingRequestReject(id: $id) {
    id
    bid
   }
} 
`;

export default CLUB_HOSTING_REQUEST_REJECT;