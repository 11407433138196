import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import React from "react";
import { injectIntl } from "react-intl";
import CLUBS_PUBLIC_TABLE from 'queries/PublicQueries/clubsPublicTable';
import Grid from '@material-ui/core/Grid';
import PublicClubsTable from './PublicClubsTable';
import { colors } from 'common/styles/configLayout';

const PublicClubListPage = (props) => {
  const { intl } = props;

  return (
    <Grid container style={{ padding: '20px 30px 20px 30px', zIndex: 1000 }}>
      <Grid item xs={12}>
        <PageHeader
          title={<span style={{ color: colors.backgroundReadInputs }}>{intl.formatMessage({ id: 'publicPages.clubs.title' })}</span>}
          withBackButton={false}
        />
        <IconCard >
          <PublicClubsTable
            query={CLUBS_PUBLIC_TABLE}
            queryDataPath={['clubsPublicTable', 'items']}
          />
        </IconCard>
      </Grid>
    </Grid>
  );
};


export default injectIntl(PublicClubListPage);