import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.js";
import paths from "paths";
import LOGIN from 'queries/AuthQueries/login'
import { useSnackbar } from "notistack";
import { processLogin } from 'helpers/helpers';
import { useMutation } from '@apollo/client';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';
import { colors } from "common/styles/configLayout";
import IconCard from 'components-lib/IconCard/IconCard';

const useStyles = makeStyles(styles);

const ConfirmPage = (props) => {
  const { intl, history } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [disableButton, setDisableButton] = useState(false);

  let location = useLocation();
  let state = location.state ? location.state : null
  const [login] = useMutation(LOGIN);

  const handleLogin = () => {
    setDisableButton(true);
    login({
      variables: {
        email: location?.state?.email,
        password: location?.state?.password,
        keepLoggedIn: true,
      }
    }).then(response => {
      enqueueSnackbar(intl.formatMessage({ id: 'login.snackbar.success' }), { variant: 'success' })
      window.location.reload()
      processLogin(response);
      if (state?.type === RequestTypesEnum.CLUB_REGISTRATION)
        history.push(`/admin${paths.requests.new}`)
      else
        history.push(`/admin${paths.profile}`)
      setDisableButton(false);
    }).catch(err => {
      console.log('[err]', err);
      setDisableButton(false);
    })
  };

  const getSubtitle = () => {
    if (state?.type === RequestTypesEnum.CLUB_REGISTRATION) return <Typography variant="body1" style={{ color: colors.backgroundWidgets }}>{intl.formatMessage({ id: 'confirm.subtitle.newClub' })} </Typography>
    else if (state?.type === RequestTypesEnum.CLUB_JOIN) return <Typography variant="body1" style={{ color: colors.backgroundWidgets }}>{intl.formatMessage({ id: 'confirm.subtitle.joinToClub' })} </Typography>
    else if (state?.type === "Register") return <Typography variant="body1" style={{ color: colors.backgroundWidgets }}>{intl.formatMessage({ id: 'confirm.subtitle' })}</Typography>
    else if (state?.type === "ClassicRegistration") return <Typography variant="body1" style={{ color: colors.backgroundWidgets }}>{intl.formatMessage({ id: 'confirm.subtitle.joinToClub' })} </Typography>
    else return <Typography variant="body1" style={{ color: colors.backgroundWidgets }}>{intl.formatMessage({ id: 'confirm.subtitle.joinToClub' })} </Typography>
  }

  function addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <div className={classes.contentCenter}>
      <Grid container spacing={1}>
        <IconCard background={addAlpha(colors.primary, 0.95)}>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ color: colors.textBody }}>{intl.formatMessage({ id: 'confirm.title' })}</Typography>
            {getSubtitle()}
            {(state?.type !== "Register") && <>
              <br />
              <Button
                color="disabled"
                size="sm"
                round
                disabled={disableButton}
                onClick={() => handleLogin()}
              >
                {(state?.type === RequestTypesEnum.CLUB_REGISTRATION)
                  ? intl.formatMessage({ id: 'confirm.button.newClub' })
                  : intl.formatMessage({ id: 'confirm.button.profile' })
                }
              </Button>
            </>
            }
          </Grid>
        </IconCard>
      </Grid>
    </div >
  );
}
export default injectIntl(withRouter(ConfirmPage));

