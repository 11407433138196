import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { clubDetail } from 'configFiles/roles'
import Box from "@material-ui/core/Box";
import moment from "moment";
import { widgets, colors, } from 'common/styles/configLayout';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { connect } from 'react-redux';
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from 'notistack';
import REQUEST from 'queries/RequestsQueries/request';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';

const useStyles = makeStyles((theme) => styles(theme))

export const RequestTable = (props) => {
    const { history, role, queryVariables, query, intl, defaultFilter = null, numberOfRows, queryDataPath } = props
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    const getProfileDetail = async (id) => {
        try {
            const result = await client.query({
                query: REQUEST,
                variables: {
                    id: id,
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            let memberId = result?.data?.request?.data?.person?.member?.id
            history.push(history.push(`/admin${generatePath(paths.members.detail, { memberId: memberId })}`))
        } catch (error) {
            throw error;
        }
    }




    const onRequestDetail = (id, status, type) => {
        if (type === RequestTypesEnum.PERSON_PROFILE_UPDATE)
            getProfileDetail(id)
        else
            history.push({
                pathname: history.push(`/admin${generatePath(paths.requests.detail, { requestId: id })}`),
                state: status
            })

    }

    const getStatus = (status) => {
        if (status === "Awaiting")
            return <Box style={{ color: colors.warning }}> {intl.formatMessage({ id: "request.table.status.awaiting" })}</Box>
        else if (status === "Approved")
            return <Box style={{ color: colors.success }}>{intl.formatMessage({ id: "request.table.status.approved" })}</Box>
        else if (status === "Rejected")
            return <Box style={{ color: colors.error }}>{intl.formatMessage({ id: "request.table.status.rejected" })}</Box>
        else if (status === "Returned")
            return <Box style={{ color: colors.primary }}>{intl.formatMessage({ id: "request.table.status.returned" })}</Box>
        else if (status === "Canceled")
            return <Box style={{ color: colors.error }}>{intl.formatMessage({ id: "request.table.status.canceled" })}</Box>
        else
            return <Box>{status}</Box>
    }

    const getType = (type) => {
        if (type === RequestTypesEnum.CLUB_REGISTRATION)
            return <Box> {intl.formatMessage({ id: "request.table.type.ClubRegistration" })}</Box>
        else if (type === RequestTypesEnum.CLUB_UPDATE)
            return <Box>{intl.formatMessage({ id: "request.table.type.ClubUpdate" })}</Box>
        else if (type === RequestTypesEnum.CLUB_JOIN)
            return <Box>{intl.formatMessage({ id: "request.table.type.ClubJoin" })}</Box>
        else if (type === RequestTypesEnum.CLUB_TRANSFER)
            return <Box>{intl.formatMessage({ id: "request.table.type.ClubTransfer" })}</Box>
        else if (type === RequestTypesEnum.PERSON_PROFILE_UPDATE)
            return <Box>Zmena profilovej fotografie</Box>
        else
            return <Box>{type}</Box>
    }

    return (
        <QueryTable
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            columnsWidth={['1%', '1%', '1%', '1%', '1%', '1.5%']}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'table.bid' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'request.table.type' }),
                    accessor: (rowData) => getType(rowData.type),
                    sortKey: 'type',
                    filterKey: 'type',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: RequestTypesEnum.CLUB_REGISTRATION,
                            label: "Založenie klubu"
                        },
                        {
                            value: RequestTypesEnum.CLUB_UPDATE,
                            label: "Zmena klubových údajov"
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'request.table.createdAt' }),
                    accessor: (rowData) => rowData.createdAt !== null ? moment(rowData.createdAt).format("DD.MM.YYYY HH:mm") : "-",
                    sortKey: "createdAt",
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: 'Vytvoril',
                    accessor: "createdBy",
                    sortKey: "createdBy",
                    filterKey: "createdBy",
                    filterOperator: 'contains'

                },
                {
                    Header: intl.formatMessage({ id: "documents.table.status" }),
                    accessor: (rowData) => <StatusTextField intl={intl} status={rowData.status} />,
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: "Awaiting",
                            label: intl.formatMessage({ id: "request.table.status.awaiting" })
                        },
                        {
                            value: "Approved",
                            label: intl.formatMessage({ id: "request.table.status.approved" })
                        },
                        {
                            value: "Rejected",
                            label: intl.formatMessage({ id: "request.table.status.rejected" })
                        },
                        {
                            value: "Returned",
                            label: intl.formatMessage({ id: "request.table.status.returned" })
                        },
                        {
                            value: "Canceled",
                            label: intl.formatMessage({ id: "request.table.status.canceled" })
                        }
                    ]
                },
                (clubDetail) ?
                    {
                        Header: ' ',
                        accessor: (rowData) => <Box className={classes.floatRight}><Button table round size="sm" color="info"
                            onClick={() => onRequestDetail(rowData.id, rowData.status, rowData.type)}>
                            {intl.formatMessage({ id: 'table.detail' })}
                        </Button></Box>
                    }
                    : { Header: " " }
            ]}
        />
    )
}
const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(RequestTable));
