import gql from 'graphql-tag';

const PERSON_PROFILE_UPDATE_REQUEST_APPROVE= gql`
mutation personProfileUpdateRequestApprove($id: ID! $input: ClubRegisterRequestInput!) { 
  personProfileUpdateRequestApprove(id: $id input: $input) {
    id
    bid
    type
    status
    createdAt
  }
} 
`;

export default PERSON_PROFILE_UPDATE_REQUEST_APPROVE;