import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";

var oldDate = new Date();
oldDate.setDate(oldDate.getDate() - 100000);

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        businessName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalForm: Yup.string().required(intl.formatMessage({ id: 'validation.required' })),
        ico: Yup.number()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),
        address1: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        address2: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        city: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            //.matches(/^[0-9]+$/, intl.formatMessage({ id: 'validation.number' }))
            //.min(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            //.max(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .nullable(),
        country: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaNew = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        businessName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalForm: Yup.string().required(intl.formatMessage({ id: 'validation.required' })),
        ico: Yup.number()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),

        registrationNumber: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        registrationDate: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),

        address1: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        address2: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        city: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            //.matches(/^[0-9]+$/, intl.formatMessage({ id: 'validation.number' }))
            //.min(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            //.max(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .nullable(),
        country: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),

        gdpr: Yup.boolean()
            .oneOf([true], intl.formatMessage({ id: 'validation.mustAccept' }))
            .required(intl.formatMessage({ id: 'validation.mustAccept' }))
    });

export const getValidationSchemaInvite = (intl) =>
    Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable()
    });

export const getValidationSchemaDocuments = (intl) =>
    Yup.object().shape({
        type: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
       /* validTo: Yup.date()
            .min(Yup.ref('validFrom') > 0 ? Yup.ref('validFrom') : oldDate, intl.formatMessage({ id: 'validation.graterDate' }))
            .nullable(),*/
    });



export const getValidationSchemaDocumentsDetail = (intl) =>
    Yup.object().shape({
        /*validFrom: Yup.date(),
        validTo: Yup.date()
            .min((Yup.ref('validFrom')) ? Yup.ref('validFrom') : oldDate, intl.formatMessage({ id: 'validation.graterDate' }))
            .nullable(),*/
    });