import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import CLUB_REGISTER_REQUEST_CREATE from 'queries/RequestsQueries/clubRegisterRequestCreate';
import CLUB_REGISTER_REQUEST_UPDATE from 'queries/RequestsQueries/clubRegisterRequestUpdate';
import CLUB_REGISTER_REQUEST_REVIEW from 'queries/RequestsQueries/clubRegisterRequestReview';
import CLUB_REGISTER_REQUEST_APPROVE from 'queries/RequestsQueries/clubRegisterRequestApprove';
import CLUB_REGISTER_REQUEST_REJECT from 'queries/RequestsQueries/clubRegisterRequestReject';
import CLUB_REGISTER_REQUEST_CANCEL from 'queries/RequestsQueries/clubRegisterRequestCancel';

import CLUB_UPDATE_REQUEST_CREATE from 'queries/RequestsQueries/clubUpdateRequestCreate';
import CLUB_UPDATE_REQUEST_UPDATE from 'queries/RequestsQueries/clubUpdateRequestUpdate';
import CLUB_UPDATE_REQUEST_REVIEW from 'queries/RequestsQueries/clubUpdateRequestReview';
import CLUB_UPDATE_REQUEST_APPROVE from 'queries/RequestsQueries/clubUpdateRequestApprove';
import CLUB_UPDATE_REQUEST_REJECT from 'queries/RequestsQueries/clubUpdateRequestReject';
import CLUB_UPDATE_REQUEST_CANCEL from 'queries/RequestsQueries/clubUpdateRequestCancel';

import REQUEST from 'queries/RequestsQueries/request';
import { generatePath } from "react-router-dom";
import paths from "paths";
import LockIcon from '@material-ui/icons/Lock';
import { useLocation } from 'react-router-dom';
import RequestDetailPage from './RequestDetailPage';
import { connect } from 'react-redux';
import RoleEnum from 'common/enums/RoleEnum';
import RequestTypesEnum from 'common/enums/RequestTypesEnum';

const useStyles = makeStyles((theme) => styles(theme))

function createData(type, validFrom, validTo, status, actions) {
    return { type, validFrom, validTo, status, actions };
}

const RequestManagementPage = (props) => {
    const { intl, history, role, defaultView, newRequest } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [view, setView] = useState(1);
    const [type, setType] = useState(null);

    let location = useLocation();
    //let status = location.state ? location.state : null
    const requestId = (newRequest) ? null : props.match.params.requestId;
    const { loading: loadingRequest, data: dataRequest, refetch: refetchRequest } = useQuery(REQUEST, { variables: { id: requestId }, skip: view === 3 });

    useEffect(() => {
        setType(pathOr('', ['request', 'type'], dataRequest))
        if (pathOr('', ['request', 'status'], dataRequest) === "Awaiting") {
            if (role === RoleEnum.ADMIN)
                setView(4)
            else
                setView(1)
        } else if (pathOr('', ['request', 'status'], dataRequest) === "Returned") {
            if (role === RoleEnum.ADMIN)
                setView(1)
            else
                setView(2)
        } else if (pathOr('', ['request', 'status'], dataRequest) === "Approved" || pathOr('', ['request', 'status'], dataRequest) === "Rejected" || pathOr('', ['request', 'status'], dataRequest) === "Canceled") {
            setView(1)
        } else setView(1)
       
    }, [dataRequest, role]);

    const [clubRegisterRequestCreate] = useMutation(CLUB_REGISTER_REQUEST_CREATE)
    const [clubRegisterRequestUpdate] = useMutation(CLUB_REGISTER_REQUEST_UPDATE)
    const [clubRegisterRequestReview] = useMutation(CLUB_REGISTER_REQUEST_REVIEW)
    const [clubRegisterRequestApprove] = useMutation(CLUB_REGISTER_REQUEST_APPROVE)
    const [clubRegisterRequestReject] = useMutation(CLUB_REGISTER_REQUEST_REJECT)
    const [clubRegisterRequestCancel] = useMutation(CLUB_REGISTER_REQUEST_CANCEL)
    const [clubUpdateRequestCreate] = useMutation(CLUB_UPDATE_REQUEST_CREATE)
    const [clubUpdateRequestUpdate] = useMutation(CLUB_UPDATE_REQUEST_UPDATE)
    const [clubUpdateRequestReview] = useMutation(CLUB_UPDATE_REQUEST_REVIEW)
    const [clubUpdateRequestApprove] = useMutation(CLUB_UPDATE_REQUEST_APPROVE)
    const [clubUpdateRequestReject] = useMutation(CLUB_UPDATE_REQUEST_REJECT)
    const [clubUpdateRequestCancel] = useMutation(CLUB_UPDATE_REQUEST_CANCEL)

    return (
        <RequestDetailPage
            loadingRequest={loadingRequest}
            dataRequest={dataRequest}
            refetchRequest={refetchRequest}
            view={view}
            setView={(newValue) => setView(newValue)}
            type={(pathOr('', ['request', 'type'], dataRequest))}
            clubCreate={(type === RequestTypesEnum.CLUB_UPDATE) ? clubUpdateRequestCreate : clubRegisterRequestCreate}
            clubUpdate={(type === RequestTypesEnum.CLUB_UPDATE) ? clubUpdateRequestUpdate : clubRegisterRequestUpdate}
            clubReview={(type === RequestTypesEnum.CLUB_UPDATE) ? clubUpdateRequestReview : clubRegisterRequestReview}
            clubApprove={(type === RequestTypesEnum.CLUB_UPDATE) ? clubUpdateRequestApprove : clubRegisterRequestApprove}
            clubReject={(type === RequestTypesEnum.CLUB_UPDATE) ? clubUpdateRequestReject : clubRegisterRequestReject}
            clubCancel={(type === RequestTypesEnum.CLUB_UPDATE) ? clubUpdateRequestCancel : clubRegisterRequestCancel}
            requestId={requestId}
        />);
};

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(RequestManagementPage));
