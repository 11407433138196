import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import { useMutation, useQuery } from '@apollo/client';
import { withRouter } from "react-router-dom";
import paths from "paths";
import { getValidationSchemaPasswordReset } from "./validationSchema";
import { Formik } from "formik";
import FormikField from "components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import PASSWORD_RESET from 'queries/AuthQueries/passwordReset'
import PASSWORD_RESET_DETAILS from 'queries/AuthQueries/passwordResetDetails'
import Typography from '@material-ui/core/Typography';
import { colors, widgets } from "common/styles/configLayout.js";
import Loading from 'components-lib/Loading/Loading';
import { pathOr } from 'rambda';
import IconCard from 'components-lib/IconCard/IconCard';

const useStyles = makeStyles(styles);

const PasswordResetPage = (props) => {
  const { intl, history } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [disableButton, setDisableButton] = useState(false);

  var url_string = window.location.href
  var url = new URL(url_string);
  var token = url.searchParams.get("token");
  let registrationType = 'club';
  const { loading, data, refetch } = useQuery(PASSWORD_RESET_DETAILS, { variables: { token: token } });
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [validateParam, setValidateParam] = useState(false)
  const [passwordReset] = useMutation(PASSWORD_RESET);

  useEffect(() => {
    setUserData(pathOr('', ['activationDetails', 'profile'], data));
  }, [data]);
  const [userData, setUserData] = useState({})

  const handlePasswordReset = (values) => {
    setDisableButton(true);
    passwordReset({
      variables: {
        token: token,
        newPassword: values?.newPassword
      },
    }).then((response) => {
      enqueueSnackbar(intl.formatMessage({ id: 'passwordReset.snackbar.success' }), { variant: 'success' })
      history.push(`/auth${paths.auth.login}`);
      setDisableButton(false);
    }).catch((err) => {
      console.log('[error]', err);
      setDisableButton(false);
    });
  };

  const getContent = () => {
    return (
      <Grid container justify="center" style={{ zIndex: 1000 }}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <form>
            <IconCard
              background={'rgba(60,72,88, 0.8)'}
              title={<Typography variant="h5" align="center" style={{ color: colors.primary, marginBottom: "10px" }}>Obnovenie hesla</Typography>}
              className={classes[cardAnimaton]}
            >
              <Formik
                enableReinitialize
                validationSchema={getValidationSchemaPasswordReset(intl)}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values) => { handlePasswordReset(values); }}
                initialValues={{}}
              >
                {(formikProps) => (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2" align="center">{intl.formatMessage({ id: 'passwordReset.info' })}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="newPassword"
                          labelText={intl.formatMessage({ id: 'passwordReset.newPassword' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          mandatory={true}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="repeatPassword"
                          labelText={intl.formatMessage({ id: 'passwordReset.repeatPassword' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          mandatory={true}
                          type="password"
                        />
                      </Grid>
                    </Grid>

                    <CardFooter
                      className={classes.justifyContentCenter}
                      style={{
                        width: '100%',
                        margin: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        disabled={disableButton}
                        color="primary"
                        size="sm"
                        round
                        onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                      >
                        {intl.formatMessage({ id: 'passwordReset.button.repeat' })}
                      </Button>
                      <Button
                        simple
                        color="primary"
                        size="sm"
                        round
                        onClick={() => { history.push(`/auth${paths.auth.login}`) }}
                      >
                        {intl.formatMessage({ id: 'register.button.back' })}
                      </Button>
                    </CardFooter>
                  </>
                )}
              </Formik>
            </IconCard>
          </form>
        </Grid>
      </Grid>
    )
  }


  const getInvalidContent = () => {
    return (
      <div className={classes.contentCenter}>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="h4" color="error"> {intl.formatMessage({ id: 'apiErrors.InvalidInvitationTokenError' })}.</Typography>
            <Typography variant="body1"> {intl.formatMessage({ id: 'passwordReset.body' })}.</Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  if (loading) return <Loading />
  return (
    <>{(data) ? getContent() : getInvalidContent()}</>
  );
}
export default injectIntl(withRouter(PasswordResetPage));

