import gql from 'graphql-tag';

const CLUB_MEMBER_DOCUMENT= gql`
query clubMemberDocument($id: ID!){
  clubMemberDocument(id: $id)  {
   files{
    downloadUrl
  },
  name,
  type,
  validFrom,
  validTo,
  createdAt
  }
}
`;

export default CLUB_MEMBER_DOCUMENT;