import { makeStyles } from '@material-ui/core/styles';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import { clubDetail } from 'configFiles/roles'
import Box from "@material-ui/core/Box";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { colors, } from 'common/styles/configLayout';
import Modal from "components-lib/Modal/Modal";
import DocumentViewer from "../DocumentViewer";
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import moment from "moment";
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from "formik";
import PROFILE_DOCUMENT from 'queries/DocumentsQueries/profileDocument';
import CLUB_DOCUMENT from 'queries/DocumentsQueries/clubDocument';
import CLUB_MEMBER_DOCUMENT from 'queries/DocumentsQueries/clubMemberDocument';
import { getApolloClient } from "configFiles/apollo";
import { getValidationSchemaDocuments } from "../validationSchema";
import DocumentUploadCard from "../DocumentUploadCard";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { connect } from 'react-redux';
import { changeDocumentsNumberAction } from 'redux/actions';
import DOCUMENTS_TABLE from 'queries/DocumentsQueries/documentsTable';
import Tooltip from '@material-ui/core/Tooltip';
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import { pathOr } from 'rambda';
import BlockIcon from '@material-ui/icons/Block';
import RoleEnum from 'common/enums/RoleEnum';

const useStyles = makeStyles((theme) => styles(theme))

export const DocumentTable = (props) => {
    const { intl, history, changeDocumentsNumberAction, role, data, loadData, refetch, numberOfRows = 25, queryDataPath, formikProps,
        parentId, documentOptions, documentUploadMutation, documentDeleteMutation, documentType, memberId } = props
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [documentsDialogModalOpen, setDocumentsDialogModalOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [actualId, setActualId] = useState(null);
    const [documentUpload] = useMutation(documentUploadMutation);
    const [documentDelete] = useMutation(documentDeleteMutation);
    const [actualStatus, setActualStatus] = useState(null);
    const [open, setOpen] = useState(false);
    const [validateParam, setValidateParam] = useState(false)
    const [documentData, setDocumentData] = useState(null);
    const [disableButton, setDisableButton] = useState(false);

    const getDocumentsNumber = async () => {
        try {
            const result = await client.query({
                query: DOCUMENTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "eq": "Awaiting"
                            },
                            "relation": {
                                "ne": "Request"
                            },
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changeDocumentsNumberAction(pathOr('', ['data', 'documentsTable', 'totalCount'], result));
        } catch (error) {
            throw error;
        }
    }

    const handleUploadedFile = (values, resetForm) => {
        setDisableButton(true)
        documentUpload({
            variables: {
                clubId: (parentId) ? (parentId) : undefined,
                memberId: (memberId) ? (memberId) : undefined,
                documents: [
                    {
                        validFrom: values?.validFrom,
                        validTo: values?.validTo,
                        type: values?.type,
                        files: uploadedFile?.map(item => item?.file)
                    }
                ]
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.upload.success" }), { variant: 'success' });
            refetch();
            setModalOpen(false);
            setOpen(false)
            setUploadedFile([]);
            resetForm();
            setDisableButton(false)
            if (role === RoleEnum.ADMIN) getDocumentsNumber();

        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false)
        });
    }

    function downloadURI(url) {
        var link = document.createElement("a");
        link.download = url;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const handleDownloadDocument = (url) => {
        downloadURI(url)
        enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.download.success" }), { variant: 'success' });
    }

    const handleDeleteDocument = () => {
        setDisableButton(true)
        documentDelete({
            variables: {
                id: actualId,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.delete.success" }), { variant: 'success' });
            if (role === RoleEnum.ADMIN) getDocumentsNumber();
            setDeleteModalOpen(false);
            refetch();
            setDisableButton(false)
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
        setDeleteModalOpen(false);
    }

    const handleGetFiles = async (id) => {
        try {
            const result = await client.query({
                query: (documentType === "Profile") ? (memberId) ? CLUB_MEMBER_DOCUMENT : PROFILE_DOCUMENT : CLUB_DOCUMENT,
                variables: {
                    id: id
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            let tempFiles = [];
            let res = (documentType === "Profile") ? (memberId) ? (result.data?.clubMemberDocument?.files) : (result.data?.profileDocument?.files) : (result.data?.clubDocument?.files)
            let resData = (documentType === "Profile") ? (memberId) ? (result.data?.clubMemberDocument) : (result.data?.profileDocument) : (result.data?.clubDocument)

            tempFiles = (res)?.map(item => ({
                uri: item.downloadUrl ////"https://api.dev.szc.cultify.studio/rest/documents/download?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6ImRvY3VtZW50LWZpbGUiLCJhY3Rpb24iOiJkb3dubG9hZCIsInJlc291cmNlSWQiOjEyLCJ1c2VySWQiOjEsImlhdCI6MTYzNjY0OTIyNywiZXhwIjoxNjM2NjUyODI3fQ.uxi3F_z0C_-wE0zLO-Po9v7YMxNB5Uai3w69P36iyRc"
            }));
            setFiles(tempFiles);
            setDocumentData(resData);
            setDocumentsDialogModalOpen(true)
        } catch (error) {
            throw error;
        }
    }

    const getStatus = (status) => {
        if (status === "Awaiting")
            return <Box style={{ color: colors.warning }} > {intl.formatMessage({ id: "documents.table.status.awaiting" })}</Box>
        else if (status === "Approved")
            return <Box style={{ color: colors.success }}>{intl.formatMessage({ id: "documents.table.status.approved" })}</Box>
        if (status === "Rejected")
            return <Box style={{ color: colors.error }}>{intl.formatMessage({ id: "documents.table.status.rejected" })}</Box>
        else
            return <Box>{status}</Box>
    }

    const renderModalButtons = (formikProps) => {
        return (
            <Button
                disabled={uploadedFile?.length <= 0 || disableButton}
                onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                color="primary"
                round
                table
                size="sm">
                {intl.formatMessage({ id: "documents.modalButton.upload" })}
            </Button>
        );
    };

    const renderDeleteModalButtons = () => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    onClick={() => handleDeleteDocument()}
                    color="danger"
                    round
                    table
                    size="sm">
                    <BlockIcon /> {intl.formatMessage({ id: 'button.delete' })}
                </Button>
            </>
        );
    };

    const renderColumns = () => {
        return ([
            {
                Header: intl.formatMessage({ id: "documents.table.name" }),
                accessor: "name",
                sortKey: 'none',
                filterKey: 'none',
            },
            {
                Header: intl.formatMessage({ id: "documents.table.createdAt" }),
                accessor: (rowData) => rowData.createdAt !== null ? moment(rowData.createdAt).format("DD.MM.YYYY HH:mm") : "-",
                sortKey: "none",
                filterKey: "none",
            },
            {
                Header: intl.formatMessage({ id: "documents.table.validFrom" }),
                accessor: (rowData) => rowData.validFrom !== null ? moment(rowData.validFrom).format("DD.MM.YYYY") : "-",
                sortKey: "none",
                filterKey: "none",
            },
            {
                Header: intl.formatMessage({ id: "documents.table.validTo" }),
                accessor: (rowData) => rowData.validTo !== null ? moment(rowData.validTo).format("DD.MM.YYYY") : "-",
                sortKey: "none",
                filterKey: "none",
            },
            {
                Header: intl.formatMessage({ id: "documents.table.status" }),
                accessor: (rowData) => <StatusTextField intl={intl} status={rowData.status} />,
                sortKey: "none",
                filterKey: "none",

            },
            (clubDetail) ?
                {
                    Header: ' ',
                    filterKey: "actions",
                    accessor: (rowData) =>
                        <Box className={classes.floatRight} style={{ marginTop: '0px', marginBottom: '4px' }}>
                            <Tooltip title={"Stiahnuť dokument"}><GetAppIcon className={classes.circle} onClick={() => handleDownloadDocument(rowData.downloadUrl, rowData.name)} /></Tooltip>
                            {(rowData.status === "Awaiting") && <Tooltip title={"Odstrániť dokument"}><DeleteIcon className={classes.circle} onClick={() => { setActualId(rowData.id); setDeleteModalOpen(true) }} /></Tooltip>}
                            <Tooltip title={"Otvoriť dokument"}><SearchIcon className={classes.circle} onClick={() => { setActualId(rowData.id); setActualStatus(rowData.status); handleGetFiles(rowData.id) }} /></Tooltip>
                        </Box>
                }
                : { Header: " " }
        ])
    };

    return (
        <>

            <QueryTableRefresh
                data={data}
                loadData={loadData}
                filterView={false}
                //paginateView={false}
                numberOfRows={numberOfRows}
                queryDataPath={queryDataPath}
                columns={renderColumns()}
            />
            <Formik
                onSubmit={(values, { resetForm }) => handleUploadedFile(values, resetForm)}
                validationSchema={getValidationSchemaDocuments(intl)}
                initialValues={{}}
                enableReinitialize
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: "documents.uploadModal.title" })}
                        open={open}
                        onClose={() => { setModalOpen(false); setUploadedFile([]); setOpen(false); formikProps.resetForm() }}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <DocumentUploadCard
                            formikProps={formikProps}
                            validateParam={validateParam}
                            callbackUploadedFile={(files) => setUploadedFile(files)}
                            documentOptions={documentOptions}
                            uploadedFile={uploadedFile}
                            open={open}
                            setOpen={(newValue) => setOpen(newValue)}
                        //submit={() => formikProps.handleSubmit()}
                        />
                    </Modal>
                )}
            </Formik >

            <Modal
                title={intl.formatMessage({ id: "documents.deleteModal.title" })}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                actions={renderDeleteModalButtons()}
                fullWidth
            >
                <Grid container spacing={1}>
                    {intl.formatMessage({ id: "documents.deleteModal.body" })}
                </Grid>
            </Modal>

            <DocumentViewer
                docs={files}
                open={documentsDialogModalOpen}
                onClose={() => setDocumentsDialogModalOpen(false)}
                data={formikProps?.values}
                documentData={documentData}
                type={documentType}
                documentId={actualId}
                documentStatus={actualStatus}
                textColor={(actualStatus === "Awaiting") ? colors.warning : (actualStatus === "Approved") ? colors.success : colors.error}
                refetch={refetch}
            />

            <Box className={classes.floatRight} style={{ paddingTop: '8px' }}>
                <Button
                    color="primary"
                    size="sm"
                    round
                    table
                    onClick={() => setOpen(true)}>
                    <CloudUploadIcon /> Nahrať dokument
                </Button>
            </Box>
        </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeDocumentsNumberAction: (value) => dispatch(changeDocumentsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentTable));