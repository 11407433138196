import { Grid } from "@material-ui/core";
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import { colors } from 'common/styles/configLayout';
import Modal from "components-lib/Modal/Modal";
import { Formik } from "formik";
import moment from "moment";
import { useMutation } from '@apollo/client';
import PAYMENT_CONFIRM from 'queries/PaymentsQueries/paymentConfirm';
import PAYMENT_PAY from 'queries/PaymentsQueries/paymentPay';
import { useSnackbar } from 'notistack';
import { generatePath } from "react-router-dom";
import paths from "paths";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { connect } from 'react-redux';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { getValidationSchema } from "../validationSchema";
import { changeUserProfileAction } from 'redux/actions';
import PROFILE from 'queries/ProfileQueries/profile';
import { pathOr } from 'rambda';
import { getApolloClient } from "configFiles/apollo";
import RoleEnum from 'common/enums/RoleEnum';

const useStyles = makeStyles((theme) => styles(theme))

const PaymentCard = (props) => {
    const { intl, history, role, data, closed = false, detail, getLicensesNumber, refetch, changeUserProfileAction } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const [modalOpen, setModalOpen] = useState(false);

    const [paymentConfirm] = useMutation(PAYMENT_CONFIRM);
    const [paymentPay] = useMutation(PAYMENT_PAY);
    const validateParam = true;

    const getUserProfileAction = async () => {
        try {
            const result = await client.query({
                query: PROFILE,
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changeUserProfileAction(pathOr('', ['data'], result));

        } catch (error) {
            throw error;
        }
    };

    const handlePaymentConfirm = (values) => {
        paymentConfirm({
            variables: {
                id: data?.id,
                input: values
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'payments.snackbar.create.success' }), { variant: 'success' });
            refetch();
            if (getLicensesNumber()) getLicensesNumber()
            getUserProfileAction()
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
        setModalOpen(false)
    }

    const handlePaymentPay = () => {
        paymentPay({
            variables: {
                id: data?.id,
            }
        }).then((response) => {
            window.open(response?.data?.paymentPay?.checkoutUrl);
            if (getLicensesNumber()) {
                refetch()
                getLicensesNumber()
                getUserProfileAction()
            }
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
        setModalOpen(false)
    }

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={false}
                    onClick={() => formikProps.handleSubmit()}
                    color="primary"
                    round
                    table
                    size="sm">
                    <AddCircleIcon /> {intl.formatMessage({ id: 'payments.create.button' })}
                </Button>
            </>
        );
    };

    const renderContent = () => {
        return (
            <Grid container spacing={1}>
                {detail &&
                    <Grid item xs={12}>
                        <Box>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ fontSize: "1.2em" }}>
                                    <span>{intl.formatMessage({ id: 'payments.paymentCard.amount' })}: </span>
                                    <Box className={classes.floatRight}>
                                        <span>{data?.amount} €</span>
                                    </Box>
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} lg={12} /*WITH QR CODE lg={(data?.status === "Pending") ? 8 : 12}*/>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box>
                                <Grid container spacing={1}>
                                    {(detail === "memberLicense" || detail=== "memberLicenseAwaiting") &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "1em", color: (data?.status === "Pending") ? colors.warning : (data?.status === 'Canceled') ? colors.error : colors.success }}>
                                                <span >{intl.formatMessage({ id: 'payments.infoCard.status' })}:</span>
                                                <Box className={classes.floatRight}>
                                                    <span>{(data?.status) ? intl.formatMessage({ id: `payments.status.${data?.status}` }) : "-"}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                    }
                                    {/*<Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "1em", color: colors.primary }}>
                                            <span >{intl.formatMessage({ id: 'payments.paymentCard.bankIBAN' })}:</span>
                                            <Box className={classes.floatRight}>
                                                <span>{data?.bankIBAN}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>*/}
                                    {/*<Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "1em" }}>
                                            <span>{intl.formatMessage({ id: 'payments.paymentCard.variableSymbol' })}:</span>
                                            <Box className={classes.floatRight}>
                                                <span>{data?.variableSymbol}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>*/}
                                    <Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "1em" }}>
                                            <span>{intl.formatMessage({ id: 'payments.paymentCard.createdAt' })}:</span>
                                            <Box className={classes.floatRight}>
                                                <span>{(data.createdAt) ? moment(data?.createdAt).format("DD.MM.YYYY") : '-'}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "1em" }}>
                                            <span>{intl.formatMessage({ id: 'payments.paymentCard.dueDate' })}:</span>
                                            <Box className={classes.floatRight}>
                                                <span>{(data.dueDate) ? moment(data?.dueDate).format("DD.MM.YYYY") : '-'}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" style={{ fontSize: "1em" }}>
                                            <span>{intl.formatMessage({ id: 'payments.paymentCard.paidAt' })}:</span>
                                            <Box className={classes.floatRight}>
                                                <span>{(data.paidAt) ? moment(data?.paidAt).format("DD.MM.YYYY") : '-'}</span>
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/* WITH QR CODE   
                (data?.status === "Pending") &&
                    <Grid item xs={12} lg={4}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className="fileinput text-center">
                                    <div style={{ backgroundColor: colors.textBody }} className={"thumbnail"}>
                                        <img src={data?.payBySquare} width="100%" />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                */}
            </Grid >
        )
    }

    const getSendButton = () => {
        //setValidateParam(true) 
        return (<Box className={classes.floatRight}>
            {(data?.status === "Pending") ?
                <>
                    <br />
                    <Button
                        color="primary"
                        size="sm"
                        table
                        round
                        onClick={() => handlePaymentPay()}
                    >
                        <CreditCardIcon /> {intl.formatMessage({ id: 'payments.online.button' })}
                    </Button>
                    {role === RoleEnum.ADMIN &&
                        <Button
                            color="primary"
                            size="sm"
                            table
                            round
                            onClick={() => setModalOpen(true)}
                        >
                            <AddCircleIcon /> {intl.formatMessage({ id: 'payments.create.button' })}
                        </Button>
                    }
                </>
                : <> {(detail) && <>
                    <br />
                    <Button
                        color="primary"
                        size="sm"
                        round
                        table
                        onClick={() => history.push(`/admin${generatePath(paths.payments.detail, { paymentId: data?.id })}`)}
                    >
                        <ReceiptIcon /> {intl.formatMessage({ id: 'payments.detail.button' })}
                    </Button>
                </>
                }
                </>
            }
        </Box >)
    }

    return (<>
        <IconCard title={intl.formatMessage({ id: 'payments.paymentCard.title' })} inside={(detail === 'memberLicense') ? true : false}>
            {renderContent()}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {getSendButton()}
                </Grid>
            </Grid>
        </IconCard>
        <Formik
            onSubmit={(values) => handlePaymentConfirm(values)}
            initialValues={{}}
            validationSchema={getValidationSchema(intl)}
            enableReinitialize
        >
            {(formikProps) => (
                <>
                    <Modal
                        title={intl.formatMessage({ id: 'payments.paymentCard.modal.title' })}
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormikField
                                    name="method"
                                    labelText={intl.formatMessage({ id: 'payments.paymentCard.method' })}
                                    mandatory={true}
                                    formikProps={formikProps}
                                    validateParam={validateParam}
                                    highlightChange={false}
                                    disabled={closed}
                                    type="select"
                                    options={[
                                        {
                                            value: "BankTransfer",
                                            label: intl.formatMessage({ id: 'payments.paymentCard.bankTransfer' })
                                        },
                                        {
                                            value: "Cash",
                                            label: intl.formatMessage({ id: 'payments.paymentCard.cash' })
                                        }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikField
                                    name="paidAt"
                                    labelText={intl.formatMessage({ id: 'payments.paymentCard.paymentDate' })}
                                    formikProps={formikProps}
                                    validateParam={validateParam}
                                    highlightChange={false}
                                    disabled={closed}
                                    type="datePicker"
                                />
                            </Grid>
                        </Grid>
                    </Modal>
                </>
            )}
        </Formik >
    </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeUserProfileAction: (value) => dispatch(changeUserProfileAction(value)),

});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentCard));
//292