import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import React from 'react';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import { colors, } from 'common/styles/configLayout';
import Typography from "@material-ui/core/Typography"
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import useStyles from 'components-lib/FormikField/styles'
import Chip from '@material-ui/core/Chip';
import styles from "common/styles/widgets.js";
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loading from "components-lib/Loading/Loading";
import FunctionsEnum from 'common/enums/FunctionsEnum';

const useStylesIcon = makeStyles((theme) => styles(theme))

export const FunctionsTable = (props) => {
    const { intl, functions, disciplines, memberFunctions, memberDetail, setMemberFunctions, memberDisciplines, setMemberDisciplines, readMode = false } = props
    const classes = useStyles();
    const classesIcon = useStylesIcon();

    const handleChangeTo = (idx, value) => {
        let row = [...memberFunctions];
        row[idx] = value
        setMemberFunctions(row)
    };

    const handleChangeDiscipline = (idx, value) => {
        const row = [...memberFunctions];
        setMemberDisciplines(value)
    };

    const handleAddRow = () => {
        const item = { value: "", label: '' };
        setMemberFunctions([...memberFunctions, item]);
    };

    const handleRemoveSpecificRow = (idx) => {
        let row = [...memberFunctions]
        row.splice(idx, 1)
        setMemberFunctions(row)
    }

    const getDisabledFunctions = (functions) => {
        if (memberDetail)
            return functions;
        else {
            let cyclistIndex = functions?.findIndex(item => item.value === FunctionsEnum.RACER.value);
            let cyclistOfficialIndex = functions?.findIndex(item => item.value === FunctionsEnum.RACER_WITH_CONTRACT.value);
            let disabledFunctions = [...functions]
            if (cyclistIndex >= 0 && cyclistOfficialIndex === -1)
                disabledFunctions.splice(cyclistIndex, 1)

            if (cyclistOfficialIndex >= 0 && cyclistIndex === -1)
                disabledFunctions.splice(cyclistOfficialIndex, 1)
            return disabledFunctions;
        }
    }

    const getDisabledDisciplines = (disc) => {
        let indexPara = memberDisciplines?.find(item => item.value === "1")
        let disabledDisciplines = [...disc]
        if (memberDisciplines?.length > 0) {
            if (indexPara)
                return [];
            else {
                disabledDisciplines.splice(indexPara, 1)
                return disabledDisciplines
            }
        } else {
            return disc
        }
    }

    if (!memberFunctions) return <Loading />;
    return (
        <>
            <Grid container spacing={1}>
                {memberFunctions?.map((item, idx) => (
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={(readMode) ? 6 : 5}>
                                {(!readMode && item?.value !== FunctionsEnum.STATUTORY.value && item?.value !== FunctionsEnum.PRESIDENT.value) ?
                                    <FormControl size="small" className={classes.root} fullWidth variant="outlined" >
                                        <Autocomplete
                                            style={{ borderRadius: '6px' }}
                                            className={classes.selectForm + ' ' + classes.root}
                                            classes={{
                                                paper: classes.selectAutocomplete,
                                                clearIndicatorDirty: classes.selectIcon,
                                                popupIndicator: classes.selectIcon
                                            }}
                                            size="small"
                                            noOptionsText={intl.formatMessage({ id: 'select.dropdown.noOptions' })}
                                            options={getDisabledFunctions(_.xorBy(functions, memberFunctions, (f) => (f.value)))}

                                            disableClearable
                                            filterSelectedOptions
                                            fullWidth
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <TextField {...params} label={item?.value
                                                ? <>{idx + 1}{intl.formatMessage({ id: 'functions.function' })} {(idx === 0) ? ' *' : ''}</>
                                                : <>{intl.formatMessage({ id: 'functions.chooseFunction' })} {(idx === 0) ? ' *' : ''}</>
                                            } variant="outlined" />}
                                            value={item}
                                            onChange={(event, value) => { handleChangeTo(idx, value, item.discipline) }}
                                            variant="outlined"
                                            defaultValue="Success"
                                            renderOption={(option) => (<div>
                                                {option?.disabled
                                                    ? <p><WarningIcon className={classesIcon.listCircle} style={{ color: colors.warning }} /> {option?.label}</p>
                                                    : <p>{option?.label}</p>}
                                            </div>
                                            )}

                                            inputProps={{
                                                classes: {
                                                    root: classes.label,
                                                },
                                            }}
                                            name={`${idx + 1}.${intl.formatMessage({ id: 'functions.function' })} ${(idx === 0) && '*'}`}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </FormControl>
                                    :
                                    <Grid item sm={12} className={classes.readOnlyGrid}>
                                        <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{idx + 1}{intl.formatMessage({ id: 'functions.function' })}</Typography>
                                        <Typography variant="body2" style={{ fontSize: "1.1em", marginLeft: 2, marginTop: "-2px" }}>
                                            {item?.label}
                                        </Typography>
                                    </Grid>

                                }
                            </Grid>
                            <Grid item xs={6}>
                                {(memberFunctions?.[idx]?.value === FunctionsEnum.RACER.value || memberFunctions?.[idx]?.value === FunctionsEnum.RACER_WITH_CONTRACT.value) &&
                                    <>
                                        {!readMode ?
                                            <FormControl size="small" className={classes.root} fullWidth variant="outlined" >

                                                <Autocomplete
                                                    multiple
                                                    style={{ borderRadius: '6px' }}
                                                    className={classes.selectForm + ' ' + classes.root}
                                                    classes={{
                                                        paper: classes.selectAutocomplete,
                                                        clearIndicatorDirty: classes.selectIcon,
                                                        popupIndicator: classes.selectIcon
                                                    }}
                                                    size="small"
                                                    id="tags-outlined"
                                                    noOptionsText={intl.formatMessage({ id: 'select.dropdown.noOptions' })}
                                                    //options={(_.xorBy(disciplines, memberDisciplines, (d) => (d.value)))}
                                                    options={getDisabledDisciplines(_.xorBy(disciplines, memberDisciplines, (d) => (d.value)))}
                                                    value={memberDisciplines}
                                                    getOptionLabel={(option) => option.label || ""}
                                                    filterSelectedOptions
                                                    onChange={(event, value) => { handleChangeDiscipline(idx, value) }}
                                                    renderValue={(selected) => (
                                                        <div>
                                                            {(selected).map((value) => (
                                                                <Chip
                                                                    key={value}
                                                                    label={value}
                                                                    style={{ color: 'red' }}
                                                                    clickable
                                                                    onClick={() => console.log("clicked chip")}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label={intl.formatMessage({ id: 'functions.disciplines' }) + ' *'}
                                                            placeholder={intl.formatMessage({ id: 'select.dropdown.find' })}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            :
                                            <Grid item sm={12} className={classes.readOnlyGrid}>
                                                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'functions.disciplines' })}</Typography>
                                                <Typography variant="body2" style={{ fontSize: "1.1em", marginLeft: 2, marginTop: "-2px" }}>
                                                    {(memberDisciplines?.length)
                                                        ? (memberDisciplines)?.map((item, discIndex) => <>{item?.label}{(memberDisciplines?.length - 1 !== discIndex) ? ", " : ""}</>)
                                                        : '-'
                                                    }
                                                </Typography>
                                            </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                            {(!readMode && (item?.value !== FunctionsEnum.STATUTORY.value && item?.value !== FunctionsEnum.PRESIDENT.value)) &&
                                <Grid item xs={1} style={{ marginTop: '8px' }}>
                                    {(memberFunctions.length > 1 && idx !== 0) &&
                                        <DeleteIcon
                                            size="sm"
                                            className={classesIcon.circle}
                                            onClick={() => handleRemoveSpecificRow(idx, item)}
                                        />
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            {!readMode && <>
                {(memberFunctions?.[memberFunctions.length - 1]?.value !== '') &&
                    <Box style={{ marginTop: '5px' }}>
                        <Button
                            color="primary"
                            size="sm"
                            round
                            table
                            onClick={handleAddRow}>
                            <AddIcon /> {intl.formatMessage({ id: 'functions.button.add' })}
                        </Button>
                    </Box>
                }
            </>
            }
        </>
    )
}

export default injectIntl(FunctionsTable);