import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useMutation } from '@apollo/client';
import { withRouter } from "react-router-dom";
import paths from "../../paths";
import { getValidationSchemaRegistration } from "./validationSchema";
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import { generatePath } from 'react-router-dom';
import REGISTER_WITH_EMAIL from 'queries/AuthQueries/registerWithEmail' //TODO: Zatial nieje query
import Typography from '@material-ui/core/Typography';
import { colors } from "common/styles/configLayout.js";
import moment from "moment";

const useStyles = makeStyles(styles);

const RegisterEmailPage = (props) => {
  const { history, intl } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [validateParam, setValidateParam] = useState(false)
  const [register] = useMutation(REGISTER_WITH_EMAIL);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const handleRegister = (values) => {
    setDisableButton(true);
    let tempDate = moment(values?.birthDate).format('YYYY-MM-DD');
    register({
      variables: {
        email: values?.email,
        input: {
          password: values?.password,
          birth: (values?.birthDate) ? tempDate : undefined,
          firstName: values?.firstName,
          lastName: values?.lastName,
        },
      },
    }).then((response) => {
      enqueueSnackbar(intl.formatMessage({ id: 'register.snackbar.success' }), { variant: 'success' })
      history.push({
        pathname: `/auth${generatePath(paths.auth.confirm)}`,
        state: {
          type: "Register",
          email: values?.email,
          password: values?.password
        }
      })
      setDisableButton(false);
    }).catch((err) => {
      console.log('[error]', err);
      setDisableButton(false);
    });
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <form>
          <Grid login className={classes[cardAnimaton]} style={{ backgroundColor: colors.backgroundWidgets, borderRadius: "16px" }}>
            <Formik
              enableReinitialize
              validationSchema={getValidationSchemaRegistration(intl)}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values) => { handleRegister(values); }}
              initialValues={{}}
            >
              {(formikProps) => (
                <>
                  <CardBody>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5" align="center" style={{ color: colors.primary }}>  {intl.formatMessage({ id: 'register.title' })}</Typography>
                        <br />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          name="firstName"
                          labelText={intl.formatMessage({ id: 'personal.firstName' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          highlightChange={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          name="lastName"
                          labelText={intl.formatMessage({ id: 'personal.lastName' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          highlightChange={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="email"
                          labelText={intl.formatMessage({ id: 'register.email' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="birthDate"
                          type="datePicker"
                          labelText={intl.formatMessage({ id: 'register.birthDate' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="password"
                          labelText={intl.formatMessage({ id: 'register.password' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="repeatPassword"
                          labelText={intl.formatMessage({ id: 'register.repeatPassword' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >Súhlasím s&nbsp;
                            <a /*onClick={() => alert('PDF s podmienkami ochrany údajov')}*/ target="_blank" style={{ textDecoration: 'underline',  color: colors.primary }}>podmienkami ochrany údajov</a>
                          </span>}
                          smallDescription={true}
                          type="checkbox"
                          name="gdpr"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '-10px' }}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >Súhlasím s&nbsp;
                          <a /*onClick={() => alert('PDF s podmienkami používania')}*/ target="_blank" style={{ textDecoration: 'underline',  color: colors.primary }}>podmienkami používania</a>
                        </span>}
                          smallDescription={true}
                          type="checkbox"
                          name="termsOfUse"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '-10px' }}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >{intl.formatMessage({ id: 'register.szcConfirmation' })}</span>}
                          smallDescription={true}
                          type="checkbox"
                          name="szcConfirmation"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                  <CardFooter
                    className={classes.justifyContentCenter}
                    style={{
                      width: '100%',
                      margin: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      color="primary"
                      disabled={disableButton}
                      size="sm"
                      round
                      onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                    >
                      {intl.formatMessage({ id: 'register.button.submit' })}
                    </Button>
                    <Button
                      simple
                      color="primary"
                      size="sm"
                      round
                      onClick={() => { history.push(`/auth${paths.auth.login}`) }}
                    >
                      {intl.formatMessage({ id: 'register.button.back' })}
                    </Button>
                    <br />
                  </CardFooter>
                </>
              )}
            </Formik>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default injectIntl(withRouter(RegisterEmailPage));
