import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";
const currentYear = new Date();
const validateYear = ((currentYear.getMonth() + 1) + '/' + (currentYear.getDate()) + '/' + (currentYear.getFullYear() - 1));

export const getValidationSchemaLogin = (intl) =>
    Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        password: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });

export const getValidationSchemaRegistration = (intl) =>
    Yup.object().shape({
        firstName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        lastName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        password: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        repeatPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'validation.password.match' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        gdpr: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        szcConfirmation: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        termsOfUse: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        birthDate: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .max(validateYear, `Minimálny vek používateľa je 1 rok`)
            .nullable(),
    });

export const getValidationSchemaActivation = (intl) =>
    Yup.object().shape({
        password: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        repeatPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'validation.password.match' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        gdpr: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        szcConfirmation: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        termsOfUse: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });

export const getValidationSchemaPasswordReset = (intl) =>
    Yup.object().shape({
        newPassword: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        repeatPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: 'validation.password.match' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
    });


export const getValidationSchemaForgottenPassword = (intl) =>
    Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable()
    });


export const getValidationSchemaRegisterClub = (intl) =>
    Yup.object().shape({
        firstName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        lastName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        personalId: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        nationality: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        countryOfBirth: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        birth: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .max(validateYear, `Minimálny vek používateľa je 1 rok`)
            .nullable(),
        gender: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        phone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .matches(/^[+,0-9]+$/, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
        address1: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        address2: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        city: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        district: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        country: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        emergencyFirstName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        emergencyLastName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        emergencyPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        password: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        passwordRepeat: Yup.string()
            .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'validation.password.match' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        gdpr: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        szcConfirmation: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        termsOfUse: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });

export const getValidationSchemaRegisterClubForeign = (intl) =>
    Yup.object().shape({
        firstName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        lastName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        nationality: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        countryOfBirth: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        birth: Yup.date()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .max(validateYear, `Minimálny vek používateľa je 1 rok`)
            .nullable(),
        gender: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        phone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .matches(/^[+,0-9]+$/, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
        address1: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        address2: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        city: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        district: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        country: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        emergencyFirstName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        emergencyLastName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        emergencyPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        password: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        passwordRepeat: Yup.string()
            .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'validation.password.match' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: 'validation.password.characters' }))
            .nullable(),
        gdpr: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        szcConfirmation: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        termsOfUse: Yup.boolean()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .oneOf([true], intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });
