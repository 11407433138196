import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { colors, parameters } from 'common/styles/configLayout';
import Modal from "components-lib/Modal/Modal";
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import moment from "moment";
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from "formik";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentViewer from "../DocumentViewer";
import { StyledTableCell, StyledTableRow } from 'common/styles/muiDesign'
import DocumentUploadCard from "../DocumentUploadCard";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { getValidationSchemaDocuments } from "../validationSchema";
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import RoleEnum from 'common/enums/RoleEnum';

const useStyles = makeStyles((theme) => styles(theme))

export const DocumentRequestTable = (props) => {
    const { history, refetch, intl, closed = false, readMode = false, formikProps, detail, callbackRows, rows, role, requestStatus, updateClub,
        callbackRemoveDocuments, removeDocuments, callbackApproveDocuments, approveDocuments, callbackRejectDocuments, rejectDocuments, documentOptions } = props
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [modalOpen, setModalOpen] = useState(false);
    const [documentsDialogModalOpen, setDocumentsDialogModalOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [actualId, setActualId] = useState(null);
    const [actualStatus, setActualStatus] = useState(null);
    const [validateParam, setValidateParam] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [docType, setDocType] = useState('request');

    const handleDownloadDocument = (id) => {
        let tempFiles = [];
        tempFiles = (rows[id]?.files)?.map(item => ({
            uri: item?.data
        }))
        setFiles(tempFiles);
        setDocumentsDialogModalOpen(true)
        setDocType('uploadRequest')
    }

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={uploadedFile?.length <= 0}
                    onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                    color="primary"
                    round
                    table
                    size="sm">
                    {intl.formatMessage({ id: "documents.modalButton.upload" })}
                </Button>
            </>
        );
    };

    const handleGetFiles = async (id) => {
        let searchFiles = rows?.[id]?.files
        const file = rows?.[id]?.files;
        setActualStatus(rows?.[id]?.status)
        let tempFiles = [];
        tempFiles = (searchFiles).map(item => ({
            uri: item.downloadUrl
        }));
        setDocType('request')
        setFiles(tempFiles);
        setDocumentsDialogModalOpen(true)
    }

    const handleChangeTo = (idx, value, resetForm) => {
        const row = [...rows];
        row[idx] = {
            type: row[idx].type,
            validFrom: (value?.validFrom) ? value.validFrom : undefined,
            validTo: (value?.validTo) ? value.validTo : undefined,
            status: (role === RoleEnum.ADMIN) ? "AdminUploaded" : "Uploaded",
            files: uploadedFile
        };

        callbackRows(row);
        setModalOpen(false);
        setUploadedFile([]);
        resetForm();
    };



    const handleAddRow = (idx, values, resetForm) => {
        const item = {
            type: values?.type,
            validFrom: (values?.validFrom) ? values.validFrom : undefined,
            validTo: (values?.validTo) ? values.validTo : undefined,
            status: (role === RoleEnum.ADMIN) ? "AdminUploaded" : "Uploaded",
            //adminUpload: (role === RoleEnum.ADMIN) ? true : false,
            files: uploadedFile,
        };
        callbackRows([...rows, item]);
        setModalOpen(false);
        setUploadedFile([]);
        resetForm();
    };

    const handleApproveDocument = (idx) => {
        const row = [...rows];
        if (row[idx]?.id) {
            let approve = [...approveDocuments, row[idx]?.id]
            callbackApproveDocuments(approve)
        }
        row[idx] = {
            type: row[idx].type,
            validFrom: row[idx].validFrom,
            validTo: row[idx].validTo,
            status: "Approved",
            files: row[idx].files,
            tempStatus: "TempRejected"
        };
        callbackRows(row);
    }

    const handleRejectDocument = (idx) => {
        const row = [...rows];
        if (row[idx]?.id) {
            let reject = [...rejectDocuments, row[idx]?.id]
            callbackRejectDocuments(reject)
        }
        row[idx] = {
            type: row[idx].type,
            validFrom: row[idx].validFrom,
            validTo: row[idx].validTo,
            status: "Rejected",
            files: row[idx].files,
            tempStatus: "TempApproved"
        };
        callbackRows(row);
    }

    const renderDeleteModalButtons = () => {
        return (
            <>
                <Button
                    disabled={false}
                    onClick={() => { (updateClub) ? handleRealRemoveSpecificRow(actualId) : handleRemoveSpecificRow(actualId) }}
                    color="danger"
                    round
                    table
                    size="sm">
                     <BlockIcon /> {intl.formatMessage({ id: 'button.delete' })}
                </Button>
            </>
        );
    };

    const handleRemoveSpecificRow = (idx) => {
        const row = [...rows];
        if (row[idx]?.id) {
            let remove = [...removeDocuments, row[idx]?.id]
            callbackRemoveDocuments(remove)
        }
        row[idx] = {
            type: row[idx].type,
            validFrom: null,
            validTo: null,
            status: "Removed",
            files: null,
        };
        callbackRows(row);
        setDeleteModalOpen(false);
    }

    const handleRealRemoveSpecificRow = (idx) => {
        const row = [...rows]
        row.splice(idx, 1)
        //setRows(row)
        callbackRows(row);
        setDeleteModalOpen(false);
        //setDeleteModalOpen(false)
    }

    const getStatus = (status) => {
        if (status === "Awaiting") return <Box style={{ color: colors.warning }}>Čaká na schválenie</Box>
        if (status === "Uploaded") return <Box style={{ color: colors.success }}>Nahratý</Box>
        if (status === "Removed") return <Box style={{ color: colors.error }}>Odstránený</Box>
        else if (status === "Empty") return <Box style={{ color: (requestStatus !== "createClub") && colors.error }}>Nenahratý</Box>
        else if (status === "Approved") return <Box style={{ color: colors.success }}>Schválený</Box>
        else if (status === "Rejected") return <Box style={{ color: colors.error }}>Zamietnutý</Box>
        else if (status === "Returned") return <Box style={{ color: colors.secondary }}>Vrátený</Box>
        else return <Box>{status}</Box>
    }

    const getType = (type) => {
        if (type === "CompanyRegistrationStatement") return <Box> {intl.formatMessage({ id: 'documents.club.CompanyRegistrationStatement' })}</Box>
        else if (type === "ICOAssignmentConfirmation") return <Box>{intl.formatMessage({ id: 'documents.club.ICOAssignmentConfirmation' })}</Box>
        else if (type === "DICRegistrationStatement") return <Box>{intl.formatMessage({ id: 'documents.club.DICRegistrationStatement' })}</Box>
        else if (type === "MinuteOfTheInauguralMeeting") return <Box>{intl.formatMessage({ id: 'documents.club.MinuteOfTheInauguralMeeting' })}</Box>
        else if (type === "StatutesOfTheCivicAssociation") return <Box>{intl.formatMessage({ id: 'documents.club.StatutesOfTheCivicAssociation' })}</Box>
        else return <Box>{type}</Box>
    }

    const getRowColor = (status) => {
        if (status === "Uploaded") return colors.successBackground
        else if (status === "Removed") return colors.errorBackground
        else if (status === "Returned") return colors.warningBackground
        if (requestStatus && requestStatus !== "createClub") if (status === "Empty") return colors.errorBackground
    }

    return (
        <>
            <TableContainer style={{ borderRadius: parameters.tablesRounded }}>
                <Table aria-label="customized table">
                    <TableHead style={{ borderBottom: "none" }}>
                        <TableRow>
                            <StyledTableCell>Názov</StyledTableCell>
                            <StyledTableCell>Platnosť od</StyledTableCell>
                            <StyledTableCell>Platnosť do</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Akcie</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(rows)?.map((item, idx) => (
                            <TableRow key={item.type} style={{ backgroundColor: getRowColor(item.status) }}  >
                                <StyledTableCell style={{ color: "white" }} component="th" scope="row">
                                    {getType(item.type)}
                                </StyledTableCell>
                                <StyledTableCell style={{ color: (item.status === "Uploaded") && colors.success }}>{(item?.validFrom) ? moment(item.validFrom).format("DD.MM.YYYY") : "-"}</StyledTableCell>
                                <StyledTableCell style={{ color: (item.status === "Uploaded") && colors.success }}>{(item?.validTo) ? moment(item.validTo).format("DD.MM.YYYY") : "-"}</StyledTableCell>
                                <StyledTableCell style={{ color: (item.status === "Uploaded") && colors.success }}><StatusTextField intl={intl} status={item.status} type={'documents'} /></StyledTableCell>
                                <StyledTableCell align="right" >
                                    {!closed ?
                                        (item.status !== "Empty" && item.status !== "Removed") ? <>
                                            {(item.files /*&& item.status !== "Uploaded"*/) &&
                                                <Tooltip title={"Otvoriť dokument"}>
                                                    <SearchIcon className={classes.circle} onClick={() => { setActualId(idx); (item?.status === "Uploaded" || item?.status === "AdminUploaded") ? handleDownloadDocument(idx) : handleGetFiles(idx) }} />
                                                </Tooltip>
                                            }
                                            {(item.files && item.status === "Approved" || item.status === "Rejected") ?
                                                <>
                                                    {item.status !== "Approved" &&
                                                        <Tooltip title={"Odstrániť dokument"}>
                                                            <DeleteIcon
                                                                size="sm"
                                                                className={classes.circle}
                                                                onClick={() => { setActualId(idx); setDeleteModalOpen(true) }}
                                                            />
                                                        </Tooltip>
                                                    }

                                                    {/*(item.tempStatus === "TempApproved" || (!item.tempStatus || item.status === "Awaiting") && role === RoleEnum.ADMIN) && 
                                                    <>
                                                        {(item.status !== "Rejected" && item.status !== "Approved") &&
                                                            <Tooltip title={"Schváliť dokument"}>
                                                                <CheckIcon className={classes.circle} onClick={() => { setActualId(idx); handleApproveDocument(idx) }}  />
                                                            </Tooltip>
                                                        }
                                                    </>
                                                    }
                                                    {(item.tempStatus === "TempRejected" || !item.tempStatus && role === RoleEnum.ADMIN || item.status === "Awaiting") && <>
                                                        {(item.status !== "Rejected" && item.status !== "Approved") &&
                                                            <Tooltip title={"Zamietnuť dokument"}>
                                                                <BlockIcon className={classes.circle} onClick={() => { setActualId(idx); handleRejectDocument(idx) }}   />
                                                            </Tooltip>
                                                        }
                                                    </>
                                                    */}
                                                </>
                                                :
                                                <>
                                                    {item.status !== "Approved" &&
                                                        <Tooltip title={"Odstrániť dokument"}>
                                                            <DeleteIcon
                                                                size="sm"
                                                                className={classes.circle}
                                                                onClick={() => { setActualId(idx); setDeleteModalOpen(true) }}
                                                            //onClick={() => { (updateClub) ? handleRealRemoveSpecificRow(idx) : handleRemoveSpecificRow(idx) }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {/*(role === RoleEnum.ADMIN && item.status === "Awaiting") &&
                                                        <>
                                                            {(item.status !== "Rejected") &&
                                                                <Tooltip title={"Schváliť dokument"}>
                                                                    <CheckIcon className={classes.circle} onClick={() => { setActualId(idx); handleApproveDocument(idx) }} />
                                                                </Tooltip>
                                                            }
                                                            {(item.status !== "Approved") &&
                                                                <Tooltip title={"Zamietnuť dokument"}>
                                                                    <BlockIcon className={classes.circle} onClick={() => { setActualId(idx); handleRejectDocument(idx) }} />
                                                                </Tooltip>
                                                            }
                                                        </>
                                                    */}
                                                </>
                                            }
                                        </>
                                            :
                                            <Tooltip title={"Nahrať dokument"}>
                                                <AddIcon className={classes.circle} onClick={() => { setActualId(idx); setModalOpen(true) }} />
                                            </Tooltip>
                                        : <>
                                            {(item.status !== "Empty" && item.status !== "Removed") &&
                                                <Tooltip title={"Otvoriť dokument"}>
                                                    <SearchIcon className={classes.circle} onClick={() => { setActualId(idx); handleGetFiles(idx) }} />
                                                </Tooltip>
                                            }
                                        </>
                                    }

                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {updateClub &&
               <Box className={classes.floatRight} style={{paddingTop: '8px'}}>
                    {(!closed) &&
                        <Button
                            color="primary"
                            size="sm"
                            round
                            table
                            onClick={() => { setModalOpen(true) }}>
                            <CloudUploadIcon /> Nahrať dokumenty
                        </Button>
                    }
                </Box>
            }
            <Formik
                onSubmit={(values, { resetForm }) => (updateClub)
                    ? handleAddRow(actualId, values, resetForm)
                    : handleChangeTo(actualId, values, resetForm)
                }
                validationSchema={(documentOptions) && getValidationSchemaDocuments(intl)}
                initialValues={{}}
                enableReinitialize
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: "documents.uploadModal.title" })}
                        open={modalOpen}
                        onClose={() => { setModalOpen(false); formikProps.resetForm(); setUploadedFile([]) }}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <DocumentUploadCard
                            formikProps={formikProps}
                            validateParam={validateParam}
                            callbackUploadedFile={(files) => setUploadedFile(files)}
                            uploadedFile={uploadedFile}
                            documentOptions={documentOptions}
                        />
                    </Modal>
                )}
            </Formik >

            <DocumentViewer
                docs={files}
                open={documentsDialogModalOpen}
                onClose={() => setDocumentsDialogModalOpen(false)}
                data={formikProps?.values}
                documentId={actualId}
                documentStatus={actualStatus}
                textColor={(actualStatus === "Awaiting") ? colors.warning : (actualStatus === "Approved") ? colors.success : colors.error}
                refetch={refetch}
                callbackDocumentReject={() => handleRejectDocument(actualId)}
                callbackDocumentApprove={() => handleApproveDocument(actualId)}
                type={docType}
                closed={closed}
            />
            <Modal
                title={intl.formatMessage({ id: "documents.deleteModal.title" })}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                actions={renderDeleteModalButtons()}
                fullWidth
            >
                <Grid container spacing={1}>
                    {intl.formatMessage({ id: "documents.deleteModal.body" })}
                </Grid>
            </Modal>
            <br />

            {
                (!readMode && detail) &&
                <Box className={classes.floatRight}>

                    <Button
                        color="primary"
                        size="sm"
                        round
                        onClick={() => setModalOpen(true)}>
                        <CloudUploadIcon /> {intl.formatMessage({ id: "documents.button.upload" })}
                    </Button>

                </Box>
            }
        </>
    )
}

export default injectIntl(DocumentRequestTable);
