import gql from 'graphql-tag';

const CLUB_MEMBER = gql`
query clubMember($id: ID!) {
  clubMember (id: $id) {
    id
    bid
    uciId
    issId
    issSyncedAt
    uciSyncedAt
    isSuspended
    isYouthWorker
    federationId
    hosting{
      club{
        profile{
          name
        }
      }
    }
    person{
      id
      bid
      user {
        id
      }
      profile{
        email
        firstName
        lastName
        gender
        birth
        nationality
        countryOfBirth
        preNominals
        postNominals
        phone
        personalId
        address1
        address2
        postal
        city
        country
        district
        emergencyFirstName
        emergencyLastName
        emergencyPhone
        photoUrl
      }
      pendingProfileRequest{
        id
        data{
          ... on PersonProfileUpdateRequest {
            profile{
              photoUrl
            }
          }
        }
      }
    }
    isYouthWorker
    functions{
      function{
        id
        name
        ISSName
        UCIRole
        UCIFunction
        isCompetitionFunction
        isLicensedFunction
        requiredDocuments
      }
      disciplines{
        id
        name
      }
    }
    club{
      id
      bid
      type
      members{
      id
      bid
      functions{
        function{
          id
          name
          ISSName
          UCIRole
          UCIFunction
        }
      disciplines{
        id
        name
        description
      }
      }
      person{
        profile{
          email
          firstName
          lastName
          gender
          birth
          nationality
          phone
          address1
          address2
          postal
          city
          country
        }
      }
    }
      profile{
        name
        logoUrl
        businessName
        address1
        address2
        postal
        city
        district
        country
      }
    }
    licenses {
      id
      year
      revokedAt
      downloadUrl
      frontImageUrl
      backImageUrl
      function {
        id
        name
      }
      disciplines {
        discipline {
          id
          name
        }
        category {
          id
          name
        }
      } 
    }
  }
}
`;

export default CLUB_MEMBER;

