import * as React from 'react';
import {SnackbarProvider} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import {snackbarStyles} from "common/styles/template";

const WithSnackbar = (props) => {
    const { children } = props;

    const useStyles = makeStyles(snackbarStyles);
    const classes = useStyles();

    return (
        <SnackbarProvider
            maxSnack={3}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {children}
        </SnackbarProvider>
    );
};

export default WithSnackbar;