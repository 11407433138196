import gql from 'graphql-tag';

const PERSON_PROFILE_UPDATE_REQUEST_CANCEL= gql`
mutation personProfileUpdateRequestCancel($id: ID!) { 
  personProfileUpdateRequestCancel(id: $id) {
    id
    bid
    type
    status
    createdAt
  }
} 
`;

export default PERSON_PROFILE_UPDATE_REQUEST_CANCEL;