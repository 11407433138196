import gql from 'graphql-tag';

const PERSON = gql`
query person($id: ID!) {
  person (id: $id) {
      id
      bid
      user {
        id
      }
      profile{
        email
        firstName
        lastName
        gender
        birth
        nationality
        countryOfBirth
        preNominals
        postNominals
        phone
        personalId
        address1
        address2
        postal
        city
        country
        district
        emergencyFirstName
        emergencyLastName
        emergencyPhone
        photoUrl
      }
  }
}
`;

export default PERSON;

