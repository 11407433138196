import gql from 'graphql-tag';

const PROFILE_UPDATE = gql`
mutation profileUpdate($input: PersonProfileInput!) {
    profileUpdate(input: $input) {
        firstName
    }
}
`;

export default PROFILE_UPDATE;