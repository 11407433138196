import React from 'react';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardTitle from "components-lib/CardTitle/CardTitle";
import Card from "components/Card/Card.js";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/cardIconStyle.js";

const useStyles = makeStyles(styles);

const IconCard = (props) => {
    const { children, title, icon = null, color = 'primary', className, titleStyles = {}, isCustom = false, background = false, inside = false } = props;

    const cardIconStyles = useStyles();

    const cardIconClasses = classNames({
        [cardIconStyles.cardIcon]: true,
        [cardIconStyles[color + "CardHeader"]]: color,
        [className]: className !== undefined
    });

    return (
        <Card className={className}
            style={{ backgroundColor: (background) && background, marginTop: (inside) && 0 }}
        >
            {title &&
                <CardHeader color={color} icon>
                    {icon && isCustom && (
                        <div className={cardIconClasses}>
                          {icon}
                        </div>
                    )}
                    <CardTitle title={<span style={{ paddingLeft: '6px' }}>{title}</span>} style={titleStyles} />
                </CardHeader>
            }
            <CardBody>
                {children}
            </CardBody>
        </Card>
    );
};

export default IconCard;