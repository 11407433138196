import React, { useState, useEffect } from "react";
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";

import paths from "../../paths";
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import { generatePath } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { colors, widgets } from "common/styles/configLayout.js";
import moment from "moment";
import IconCard from 'components-lib/IconCard/IconCard';
import { useLocation } from 'react-router-dom';
import CLUBS from "queries/ClubsQueries/clubs";
import { pathOr } from 'rambda';
import { useQuery } from '@apollo/client';
import MEMBER_LICENSE_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseRequestsTable';
import Modal from "components-lib/Modal/Modal";
import AddIcon from '@material-ui/icons/Add';
import PageHeader from "components-lib/PageHeader/PageHeader";
import LicenseTable from "./LicenseTable";
import { getApolloClient } from "configFiles/apollo";
import { useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { getValidationSchemaButton, getValidationSchemaYearAndClub, getValidationSchemaYear } from "./validationSchema";
import RoleEnum from 'common/enums/RoleEnum'
import { changeLicensesNumberAction } from 'redux/actions';
import PROFILE from 'queries/ProfileQueries/profile';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";

const LicenseListPage = (props) => {
  const { intl, history, role, changeLicensesNumberAction } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
  const { enqueueSnackbar } = useSnackbar();
  const { data: userProfile } = useQuery(PROFILE);
  const [loadLicenses, { data: dataLicenses, refetch: refetchLicenses }] = useLazyQuery(MEMBER_LICENSE_REQUESTS_TABLE);
  const { data: licensingInfoData } = useQuery(LICENSING_INFO);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentClubs, setCurrentClubs] = useState([]);
  const [nextClubs, setNextClubs] = useState([]);
  const [isNextYearAvailable, setIsNextYearAvailable] = useState(false)

  useEffect(() => {
    setIsNextYearAvailable(licensingInfoData?.licensingInfo?.periods?.next?.isPurchasable)
  }, [licensingInfoData]);

  const client = getApolloClient(enqueueSnackbar, history, intl);

  const getLicensesNumber = async () => {
    try {
      const result = await client.query({
        query: MEMBER_LICENSE_REQUESTS_TABLE,
        variables: {
          "filter": [
            {
              "status": {
                "in": (role === RoleEnum.ADMIN) ? ["Ready", "Downloaded"] : ["Pending"]
              }
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changeLicensesNumberAction(pathOr('', ['data', 'memberLicenseRequestsTable', 'totalCount'], result));
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getLicensesNumber()
  }, []);

  const getLicensedClubs = async () => {
    try {
      const result = await client.query({
        query: CLUBS
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      let currentClubsSelectItems = pathOr([], ["data", "clubs", "items"], result).filter(i => i?.licenses?.current?.isLicensed).map((item) => {
        return {
          value: item?.id,
          label: item?.profile?.name
        }
      });
      let nextClubsSelectItems = pathOr([], ["data", "clubs", "items"], result).filter(i => i?.licenses?.next?.isLicensed).map((item) => {
        return {
          value: item.id,
          label: item?.profile?.name
        }
      });
      setCurrentClubs(currentClubsSelectItems);
      setNextClubs(nextClubsSelectItems);
    } catch (error) {
      throw error;
    }
  }

  const getActions = () => {
    return [
      {
        title: "Nová žiadosť",
        icon: <AddIcon />,
        onClick: () => (role === RoleEnum.ADMIN)
          ? (setModalOpen(true), getLicensedClubs())
          : (isNextYearAvailable)
            ? setModalOpen(true)
            : (pathOr('', ['me', 'member', 'club', 'licenses', 'current', 'isLicensed'], userProfile) ||
              pathOr('', ['me', 'member', 'club', 'licenses', 'next', 'isLicensed'], userProfile))
              ? history.push({
                pathname: `/admin${generatePath(paths.licenses.new)}`,
                search: `?clubId=${pathOr('', ['me', 'member', 'club', 'id'], userProfile)}&year=${licensingInfoData?.licensingInfo?.periods?.current?.year}`,
              })
              : enqueueSnackbar("Nemôžete licencovať členov, pretože váš klub nemá platnú licenciu.", { variant: 'warning' })
      },
    ];
  };

  const renderModalButtons = (formikProps) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => formikProps?.handleSubmit()}
          color="primary"
          round
          table
          size="sm">
          Vytvoriť žiadosť
        </Button>
      </>
    );
  };

  const renderTables = () => {
    return (
      <div>
        <LicenseTable
          data={dataLicenses}
          loadData={loadLicenses}
          refetch={refetchLicenses}
          defaultFilter={defaultFilter}
          queryDataPath={['memberLicenseRequestsTable', 'items']}
          history={history}
          currentYear={licensingInfoData?.licensingInfo?.periods?.current?.year}
        />
      </div>
    );
  };
  return (
    <>
      <PageHeader
        actions={getActions()}
        withBackButton={false}
      />
      <IconCard>
        {renderTables()}
      </IconCard>
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        //onSubmit={(values) => setModalOpen(true)}
        onSubmit={(values) => history.push({
          pathname: `/admin${generatePath(paths.licenses.new)}`,
          search: `?clubId=${(role === RoleEnum.ADMIN) ? values?.clubId : pathOr('', ['me', 'member', 'club', 'id'], userProfile)}&year=${(values?.year === "current" || !isNextYearAvailable) ? licensingInfoData?.licensingInfo?.periods?.current?.year : licensingInfoData?.licensingInfo?.periods?.next?.year}`,
        })}
        validationSchema={
          isNextYearAvailable
            ? (role !== RoleEnum.ADMIN)
              ? getValidationSchemaYear(intl)
              : getValidationSchemaYearAndClub(intl)
            : getValidationSchemaButton(intl)
        }
        initialValues={{}}
        enableReinitialize
      >
        {(formikProps) => (
          <>
            <Modal
              title={"Vytvorenie žiadosti o vydanie licencií"}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              actions={renderModalButtons(formikProps)}
              fullWidth
            >
              {isNextYearAvailable ?
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormikField
                      name="year"
                      labelText={"Vyberte rok licencie"}
                      mandatory={true}
                      formikProps={formikProps}
                      validateParam={true}
                      highlightChange={false}
                      type="select"
                      options={[
                        {
                          value: "current",
                          label: licensingInfoData?.licensingInfo?.periods?.current?.year,
                          disabled: (role !== RoleEnum.ADMIN && !pathOr('', ['me', 'member', 'club', 'licenses', 'current', 'isLicensed'], userProfile)) && `Klub nemá licenciu na rok ${licensingInfoData?.licensingInfo?.periods?.current?.year}`
                        },
                        {
                          value: "next",
                          label: licensingInfoData?.licensingInfo?.periods?.next?.year,
                          disabled: (role !== RoleEnum.ADMIN && !pathOr('', ['me', 'member', 'club', 'licenses', 'next', 'isLicensed'], userProfile)) && `Klub nemá licenciu na rok ${licensingInfoData?.licensingInfo?.periods?.next?.year}`
                        }
                      ]}
                    />
                  </Grid>

                  {(formikProps?.values?.year === "current") &&
                    <Grid container spacing={1}>
                      {role === RoleEnum.ADMIN && <>
                        <Grid item xs={12}>
                          <br />
                          <Typography variant="body2" style={{ color: colors.textBody }}>Zoznam licencovaných klubov na rok {licensingInfoData?.licensingInfo?.periods?.current?.year} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormikField
                            name="clubId"
                            labelText={"Vyberte klub"}
                            mandatory={true}
                            formikProps={formikProps}
                            validateParam={true}
                            highlightChange={false}
                            type="autocomplete"
                            options={currentClubs}
                          />
                        </Grid>
                      </>}
                    </Grid>
                  }
                  {(formikProps?.values?.year === "next") &&
                    <Grid container spacing={1}>
                      {role === RoleEnum.ADMIN && <>
                        <Grid item xs={12}>
                          <br />
                          <Typography variant="body2" style={{ color: colors.textBody }}>Zoznam licencovaných klubov na rok {licensingInfoData?.licensingInfo?.periods?.next?.year} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormikField
                            name="clubId"
                            labelText={"Vyberte klub"}
                            mandatory={true}
                            formikProps={formikProps}
                            validateParam={true}
                            highlightChange={false}
                            type="autocomplete"
                            options={nextClubs}
                          />
                        </Grid>
                      </>}
                    </Grid>
                  }
                </Grid>
                :
                <Grid container spacing={1}>
                  {role === RoleEnum.ADMIN && <>
                    <Grid item xs={12}>
                      <br />
                      <Typography variant="body2" style={{ color: colors.textBody }}>Zoznam licencovaných klubov na rok {licensingInfoData?.licensingInfo?.periods?.current?.year} </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormikField
                        name="clubId"
                        labelText={"Vyberte klub"}
                        mandatory={true}
                        formikProps={formikProps}
                        validateParam={true}
                        highlightChange={false}
                        type="autocomplete"
                        options={currentClubs}
                      />
                    </Grid>
                  </>
                  }
                </Grid>
              }
            </Modal>
          </>
        )}
      </Formik >
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LicenseListPage));

