import gql from 'graphql-tag';

const CLUB_MEMBER_INVITE = gql`
mutation clubMemberInvite($clubId: ID!, $input: ClubMemberInviteInput!) {
  clubMemberInvite(clubId: $clubId, input: $input)
}
`;

export default CLUB_MEMBER_INVITE;

