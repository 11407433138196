import gql from 'graphql-tag';

const USER_PASSWORD_RESET = gql`
mutation userPasswordReset($id: ID!){
	userPasswordReset(id: $id) 
  }
`;

export default USER_PASSWORD_RESET;

