import gql from 'graphql-tag';

const CLUB_MEMBER_RESUME = gql`
mutation clubMemberResume($id: ID!) {
  clubMemberResume(id: $id) 
}
`;

export default CLUB_MEMBER_RESUME;

