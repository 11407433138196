import gql from 'graphql-tag';

const MEMBER_LICENSE_REQUESTS_TABLE = gql`
query memberLicenseRequestsTable($offset: Int, $limit: Int, $sort: [MemberLicenseRequestsTableSortInput!], $filter: [MemberLicenseRequestsTableFilterInput]) {
  memberLicenseRequestsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
        id
        bid
        status
        year
        price
        createdAt
        updatedAt
        deletedAt
        clubId
        club
        paymentId
        createdById
        createdBy
        licenses
        cardFileUrl  		
      }
  }
}
`;

export default MEMBER_LICENSE_REQUESTS_TABLE;