import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.js";
import paths from "paths";
import ACTIVATION_DETAILS from 'queries/AuthQueries/activationDetails'
import { useQuery, useMutation } from '@apollo/client';
import ActivatePasswordPage from './ActivatePasswordPage';
import Loading from 'components-lib/Loading/Loading';
import { pathOr } from 'rambda';
import ACTIVATE from 'queries/AuthQueries/activate'
import { useSnackbar } from "notistack";
import { colors } from "common/styles/configLayout";
import IconCard from 'components-lib/IconCard/IconCard';

const useStyles = makeStyles(styles);

const ActivatePage = (props) => {
  const { intl, history } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  var url_string = window.location.href
  var url = new URL(url_string);
  var token = url.searchParams.get("token");
  const [disableButton, setDisableButton] = useState(false);

  const { loading, data } = useQuery(ACTIVATION_DETAILS, { variables: { token: token } });
  const [activate] = useMutation(ACTIVATE);
  useEffect(() => {
    if (!loading && !pathOr('', ["activationDetails", 'requirements', 'password'], data)) {
      handleActivate();
    }
  }, [data]);

  const handleActivate = () => {
    setDisableButton(true);
    const result = { ...pathOr('', ["activationDetails", 'profile'], data) };
    delete result.gdpr;
    delete result.email;
    delete result.passwordRepeat;
    delete result.__typename;

    activate({
      variables: {
        token: token,
        input: {
          ...result
        }
      }
    }).then((response) => {
      enqueueSnackbar(intl.formatMessage({ id: 'activate.snackbar.success' }), { variant: 'success' });
      setDisableButton(false);
    }).catch((err) => {
      console.log('[error]', err);
      setDisableButton(false);
    });
  };

  function addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  const getContent = () => {
    if (!pathOr('', ["activationDetails", 'requirements', 'password'], data)) {
      return (
        <div className={classes.contentCenter}>
          <Grid container>
            <IconCard background={addAlpha(colors.primary, 0.95)}>
              <Grid item md={12}>
                <Typography variant="h4" style={{ color: 'colors.primary' }}>{intl.formatMessage({ id: 'activate.title' })}</Typography>
                <Typography variant="body1" style={{ color: colors.backgroundWidgets }}>{intl.formatMessage({ id: 'activate.subtitle' })}</Typography>
                <br />
                <Button
                  color="disabled"
                  disabled={disableButton}
                  size="sm"
                  round
                  onClick={() => { history.push(`/auth${paths.auth.login}`) }}
                >
                  {intl.formatMessage({ id: 'activate.button.login' })}
                </Button>
              </Grid>
            </IconCard>
          </Grid>
        </div>
      )
    } else {
      return <ActivatePasswordPage data={pathOr('', ["activationDetails", 'profile'], data)} email={pathOr('', ["activationDetails", 'profile', 'email'], data)} token={token} />
    }
  }

  const getInvalidContent = () => {
    return (
      <div className={classes.contentCenter}>
        <Grid container>
          <IconCard background={addAlpha(colors.error, 0.8)}>
            <Grid item md={12}>
              <Typography variant="h4" style={{ color: colors.errorAlpha }}>{intl.formatMessage({ id: 'activate.not.title' })}</Typography>
            </Grid>
          </IconCard>
        </Grid>
      </div>
    )
  }

  if (loading) return <Loading />
  return (
    <>{(data) ? getContent() : getInvalidContent()}</>
  );
}
export default injectIntl(withRouter(ActivatePage));

