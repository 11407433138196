import {
  CHANGE_USER_ROLE,
  CHANGE_ALL_USER_ROLES,
  CHANGE_USER_PROFILE,
  CHANGE_ADDRESS,
  CHANGE_LAST_URL,
  CHANGE_PENDING_REQUESTS_NUMBER,
  CHANGE_LICENSES_NUMBER,
  CHANGE_DOCUMENTS_NUMBER,
  CHANGE_PENDING_TRANSFERS_AND_HOSTING_NUMBER,
  CHANGE_LOCATION
} from "./actionTypes";

export function changeUserRoleAction(value) {
  return {
    type: CHANGE_USER_ROLE,
    value: value
  };
};

export function changeAllUserRolesAction(value) {
  return {
    type: CHANGE_ALL_USER_ROLES,
    value: value
  };
};

export function changeUserProfileAction(value) {
  return {
    type: CHANGE_USER_PROFILE,
    value: value
  };
};

export function changeAddressAction(value) {
  return {
    type: CHANGE_ADDRESS,
    value: value
  };
};

export function changeLastUrlAction(value) {
  return {
    type: CHANGE_LAST_URL,
    value: value
  };
};

export function changePendingRequestsNumberAction(value) {
  return {
    type: CHANGE_PENDING_REQUESTS_NUMBER,
    value: value
  };
};

export function changeLicensesNumberAction(value) {
  return {
    type: CHANGE_LICENSES_NUMBER,
    value: value
  };
};

export function changeDocumentsNumberAction(value) {
  return {
    type: CHANGE_DOCUMENTS_NUMBER,
    value: value
  };
};

export function changePendingTransfersAndHostingNumberAction(value) {
  return {
    type: CHANGE_PENDING_TRANSFERS_AND_HOSTING_NUMBER,
    value: value
  };
};

export function changeLocation(countries, districts, cities) {
  return {
    type: CHANGE_LOCATION,
    value: {
      countries: countries,
      districts: districts,
      cities: cities
    }
  };
};