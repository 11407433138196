import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useMutation } from '@apollo/client';
import { withRouter } from "react-router-dom";
import paths from "../../paths";
import { getValidationSchemaActivation } from "./validationSchema";
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import { generatePath } from 'react-router-dom';
import ACTIVATE from 'queries/AuthQueries/activate'
import Typography from '@material-ui/core/Typography';
import { colors } from "common/styles/configLayout.js";

const useStyles = makeStyles(styles);

const ActivatePasswordPage = (props) => {
  const { history, intl, data, token, email } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [validateParam, setValidateParam] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [activate] = useMutation(ACTIVATE);
  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const handleActivate = (values) => {
    setDisableButton(true);
    const result = data;
    delete result.gdpr;
    delete result.szcConfirmation;
    delete result.termsOfUse;
    delete result.email;
    delete result.passwordRepeat;
    delete result.__typename;

    activate({
      variables: {
        token: token,
        input: {
          ...result,
          password: values?.password,
        }
      }
    }).then((response) => {
      enqueueSnackbar(intl.formatMessage({ id: 'activate.snackbar.success' }), { variant: 'success' })
      history.push({
        pathname: `/auth${generatePath(paths.auth.confirm)}`,
        state: {
          //type: "activate",
          email: email,
          password: values?.password
        }
      })
      setDisableButton(false);
    }).catch((err) => {
      console.log('[error]', err);
      setDisableButton(false);
    });
  };


  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <form>
          <Grid login className={classes[cardAnimaton]} style={{ backgroundColor: colors.backgroundWidgets, borderRadius: "16px" }}>
            <Formik
              enableReinitialize
              validationSchema={getValidationSchemaActivation(intl)}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values) => { handleActivate(values); }}
              initialValues={data}
            >
              {(formikProps) => (
                <>
                  <CardBody>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5" align="center" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'activatePassword.title' })}</Typography>
                        <br />
                        <Typography variant="body2" align="center">{intl.formatMessage({ id: 'activatePassword.subtitle' }, [formikProps.values.email])}</Typography>
                        <br />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="password"
                          labelText={intl.formatMessage({ id: 'register.password' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          name="repeatPassword"
                          labelText={intl.formatMessage({ id: 'register.repeatPassword' })}
                          formikProps={formikProps}
                          validateParam={validateParam}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >Súhlasím s&nbsp;
                            <a /*onClick={() => alert('PDF s podmienkami ochrany údajov')}*/ target="_blank" style={{ textDecoration: 'underline', color: colors.primary }}>podmienkami ochrany údajov</a>
                          </span>}
                          smallDescription={true}
                          type="checkbox"
                          name="gdpr"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '-10px' }}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >Súhlasím s&nbsp;
                            <a /*onClick={() => alert('PDF s podmienkami používania')}*/ target="_blank" style={{ textDecoration: 'underline', color: colors.primary }}>podmienkami používania</a>
                          </span>}
                          smallDescription={true}
                          type="checkbox"
                          name="termsOfUse"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '-10px' }}>
                        <FormikField
                          highlightChange={false}
                          labelText={<span style={{ fontSize: '0.8em', color: colors.textBody }} >{intl.formatMessage({ id: 'register.szcConfirmation' })}</span>}
                          smallDescription={true}
                          type="checkbox"
                          name="szcConfirmation"
                          formikProps={formikProps}
                          validateParam={validateParam}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                  <CardFooter
                    className={classes.justifyContentCenter}
                    style={{
                      width: '100%',
                      margin: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      color="primary"
                      size="sm"
                      round
                      disabled={disableButton}
                      onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                    >
                      {intl.formatMessage({ id: 'activatePassword.button.submit' })}
                    </Button>
                    <br />
                  </CardFooter>
                </>
              )}
            </Formik>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default injectIntl(withRouter(ActivatePasswordPage));
