import gql from 'graphql-tag';

const CLUB_TRANSFER_REQUEST_RESPOND= gql`
mutation clubTransferRequestRespond($id: ID!,$result:ResponseResult ) {
  clubTransferRequestRespond(id: $id, result: $result) {
    id
    bid
  }
}
`;

export default CLUB_TRANSFER_REQUEST_RESPOND;