import gql from 'graphql-tag';

const CLUBS_TABLE= gql`
query clubsTable($offset: Int, $limit: Int, $sort: [ClubsTableSortInput!], $filter: [ClubsTableFilterInput]) {
  clubsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
       	id
        bid
        name
        location
        members
        email
        functionary
        createdAt
        updatedAt
        deletedAt
        createdById
        createdBy
        license
      }
  }
}
`;

export default CLUBS_TABLE;