import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import PaymentTable from "./PaymentTable";
import PAYMENTS_TABLE from 'queries/PaymentsQueries/paymentsTable';
import { useLocation } from 'react-router-dom';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import RoleEnum from "common/enums/RoleEnum";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import FormikField from "components-lib/FormikField/FormikField";
import { colors } from "common/styles/configLayout.js";
import Typography from "@material-ui/core/Typography"
import Button from "components/CustomButtons/Button.js";
import { getAPIUrl } from "configFiles/config";
import { getUserToken } from "helpers/helpers";
import { Formik } from "formik";
import Modal from "components-lib/Modal/Modal";
import { getValidationSchemaExport } from "./validationSchema";
import moment from 'moment';

const PaymentListPage = (props) => {
  const { intl, history, role } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
  const [modalOpen, setModalOpen] = useState(false);


  const getActions = () => {
    let array = [];
    if (role === RoleEnum.ADMIN)
      array.push({
        title: intl.formatMessage({ id: 'payments.action.invoiceExport' }),
        icon: <SystemUpdateAltIcon />,
        onClick: () => setModalOpen(true)
      })
    return array;
  }

  const renderModalButtons = (formikProps) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => formikProps.handleSubmit()}
          color="primary"
          round
          table
          size="sm">
          <SystemUpdateAltIcon /> {intl.formatMessage({ id: 'payments.action.invoiceExport.button' })}
        </Button>
      </>
    );
  };

  const handleGetInvoiceExport = async (from, to, resetForm) => {
    let dateFrom = moment(from).startOf('day');
    let dateTo = moment(to).endOf('day');
    let url = `${getAPIUrl('rest')}/export/invoices?from=${dateFrom}&to=${dateTo}`

    const result = await fetch(url, {
      headers: {
        authorization: `Bearer ${(getUserToken() || "")}`,
      }
    })
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    var downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `${intl.formatMessage({ id: 'payments.modal.invoiceExport.file' })}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    resetForm();
    setModalOpen(false)
  }

  return (
    <>
      <PageHeader
        withBackButton={false}
        actions={getActions()}
      />
      <IconCard>
        <PaymentTable
          query={PAYMENTS_TABLE}
          defaultFilter={defaultFilter}
          history={history}
          queryDataPath={['paymentsTable', 'items']}
        />
      </IconCard>
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values, { resetForm }) => handleGetInvoiceExport(values?.from, values?.to, resetForm)}
        validationSchema={getValidationSchemaExport(intl)}
        initialValues={{}}
        enableReinitialize
      >
        {(formikProps) => (
          <>
            <Modal
              title={intl.formatMessage({ id: 'payments.modal.invoiceExport.title' })}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              actions={renderModalButtons(formikProps)}
              fullWidth
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ color: colors.textBody }}>{intl.formatMessage({ id: 'payments.modal.invoiceExport.body' })}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikField
                    name="from"
                    labelText={intl.formatMessage({ id: 'payments.modal.invoiceExport.from' })}
                    mandatory={true}
                    formikProps={formikProps}
                    validateParam={true}
                    highlightChange={false}
                    type="datePicker"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikField
                    name="to"
                    labelText={intl.formatMessage({ id: 'payments.modal.invoiceExport.to' })}
                    mandatory={true}
                    formikProps={formikProps}
                    validateParam={true}
                    highlightChange={false}
                    type="datePicker"
                  />
                </Grid>
              </Grid>
            </Modal>
          </>
        )}
      </Formik >
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps)(injectIntl(PaymentListPage));