import gql from 'graphql-tag';

const CLUB_IS_LICENSED = gql`
query club($id: ID!){
  club(id: $id)  {
    licenses {
      current {
        isLicensed
    	}   
      next {
        isLicensed
      }
    }
  }
}
`;

export default CLUB_IS_LICENSED;