import { Grid } from "@material-ui/core";
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { colors, widgets } from 'common/styles/configLayout';
import Loading from "components-lib/Loading/Loading";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  readOnlyGrid: {
    backgroundColor: 'rgba(60,72,88, 0.4)',
    borderRadius: "4px",
    //padding: "4px 14px",

    padding: "2px 0px 2px 6px"
  }
});

const LegalEntityCard = (props) => {
  const { intl, formikProps, loading, title = true, validateParam, readMode = false, closed = false, setReadMode } = props;
  const classes = useStyles();

  const renderContent = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikField
            name="name"
            labelText={intl.formatMessage({ id: 'club.clubCard.clubName' })}
            formikProps={formikProps}
            validateParam={validateParam}
            disabled={closed}
            readMode={readMode}
            highlightChange
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary}}>{intl.formatMessage({ id: 'club.legalEntityCard.title' })}</Typography></Grid>
            <Grid item xs={12}>
              <FormikField
                name="legalName"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.name' })}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                highlightChange
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="legalForm"
                type="autocomplete"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.legalForm' })}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                options={[
                  {
                    value: 1,
                    label: 's.r.o.'
                  },
                  {
                    value: 2,
                    label: 'Živnostník'
                  },
                ]}
                highlightChange
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="ico"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.ico' })}
                formikProps={formikProps}
                validateParam={validateParam}
                disabled={closed}
                readMode={readMode}
                type="number"
                highlightChange
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="dic"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.dic' })}
                readMode={readMode}
                type="number"
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange={false}
                disabled={closed}
              />
            </Grid>
            {/*<Grid item xs={12} sm={6}>
              <FormikField
                name="mvSrRegistration"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.mvSrRegistration' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="mvSrRegistrationDate"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.mvSrRegistrationDate' })}
                formikProps={formikProps}
                validateParam={validateParam}
                //disabled={true}
                type="datePicker"
                disabled={closed}
                readMode={readMode}
                highlightChange
              />
              </Grid>*/}
            <Grid item xs={12}>
              <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.textBody }}>Sídlo</Typography>
                <Typography variant="body2" style={{ fontSize: "1em" }}>{formikProps?.values?.address1} {formikProps?.values?.address2}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginTop: "-2px" }}>{formikProps?.values?.postal} {formikProps?.values?.city}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginTop: "-2px" }}> {formikProps?.values?.country} </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <FormikField
                name="iban"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.iban' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="bankLocation"
                labelText={intl.formatMessage({ id: 'club.legalEntityCard.bankLocation' })}
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="swift"
                labelText="SWIFT"
                formikProps={formikProps}
                validateParam={validateParam}
                highlightChange
                disabled={closed}
                readMode={readMode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary}}>Predseda</Typography></Grid>
            <Grid item xs={12}>
              <FormikField
                name={`statutoryEmail`}
                labelText={intl.formatMessage({ id: 'personal.email' })}
                formikProps={formikProps}
                disabled={closed}
                readMode={readMode}
                highlightChange
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name={`statutoryFirstName`}
                labelText={intl.formatMessage({ id: 'personal.firstName' })}
                formikProps={formikProps}
                disabled={closed}
                readMode={readMode}
                highlightChange
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name={`statutoryLastName`}
                labelText={intl.formatMessage({ id: 'personal.lastName' })}
                formikProps={formikProps}
                disabled={closed}
                readMode={readMode}
                highlightChange
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name={`statutoryPhone`}
                labelText={intl.formatMessage({ id: 'personal.phone' })}
                formikProps={formikProps}
                disabled={closed}
                readMode={readMode}
                highlightChange
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ margin: "1px" }} className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.textBody }}>Trvalý pobyt</Typography>
                <Typography variant="body2" style={{ fontSize: "1em" }}>{formikProps?.values?.address1} {formikProps?.values?.address2}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginTop: "-2px" }}>{formikProps?.values?.postal} {formikProps?.values?.city}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginTop: "-2px" }}> {formikProps?.values?.country} </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    )
  }

  if (loading) return <Loading />

  return (
    <>
      {renderContent()}
    </>
  );
};

export default withRouter(injectIntl(LegalEntityCard));
