const ProfileDocumentsEnum = {
    AFFIDAVITOFPERSONALINTEGRITY: "AffidavitOfPersonalIntegrity",
    CRIMINALRECORDCERTIFICATE: "CriminalRecordCertificate",
    ANTIDOPINGCERTIFICATE: "AntidopingCertificate",
    OSHCERTIFICATE: "OSHCertificate",
    DRIVERLICENSE: "DriverLicense",
    GRADUATECERTIFICATE: "GraduateCertificate",
    TRAINERCERTIFICATION: "TrainerCertification",
    MASSAGETHERAPISTCERTIFICATION: "MassageTherapistCertification",
    MECHANICCERTIFICATION: "MechanicCertification",
    REFEREECERTIFICATION: "RefereeCertification",
    MEDICALDEGREE: "MedicalDegree"
}
export default ProfileDocumentsEnum;
