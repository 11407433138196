import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import getProjectRoutes from "routes.js";
import { injectIntl } from 'react-intl';
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import backgroundImage from "assets/img/backgroundImage.jpg";
import paths from 'paths';
import Grid from '@material-ui/core/Grid';
import logo from 'assets/img/logo-whitee.png';
import Typography from '@material-ui/core/Typography';
import { colors, widgets } from "common/styles/configLayout.js";

const useStyles = makeStyles(styles);

function Pages(props) {
  const { intl } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();

  const routes = getProjectRoutes(intl);
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getPublicRoutType = () => {
    if (window.location.pathname.indexOf(`${paths.public.clubs}`) !== -1) {
      return 'publicQuery';
    } else if (window.location.pathname.indexOf(`${paths.public.members}`) !== -1) {
      return 'publicQuery';
    } else {
      return 'authPages';
    }
  };

  const getBgImage = () => {
    return backgroundImage;
  };

  /*const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };*/

  return (
    <div>
      {getPublicRoutType() === 'authPages' ?
        <div ref={wrapper}>
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + getBgImage() + ")" }}
          >
            <Grid container style={{ zIndex: 1000, margin: "20px" }}>
              {/*<div style={{ position: "fixed", top: 15, left: 15 }}>
                <Grid item>
                  <img src={logo} alt={logo} style={{ width: '50px', minWidth: "50px", marginTop: "-20px", display: 'inline-block', }} />
                  <div style={{ display: 'inline-block' }}>
                    <Typography variant="h4" style={{ fontSize: "1.5em", minWidth: "200px" }}> {"Slovenský zväz cyklistiky"}</Typography>
                    <Typography variant="h4" style={{ color: colors.primary, fontSize: "0.8em", marginTop: "-2px" }}> {"Slovak Cycling Federation"}</Typography>
                  </div>
                </Grid>
              </div>*/}
              <Grid container justify="center" direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="" to={`/auth/${paths.auth.login}`} />
                </Switch>
              </Grid>
            </Grid>
          </div>
        </div >
        :
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="" to={`/auth/${paths.auth.login}`} />
          </Switch>
        </div>
      }
    </div >
  );
}

export default injectIntl(Pages);
