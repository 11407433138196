import gql from 'graphql-tag';

const MEMBER_LICENSE_REQUEST_DOWNLOAD = gql`
mutation memberLicenseRequestDownload($id: ID!) {
	memberLicenseRequestDownload (id: $id) {
 		type
    url
    expiresAt
  }
}
`;

export default MEMBER_LICENSE_REQUEST_DOWNLOAD;