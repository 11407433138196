import gql from 'graphql-tag';

const CLUB_UPDATE_REQUEST_CANCEL= gql`
mutation clubUpdateRequestCancel($id: ID!) { 
  clubUpdateRequestCancel(id: $id) {
    id
  }
} 
`;

export default CLUB_UPDATE_REQUEST_CANCEL;