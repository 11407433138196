import { Grid } from "@material-ui/core";
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import { colors, } from 'common/styles/configLayout';
import Loading from "components-lib/Loading/Loading";
import { useSnackbar } from 'notistack';
import Modal from "components-lib/Modal/Modal";
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import CLUB_TRANSFER_REQUEST_RESPOND_AS_MEMBER from 'queries/TransferAndHostingQueries/clubTransferRequestRespondAsMember';
import CLUB_TRANSFER_REQUEST_RESPOND_AS_CLUB from 'queries/TransferAndHostingQueries/clubTransferRequestRespondAsClub';
import CLUB_TRANSFER_REQUEST_RESPOND from 'queries/TransferAndHostingQueries/clubTransferRequestRespond';
import CLUB_HOSTING_REQUEST_RESPOND_AS_MEMBER from 'queries/TransferAndHostingQueries/clubHostingRequestRespondAsMember';
import CLUB_HOSTING_REQUEST_RESPOND_AS_CLUB from 'queries/TransferAndHostingQueries/clubHostingRequestRespondAsClub';
import CLUB_HOSTING_REQUEST_RESPOND from 'queries/TransferAndHostingQueries/clubHostingRequestRespond';
import { useMutation } from '@apollo/client';
import moment from "moment";
import RoleEnum from 'common/enums/RoleEnum'
import TransfersAndHostingStatusesEnum from 'common/enums/TransfersAndHostingStatusesEnum';

const useStyles = makeStyles((theme) => styles(theme));

const ApprovedCard = (props) => {
  const { intl, formikProps, clubMemberApprovedData, status, role, type, loading, me, refetchRequest, requestId } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpenAgree, setModalOpenAgree] = useState(false)
  const [modalOpenDisagree, setModalOpenDisagree] = useState(false)
  const [actualRole, setActualRole] = useState(null)
  const [disableButton, setDisableButton] = useState(false);

  const [clubRequestRespondAsMember] = useMutation((type === 'hosting') ? CLUB_HOSTING_REQUEST_RESPOND_AS_MEMBER : CLUB_TRANSFER_REQUEST_RESPOND_AS_MEMBER)
  const [clubRequestRespondAsClub] = useMutation((type === 'hosting') ? CLUB_HOSTING_REQUEST_RESPOND_AS_CLUB : CLUB_TRANSFER_REQUEST_RESPOND_AS_CLUB)
  const [clubRequestRespond] = useMutation((type === 'hosting') ? CLUB_HOSTING_REQUEST_RESPOND : CLUB_TRANSFER_REQUEST_RESPOND)

  const handleGiveAgreement = (result) => {
    setDisableButton(true);
    if (role === RoleEnum.ADMIN) {
      if (actualRole === 'memberResponse') {
        clubRequestRespondAsMember({
          variables: {
            id: requestId,
            memberId: formikProps?.values?.member?.id,
            result: result
          }
        }).then((response) => {
          (result === TransfersAndHostingStatusesEnum.APPROVED)
            ? enqueueSnackbar(intl.formatMessage({ id: 'transfersAndHosting.approvedCard.snackbar.agreement' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })]), { variant: 'success' })
            : enqueueSnackbar(intl.formatMessage({ id: 'transfersAndHosting.approvedCard.snackbar.disagreement' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })]), { variant: 'success' })
          setModalOpenDisagree(false);
          setModalOpenAgree(false);
          refetchRequest();
          setDisableButton(false);
        }).catch((err) => {
          console.log('[Error]: ', err);
          setDisableButton(false);
        });
      } else {
        clubRequestRespondAsClub({
          variables: {
            id: requestId,
            clubId: (actualRole === 'sourceClubResponse') ? formikProps?.values?.sourceClub?.id : formikProps?.values?.targetClub?.id,
            result: result
          }
        }).then((response) => {
          (result === TransfersAndHostingStatusesEnum.APPROVED)
            ? enqueueSnackbar(intl.formatMessage({ id: 'transfersAndHosting.approvedCard.snackbar.agreement' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })]), { variant: 'success' })
            : enqueueSnackbar(intl.formatMessage({ id: 'transfersAndHosting.approvedCard.snackbar.disagreement' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })]), { variant: 'success' })
          setModalOpenDisagree(false);
          setModalOpenAgree(false);
          refetchRequest();
          setDisableButton(false);
        }).catch((err) => {
          console.log('[Error]: ', err);
          setDisableButton(false);
        });
      }
    } else {
      clubRequestRespond({
        variables: {
          id: requestId,
          result: result
        }
      }).then((response) => {
        (result === TransfersAndHostingStatusesEnum.APPROVED)
          ? enqueueSnackbar(intl.formatMessage({ id: 'transfersAndHosting.approvedCard.snackbar.agreement' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })]), { variant: 'success' })
          : enqueueSnackbar(intl.formatMessage({ id: 'transfersAndHosting.approvedCard.snackbar.disagreement' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })]), { variant: 'success' })
        setModalOpenDisagree(false);
        setModalOpenAgree(false);
        refetchRequest();
        setDisableButton(false);
      }).catch((err) => {
        console.log('[Error]: ', err);
        setDisableButton(false);
      });
    }
  }

  const renderModalDisagreeButtons = () => {
    return (
      <>
        <Button
          disabled={disableButton}
          onClick={() => handleGiveAgreement(TransfersAndHostingStatusesEnum.REJECTED)}
          color="danger"
          round
          table
          size="sm">
          <BlockIcon /> {intl.formatMessage({ id: 'transfersAndHosting.approvedCard.modal.button.disagreement' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })])}
        </Button>
      </>
    );
  };

  const renderModalAgreeButtons = () => {
    return (
      <>
        <Button
          disabled={disableButton}
          onClick={() => handleGiveAgreement(TransfersAndHostingStatusesEnum.APPROVED)}
          color="primary"
          round
          table
          size="sm">
          <CheckIcon /> {intl.formatMessage({ id: 'transfersAndHosting.approvedCard.modal.button.agreement' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })])}
        </Button>
      </>
    );
  };

  const getAgreementResultText = (result) => {
    if (result === TransfersAndHostingStatusesEnum.APPROVED) return <span style={{ color: colors.success }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.me.agree' })}</span>
    else if (result === TransfersAndHostingStatusesEnum.REJECTED) return <span style={{ color: colors.error }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.me.disagree' })}</span>
    else return <span style={{ color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.me.waiting' })}</span>
  }

  const getAgreement = (roleColumn) => {
    let agreement = null
    if (roleColumn === 'memberResponse') agreement = clubMemberApprovedData?.memberResponse
    else if (roleColumn === 'sourceClubResponse') agreement = clubMemberApprovedData?.sourceClubResponse
    else agreement = clubMemberApprovedData?.targetClubResponse

    return (<>
      {(((!agreement && role === RoleEnum.ADMIN) || (!agreement && me === roleColumn)) && status === TransfersAndHostingStatusesEnum.PAID) ?
        <Grid item xs={12}>
          <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
            <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.approve' })}</Typography>
            <Grid container spacing={0}>
              <Grid item xs={12} align="center" style={{ padding: 0 }}>
                <Button
                  color="danger"
                  size="sm"
                  round
                  table
                  onClick={() => { setActualRole(roleColumn); setModalOpenDisagree(true) }}
                >
                  {/*<BlockIcon />*/} {intl.formatMessage({ id: 'transfersAndHosting.approvedCard.disagree' })}
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  round
                  table
                  onClick={() => { setActualRole(roleColumn); setModalOpenAgree(true) }}
                >
                  {/*<CheckIcon />*/} {intl.formatMessage({ id: 'transfersAndHosting.approvedCard.agree' })}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        :
        (agreement) ?
          <>
            <Grid item xs={12}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.approve' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {(agreement) ? getAgreementResultText(agreement?.result) : '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.date' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {(agreement?.createdAt) ? moment(agreement?.createdAt).format("DD.MM.YYYY HH:mm") : '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.readOnlyGrid}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.name' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", marginLeft: 2, marginTop: "-2px", color: colors.textReadInputs }}>
                  {(agreement?.person?.profile) ? <>{agreement?.person?.profile?.firstName} {agreement?.person?.profile?.lastName}</> : '-'}
                </Typography>
              </Box>
            </Grid>
          </>
          :
          <>
            <Grid item xs={12}>
              <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
                <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.approve' })}</Typography>
                <Typography variant="body2" style={{ fontSize: "1em", color: colors.warning }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.me.waiting' })}</Typography>
              </Box>
            </Grid>
          </>
      }
    </>

    )
  }

  if (loading) return <Loading />
  return (
    <>
      <IconCard >
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.agreement.member' })}</Typography></Grid>
              {getAgreement('memberResponse')}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.agreement.sourceClub' })}</Typography></Grid>
              {getAgreement('sourceClubResponse')}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'transfersAndHosting.approvedCard.agreement.targetClub' })}</Typography></Grid>
              {getAgreement('targetClubResponse')}
            </Grid>
          </Grid>
        </Grid >
      </IconCard>
      <Modal
        title={`${intl.formatMessage({ id: 'transfersAndHosting.modal.disagreement.title' })} ${(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })}`}
        open={modalOpenDisagree}
        onClose={() => setModalOpenDisagree(false)}
        actions={renderModalDisagreeButtons(formikProps)}
        fullWidth
      >
        {intl.formatMessage({ id: 'transfersAndHosting.modal.disagreement.body' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })])}
      </Modal>
      <Modal
        title={`${intl.formatMessage({ id: 'transfersAndHosting.modal.agreement.title' })} ${(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })}`}
        open={modalOpenAgree}
        onClose={() => setModalOpenAgree(false)}
        actions={renderModalAgreeButtons(formikProps)}
        fullWidth
      >
        {intl.formatMessage({ id: 'transfersAndHosting.modal.agreement.body' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.approvedCard.hosting' }) : intl.formatMessage({ id: 'transfers.approvedCard.transfer' })])}
      </Modal>
    </>
  );
};

export default withRouter(injectIntl(ApprovedCard));

//263