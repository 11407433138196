import gql from 'graphql-tag';

const CLUB_HOSTING_REQUEST_RESPOND_AS_MEMBER= gql`
mutation clubHostingRequestRespondAsMember($id: ID!, $memberId: ID!, $result:ResponseResult ) {
  clubHostingRequestRespondAsMember(id: $id, memberId: $memberId, result: $result) {
    id
    bid
  }
}
`;

export default CLUB_HOSTING_REQUEST_RESPOND_AS_MEMBER;