import gql from 'graphql-tag';

const PROFILE_DOCUMENTS_TABLE= gql`
query profileDocumentsTable($offset: Int, $limit: Int, $sort: [PersonDocumentsTableSortInput!], $filter: [PersonDocumentsTableFilterInput]) {
  profileDocumentsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
          bid
          id
          type
          status
          name
          validFrom
          validTo
          createdAt
          updatedAt
          downloadUrl
      }
  }
}
`;

export default PROFILE_DOCUMENTS_TABLE;