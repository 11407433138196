import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalForm: Yup.string().required(intl.formatMessage({ id: 'validation.required' })),
        ico: Yup.number()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),

        street: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        streetNumber: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        city: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        country: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),



        contactFirstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactLastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactEmail: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .matches(/^[+,0-9]+$/, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
    });

export const getValidationSchemaNew = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalForm: Yup.string().required(intl.formatMessage({ id: 'validation.required' })),
        ico: Yup.number()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),

        street: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        streetNumber: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        city: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            //.matches(/^[0-9]+$/, intl.formatMessage({ id: 'validation.number' }))
            //.min(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            //.max(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .nullable(),
        country: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactFirstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactLastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactEmail: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .matches(/^[+,0-9]+$/, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),

        statutoryStreet: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryStreetNumber: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryCity: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryPostal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            //.matches(/^[0-9]+$/, intl.formatMessage({ id: 'validation.number' }))
            //.min(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            //.max(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .nullable(),
        statutoryCountry: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryFirstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryLastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryEmail: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .matches(/^[+,0-9]+$/, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
    });

export const getValidationSchemaButton = (intl) =>
    Yup.object().shape({
        clubId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaYearAndClub = (intl) =>
    Yup.object().shape({
        clubId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        year: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaYear = (intl) =>
    Yup.object().shape({
        year: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

