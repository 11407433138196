import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import { useMutation } from '@apollo/client';
import paths from "paths";
import { getValidationSchemaInviteMember, getValidationSchemaInviteMemberWithClub } from "../validationSchema";
import { Formik } from "formik";
import FormikField from "components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import { generatePath } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { colors, variables } from "common/styles/configLayout.js";
import moment from "moment";
import IconCard from 'components-lib/IconCard/IconCard';
import CLUB_MEMBER_INVITE from "queries/MembersQueries/clubMemberInvite";
import { useLocation } from 'react-router-dom';
import CLUB_FUNCTIONS from "queries/ClubsQueries/clubFunctions";
import CLUBS from "queries/ClubsQueries/clubs";
import DISCIPLINES from "queries/ClubsQueries/disciplines";
import FunctionsTable from 'pages/Profile/DetailCards/FunctionsTable'
import { pathOr } from 'rambda';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import CheckIcon from '@material-ui/icons/Check';
import PROFILE from 'queries/ProfileQueries/profile';
import RoleEnum from 'common/enums/RoleEnum';
import InvitationFormData from "./InvitationFormData";
import FunctionsEnum from 'common/enums/FunctionsEnum';

const MemberInvitePage = (props) => {
  const { history, intl, role } = props;
  const { enqueueSnackbar } = useSnackbar();
  let location = useLocation();
  let name = location.state ? location.state?.name : null
  let id = location.state ? location.state?.id : null

  const { data: functionsData } = useQuery(CLUB_FUNCTIONS);
  const { data: profileData } = useQuery(PROFILE);
  const { data: disciplinesData } = useQuery(DISCIPLINES);
  const { data: clubsData } = useQuery(CLUBS);

  const [validateParam, setValidateParam] = useState(false)
  const [clubMemberInvite] = useMutation(CLUB_MEMBER_INVITE);
  const [functions, setFunctions] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [memberFunctions, setMemberFunctions] = useState([{ value: '', label: '' }]);
  const [memberDisciplines, setMemberDisciplines] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let clubsSelectItems = pathOr([], "clubs.items", clubsData).map(
      (c) => {
        return {
          value: c.id,
          label: c.profile.name
        };
      }
    );
    setClubs(clubsSelectItems);
  }, [clubsData]);

  useEffect(() => {
    let functionsSelectItems = pathOr([], "clubFunctions.items", functionsData).filter(i => (
      i.id !== FunctionsEnum.STATUTORY.value &&
      i.id !== FunctionsEnum.PRESIDENT.value &&
      i.id !== FunctionsEnum.BOSS.value &&
      i.id !== FunctionsEnum.GENERAL_SECRETARY.value
    )).reverse().map(
      (f) => {
        return {
          value: f.id,
          label: f.name
        };
      }
    );
    setFunctions(functionsSelectItems);
  }, [functionsData]);

  useEffect(() => {
    let disciplinesSelectItems = pathOr([], "disciplines.items", disciplinesData).map(
      (d) => {
        return {
          value: d.id,
          label: d.name
        };
      }
    );
    setDisciplines(disciplinesSelectItems);
  }, [disciplinesData]);

  const handleInvite = (values) => {
    const result = { ...values };
    delete result.__typename;
    delete result.clubs;
    delete result.isYouthWorker;
    delete result.birth;
    let tempDate = moment(values?.birth).format('YYYY-MM-DD');

    let functions = []

    for (let i = 0; i < memberFunctions.length; i++) {
      if (memberFunctions[i] !== undefined && memberFunctions[i]?.value !== '')
        functions.push({
          functionId: Number(memberFunctions?.[i]?.value),
          disciplineIds: (memberDisciplines?.length > 0) ? (memberDisciplines)?.map(disc => disc?.value) : [],
        });
    }
    let clubId = (id) ? id : (role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) ? (profileData?.me?.member?.club?.id) : values?.clubs
    if (functions.length === 0 || functions?.[0]?.functionId === null)
      enqueueSnackbar("Musite vybrať členovi aspoň jednu klubovú funkciu", { variant: 'warning' });
    else
      if ((memberFunctions?.find(item => item.value === FunctionsEnum.RACER.value || item.value === FunctionsEnum.RACER_WITH_CONTRACT.value)) && (!memberDisciplines || memberDisciplines?.length === 0)) {
        enqueueSnackbar("Pretekárovi musíte vybrať aspoň jedno odvetvie", { variant: 'warning' });
      } else {
        setDisableButton(true)
        clubMemberInvite({
          variables: {
            clubId: clubId,
            input: {
              person: {
                ...result,
                birth: (values?.birth) ? tempDate : undefined,
              },
              isYouthWorker: (values?.isYouthWorker) ? true : false,
              functions: functions
            },
          },
        }).then((response) => {
          enqueueSnackbar("Pozvánka na prijatie do klubu bola odoslaná", { variant: 'success' })
          history.push(`/admin${generatePath(paths.members.list)}`);
          setDisableButton(false)
        }).catch((err) => {
          console.log('[error]', err);
          setDisableButton(false);
        });
      }
  };

  return (<Grid container spacing={2}>
    <Grid item xs={12} lg={8}>
      <PageHeader
        withBackButton={false}
        title={"Pozvánka člena do klubu"}
        subTitle={(name) ? name : (role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) && profileData?.me?.member?.club?.profile?.name}
      />
      <Formik
        enableReinitialize
        validationSchema={(location?.state || role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) ? getValidationSchemaInviteMember(intl) : getValidationSchemaInviteMemberWithClub(intl)}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values) => { handleInvite(values); }}
        initialValues={{
          country: variables.defaultCountry,
          nationality: variables.defaultCountry,
          countryOfBirth: variables.defaultCountry,
        }}
      >
        {(formikProps) => (
          <>
            <IconCard>
              <Grid container spacing={1}>
                {!location?.state && <>
                  {role === RoleEnum.ADMIN &&
                    <Grid item xs={12}>
                      <FormikField
                        type="autocomplete"
                        options={clubs}
                        highlightChange={false}
                        name="clubs"
                        labelText="Vyberte klub *"
                        formikProps={formikProps}
                        validateParam={validateParam}
                      />
                    </Grid>
                  }
                </>
                }
                <InvitationFormData
                  formikProps={formikProps}
                  validateParam={validateParam}
                />
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ color: colors.primary, marginBottom: "7px" }}>Výber klubových funkcií</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FunctionsTable
                        formikProps={formikProps}
                        validateParam={validateParam}
                        functions={functions}
                        disciplines={disciplines}
                        memberFunctions={memberFunctions}
                        memberDisciplines={memberDisciplines}
                        setMemberFunctions={(newValue) => setMemberFunctions(newValue)}
                        setMemberDisciplines={(newValue) => setMemberDisciplines(newValue)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </IconCard>
            <Grid container spacing={1}>
              <Grid item xs={12} align="center">
                <br />
                <Button
                  disabled={disableButton}
                  color="primary"
                  size="sm"
                  round
                  onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                >
                  <CheckIcon /> Odoslať pozvánku
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Grid>
  </Grid>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
  userProfile: state.userProfile
});

export default connect(mapStateToProps)(injectIntl(MemberInvitePage));

//578
