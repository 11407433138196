import gql from 'graphql-tag';

const CLUB_NAME = gql`
query club($id: ID!){
  club(id: $id)  {
    profile{
      name
    }
  }  
}
`;

export default CLUB_NAME;