import gql from 'graphql-tag';

const REGISTER_WITH_INVITATION = gql`
mutation registerWithInvitation ($token:String!, $input: UserRegisterInput!) {
  registerWithInvitation(token:$token, input: $input) {
		token
  }
}
`;

export default REGISTER_WITH_INVITATION;

