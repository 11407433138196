import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";


export const getValidationSchemaRequestReview = (intl) =>
    Yup.object().shape({
        description: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });


export const getValidationSchemaRequestUpdate = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        businessName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalForm: Yup.string().required(intl.formatMessage({ id: 'validation.required' })),
        ico: Yup.number()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),
        address1: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        address2: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        city: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        district: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        country: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });



