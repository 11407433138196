import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import FormikField from "components-lib/FormikField/FormikField";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import { colors, } from 'common/styles/configLayout';
import { useMutation } from '@apollo/client';
import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import DOCUMENT_APPROVE from 'queries/DocumentsQueries/documentApprove';
import DOCUMENT_REJECT from 'queries/DocumentsQueries/documentReject';
import { connect } from 'react-redux';
import { changeDocumentsNumberAction } from 'redux/actions';
import DOCUMENTS_TABLE from 'queries/DocumentsQueries/documentsTable';
import { getApolloClient } from "configFiles/apollo";
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import { pathOr } from 'rambda';
import RoleEnum from 'common/enums/RoleEnum';
import DocumentStatusesEnum from 'common/enums/DocumentStatusesEnum';

const useStyles = makeStyles((theme) => styles(theme))

const CompareDocumentCard = (props) => {
    const { intl, history, role, changeDocumentsNumberAction, personData, documentData, refetch, onClose, documentId, documentStatus } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const [buttonStatus, setButtonStatus] = useState(null);
    const [documentApprove] = useMutation(DOCUMENT_APPROVE);
    const [documentReject] = useMutation(DOCUMENT_REJECT);
    const [disableButton, setDisableButton] = useState(false);

    const getDocumentsNumber = async () => {
        try {
            const result = await client.query({
                query: DOCUMENTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "eq": DocumentStatusesEnum.AWAITING
                            },
                            "relation": {
                                "ne": "Request"
                            },
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changeDocumentsNumberAction(pathOr('', ['data', 'documentsTable', 'totalCount'], result));
        } catch (error) {
            throw error;
        }
    }

    const handleDocumentApprove = (value) => {
        setDisableButton(true)
        documentApprove({
            variables: {
                id: documentId,
                input: {
                    validFrom: value?.validFrom,
                    validTo: value?.validTo
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.approve" }), { variant: 'success' });
            setDisableButton(false)
            onClose();
            refetch();
            getDocumentsNumber();
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false)
        });
    }

    const handleDocumentReject = () => {
        setDisableButton(true)
        documentReject({
            variables: {
                id: documentId,
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.reject" }), { variant: 'success' });
            setDisableButton(false)
            onClose();
            refetch();
            getDocumentsNumber();
        }).catch((err) => {
            setDisableButton(false)
            console.log('[Error]: ', err);
        });
    }
    return (<>
        <Formik
            initialValues={{ ...personData, ...documentData }}
            enableReinitialize
            onSubmit={(value) => (buttonStatus === "Approve") ? handleDocumentApprove(value) : handleDocumentReject()}
        >
            {(formikProps) => (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <FormikField
                                name="validFrom"
                                labelText={intl.formatMessage({ id: "documents.table.validFrom" })}
                                type="datePicker"
                                formikProps={formikProps}
                                readMode={(role === RoleEnum.ADMIN && documentStatus === DocumentStatusesEnum.AWAITING) ? false : true}
                                highlightChange
                                validateParam={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormikField
                                name="validTo"
                                labelText={intl.formatMessage({ id: "documents.table.validTo" })}
                                type="datePicker"
                                formikProps={formikProps}
                                readMode={(role === RoleEnum.ADMIN && documentStatus === DocumentStatusesEnum.AWAITING) ? false : true}
                                highlightChange
                                validateParam={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormikField
                                name="createdAt"
                                labelText={intl.formatMessage({ id: "documents.table.createdAt" })}
                                type="dateTimePicker"
                                formikProps={formikProps}
                                readMode={true}
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12}><Typography variant="body1" style={{ color: colors.textBody }} >Osoba</Typography></Grid>
                        <Grid item xs={12} md={6}>
                            <FormikField
                                name="firstName"
                                labelText={intl.formatMessage({ id: 'personal.firstName' })}
                                formikProps={formikProps}
                                readMode={true}
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormikField
                                name="lastName"
                                labelText={intl.formatMessage({ id: 'personal.lastName' })}
                                formikProps={formikProps}
                                readMode={true}
                                highlightChange
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormikField
                                name="personalId"
                                labelText={intl.formatMessage({ id: 'profile.personalDataCard.idNumber' })}
                                formikProps={formikProps}
                                readMode={true}
                                type="number"
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormikField
                                name="birth"
                                labelText={intl.formatMessage({ id: 'profile.personalDataCard.birth' })}
                                formikProps={formikProps}
                                readMode={true}
                                type="datePicker"
                                highlightChange
                            />
                        </Grid>
                        <br />
                        <Grid item xs={12} >
                            <Divider style={{ backgroundColor: colors.labelAndBorderInputs }} />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>

                            {(documentStatus === DocumentStatusesEnum.AWAITING) &&
                                <Grid item xs={12}>
                                    <Box style={{ padding: "10px", backgroundColor: colors.warning }} className={classes.readOnlyGrid}>
                                        <Typography variant="body2" style={{ color: 'black' }}>{intl.formatMessage({ id: "documents.compareCard.status.awaiting" })}</Typography>
                                    </Box>
                                </Grid>
                            }
                            {(documentStatus === DocumentStatusesEnum.REJECTED) &&
                                <Grid item xs={12}>
                                    <Box style={{ padding: "10px", backgroundColor: colors.error }} className={classes.readOnlyGrid}>
                                        <Typography variant="body2" style={{ color: 'black' }}>{intl.formatMessage({ id: "documents.compareCard.status.rejected" })}</Typography>
                                    </Box>
                                </Grid>
                            }
                            {(documentStatus === DocumentStatusesEnum.APPROVED) &&
                                <Grid item xs={12}>
                                    <Box style={{ padding: "10px", backgroundColor: colors.success }} className={classes.readOnlyGrid}>
                                        <Typography variant="body2" style={{ color: 'black' }}>{intl.formatMessage({ id: "documents.compareCard.status.approved" })}</Typography>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                        {(documentStatus === DocumentStatusesEnum.AWAITING) &&
                            <>
                                {(role === RoleEnum.ADMIN) &&
                                    <>

                                        <Box style={{
                                            textAlign: "center",
                                            position: "absolute",
                                            bottom: "10px"
                                        }}>
                    
                                            <Button
                                                color="primary"
                                                size="sm"
                                                disabled={disableButton}
                                                round
                                                onClick={() => { setButtonStatus("Approve"); formikProps.handleSubmit("Approve"); onClose() }}
                                            >
                                                <CheckIcon /> {intl.formatMessage({ id: "documents.button.approve" })}
                                            </Button>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                round
                                                disabled={disableButton}
                                                onClick={() => { setButtonStatus("Reject"); formikProps.handleSubmit("Reject"); onClose() }}
                                            >
                                                <BlockIcon /> {intl.formatMessage({ id: "documents.button.reject" })}
                                            </Button>
                                        </Box>
                                    </>
                                }
                            </>
                        }
                    </Grid>

                </>
            )}
        </Formik >
    </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeDocumentsNumberAction: (value) => dispatch(changeDocumentsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompareDocumentCard));