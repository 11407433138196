import gql from 'graphql-tag';

const DOCUMENT_REJECT= gql`
mutation documentReject ($id:ID!) {
  documentReject(id:$id) {
  id
  }
}
`;

export default DOCUMENT_REJECT;