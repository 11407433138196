import gql from 'graphql-tag';

const CLUB_FUNCTIONS = gql`
query clubFunctions($offset: Int, $limit: Int, $sort: [ClubFunctionSortInput!], $filter: [ClubFunctionFilterInput]) {
  clubFunctions(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount
      items {
          id
          name
          isLicensedFunction
      }
  }
}
`;

export default CLUB_FUNCTIONS;