import gql from 'graphql-tag';

const REGISTER_WITH_EMAIL = gql`
mutation registerWithEmail ($email:EmailAddress!, $input: UserRegisterInput!) {
  registerWithEmail(email:$email, input: $input) {
   	id
    bid
  }
}
`;

export default REGISTER_WITH_EMAIL;

