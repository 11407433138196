import React from 'react';
import { injectIntl } from 'react-intl';
import moment from "moment";
import QueryTableActivity from 'components-lib/QueryTable/QueryTableActivity';
import ActivityTypesEnum from 'common/enums/ActivityTypesEnum';

export const ActivityTable = (props) => {
    const { queryVariables, query, intl, defaultFilter = null, numberOfRows = 25, queryDataPath } = props

    return (
        <QueryTableActivity
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            link={(rowData) => (rowData.entities)}
            columnsWidth={['12%', "12%", '12%', '12%', '12%', '12%']}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'table.date' }),
                    accessor: (rowData) => rowData.date !== null ? moment(rowData.date).format("DD.MM.YYYY HH:mm:ss") : "",
                    sortKey: 'date',
                    filterKey: 'date',
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'activities.table.type' }),
                    accessor: (rowData) => (rowData.type) ? intl.formatMessage({ id: `activities.table.type.${rowData.type}` }) : '',
                    sortKey: 'type',
                    filterKey: 'type',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: Object.values(ActivityTypesEnum).map((item) => {
                        return ({
                            value: item,
                            label: intl.formatMessage({ id: `activities.table.type.${item}` })
                        })
                    })
                },
                {
                    Header: intl.formatMessage({ id: 'activities.table.club' }),
                    accessor:  (rowData) => (rowData.club) ? <>({rowData.club}):</> : ':',
                    sortKey: "club",
                    filterKey: 'club',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'activities.table.person' }),
                    accessor: 'person',
                    sortKey: "person",
                    filterKey: 'person',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'activities.table.activity' }),
                    accessor: "text",
                    sortKey: "text",
                    filterKey: "text",
                    filterOperator: 'contains'
                },
            ]}
        />
    )
}

export default injectIntl(ActivityTable);