import gql from 'graphql-tag';

const FORGOTTEN_PASSWORD = gql`
mutation passwordRequestReset ($email: EmailAddress!) {
  passwordRequestReset(email: $email)
}
`;

export default FORGOTTEN_PASSWORD;

