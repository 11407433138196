import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";

export const getValidationSchemaClubCreate = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        businessName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalForm: Yup.string().required(intl.formatMessage({ id: 'validation.required' })),
        ico: Yup.number()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        address1: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        address2: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        city: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        country: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        district: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        statutoryEmail: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryFirstName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        statutoryLastName: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        isYouthWorker: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaClubCreateForeign = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        city: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        country: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        businessName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });


export const getValidationSchemaClubDetail = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        businessName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        legalForm: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        district: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        ico: Yup.number()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),
        address1: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        address2: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        city: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        postal: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        country: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaDocuments = (intl) =>
    Yup.object().shape({
        type: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });
