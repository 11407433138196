import gql from 'graphql-tag';

const CATEGORIES_BY_MEMBER = gql`
query categoriesByMember($memberId: ID!, $functionId: ID!, $disciplineId: ID) {
  categoriesByMember(memberId: $memberId, functionId: $functionId, disciplineId: $disciplineId) {
		id
    name
    UCICategory
  }
}
`;

export default CATEGORIES_BY_MEMBER;