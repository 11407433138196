import gql from 'graphql-tag';

const CLUB_MEMBER_UPDATE = gql`
mutation clubMemberUpdate($id: ID!, $input: ClubMemberUpdateInput!) {
  clubMemberUpdate(id: $id, input: $input) {
    id
  }
}
`;

export default CLUB_MEMBER_UPDATE;

