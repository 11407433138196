import gql from 'graphql-tag';

const TRANSFER_AND_HOSTING_DETAIL = gql`
query request($id: ID!) {
  request(id: $id) {
    id
    bid
    type
    status
    expiresIn
    expiresAt
    createdAt
    updatedAt
    deletedAt
    data {
    ... on ClubTransferRequest {
    	from
      sourceClub {
        id
        bid
        profile{
          name
          address1
          address2
          postal
          city
          district
          country
        }
        chairman{
          id
          bid
          person{
            profile{
              firstName
              lastName
              phone
              email
            }
          }
        }
        statutory{
          id
          bid
          person{
            profile{
              firstName
              lastName
              phone
              email
            }
          }
        }
      }
      targetClub {
        id
        bid
        profile{
          name
          address1
          address2
          postal
          city
          district
          country
        }
        chairman{
          id
          bid
          person{
            profile{
              firstName
              lastName
              phone
              email
            }
          }
        }
        statutory{
          id
          bid
          person{
            profile{
              firstName
              lastName
              phone
              email
            }
          }
        }
      }
      member{
        id
        bid
        uciId
        federationId
        person{
          profile{
            firstName
            lastName
            birth
            email
            phone     
          }
        }
        functions{
          function{
            id
            name
          }
          disciplines{
            id
            name
          }
        }
      }
    memberResponse{
      result
      createdAt
      person{
        profile{
          firstName
          lastName
        }
      }
    }
    sourceClubResponse{
      result
      createdAt
      person{
        profile{
          firstName
          lastName
        }
      }
    }
    targetClubResponse{
      result
      createdAt
      person{
        profile{
          firstName
          lastName
        }
      }
    }
      payment{
        id
        bid
        status
        purpose
        method
        amount
        description
        bankIBAN
        variableSymbol
        invoiceNumber
        payBySquare
        createdAt
        dueDate
        paidAt
        club{
          id
          bid
          profile{
            name
          }
        }
      }
    }
    ... on ClubHostingRequest {
   		from
      to
      sourceClub {
        id
        bid
        profile{
          name
          address1
          address2
          postal
          city
          district
          country
        }
        members{
          id
          bid
          functions{
            function{
              id
              name
            }
          }
          person{
            profile{
              firstName
              lastName
              email
              phone
            }
          }
        }
      }
      targetClub {
        id
        bid
        profile{
          name
          address1
          address2
          postal
          city
          district
          country
        }
        members{
          id
          bid
          functions{
            function{
              id
              name
            }
          }
          person{
            profile{
              firstName
              lastName
              email
              phone
            }
          }
        }
      }
      member{
        id
        bid
        uciId
        federationId
        person{
          profile{
            firstName
            lastName
            birth
            email
            phone     
          }
        }
        functions{
          function{
            id
            name
          }
          disciplines{
            id
            name
          }
        }
      }
    memberResponse{
      result
      createdAt
      person{
        profile{
          firstName
          lastName
        }
      }
    }
    sourceClubResponse{
      result
      createdAt
      person{
        profile{
          firstName
          lastName
        }
      }
    }
    targetClubResponse{
      result
      createdAt
      person{
        profile{
          firstName
          lastName
        }
      }
    }
            payment{
        id
        bid
        status
        purpose
        method
        amount
        description
        bankIBAN
        variableSymbol
        invoiceNumber
        payBySquare
        createdAt
        dueDate
        paidAt
        club{
          id
          bid
          profile{
            name
          }
        }
      }
    }
    }
  }
}
`;

export default TRANSFER_AND_HOSTING_DETAIL;