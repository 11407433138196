import gql from 'graphql-tag';

const COUNTRIES = gql`
query countries( $offset: Int, $limit: Int, $sort: [CountrySortInput!], $filter: [CountryFilterInput]){
  countries(offset: $offset, limit: $limit, sort:$sort, filter: $filter ) {
    items {
      id
      code
      name
      order
    }
  }
}
`;

export default COUNTRIES;