const countries = {
    'select.AD': 'Andorra',
    'select.AE': 'دولة الإمارات العربية المتحدة',
    'select.AF': 'افغانستان',
    'select.AG': 'Antigua and Barbuda',
    'select.AI': 'Anguilla',
    'select.AL': 'Shqipëria',
    'select.AM': 'Հայաստան',
    'select.AO': 'Angola',
    'select.AQ': 'Antarctica',
    'select.AR': 'Argentina',
    'select.AS': 'American Samoa',
    'select.AT': 'Österreich',
    'select.AU': 'Australia',
    'select.AW': 'Aruba',
    'select.AX': 'Åland',
    'select.AZ': 'Azərbaycan',
    'select.BA': 'Bosna i Hercegovina',
    'select.BB': 'Barbados',
    'select.BD': 'Bangladesh',
    'select.BE': 'België',
    'select.BF': 'Burkina Faso',
    'select.BG': 'България',
    'select.BH': '‏البحرين',
    'select.BI': 'Burundi',
    'select.BJ': 'Bénin',
    'select.BL': 'Saint-Barthélemy',
    'select.BM': 'Bermuda',
    'select.BN': 'Negara Brunei Darussalam',
    'select.BO': 'Bolivia',
    'select.BQ': 'Bonaire',
    'select.BR': 'Brasil',
    'select.BS': 'Bahamas',
    'select.BT': 'ʼbrug-yul',
    'select.BV': 'Bouvetøya',
    'select.BW': 'Botswana',
    'select.BY': 'Белару́сь',
    'select.BZ': 'Belize',
    'select.CA': 'Canada',
    'select.CC': 'Cocos (Keeling) Islands',
    'select.CD': 'République démocratique du Congo',
    'select.CF': 'Ködörösêse tî Bêafrîka',
    'select.CG': 'République du Congo',
    'select.CH': 'Schweiz',
    'select.CI': "Côte d'Ivoire",
    'select.CK': 'Cook Islands',
    'select.CL': 'Chile',
    'select.CM': 'Cameroon',
    'select.CN': '中国',
    'select.CO': 'Colombia',
    'select.CR': 'Costa Rica',
    'select.CU': 'Cuba',
    'select.CV': 'Cabo Verde',
    'select.CW': 'Curaçao',
    'select.CX': 'Christmas Island',
    'select.CY': 'Κύπρος',
    'select.CZ': 'Česká republika',
    'select.DE': 'Deutschland',
    'select.DJ': 'Djibouti',
    'select.DK': 'Danmark',
    'select.DM': 'Dominica',
    'select.DO': 'República Dominicana',
    'select.DZ': 'الجزائر',
    'select.EC': 'Ecuador',
    'select.EE': 'Eesti',
    'select.EG': 'مصر‎',
    'select.EH': 'الصحراء الغربية',
    'select.ER': 'ኤርትራ',
    'select.ES': 'España',
    'select.ET': 'ኢትዮጵያ',
    'select.FI': 'Suomi',
    'select.FJ': 'Fiji',
    'select.FK': 'Falkland Islands',
    'select.FM': 'Micronesia',
    'select.FO': 'Føroyar',
    'select.FR': 'France',
    'select.GA': 'Gabon',
    'select.GB': 'United Kingdom',
    'select.GD': 'Grenada',
    'select.GE': 'საქართველო',
    'select.GF': 'Guyane française',
    'select.GG': 'Guernsey',
    'select.GH': 'Ghana',
    'select.GI': 'Gibraltar',
    'select.GL': 'Kalaallit Nunaat',
    'select.GM': 'Gambia',
    'select.GN': 'Guinée',
    'select.GP': 'Guadeloupe',
    'select.GQ': 'Guinea Ecuatorial',
    'select.GR': 'Ελλάδα',
    'select.GS': 'South Georgia',
    'select.GT': 'Guatemala',
    'select.GU': 'Guam',
    'select.GW': 'Guiné-Bissau',
    'select.GY': 'Guyana',
    'select.HK': '香港',
    'select.HM': 'Heard Island and McDonald Islands',
    'select.HN': 'Honduras',
    'select.HR': 'Hrvatska',
    'select.HT': 'Haïti',
    'select.HU': 'Magyarország',
    'select.ID': 'Indonesia',
    'select.IE': 'Éire',
    'select.IL': 'יִשְׂרָאֵל',
    'select.IM': 'Isle of Man',
    'select.IN': 'भारत',
    'select.IO': 'British Indian Ocean Territory',
    'select.IQ': 'العراق',
    'select.IR': 'ایران',
    'select.IS': 'Ísland',
    'select.IT': 'Italia',
    'select.JE': 'Jersey',
    'select.JM': 'Jamaica',
    'select.JO': 'الأردن',
    'select.JP': '日本',
    'select.KE': 'Kenya',
    'select.KG': 'Кыргызстан',
    'select.KH': 'Kâmpŭchéa',
    'select.KI': 'Kiribati',
    'select.KM': 'Komori',
    'select.KN': 'Saint Kitts and Nevis',
    'select.KP': '북한',
    'select.KR': '대한민국',
    'select.KW': 'الكويت',
    'select.KY': 'Cayman Islands',
    'select.KZ': 'Қазақстан',
    'select.LA': 'ສປປລາວ',
    'select.LB': 'لبنان',
    'select.LC': 'Saint Lucia',
    'select.LI': 'Liechtenstein',
    'select.LK': 'śrī laṃkāva',
    'select.LR': 'Liberia',
    'select.LS': 'Lesotho',
    'select.LT': 'Lietuva',
    'select.LU': 'Luxembourg',
    'select.LV': 'Latvija',
    'select.LY': '‏ليبيا',
    'select.MA': 'المغرب',
    'select.MC': 'Monaco',
    'select.MD': 'Moldova',
    'select.ME': 'Црна Гора',
    'select.MF': 'Saint-Martin',
    'select.MG': 'Madagasikara',
    'select.MH': 'M̧ajeļ',
    'select.MK': 'Северна Македонија',
    'select.ML': 'Mali',
    'select.MM': 'မြန်မာ',
    'select.MN': 'Монгол улс',
    'select.MO': '澳門',
    'select.MP': 'Northern Mariana Islands',
    'select.MQ': 'Martinique',
    'select.MR': 'موريتانيا',
    'select.MS': 'Montserrat',
    'select.MT': 'Malta',
    'select.MU': 'Maurice',
    'select.MV': 'Maldives',
    'select.MW': 'Malawi',
    'select.MX': 'México',
    'select.MY': 'Malaysia',
    'select.MZ': 'Moçambique',
    'select.NA': 'Namibia',
    'select.NC': 'Nouvelle-Calédonie',
    'select.NE': 'Niger',
    'select.NF': 'Norfolk Island',
    'select.NG': 'Nigeria',
    'select.NI': 'Nicaragua',
    'select.NL': 'Nederland',
    'select.NO': 'Norge',
    'select.NP': 'नपल',
    'select.NR': 'Nauru',
    'select.NU': 'Niuē',
    'select.NZ': 'New Zealand',
    'select.OM': 'عمان',
    'select.PA': 'Panamá',
    'select.PE': 'Perú',
    'select.PF': 'Polynésie française',
    'select.PG': 'Papua Niugini',
    'select.PH': 'Pilipinas',
    'select.PK': 'Pakistan',
    'select.PL': 'Polska',
    'select.PM': 'Saint-Pierre-et-Miquelon',
    'select.PN': 'Pitcairn Islands',
    'select.PR': 'Puerto Rico',
    'select.PS': 'فلسطين',
    'select.PT': 'Portugal',
    'select.PW': 'Palau',
    'select.PY': 'Paraguay',
    'select.QA': 'قطر',
    'select.RE': 'La Réunion',
    'select.RO': 'România',
    'select.RS': 'Србија',
    'select.RU': 'Россия',
    'select.RW': 'Rwanda',
    'select.SA': 'العربية السعودية',
    'select.SB': 'Solomon Islands',
    'select.SC': 'Seychelles',
    'select.SD': 'السودان',
    'select.SE': 'Sverige',
    'select.SG': 'Singapore',
    'select.SH': 'Saint Helena',
    'select.SI': 'Slovenija',
    'select.SJ': 'Svalbard og Jan Mayen',
    'select.SK': 'Slovensko',
    'select.SL': 'Sierra Leone',
    'select.SM': 'San Marino',
    'select.SN': 'Sénégal',
    'select.SO': 'Soomaaliya',
    'select.SR': 'Suriname',
    'select.SS': 'South Sudan',
    'select.ST': 'São Tomé e Príncipe',
    'select.SV': 'El Salvador',
    'select.SX': 'Sint Maarten',
    'select.SY': 'سوريا',
    'select.SZ': 'Swaziland',
    'select.TC': 'Turks and Caicos Islands',
    'select.TD': 'Tchad',
    'select.TF': 'Territoire des Terres australes et antarctiques fr',
    'select.TG': 'Togo',
    'select.TH': 'ประเทศไทย',
    'select.TJ': 'Тоҷикистон',
    'select.TK': 'Tokelau',
    'select.TL': 'Timor-Leste',
    'select.TM': 'Türkmenistan',
    'select.TN': 'تونس',
    'select.TO': 'Tonga',
    'select.TR': 'Türkiye',
    'select.TT': 'Trinidad and Tobago',
    'select.TV': 'Tuvalu',
    'select.TW': '臺灣',
    'select.TZ': 'Tanzania',
    'select.UA': 'Україна',
    'select.UG': 'Uganda',
    'select.UM': 'United States Minor Outlying Islands',
    'select.US': 'United States',
    'select.UY': 'Uruguay',
    'select.UZ': 'O‘zbekiston',
    'select.VA': 'Vaticano',
    'select.VC': 'Saint Vincent and the Grenadines',
    'select.VE': 'Venezuela',
    'select.VG': 'British Virgin Islands',
    'select.VI': 'United States Virgin Islands',
    'select.VN': 'Việt Nam',
    'select.VU': 'Vanuatu',
    'select.WF': 'Wallis et Futuna',
    'select.WS': 'Samoa',
    'select.XK': 'Republika e Kosovës',
    'select.YE': 'اليَمَن',
    'select.YT': 'Mayotte',
    'select.ZA': 'South Africa',
    'select.ZM': 'Zambia',
    'select.ZW': 'Zimbabwe'
};

export default countries