import gql from 'graphql-tag';

const PASSWORD_RESET = gql`
mutation passwordReset ($token: String!, $newPassword: String!) {
  passwordReset(token: $token, newPassword:$newPassword) 
}
`;

export default PASSWORD_RESET;

